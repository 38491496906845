import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cashmemo-online-payment-details',
    templateUrl: './cashmemo-online-payment-details.component.html',
    styleUrls: ['./cashmemo-online-payment-details.component.css']
})
export class CashmemoOnlinePaymentDetailsComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "cshmemoonlndtls";
    dtTriggerCashmemoOnlinePaymentDetails: Subject<any> = new Subject();
    dtOptionsCashmemoOnlinePaymentDetails: any;
    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    selectedConsigneeId: any;
    showSpinnerForAction = false;
    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoCollectionManAll: UserDataDto = new UserDataDto();
    cashmemoForGet: CashMemoDto = new CashMemoDto();
    selectedFromDate: any;
    selectedToDate: any;
    selectedCollectionMan: any;
    selectedConsigneeName: any;
    toSetDestForConsignee: any;
    toSetDestForColMan: any;
    selecDestinationForTemp: any;
    public modelDestinationFotGet: any;
    cashmemoOnlinePaymentDetailLists: any;
    cashmemoUpdate: CashMemoDto = new CashMemoDto();
    cashmemoRet: any;
    public modelConsigneeNameId: any;

    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consigneeNameTA: Array<PartyMasterDto> = [];
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    consigneeNameTATempForDest: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    collectionManAllOption: UserDataDto = new UserDataDto();
    collectionManTA: Array<UserDataDto> = [];
    collectionManTATemp: Array<UserDataDto> = [];
    collectionManTATempForDest: Array<UserDataDto> = [];

    focusCollectionManTA$ = new Subject<string>();
    collectionManSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManTA
                : this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
    collectionManDataList: any;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    colmrIsChecked = false;

    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;


    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService,
        private memoReport: MemoReport) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.toSetDestForConsignee = this.userDataDtoReturnSession.mainStation;
            this.toSetDestForColMan = this.userDataDtoReturnSession.mainStation;
            this.getConsigneeDetails(false);
            this.getCollectionManDetailsList(false);
        }
    }

    ngOnInit() {
        this.dtOptionsCashmemoOnlinePaymentDetails = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "Cashmemo Online Payment Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var amount = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var neftAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var upiAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cashAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var amountCollected = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var article = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(amount);
                $(api.column(7).footer()).html(neftAmt);
                $(api.column(8).footer()).html(upiAmt);
                $(api.column(9).footer()).html(cashAmt);
                $(api.column(10).footer()).html(amountCollected);
                $(api.column(15).footer()).html(article);
                $(api.column(17).footer()).html(toPay);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerCashmemoOnlinePaymentDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerCashmemoOnlinePaymentDetails.next();
    }

    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    $("#" + this.pageId + "consigneeName").val('All');
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.selecDestinationForTemp = this.toSetDestForConsignee;
                    if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
                        this.consigneeNameTATempForDest = this.consigneeNameTA;
                    }
                    this.toSetDestForConsignee = '';
                    this.consigneeNameTATemp = this.consigneeNameTA;

                    $("#" + this.pageId + "consigneeName").val('All');

                    console.log(this.consigneeNameTA, this.consigneeNameTATemp);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    getDetailsForCollectionMasterRead(destShow) {
        this.userDtoCollectionMan = new UserDataDto();
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";
    }

    getCollectionManDetailsList(destShow) {
        this.collectionManDataList = [];
        this.collectionManTA = [];
        this.getDetailsForCollectionMasterRead(destShow);
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Collection Man Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.collectionManDataList = [];
                    this.collectionManTA = [];
                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    $("#" + this.pageId + "collectionMan").val('All');
                } else {
                    console.log(response);
                    this.collectionManDataList = response;
                    console.log(this.collectionManDataList);
                    this.collectionManTA = [];
                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    for (let i = 0; i < this.collectionManDataList.length; i++) {
                        this.collectionManTA.push(this.collectionManDataList[i]);
                    }
                    this.selecDestinationForTemp = this.toSetDestForColMan;
                    if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
                        this.collectionManTATempForDest = this.collectionManTA;
                    }
                    this.toSetDestForColMan = '';
                    console.log(this.collectionManTATempForDest, this.selecDestinationForTemp)
                    this.collectionManTATemp = this.collectionManTA;

                    $("#" + this.pageId + "collectionMan").val('All');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
            }, () => console.log('done');
    };

    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
    }

    setCashmemoOnlinePaymentDtls() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();

        this.cashmemoForGet = new CashMemoDto();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return false;
        } else {
            this.cashmemoForGet.fromdate = this.selectedFromDate;
            this.cashmemoForGet.todate = this.selectedToDate;
            this.cashmemoForGet.tillDateMode = "false";
        }

        this.cashmemoForGet.reportMode = 'All';
        this.cashmemoForGet.mode = 'amt';
        this.cashmemoForGet.companyId = this.userDataDtoReturnSession.companyId;
        this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;

        if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
            this.cashmemoForGet.collectionMan = this.selectedCollectionMan;
        } else {
            this.cashmemoForGet.collectionMan = 'All';
        }
        if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
            this.cashmemoForGet.consigneeId = this.selectedConsigneeId;
            this.cashmemoForGet.consigneeName = this.selectedConsigneeName;
        } else {
            this.cashmemoForGet.consigneeId = this.selectedConsigneeId;
            this.cashmemoForGet.consigneeName = 'All';
        }
        console.log(this.cashmemoForGet);
        this.getCashmemoOnlinePaymentDtls();
    }

    getCashmemoOnlinePaymentDtls = () => {
        this.showSpinnerForAction = true;
        this.memoReport.getCashmemoOnlinePaymentDetails(this.cashmemoForGet).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "cashmemoOnlinePaymentDetailsTableId").DataTable().destroy();
                this.cashmemoOnlinePaymentDetailLists = [];
                if (response.consigneeWiseMemo.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.cashmemoOnlinePaymentDetailLists = response.consigneeWiseMemo;
                }
                this.dtTriggerCashmemoOnlinePaymentDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Cashmemo Online Payment Details", "info");
            }, () => console.log('done');
    };

    clearDetails() {
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "collectionMan").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedCollectionMan = '';
        this.selectedConsigneeName = '';
        this.cashmemoForGet = new CashMemoDto();
        $("#" + this.pageId + "cashmemoOnlinePaymentDetailsTableId").DataTable().destroy();
        this.cashmemoOnlinePaymentDetailLists = [];
        this.dtTriggerCashmemoOnlinePaymentDetails.next();
        this.modelConsigneeNameId = '';
    }

    userUpdateDetails(cashmemoOnlinePaymentData) {
        this.cashmemoUpdate = new CashMemoDto();
        this.cashmemoUpdate.memoNumber = cashmemoOnlinePaymentData.memoNumber;
        this.cashmemoUpdate.userName = this.userDataDtoReturnSession.userId;
        console.log(this.cashmemoUpdate);
        this.updateCashmemoOnlinePaymentDetails();
    }

    updateCashmemoOnlinePaymentDetails() {
        swal({
            title: "Confirm Update",
            text: "Sure you want to update the seleted payment details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.memoReport.updateCashmemoOnlinePaymentDetails(this.cashmemoUpdate).
                    subscribe((data) => {
                        this.cashmemoRet = data;
                        if (this.cashmemoRet.status == "Success") {
                            swal("Success", "Cashmemo Online Payment Details Updated Successfully!", "info");
                            this.setCashmemoOnlinePaymentDtls();
                        } else {
                            swal("Failed", "Failed to Update the Cashmemo Online Payment Details!", "error");
                        }
                    }), (error) => {
                        swal("Error", "Server problem occurred while Updating the Cashmemo Online Payment Details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }
}
