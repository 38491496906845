import { FortNightStmtDto } from "./FortNightStmt-dto";

export class HireSlipDto {
    hireslipnumber: string;
    fromstation: string;
    tostation: string;
    supplierid: string;
    payableat: string;
    drivername: string;
    driverlicencenumber: string;
    loadedby: string;
    enteryby: string;
    financialyear: string;
    paymenttype: string;
    chequeno: string;
    bankname: string;
    remarks: string;
    lastupdatedby: string;
    invoiceNumber: string;
    vehicleNumber: string;
    totalhire: number;
    advance: number;
    balance: number;
    departuredate: Date;
    hireslipdate: Date;
    entereddate: Date;
    scheduleddate: Date;
    arrivaldate: Date;
    chequedate: Date;
    lastupdateddate: Date;
    driverMobileNumber: string;
    driverrewardingAmt: number;
    //List<String> list;
    tinNumber: string;
    vehiCompName: string;
    driverMobno: number;
    totalArticles: number;
    totalLRs: number;
    departureTime: string;
    scheduledTime: string;
    arrivalTime: string;
    actWeight: number;
    kantaWeight: number;
    gurWeight: number;
    status: string;
    mode: string;
    uploadBy: string;
    fromStation: string;
    toStation: string;
    enteredby: string;
    financialYear: string;
    //List<String> listLrsRem;
    supId: number;
    branch: string;
    stocksAt: string;
    //List<String> listLrsShortage;
    //List<String> listTotalLrs;
    //List<HireSlipDto> listShort;
    //List<HireSlipDto> listExcess;
    //List<HireSlipDto> listOpen;
    //List<HireSlipDto> listWet;
    durationInHours: string;
    bufferedTimeInHours: string;
    rewardingAmount: number;
    column1: string;
    column2: string;
    column3: string;
    vehiCompAddress: string;
    vehiCompPanNo: string;
    brokerName: string;
    brokerPanNo: string;
    companyName: string;
    toPay: number;
    companyImgUrl: string;
    barcodeImgUrl: string;
    messageText: string;
    searchField: string;
    searchBy: string;
    daysToReach: string;
    size: number;
    depDateYear: number;
    depDateMonth: number;
    depDateDate: number;
    depHours: number;
    depMins: number;
    arrDateYear: number;
    arrDateMonth: number;
    arrDateDate: number;
    arrHours: number;
    arrMins: number;
    inDays: number;
    inMinutes: number;
    inSeconds: number;
    inMilliSeconds: number;
    fromDate: string;
    toDate: string;
    role: string;
    inMilliSecondsLong: number;
    // pdf columns
    pdfColumn1: string;
    pdfColumn2: string;
    pdfColumn3: string;
    pdfColumn4: string;
    pdfColumn5: string;
    pdfColumn6: string;
    pdfColumn7: string;
    pdfColumn8: string;
    pdfColumn9: string;
    pdfColumn10: string;
    pdfColumnGroupBy: string;
    isExcuseTime: boolean;
    excuseTime: string;
    buffTimeInHr: string;
    buffTimeInMin: number;
    suppliername: string;
    contactPerson: string;
    officeType: string;
    type: string;
    doorOpen: number;
    vehiHeight: number;
    doorDelivery: number;
    extraWeight: number;
    chkPost: number;
    otherChgs: number;
    baseHireAmt: number;
    invoicedate: Date;
    supplierpanno: string;
    deliveryPoints: number;
    waitingHrs: number;
    totDelvStat: string;
    lesslatearrival: number;
    lessunloadhamali: number;
    lessmunishiana: number;
    preferredDuration: string;
    bufferTime: string;
    isRewarded: boolean;
    isLryBalancePaid: boolean;
    isScanned: boolean;
    isMultiPntDelv: boolean;
    firstPntDelv: string;
    //List < String > listOfInvoice;
    //HashMap < String, String > hashMap;
    chargedWt: number;
    totalAmt: number;
    date: string;
    paid: number;
    sundayWorking: string;
    deductionAmt: number;
    suppAddress: string;
    suppPhNo: string;
    driverLicState: string;
    driverLicStateCode: string;
    driverAdd: string;
    driverPinCode: string;
    vehiclePinCode: string;
    vehOwnerState: string;
    vehOwnerStateCode: string;
    entryCP: string;
    entryCPCode: string;
    exitCP: string;
    exitCPCode: string;
    expExitDate: Date;
    expExitDays: string;
    bookingDate: Date;
    lrNumber: string;
    consigneeName: string;
    consigneeTin: string;
    consigneeState: string;
    consigneeStateCode: string;
    consigneeAdd: string;
    consignorName: string;
    consignorTin: string;
    consignorState: string;
    consignorStateCode: string;
    consignorAdd: string;
    commodityName: string;
    commodityDesc: string;
    commodityCode: string;
    goodsQuantity: number;
    goodsValue: number;
    //List < HireSlipDto > hireSlipDtoList;
    invoiceType: string;
    reportMode: string;
    truckType: string;
    paymentDate: Date;
    stationList: string[];
    isLryAdvPaid: boolean;
    excludeSunday: boolean;
    reachedDuration: string;
    amount: number;
    fromDateInDate: Date;
    toDateInDate: Date;
    hireSlipBalance: boolean;
    invoiceDtStr: string;
    depatureDtStr: string;
    arrivalDtStr: string;
    paymentDtStr: string;
    barcodeValue: string;
    driverId: number;
    mainStation: string;
    mainBookingStation: string;
    hireSlipDateStr: string;
    advChqAmt: string;
    cashAmt: number;
    advTotalPaid: number;
    advBalToBePaid: number;
    advTotalChqAmt: number;
    advTotalPdcAmt: number;
    advChqNumber: string;
    advChqBankName: string;
    advPdcChqAmt: string;
    advPdcChqNumber: string;
    advPdcChqDueDate: string;
    advPdcChqBankName: string;
    advHireAmtPaidDate: Date;
    loaderHead: string;
    loadingHamali: number;
    unloadingHamali: number;
    loadingHamaliUnit: string;
    unloadingHamaliUnit: string;
    commsId: number;
    isApplicable: boolean;
    stmtNo: string;
    multipleAdd: string;
    multipleDed: string;
    totalComms: number;
    toBePaidAmt: number;
    stmtDate: Date;
    OSBalance: number;
    expensesHead: string;
    deliveryPoint: number;
    monthName: string;
    frtPerKg: number;
    hirePerKg: number;
    lrDestination: string;
    via: string;
    //List < String > listOfSource;
    //List < String > listOfDest;
    listOfSource: string[];
    listOfDest: string[];
    countTrucks: number;
    depatureMode: string;
    lessAmtDed: number;
    shortGoods: string;
    excessGoods: string;
    openConditionGoods: string;
    remarkExtGoods: string;
    remarkShtGoods: string;
    remarkOpnGoods: string;
    groupId: number;
    orderLrTonn: number;
    heavyLrSlabs: string;
    thappiRateTonn: number;
    thappiRateWgt: number;
    warfare: number;
    levi: number;
    fromLdgWgt: number;
    toLdgWgt: number;
    rate: number;
    //HashMap < String, List < String >> hashMapList;
    totalTonns: number;
    remarkWetGoods: string;
    wetConditionGoods: string;
    totExtraLrs: number;
    isBalanceHsPrint: boolean;
    netBalance: number;
    imgURLPan: string;
    trkLgth: number;
    tripId: number;
    tripNo: number;
    panNo: string;
    tdsAmount: number;
    tdsPercent: string;
    advChequeAmt: number;
    advCashAmt: number;
    companyid: string;
    driverrewardingHAmt: number;
    riskChgAmt: number;
    insuranceNumber: string;
    goodsValueAbove: number;
    goodsValueBelow: number;
    goodsValueFOVLimit: number;
    deviceId: string;
    contactNumber: string;
    ewayBill: string;
    searchMode: string;
    twoPtLrs: string;
    listTimeSettings: string[];
    fromTime: string;
    toTime: string;
    rewardAmt: number;
    userRights: string;
    balPaymentType: string;


    trackingFTStatus: Boolean;
    trackingFTTripId: number;
    trackingFTShareUrl: string;
    trackingFTMsg: string;
    trackingFTConsentStatus: string;
    trackingFTCarrier: string;
    trackingFTConsentInitiatedTimestamp: number;
    trackingFTConsentCompletedTimestamp: number;
    trackingFTMissedCallTimestamp: number;
    trackingFTMissedCallRec: Boolean;
    trackingFTTrackingSupported: string;
    trackingCarrierMsg: string;
    trackingFTTripStatus: string;
    truckTypeCommonId: number;
    gurWeightTotal: number;

    isMultiInvFT: boolean;
    isLastPtDelvFT: boolean;
    ftRemarks: boolean;
    lat: number;
    lng: number;
    location: string;
    isFTTripReq: boolean;
    goodsType: string;
    //PENDING TO 
    //private HashMap<String, HireSlipDto> hMapHireslipDto;
    departuredateStr: string;
    scheduleddateStr: string;
    //added at 20-02-2021
    listLrsShortage: Array<String>;
    listTotalLrs: Array<String>;
    listLrsRem: Array<String>;
    list: Array<String>;
    listOfInvoice: Array<String>;

    hashMap: Map<String, String> = new Map<String, String>();
    diffFtAllow: boolean;

    //basha bh
    hireSlipDtoList: string[];
    modeForScheduleDateTime: string;
    bayNo: string;
    paymentDateStr: string;

    //Basha 11052021
    fromStationList: string[];
    toStationList: string[];
    //08052021 maaz
    tdsYesNo: string;
    hours: string;
    arrivaldateStr: string;

    fortNightStmtDto: FortNightStmtDto[];
    touchingHire: number;
    bayFlow: boolean;
    arrivalDateTwo: Date;
    arrivalDateStrTwo: string;
    stockDate: Date;
    stockDateStr: string;
    localTripMulti: boolean;
    localTripTrns: boolean;
    articles: number;
    hireamtbaloktopayatStr: string;

    trnxType: string;
    benfCode: string;
    acNo: string;
    trnxBankAmt: number;
    trnxCashAmt: number;
    benfName: string;
    debitStmtNarrt: string;
    trnxDate: Date;
    ifscCode: string;
    benfEmail: string;
    trnxTypeCode: string;
    truckBankAcId: number;
    balPaymentStatus: string;
    acType: string;
    acNumber: string;
    acHolder: string;
    branchName: string;
    hireamtbalbankacproofcopy: string;
    hireamtbaloktopaystatus: string;
    isPanAadharLink: boolean;
    panNumberAdvPayment: string;
    isAdminHireEdit: boolean;
    ftArrivalDate: Date;
    ftArrivalTime: string;
    ftReachedDuration: string;
    disableHireInGeneration: boolean;
    twoPointLrDetails: string[];
    isTwoPointDeliveryLr: boolean;
    insuranceExpDate: Date;
    insuranceExpDateStr: string;
    isRewardApplicable: boolean;
    enableBarcodeGen: boolean;
}