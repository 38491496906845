//CHG_Ver_CRPT_01_Added for new consignor taking long to come in dropdown soo added index wise consinor (Added By Imran On 02/08/2024)
//CHG_Ver_CRPT_02 : Add All option on consignor Index and Consingor Name (Asrar Jr / 13/12/2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import *  as moment from 'moment';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";

@Component({
    selector: 'app-consignor-report',
    templateUrl: './consignor-report.component.html',
    styleUrls: ['./consignor-report.component.css']
})
export class ConsignorReportComponent implements OnInit {

    summaryDataList: any;
    detailDataList: any;
    model: NgbDateStruct;
    viewTypeSummary = true;
    viewTypeDetail = false;
    searchWithOldConsignor = false;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerSummary: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();
    dtOptionsSummary: any;
    dtOptionDetail: any;
    summaryTable = true;
    detailTable = false;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;
    /////////

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    address: any;
    superAdminview: boolean = false;
    showSpinnerForAction = false;
    public modelConsignorNameId: any;
    public modelConsignorName: any;
    //For Consignor Index
    consignorIndexOptions = [
        { id: 0, label: 'ALL' },
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];
    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    consignorNameTATemp: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    //
    //For Consignee Index
    consigneeIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'M' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];

    //For consignor Index
    public modelConsigeeIndex: any;
    @ViewChild('instanceConsigneeIndex') instanceConsigneeIndex: NgbTypeahead;
    consigneeIndexTA: Array<any> = [];
    focusConsigneeIndexTA$ = new Subject<string>();
    consigneeIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeIndexTA
                : this.consigneeIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    /////
    //For Consignee
    consigneeNameOptions: any;
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.consigneeNameTA
                    : this.consigneeNameTA.filter(
                        (v) =>
                            v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
    public modelDestinationFotGet: any;
    public modelConsigneeNameId: any;
    ///For Commodity
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndex: any;
    selectedConsigneeId: any;
    selectedConsignorId: any;
    selectedConsignorGSTNumber: string | number | string[];
    destAll = false;
    srcAll = false;
    srcSpec = false;
    destSpec = false;
    showDestination = false;
    srcListsSet: Array<any> = [];
    srcList: Array<any> = [];
    selectedFromDate: any;
    selectedToDate: any;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    selectedConsignorName: any;
    selectedConsigneeName: any;
    selectedDestination: any;
    selectedSearchMode: any;
    selectedViewMode: any;
    lrDtoForSearch: LRDto = new LRDto();
    sentSrcList: Array<any> = [];
    selectedCommodityName: any;
    selectedLrNumber: any;
    viewCustomPrintV1 = false;
    viewCustomPrintV2 = false;

    grandActWt = 0;
    grandChWt = 0;
    grandToPay = 0;
    grandPaid = 0;
    grandTotalLRVal = 0;
    grandTotalArtVal = 0;
    grandFovVal = 0;
    grandAOCVal = 0;

    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

    pageId = "corrc";

    partyMasterDtoForconsignorNew: PartyMasterDto = new PartyMasterDto();
    searchWithNewConsignor = false;
    //For consignor Index
    public modelNewConsignorIndex: any;
    @ViewChild('instanceNewConsignorIndex') instanceNewConsignorIndex: NgbTypeahead;
    newConsignorIndexTA: Array<any> = [];
    focusNewConsignorIndexTA$ = new Subject<string>();
    newConsignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusNewConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.newConsignorIndexTA
                : this.newConsignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    constructor(private reportService: ReportService,
        private masterReadService: MasterReadService, private datePipe: DatePipe,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        //Rights For Agent
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.getDestinationDetails();
            //  this.setConsginorIndex();
            // this.getConsignorDetailsForGst();
            this.setConsginorIndex();
            //   this.getConsignorDetailsList();
            this.setConsgineeIndex();
            this.getConsigneeDetails(false);
            this.getCommodityMasterDetailsList();
            this.selectTodayDate();
            this.address = this.userDataDtoReturnSession.addressId == null ? "" : this.userDataDtoReturnSession.addressId;
            //CHG_Ver_CRPT_01
            // this.getConsignorDetailsListForNewConsignor();
            this.setNewConsginorIndex();
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    // if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LrIssueRpt ShowAll") {
                    //     this.superAdminview = true;

                    // }
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsignorRpt DestAll") {
                        this.destAll = true;
                        this.showDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsignorRpt SrcAll") {
                        this.srcAll = true;
                        this.showDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsignorRpt SrcSpec") {
                        this.srcSpec = true;
                        this.showDestination = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "ConsignorRpt DestSpec") {
                        this.destSpec = true;
                    }

                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "Super Administrator") {
                        this.srcList = [];

                    } else {
                        this.srcList = this.userDataDtoReturnSession.stationList;

                    }


                }
            }
        }

    }

    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    ngOnInit(): void {
        this.dtOptionsSummary = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,

                title: function () {

                    var returSummary = null;
                    returSummary = "Consignor Summary Report " +
                        " From Date : " + moment($("#corrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#corrctoDate").val()).format('DD-MM-YYYY');
                    return returSummary;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var lrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var art = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var fov = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(lrs);
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html(toPay);
                $(api.column(4).footer()).html(paid);
                $(api.column(5).footer()).html(art);
                $(api.column(6).footer()).html(actWgt);
                $(api.column(7).footer()).html(chgWgt);
                $(api.column(8).footer()).html(fov);
                $(api.column(9).footer()).html(aoc);

            }
        },
            this.dtOptionDetail = {
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returDetailed = null;
                        returDetailed = "Consignor Details Report " +
                            " From Date : " + moment($("#corrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#corrctoDate").val()).format('DD-MM-YYYY');
                        return returDetailed;
                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 300,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var toPay = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var paid = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var art = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var fov = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var aoc = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(7).footer()).html(toPay);
                    $(api.column(8).footer()).html(paid);
                    $(api.column(9).footer()).html(art);
                    $(api.column(10).footer()).html(actWgt);
                    $(api.column(11).footer()).html(chgWgt);
                    $(api.column(12).footer()).html(fov);
                    $(api.column(13).footer()).html(aoc);

                }
            }


    }


    ngOnDestroy(): void {
        this.dtTriggerSummary.unsubscribe();
        this.dtTriggerDetail.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerSummary.next();
        this.dtTriggerDetail.next();
    }

    searchWithMode(searchWith: string) {
        if (searchWith === 'newConsignor') {
            this.searchWithOldConsignor = false;
            $("#" + this.pageId + "consignorName").val('');
            this.modelConsignorName = '';
            this.consignorNameTA = [];
            this.modelConsignorIndex = '';
            $("#" + this.pageId + "consignorIndex").val('');
            this.consignorNameTA = this.consignorNameTATemp;
            this.selectTodayDate();
            this.modelNewConsignorIndex = '';
            $("#" + this.pageId + "newConsignorIndex").val('');

        } else if (searchWith === 'oldConsignor') {
            this.searchWithOldConsignor = true;
            $("#" + this.pageId + "consignorName").val('');
            this.modelConsignorName = '';
            this.consignorNameTA = [];
            this.modelConsignorIndex = '';
            $("#" + this.pageId + "consignorIndex").val('');
            this.selectTodayDate();
        } else {
            $("#" + this.pageId + "consignorName").val('');
            this.modelConsignorName = '';
            this.consignorNameTA = [];
            this.modelConsignorIndex = '';
            $("#" + this.pageId + "consignorIndex").val('');
            this.searchWithOldConsignor = false;
            this.modelNewConsignorIndex = '';
            $("#" + this.pageId + "newConsignorIndex").val('');
        }
    }

    // if((this.destAll == true) || (this.srcAll == true) || (this.srcSpec == true)) {
    //     this.showDestination = true;
    // }


    viewTypeMode(viewType: string) {
        if (viewType === 'summary') {
            this.viewTypeSummary = true;
            this.viewTypeDetail = false;
            this.hideTable();
            this.clearTable();
            this.summaryTable = true;

            //this.selectTodayDate();


        } else if (viewType === 'detail') {
            this.viewTypeSummary = false;
            this.viewTypeDetail = true;
            this.hideTable();
            this.clearTable();
            this.detailTable = true;
            //	this.selectTodayDate();
        } else {
            this.viewTypeSummary = false;
            this.viewTypeDetail = false;
        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //For Destination Listener
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destination").val(this.modelDestination.destination);
    }


    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }
    //For Consignor Index
    setConsgineeIndex() {
        for (let i = 0; i < this.consigneeIndexOptions.length; i++) {
            this.consigneeIndexTA.push(this.consigneeIndexOptions[i].label);
        }
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }
    //For Destination DropDown
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.lrDtoDestinationAll.destination = "All";
                        this.destinationTA.push(this.lrDtoDestinationAll);
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the Destination Details",
                    "info"
                );
            },
            () => console.log("done");
    }


    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();

    }



    hideTable() {
        this.summaryTable = false;
        this.detailTable = false;
    }
    clearTable() {
        $("#" + this.pageId + "summaryTableId").DataTable().destroy();
        this.summaryDataList = [];
        this.dtTriggerSummary.next();
        $("#" + this.pageId + "detailTableId").DataTable().destroy();
        this.detailDataList = [];
        this.dtTriggerDetail.next();

    }
    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    // $("#"+this.pageId+"consignorName").val('');
                    // this.consignorNamePartyDtoAllOption.consignorName = "All";
                    // this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    // window.setTimeout(function () {
                    //     $("#"+this.pageId+"consignorName").focus();
                    // }, 100);
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedDestination = this.modelDestinationFotGet.destination;
        console.log(this.selectedDestination)
        if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != "All") {
            this.getConsigneeDetails(true);
        } else {
            this.consigneeNameTA = [];
            this.modelConsigneeName = '';
        }

    }
    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
        console.log(this.selectedConsigneeId);
        console.log(this.modelConsigneeNameId);
    }

    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.showSpinnerForAction = true;
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedDestination = this.modelDestinationFotGet;
        if (destShow) {
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService
            .getConsigneeMaster(this.partyMasterDtoForconsignee)
            .subscribe((response) => {
                // this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val("");
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val("");
                    this.consigneeNamePartyDtoAllOption.consigneeName = 'All';
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.consigneeNameTATemp = this.consigneeNameTA;
                    console.log(this.consigneeNameTA);
                    if (destShow) {
                        $("#" + this.pageId + "consigneeName").focus();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the consignee details",
                    "info"
                );
            },
            () => console.log("done");
    };


    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
        console.log(this.selectedConsignorId);
    }
    //For Commodity Drop Down
    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.status = "Working";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption.commodityName = 'All';
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        $("#" + this.pageId + "commodityListId").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getConsignorDetailsListForNewConsignor() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignorNew = new PartyMasterDto();
        this.partyMasterDtoForconsignorNew.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignorNew.mode = "newConsignor";
        this.selectedIndex = this.modelNewConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignorNew.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignorNew.indexValue = "";
            }
            this.getConsignorDetailsForNewConsignor();
        }

        console.log('here');
        console.log(this.partyMasterDtoForconsignorNew);
    }
    getConsignorDetailsForNewConsignor = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignorNew).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);

                    }
                    this.consignorNameTATemp = this.consignorNameTA;
                    // window.setTimeout(function () {
                    //     $("#"+this.pageId+"consignorName").focus();
                    // }, 100);
                    console.log('chekc');
                    console.log(this.consignorNameTA);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    btnSearchValidate() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.selectedCommodityName = $("#" + this.pageId + "commodityListId").val();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSearchMode = $("#" + this.pageId + "searchWith").val();
        this.selectedViewMode = $("#" + this.pageId + "viewType").val();

        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '' || this.selectedConsignorName == null || this.selectedConsignorName == '') {
            swal({
                title: "Mandatory Field",
                text: "Please select From Date,To Date and ConsignorName to get the  details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {

            this.lrDtoForSearch = new LRDto();
            if (this.selectedViewMode == "summary") {
                this.lrDtoForSearch.reportMode = "summary";
            } else {
                this.lrDtoForSearch.reportMode = "details";
            }
            if (this.destAll == true) {
                if (this.selectedDestination != null && this.selectedDestination != "") {
                    this.lrDtoForSearch.destination = this.selectedDestination;
                } else {
                    this.lrDtoForSearch.destination = null;
                }

            } else if (this.srcSpec == true) {

                this.sentSrcList = [];
                this.sentSrcList.push(this.userDataDtoReturnSession.mainStation);
                this.lrDtoForSearch.list = this.sentSrcList;
                if (this.selectedDestination != null && this.selectedDestination != "") {
                    this.lrDtoForSearch.destination = this.selectedDestination;
                } else {
                    this.lrDtoForSearch.destination = null;
                }
            } else if (this.srcAll == true) {

                this.sentSrcList = [];
                this.sentSrcList = this.srcList;
                this.lrDtoForSearch.list = this.sentSrcList;
                if (this.selectedDestination != null && this.selectedDestination != "") {
                    this.lrDtoForSearch.destination = this.selectedDestination;
                } else {
                    this.lrDtoForSearch.destination = null;
                }
            } else if (this.destSpec == true) {
                this.lrDtoForSearch.destination = this.userDataDtoReturnSession.mainStation;

            }

            if (this.destAll == true) {
                if (this.selectedDestination == null || this.selectedDestination == '' || this.selectedCommodityName == '' || this.selectedCommodityName == null) {
                    swal({
                        title: "Mandatory Field",
                        text: "Please select Destination and Commodity to get the  details!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrDtoForSearch.fromDate = this.selectedFromDate;
                    this.lrDtoForSearch.toDate = this.selectedToDate;
                    this.lrDtoForSearch.consigneeName = this.selectedConsigneeName;
                    this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
                    this.lrDtoForSearch.consigneeId = 0;
                    if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
                        this.lrDtoForSearch.consigneeId = this.selectedConsigneeId
                    }
                    this.lrDtoForSearch.consignorId = 0;
                    this.lrDtoForSearch.consignorName = this.selectedConsignorName;
                    if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                        this.lrDtoForSearch.consignorId = this.selectedConsignorId;
                        console.log(this.selectedConsignorId);
                    }
                    this.lrDtoForSearch.mode = "Id";
                    this.lrDtoForSearch.column2 = this.selectedCommodityName;
                    this.searchMethod();


                }
            } else {
                if (this.selectedCommodityName == '' || this.selectedCommodityName == null) {
                    swal({
                        title: "Mandatory Field",
                        text: "Please select  Commodity to get the  details!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrDtoForSearch.fromDate = this.selectedFromDate;
                    this.lrDtoForSearch.toDate = this.selectedToDate;
                    this.lrDtoForSearch.consigneeName = this.selectedConsigneeName;
                    this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
                    this.lrDtoForSearch.consigneeId = 0;
                    if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
                        this.lrDtoForSearch.consigneeId = this.selectedConsigneeId
                    }
                    this.lrDtoForSearch.consignorId = 0;
                    this.lrDtoForSearch.consignorName = this.selectedConsignorName;
                    if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                        this.lrDtoForSearch.consignorId = this.selectedConsignorId;
                        console.log(this.selectedConsignorId);
                    }
                    this.lrDtoForSearch.mode = "Id";
                    this.lrDtoForSearch.column2 = this.selectedCommodityName;
                    this.searchMethod();
                }

            }
            console.log(this.lrDtoForSearch);
        }


    }
    searchMethod = () => {
        this.showSpinnerForAction = true;
        this.reportService.getConsignorNamesReport(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "summaryTableId").DataTable().destroy();
                this.summaryDataList = [];
                $("#" + this.pageId + "detailTableId").DataTable().destroy();
                this.detailDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.selectedViewMode = $("#" + this.pageId + "viewType").val();
                    if (this.selectedViewMode == "summary") {
                        this.summaryDataList = response;
                        console.log("this.summaryDataList");
                        console.log(this.summaryDataList);
                    } else {
                        this.detailDataList = response;
                        console.log("this.detailDataList");
                        console.log(this.detailDataList);
                    }

                }
                this.dtTriggerSummary.next();
                this.dtTriggerDetail.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Consignor  Report ", "info");
            }, () => console.log('done');
    };

    lrNumberToEnquiryForm(validationLrsDetailsData) {
        this.selectedLrNumber = validationLrsDetailsData.lrNumber;
        console.log(this.selectedLrNumber);
        localStorage.clear();
        localStorage.setItem('lrNumberFromEwayBillValidation',
            JSON.stringify(this.selectedLrNumber));
        this.router.navigate(['/lr/enquiry/lrEnquiry']);
    }

    printMethodForSummaryWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.summaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Consignor Name", "Total Lrs", "Consignor Gst", "To Pay", "Paid", "Article", "Act Wgt", "Chg Wgt", "FOV", "AOC"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalLRVal = 0;
            this.grandTotalArtVal = 0;
            this.grandFovVal = 0;
            this.grandAOCVal = 0;

            for (let i = 0; i < this.summaryDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].consignorName, this.summaryDataList[i].totalLrs, this.summaryDataList[i].gstNoConsignor, this.summaryDataList[i].toPay, this.summaryDataList[i].paid, this.summaryDataList[i].totalArticles, this.summaryDataList[i].actualWeight, this.summaryDataList[i].chargedWeight, this.summaryDataList[i].riskCharge, this.summaryDataList[i].aoc];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandToPay = +this.grandToPay + +this.summaryDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.summaryDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.summaryDataList[i].totalArticles;
                this.grandTotalLRVal = +this.grandTotalLRVal + +this.summaryDataList[i].totalLrs;
                this.grandActWt = +this.grandActWt + +this.summaryDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.summaryDataList[i].chargedWeight;
                this.grandFovVal = +this.grandFovVal + +this.summaryDataList[i].riskCharge;
                this.grandAOCVal = +this.grandAOCVal + +this.summaryDataList[i].aoc;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, this.grandTotalLRVal, "", this.grandToPay, this.grandPaid, this.grandTotalArtVal, this.grandActWt, this.grandChWt, this.grandFovVal, this.grandAOCVal];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From", "To "];
            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Consignor Summary Report ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    printMethodForDetailsWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.detailDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            //   this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Destination", "Booking Date","Invoice Number", "Consignor Name", "Consignor Gst", "To Pay", "Paid","Art","A.W", "C.W",  "FOV", "AOC"];  
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Destination", "Booking Date", "Consignee Name", "Consignor Name", "To Pay", "Paid", "Art", "A.W", "C.W"];
            //   this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 13, 12, 13, 5, 5, 4, 5, 5, 4, 4];
            this.cashMemoDtoForCustomPrintListColumnWidths = [12, 12, 11, 15, 15, 8, 5, 4, 5, 5, 4, 4];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalArtVal = 0;
            // this.grandFovVal = 0;
            // this.grandAOCVal = 0;

            for (let i = 0; i < this.detailDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].lrNumber, this.detailDataList[i].destination, this.detailDataList[i].bookingDateStr, this.detailDataList[i].consigneeName, this.detailDataList[i].consignorName, this.detailDataList[i].toPay, this.detailDataList[i].paid, this.detailDataList[i].totalArticles, this.detailDataList[i].actualWeight, this.detailDataList[i].chargedWeight];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandToPay = +this.grandToPay + +this.detailDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.detailDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.detailDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.detailDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.detailDataList[i].chargedWeight;
                // this.grandFovVal = +this.grandFovVal + +this.detailDataList[i].riskCharge;
                // this.grandAOCVal = +this.grandAOCVal + +this.detailDataList[i].aoc;


                // this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", "", "", "",this.grandToPay,this.grandPaid,this.grandTotalArtVal , this.grandActWt, this.grandChWt, this.grandFovVal, this.grandAOCVal];
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", "", "", this.grandToPay, this.grandPaid, this.grandTotalArtVal, this.grandActWt, this.grandChWt];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From", "To "];
            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Consignor Details Report ");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }

    clearAll() {
        this.selectedFromDate = '';
        this.selectedLrNumber = '';
        this.selectedToDate = '';
        this.selectedConsignorName = '';
        $("#" + this.pageId + "consignorName").val('');
        this.selectedCommodityName = '';
        $("#" + this.pageId + "commodityListId").val('');
        this.selectedConsigneeName = '';
        $("#" + this.pageId + "consigneeName").val('');
        this.selectedDestination = '';
        $("#" + this.pageId + "destination").val('');
        this.selectedSearchMode = '';
        $("#" + this.pageId + "searchWith").val('newConsignor');
        this.selectedViewMode = '';
        $("#" + this.pageId + "viewType").val('summary');
        this.lrDtoForSearch = new LRDto();
        this.searchWithOldConsignor = false;
        $("#" + this.pageId + "consignorName").val('');
        this.modelConsignorName = '';
        this.consignorNameTA = [];
        this.modelConsignorIndex = '';
        $("#" + this.pageId + "consignorIndex").val('');
        this.consignorNameTA = this.consignorNameTATemp;
        this.selectTodayDate();
        this.viewTypeSummary = true;
        this.viewTypeDetail = false;
        this.hideTable();
        this.clearTable();
        this.summaryTable = true;

        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandToPay = 0;
        this.grandPaid = 0;
        this.grandTotalArtVal = 0;
        this.grandFovVal = 0;
        this.grandAOCVal = 0;

        this.viewCustomPrintV2 = false;
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.selectTodayDate();
        this.showSpinnerForAction = false;
        this.modelConsignorNameId = '';
        this.modelConsignorNameId = '';
        this.selectedConsignorId = '';
        this.modelConsigneeNameId = '';
        this.selectedConsigneeId = '';
        this.modelDestinationFotGet = '';
        this.selectedDestination = '';
        this.modelNewConsignorIndex = '';
        $("#" + this.pageId + "newConsignorIndex").val('');

    }
    //For Consignor Index
    setNewConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.newConsignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }

    //CHG_Ver_CRPT_02
    // newConsignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
    //     this.modelNewConsignorIndex = e.item;
    //     //CHG_Ver_CRPT_01
    //     this.getConsignorDetailsListForNewConsignor();
    // }

    //CHG_Ver_CRPT_02
    newConsignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelNewConsignorIndex = e.item;
        console.log("ALL");
        console.log(this.modelNewConsignorIndex);
        if (this.modelNewConsignorIndex == 'ALL') {
            $("#" + this.pageId + "consignorName").val('');
            this.consignorNameTA = [];
            this.consignorNamePartyDtoAllOption.consignorName = "ALL";
            this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
        } else {
            //CHG_Ver_CRPT_01
            this.getConsignorDetailsListForNewConsignor();
        }
    }
}
