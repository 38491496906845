import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
const my = new Date();
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { TripSheetDto } from 'src/app/dto/TripSheet-dto';
import { Invoice } from 'src/app/dataService/invoice';

@Component({
    selector: 'app-local-trip-sheet-lr-details',
    templateUrl: './local-trip-sheet-lr-details.component.html',
    styleUrls: ['./local-trip-sheet-lr-details.component.css']
})
export class LocalTripSheetLrDetailsComponent implements OnInit {
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerTripSheetLRDetails: Subject<any> = new Subject();
    localTripSheetLRRptDataList: any;
    dtOptionsTripSheetLRDetails: any;
    loadingIndicator = true;
    pageId = "ltslr";
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction = false;
    tripSheetDtoLocalStorage: TripSheetDto = new TripSheetDto();
    lrDtoPopUp: LRDto = new LRDto();
    vehicleName: any = null;
    vehicleNumber: any = null;
    driverName: any = null;
    tripDate: any = null;
    tripNumber: any = null;
    areaDelivery: any = null;
    consolidatedEwayBill: any = null;
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    totArtSummary: number;
    hamGdSummary: number;
    hamDDSummary: number;
    actWtSummary: number;
    chgWtSummary: number;

    constructor(private router: Router, private invoice: Invoice, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.tripSheetDtoLocalStorage = JSON.parse(localStorage.getItem('popupLocalTripSheet'));
            localStorage.clear();
            this.getLocalTripSheetLRDetails(this.tripSheetDtoLocalStorage.id);
            console.log(this.tripSheetDtoLocalStorage);
            this.vehicleName = this.tripSheetDtoLocalStorage.transportName;
            this.vehicleNumber = this.tripSheetDtoLocalStorage.vehiclenumber;
            this.driverName = this.tripSheetDtoLocalStorage.drivername;
            this.tripDate = this.tripSheetDtoLocalStorage.tripDateStr;
            this.tripNumber = this.tripSheetDtoLocalStorage.tripNo;
            this.areaDelivery = this.tripSheetDtoLocalStorage.deliveryarea;
            this.consolidatedEwayBill = (this.tripSheetDtoLocalStorage.ewayBill == null ? "NA" : this.tripSheetDtoLocalStorage.ewayBill);
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsTripSheetLRDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "DOOR DELIVERY Trip Sheet Report ";
                    },
                    /*exportOptions: {
                        columns: [0, 1, 3, 5, 6, 7, 8, 9, 12, 2, 14, 15]
                    }*/
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "DOOR DELIVERY Trip Sheet Report ";
                    }
                    /*exportOptions: {
                        columns: [0, 3, 5, 6, 7, 8, 9, 10, 11, 12, 2, 13, 14, 15]
                    }*/
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var article = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamaliGD = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamaliDD = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actualWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lrDDAmt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(article);
                $(api.column(6).footer()).html(hamaliGD);
                $(api.column(7).footer()).html(hamaliDD);
                $(api.column(8).footer()).html(actualWgt);
                $(api.column(9).footer()).html(chgWgt);
                $(api.column(10).footer()).html(lrDDAmt);
                $(api.column(11).footer()).html(aoc);
            }
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngAfterViewInit(): void {
        this.dtTriggerTripSheetLRDetails.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerTripSheetLRDetails.unsubscribe();
    }

    getLocalTripSheetLRDetails(gettingId) {
        this.lrDtoPopUp = new LRDto();
        this.lrDtoPopUp.id = gettingId;
        this.lrDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.invoice.getPopUpTripSheetDetails(this.lrDtoPopUp).subscribe(
            (response) => {
                console.log(response);
                this.localTripSheetLRRptDataList = [];
                $("#" + this.pageId + "localTripSheetLRPopUpTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.localTripSheetLRRptDataList = response;
                    this.showSpinnerForAction = false;
                    this.customPrintLocalTripSheetLrDetPopup();
                } else {
                    this.showSpinnerForAction = false;
                    swal("No Data", "No Record Found for this search", "info");
                }
                this.dtTriggerTripSheetLRDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting local trip sheet of lr details", "error");
            },
            () => console.log('done');
    }

    customPrintLocalTripSheetLrDetPopup() {
        if (this.localTripSheetLRRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR NO", "CONSIGNEE", "ART", "GD", "DD", "A.WT", "C.WT", "BKNG DT", "LDNG DT", "UNDG DT", "SRC", "DISP W.IN DAYS", "ARR W.IN DAYS", "DELV W.IN DAYS"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [11, 20, 4, 4, 4, 5, 5, 9, 9, 10, 4, 5, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["AREA", "DATE", "TRIP NO", "TRIP ID"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.areaDelivery == null ? "NA" : this.areaDelivery, this.tripDate == null ? "NA" : this.tripDate, this.tripNumber == null ? "NA" : this.tripNumber, this.tripSheetDtoLocalStorage.id];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["VEHICLE NAME", "VEHICLE NUMBER", "DRIVER NAME", "EWAY BILL"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.vehicleName == null ? "NA" : this.vehicleName.toUpperCase(), this.vehicleNumber == null ? "NA" : this.vehicleNumber, this.driverName == null ? "NA" : this.driverName, this.consolidatedEwayBill];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.totArtSummary = this.hamGdSummary = this.hamDDSummary = this.actWtSummary = this.chgWtSummary = 0;

            for (let i = 0; i < this.localTripSheetLRRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.localTripSheetLRRptDataList[i].lrNumber, this.localTripSheetLRRptDataList[i].consigneeName,
                this.localTripSheetLRRptDataList[i].totalArticles, this.localTripSheetLRRptDataList[i].hamaliGD, this.localTripSheetLRRptDataList[i].hamaliDD,
                this.localTripSheetLRRptDataList[i].actualWeight, this.localTripSheetLRRptDataList[i].chargedWeight,
                this.localTripSheetLRRptDataList[i].bookingDateStr, this.localTripSheetLRRptDataList[i].loadingDateStr, this.localTripSheetLRRptDataList[i].unloadingDateStr,
                this.localTripSheetLRRptDataList[i].source,
                this.localTripSheetLRRptDataList[i].dispatchedwithin, this.localTripSheetLRRptDataList[i].arrivalwithin, this.localTripSheetLRRptDataList[i].deliveredwithin];
                this.totArtSummary = this.totArtSummary + this.localTripSheetLRRptDataList[i].totalArticles;
                this.hamGdSummary = this.hamGdSummary + this.localTripSheetLRRptDataList[i].hamaliGD;
                this.hamDDSummary = this.hamDDSummary + this.localTripSheetLRRptDataList[i].hamaliDD;
                this.actWtSummary = this.actWtSummary + this.localTripSheetLRRptDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.localTripSheetLRRptDataList[i].chargedWeight;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.localTripSheetLRRptDataList.length, "", this.totArtSummary, this.hamGdSummary, this.hamDDSummary, this.actWtSummary, this.chgWtSummary, "", "", "", "", "", "", ""];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "LOCAL TRIP SHEET");

            /*this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });*/

        }
    }
}
