//CHG_VERINVLFL_01==Added a loading remarks columns as input field in maintable and normal column in popup table on 07062024
//Chg_V2 : Fixed added duplicate entry on popup table and remove ohter rows data (Asrar Jr / 15-10-2024)

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ModalDismissReasons,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrService } from "src/app/dataService/lr-service";
import { StockService } from "src/app/dataService/stock-service";
import { LRDto } from "src/app/dto/LR-dto";
import swal from "sweetalert";

@Component({
  selector: "app-local-trip-loading-sheet-flow",
  templateUrl: "./local-trip-loading-sheet-flow.component.html",
  styleUrls: ["./local-trip-loading-sheet-flow.component.css"],
})
export class LocalTripLoadingSheetFlowComponent implements OnInit {
  loadingSheetDataList: any;
  model: NgbDateStruct;
  dtTriggerLoadingSheet: Subject<any> = new Subject();
  dtOptionsLoadingSheet: any;
  dtOptionsStockSummary: any;
  dtOptionsStockDetails: any;
  dtTriggerStockSummary: Subject<any> = new Subject();
  dtTriggerStockDetails: Subject<any> = new Subject();
  stockSummaryDataList: any;
  stockDetailsDataList: any;
  isLoggedIn = true;
  userDataDtoReturnSession: any;
  showSpinnerForAction = false;
  showSpinnerForAction2 = false;
  showSpinnerForAction3 = false;
  lrDtoSearch: LRDto = new LRDto();
  pageId = "ltlsf";
  selectedLoadingLocalTripData: any;
  selectedLoadingLocalTripId: any;
  loadingArticles: any;
  public modelLoadingArticle: any;
  summaryLrs: any;
  summaryActWt: any;
  summaryChgWt: any;
  summaryAmt: any;
  summaryArt: any;
  summaryLoadingArt: any;
  summaryBalArt: any;
  summaryTargetFrgt: any;
  summaryLoadedFrgt: any;
  summaryBalFrgt: any;
  summaryBalFrgtPerActWgt: any;
  summaryLoadedAW: any;
  summaryBalAW: any;
  summaryLoadedCW: any;
  summaryBalCW: any;
  ewayBillAlert = false;
  viewEwayBillStatus = false;
  lrDtoStockSummary: LRDto = new LRDto();
  lrDtoStocksDetails: LRDto = new LRDto();
  selectedStockDest: any;
  lrDtoUpdate: LRDto = new LRDto();
  lrDtosLoadingShortageUpdate: LRDto[];
  lrDtosLoadingUpdate: LRDto[];
  private unsavedChanges = false;
  lrDtoNewLR: LRDto = new LRDto();
  closeResult: string;
  lrDtoAutoLrPrint: LRDto = new LRDto();
  getvalueForLrPrint: any;
  setValueForLrPrint: LRDto = new LRDto();
  modalRefferenceLrPrintCopyPopUp: NgbModalRef;
  modalRefferenceLrPrintCopyClosePopUp: string;
  viewLrPrintCopyPopUp = false;
  loadedLRsDataList: any;
  dtTriggerloadedLRsDetail: Subject<any> = new Subject();
  dtOptionsloadedLRsDetail: any;
  lrDtosLoadingUpdateForSummary: any;
  barcodeInput: string = '';
  scanTimeout: any;
  lastInputTime: number | null = null;
  lrDtoSetTripDetailsReturn: LRDto = new LRDto();
  lrDtoDepartTrip: LRDto = new LRDto();
  
  listOfLrs: any;
  viewUpdateTripBtn: Boolean = false;
  dtOptionsExtraLoadedLRsDetail: any;
  dtTriggerExtraLoadedLRsDetail: Subject<any> = new Subject();
  extraLoadedLRsDataList: any;
  finishBtnText: string = '';
  viewFinishTripBtn: boolean = false;
  lrNumberTxt: string = '';
  showPopupPlusBtn: Boolean = false;
  enteredManualLrNumber: any;
  enteredManualBarcodeArticles: any;
  lrDtoBarcode: LRDto = new LRDto();
  lrDtoForLrRet = new LRDto();
  viewDepartTripBtn: Boolean =false;
  constructor(
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private stockService: StockService,
    private lrService: LrService,
    private modalService: NgbModal,
    private hireslipService: HireslipService
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.selectedLoadingLocalTripData = JSON.parse(
        localStorage.getItem("stockForTripsheetDashboardLocalStorage")
      );
      if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
        this.selectedLoadingLocalTripId = this.selectedLoadingLocalTripData.invoiceNumber;
        this.viewUpdateTripBtn = true;
        this.finishBtnText = 'Finish Loading';
      } else {
        this.selectedLoadingLocalTripId = this.selectedLoadingLocalTripData.tripId;
       this.viewDepartTripBtn=true;
        this.finishBtnText = 'Finish Unloading';
      }
      if (this.selectedLoadingLocalTripData.type != null && this.selectedLoadingLocalTripData.type == 'Unload') {
        this.viewFinishTripBtn = true;
      } else {
        this.viewFinishTripBtn = false;
      }

      this.getLocalTripLoadinSheetDetails();
      this.getArticlesStocksSummaryDetailsList();
      if (this.userDataDtoReturnSession.ewayBillAlert) {
        this.ewayBillAlert = true;
        this.viewEwayBillStatus = true;
      }
      this.getExtraLoadedLrsDetails();
    }
  }
  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngOnInit(): void {
    (this.dtOptionsLoadingSheet = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 400,
      scrollCollapse: true,
      paging: false,
      info: false,
    }),
      (this.dtOptionsStockSummary = {
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 230,
        scrollCollapse: true,
        pagingType: "full_numbers",
        // pageLength: 10,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };
          var totLrs = api
            .column(2)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var articles = api
            .column(3)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(4)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var totAmt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(2).footer()).html(totLrs);
          $(api.column(3).footer()).html(articles);
          $(api.column(4).footer()).html(actWgt);
          $(api.column(5).footer()).html(chgWgt);
          $(api.column(6).footer()).html(totAmt);
        },
      }),
      (this.dtOptionsStockDetails = {
        dom: "Bfrtip",
        buttons: [
          {
            extend: "excel",
            text: '<i class="fas fa-file-excel"> Excel</i>',
            titleAttr: "Excel",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
        },
        processing: true,
        //scroll in datatable starts
        responsive: true,
        scrollX: true,
        scrollY: 230,
        scrollCollapse: true,
        pagingType: "full_numbers",
        // pageLength: 10,
        paging: false,
        info: false,
        footerCallback: function (row, data, start, end, display) {
          var api = this.api(),
            data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === "string"
              ? +i.replace(/[\$,]/g, "") * 1
              : typeof i === "number"
                ? i
                : 0;
          };

          var articles = api
            .column(5)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);
          var actWgt = api
            .column(6)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var chgWgt = api
            .column(7)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          var totAmt = api
            .column(8)
            .data()
            .reduce(function (a, b) {
              return intVal(a) + intVal(b);
            }, 0);

          $(api.column(0).footer()).html("Total : " + data.length);
          $(api.column(5).footer()).html(articles);
          $(api.column(6).footer()).html(actWgt);
          $(api.column(7).footer()).html(chgWgt);
          $(api.column(8).footer()).html(chgWgt);
        },
      });
    window.addEventListener('keydown', this.handleBarcodeScan);
  }

  ngOnDestroy(): void {
    this.dtTriggerLoadingSheet.unsubscribe();
    this.dtTriggerloadedLRsDetail.unsubscribe();
    this.dtTriggerExtraLoadedLRsDetail.unsubscribe();
    window.removeEventListener('keydown', this.handleBarcodeScan);
  }
  ngAfterViewInit(): void {
    this.dtTriggerLoadingSheet.next();
    this.dtTriggerStockSummary.next();
    this.dtTriggerStockDetails.next();
    this.dtTriggerloadedLRsDetail.next();
    this.dtTriggerExtraLoadedLRsDetail.next();
  }

  getLocalTripLoadinSheetDetails() {
    this.lrDtoSearch = new LRDto();



    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoSearch.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      if (this.selectedLoadingLocalTripData.type != null && this.selectedLoadingLocalTripData.type == 'Unload') {
        this.lrDtoSearch.status = "Transit";
      } else {
        this.lrDtoSearch.status = "Loading";
      }
      this.lrDtoSearch.mode = 'invoice';

    } else {
      this.lrDtoSearch.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoSearch.mode = 'localTrip';
      this.lrDtoSearch.status = "localScheduled";
    }
    this.showSpinnerForAction = true;
    this.stockService
      .getLrBarcodeLoadingSheetFlow(this.lrDtoSearch).subscribe((response) => {
        this.loadingSheetDataList = [];
        this.loadedLRsDataList = [];
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            if (response[i].remArticles == 0) {
              this.loadedLRsDataList.push(response[i]);
            } else {
              this.loadingSheetDataList.push(response[i]);
            }
          }
        } else {
          swal("Alert", "No Records Found", "warning");
        }

        $("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
        this.dtTriggerLoadingSheet.next();

        $("#" + this.pageId + "loadedLRsDetailTableId").DataTable().destroy();
        this.dtTriggerloadedLRsDetail.next();
        this.updateSummary();

        $("#" + this.pageId + "extraLoadedLRsDetailTableId").DataTable().destroy();
        this.dtTriggerExtraLoadedLRsDetail.next();

        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting details!", "warning");
      },
      () => console.log("done");
  }

  // onMinusButtonClick(loadingSheetData) {
  //   if (loadingSheetData.loadingArticle > 0) {
  //     this.unsavedChanges = true;
  //     let curLoadingArticle = parseInt(loadingSheetData.loadingArticle);
  //     loadingSheetData.loadingArticle = curLoadingArticle - 1;
  //     loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

  //     this.updateLoadingSheetLocalTripLr(loadingSheetData);
  //   } else {
  //     console.log("lessthan zero");
  //   }
  //   // if (loadingSheetData.remArticles > 0) {
  //   //   const deleteRow = this.loadedLRsDataList.find((x) => x.lrNumber === loadingSheetData.lrNumber);
  //   //   this.loadedLRsDataList.splice(this.loadedLRsDataList.indexOf(deleteRow), 1);
  //   // }
  // }

  // onPlusButtonClick(loadingSheetData) {
  //   if (loadingSheetData.loadingArticle == undefined) {
  //     loadingSheetData.loadingArticle = 0;
  //   }
  //   if (loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
  //     this.unsavedChanges = true;
  //     let curLoadingArticle = parseInt(loadingSheetData.loadingArticle);
  //     loadingSheetData.loadingArticle = curLoadingArticle + 1;
  //     loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

  //     setTimeout(() => {
  //       this.updateLoadingSheetLocalTripLr(loadingSheetData);
  //     }, 10000);

  //     this.changeDetectorRef.detectChanges();
  //   } else {
  //     swal({
  //       title: "Not Allowed!",
  //       text: "Loading Articles is Reached to Actual Articles!",
  //       icon: "warning",
  //       closeOnClickOutside: false,
  //       closeOnEsc: false,
  //     });
  //   }
  //   //$("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
  //   //this.dtTriggerLoadingSheet.next();
  // }

  articleKeyPress(event: any, loadingSheetData) {
    //this.onPlusButtonClick(loadingSheetData);
    this.unsavedChanges = true;
    console.log(loadingSheetData.loadingArticle);
    if (loadingSheetData.loadingArticle < 0) {
      swal({
        title: "Not Allowed!",
        text: "Please input the correct Loading Articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      loadingSheetData.loadingArticle = 0;
    } else if (loadingSheetData.loadingArticle <= loadingSheetData.totalArticles) {
      loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

      this.updateLoadingSheetLocalTripLr(loadingSheetData);
    } else {
      swal({
        title: "Not Allowed!",
        text: "Loading Articles is Reached to Actual Articles!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      loadingSheetData.loadingArticle = 0;
    }
  }

  updateSummary() {
    this.summaryLrs = 0;
    this.summaryActWt = 0;
    this.summaryChgWt = 0;
    this.summaryAmt = 0;
    this.summaryArt = 0;
    this.summaryLoadingArt = 0;
    this.summaryBalArt = 0;
    this.summaryTargetFrgt = 0;
    this.summaryLoadedFrgt = 0;
    this.summaryBalFrgt = 0;
    this.summaryBalFrgtPerActWgt = 0;
    this.summaryLoadedAW = 0;
    this.summaryBalAW = 0;
    this.summaryLoadedCW = 0;
    this.summaryBalCW = 0;


    this.lrDtosLoadingUpdateForSummary = [];

    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      this.lrDtosLoadingUpdateForSummary.push(this.loadingSheetDataList[i]);
    }

    for (let i = 0; i < this.loadedLRsDataList.length; i++) {
      this.lrDtosLoadingUpdateForSummary.push(this.loadedLRsDataList[i]);
    }

    console.log('LR Counts : ' + this.loadingSheetDataList.length + " - " + this.loadedLRsDataList.length + " - " + this.lrDtosLoadingUpdateForSummary.length);

    this.summaryLrs = this.lrDtosLoadingUpdateForSummary.length;
    for (let i = 0; i < this.lrDtosLoadingUpdateForSummary.length; i++) {
      //console.log('LR : ' + this.lrDtosLoadingUpdateForSummary[i].lrNumber + " - " + this.lrDtosLoadingUpdateForSummary[i].loadingArticle);
      this.summaryActWt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].actualWeight) + this.summaryActWt;
      this.summaryChgWt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].chargedWeight) +
        this.summaryChgWt;
      this.summaryAmt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalAmount) + this.summaryAmt;
      this.summaryArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalArticles) + this.summaryArt;
      this.summaryLoadingArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].loadingArticle) +
        this.summaryLoadingArt;
      this.summaryBalArt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].remArticles) + this.summaryBalArt;
      //currently target=tot frgt later we may need to work as per Musaddik bh
      this.summaryTargetFrgt =
        parseInt(this.lrDtosLoadingUpdateForSummary[i].totalAmount) +
        this.summaryTargetFrgt;

      let curFrgt = this.lrDtosLoadingUpdateForSummary[i].totalAmount;
      let curTotArticle = this.lrDtosLoadingUpdateForSummary[i].totalArticles;
      let curLoadingArticle = this.lrDtosLoadingUpdateForSummary[i].loadingArticle;
      let frgtPerArt = curFrgt / curTotArticle;
      let loadedFrgt = frgtPerArt * curLoadingArticle;
      this.summaryLoadedFrgt = loadedFrgt + this.summaryLoadedFrgt;
      if (this.summaryLoadedFrgt > 0) {
        this.summaryLoadedFrgt = +this.summaryLoadedFrgt.toFixed(2);
      }

      let curAw = this.lrDtosLoadingUpdateForSummary[i].actualWeight;
      let awPerArt = curAw / curTotArticle;
      let loadedAw = awPerArt * curLoadingArticle;
      this.summaryLoadedAW = loadedAw + this.summaryLoadedAW;
      if (this.summaryLoadedAW > 0) {
        this.summaryLoadedAW = +this.summaryLoadedAW.toFixed(2);
      }

      let curCw = this.lrDtosLoadingUpdateForSummary[i].chargedWeight;
      let cwPerArt = curCw / curTotArticle;
      let loadedCw = cwPerArt * curLoadingArticle;
      this.summaryLoadedCW = loadedCw + this.summaryLoadedCW;
      if (this.summaryLoadedCW > 0) {
        this.summaryLoadedCW = +this.summaryLoadedCW.toFixed(2);
      }
    }
    this.summaryBalFrgt = this.summaryTargetFrgt - this.summaryLoadedFrgt;
    this.summaryBalFrgt = +this.summaryBalFrgt.toFixed(2);

    this.summaryBalAW = this.summaryActWt - this.summaryLoadedAW;
    this.summaryBalAW = +this.summaryBalAW.toFixed(2);

    this.summaryBalCW = this.summaryChgWt - this.summaryLoadedCW;
    this.summaryBalCW = +this.summaryBalCW.toFixed(2);

    let frgtPerAW = this.summaryTargetFrgt / this.summaryActWt;
    this.summaryBalFrgtPerActWgt = +frgtPerAW.toFixed(2);
    this.changeDetectorRef.detectChanges();
  }

  deleteLocalTripLoading(loadingSheetData) {
    swal({
      title: "Loading Local Trip",
      text: "Sure you want to remove the selected LR from this Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        const deleteRow = this.loadingSheetDataList.find(
          (x) => x.lrNumber === loadingSheetData.lrNumber
        );
        this.loadingSheetDataList.splice(
          this.loadingSheetDataList.indexOf(deleteRow),
          1
        );
        $("#" + this.pageId + "loadingSheetTableId")
          .DataTable()
          .destroy();
        this.dtTriggerLoadingSheet.next();
        this.updateSummary();
        this.changeDetectorRef.detectChanges();
        this.deleteLrFromLoadingLocalTrip(loadingSheetData);
      }
    });
  }

  getArticlesStocksSummaryDetails() {
    this.lrDtoStockSummary = new LRDto();
    this.lrDtoStockSummary.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoStockSummary.branch = this.userDataDtoReturnSession.office;
    this.lrDtoStockSummary.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStockSummary.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStockSummary.destination =
      this.userDataDtoReturnSession.mainStation;
    this.lrDtoStockSummary.OfficeType =
      this.userDataDtoReturnSession.officeType;
    this.lrDtoStockSummary.reportMode = "Loadingsheet";
  }

  getArticlesStocksSummaryDetailsList() {
    this.getArticlesStocksSummaryDetails();
    this.showSpinnerForAction2 = true;
    this.stockService.getArticlesStocksSummary(this.lrDtoStockSummary).subscribe((response) => {
      if (response.length > 0) {
        this.stockSummaryDataList = [];
        $("#" + this.pageId + "stockSummaryTableId").DataTable().destroy();
        this.stockSummaryDataList = response;
        this.dtTriggerStockSummary.next();
      } 
      // else {
      //   swal("Alert", "No Record Found", "warning");
      // }
      this.showSpinnerForAction2 = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction2 = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Summary",
          "warning"
        );
      },
      () => console.log("done");
  }


  rowSelectedGetTripLRDeatils(stockSummaryTableData) {
    this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
    this.stockDetailsDataList = [];
    $("#" + this.pageId + "stockDetailsTableId").DataTable().destroy();
    //this.dtTriggerStockDetails.next();
    this.lrDtoStocksDetails.destination = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
    this.lrDtoStocksDetails.subStations = stockSummaryTableData.endDest;
    this.lrDtoStocksDetails.branchType =
      this.userDataDtoReturnSession.stationType;
    this.lrDtoStocksDetails.destType = this.userDataDtoReturnSession.destType;
    this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;
    this.selectedStockDest = " - " + stockSummaryTableData.endDest;
    this.getArticlesStocksDetailsList();
  }

  getArticlesStocksDetailsList() {
    this.showSpinnerForAction3 = true;
    this.stockService
      .getArticlesStocks(this.lrDtoStocksDetails)
      .subscribe((response) => {
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "stockDetailsTableId")
          .DataTable()
          .destroy();
        if (response.length > 0) {
          this.stockDetailsDataList = response;
          setTimeout(() => {
            this.dtTriggerStockDetails.next();
          }, 1000);

          if (this.userDataDtoReturnSession.ewayBillAlert) {
            this.ewayBillAlertMsg(this.stockDetailsDataList);
          } else {
            this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
          }

          this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
        }
        this.showSpinnerForAction3 = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction3 = false;
        swal(
          "Error",
          "Server Error While Getting Trip Creation Stock Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  ewayBillAlertMsg(dataForEway) {
    var count = 0;
    var ewayBillStatus = "Multiple Lr's, No EWB";
    var lrs = "";
    for (let i = 0; i < dataForEway.length; i++) {
      if (
        dataForEway[i].ewayBillStatus != null &&
        dataForEway[i].ewayBillStatus == ewayBillStatus
      ) {
        if (count == 0) {
          lrs = dataForEway[i].lrNumber;
        } else {
          lrs += ", " + dataForEway[i].lrNumber;
        }
        count++;
      }
    }
    if (count >= 1) {
      swal({
        title:
          "LR Numbers without Eway Bill Number are listed below. Total:" +
          count +
          "",
        text: "" + lrs + "",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
      });
    } else {
      this.lrWithNoUnloadingDetailsAlertMsg(this.stockDetailsDataList);
    }
  }

  lrWithNoUnloadingDetailsAlertMsg(data) {
    if (data != null && data != undefined) {
      if (data[0].reason != null) {
        swal({
          title:
            "LR Numbers with No Unloading details filled, please update the Unloading details from the Dashboard",
          text: data[0].reason,
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    }
  }

  validateLoadingSheetUpdate() {
    alert('ok');
    if (this.summaryLoadingArt == 0) {
      swal(
        "Not Allowed",
        "No articles loaded to update the Loading Sheet!",
        "warning"
      );
    } else if (this.summaryBalArt > 0) {
      swal({
        title: "Incomplete Loading",
        text: "All the articles are not loaded into this Loading sheet, you want to mark these balance articles as Short?",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          this.updateLrShortageLoadingSheetLocalTrip();
        } else {
          this.confirmLoadingSheetUpdate();
        }
      });
    } else {
      this.confirmLoadingSheetUpdate();
    }
  }

  confirmLoadingSheetUpdate() {
    swal({
      title: "Confirm Update",
      text: "Sure your want to update this Local Trip Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.updateLoadingSheetLocalTrip();
      }
    });
  }

  updateLrShortageLoadingSheetLocalTrip() {
    this.loadingSheetShortageLrsLocalTripData();
    this.showSpinnerForAction = true;
    this.lrService
      .updateLRsShortageFromLoadingInvoice(this.lrDtosLoadingShortageUpdate)
      .subscribe((response) => {
        if (response.status == "Success") {
          swal(
            "Success",
            "Local Trip Loading sheet updated with Short details!",
            "info"
          );

          this.confirmLoadingSheetUpdate();
        } else {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetShortageLrsLocalTripData() {
    this.lrDtosLoadingShortageUpdate = [];
    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      if (this.loadingSheetDataList[i].remArticles > 0) {
        this.lrDtoUpdate = new LRDto();
        this.lrDtoUpdate.status = "Short";
        this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoUpdate.lrNumber = this.loadingSheetDataList[i].lrNumber;
        this.lrDtoUpdate.lrsShortage = this.loadingSheetDataList[i].remArticles;
        //CHG_VERINVLFL_01
        this.lrDtoUpdate.loadingRemarks = this.loadingSheetDataList[i].loadingRemarks
        this.lrDtoUpdate.remarks = "Loading Sheet";
        this.lrDtoUpdate.stocksAt = "Transit";
        if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
          this.lrDtoUpdate.invoiceNumber =
            this.selectedLoadingLocalTripData.invoiceNumber;
          this.lrDtoUpdate.mode = 'invoice'
        } else {
          this.lrDtoUpdate.invoiceNumber =
            this.selectedLoadingLocalTripData.tripId;
          this.lrDtoUpdate.mode = 'localTrip'
        }

        this.lrDtoUpdate.vehicleNumber =
          this.selectedLoadingLocalTripData.vehicleNumber;
        this.lrDtoUpdate.fromStation =
          this.selectedLoadingLocalTripData.fromStation;
        this.lrDtoUpdate.toStation = this.selectedLoadingLocalTripData.toStation;
        this.lrDtoUpdate.destination =
          this.selectedLoadingLocalTripData.toStation;
        this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtosLoadingShortageUpdate.push(this.lrDtoUpdate);
      }
    }
  }

  updateLoadingSheetLocalTrip() {
    this.loadingSheetLocalTripData();
    this.showSpinnerForAction = true;
    this.lrService
      .updateLrLoadingSheetInvoiceDetails(this.lrDtosLoadingUpdate).subscribe((response) => {
        if (response.status == "Success") {
          swal("Success", "Local Trip Loading sheet updated!", "success");
        } else {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetLocalTripData() {
    this.lrDtosLoadingUpdate = [];
    for (let i = 0; i < this.loadingSheetDataList.length; i++) {
      this.lrDtoUpdate = new LRDto();
      this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
      this.lrDtoUpdate.lrNumber = this.loadingSheetDataList[i].lrNumber;
      this.lrDtoUpdate.loadingArticle =
        this.loadingSheetDataList[i].loadingArticle;
      //CHG_VERINVLFL_01
      this.lrDtoUpdate.loadingRemarks =
        this.loadingSheetDataList[i].loadingRemarks;
      if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
        this.lrDtoUpdate.invoiceNumber =
          this.selectedLoadingLocalTripData.invoiceNumber;
        this.lrDtoUpdate.mode = 'invoice'
      } else {
        this.lrDtoUpdate.invoiceNumber =
          this.selectedLoadingLocalTripData.tripId;
        this.lrDtoUpdate.mode = 'localTrip'
      }

      this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
      this.lrDtosLoadingUpdate.push(this.lrDtoUpdate);
    }

    for (let i = 0; i < this.loadedLRsDataList.length; i++) {
      this.lrDtoUpdate = new LRDto();
      this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
      this.lrDtoUpdate.lrNumber = this.loadedLRsDataList[i].lrNumber;
      this.lrDtoUpdate.loadingArticle =
        this.loadedLRsDataList[i].loadingArticle;

      if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
        this.lrDtoUpdate.invoiceNumber =
          this.selectedLoadingLocalTripData.invoiceNumber;
        this.lrDtoUpdate.mode = 'invoice'
      } else {
        this.lrDtoUpdate.invoiceNumber =
          this.selectedLoadingLocalTripData.tripId;
        this.lrDtoUpdate.mode = 'localTrip'
      }
      this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
      this.lrDtosLoadingUpdate.push(this.lrDtoUpdate);
    }
  }

  updateLoadingSheetLocalTripLr(loadingSheetData) {
    this.loadingSheetLocalTripLrData(loadingSheetData);
    this.lrService.updateLrLoadingSheetLocalTripSingleLrDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status == "Duplicate") {
          swal("Warning", "This article already loaded or invalid barcode", "warning");
        } else if (response.status == "Failed") {
          swal("Error", "Problem occurred while updating the details", "error");
        } else {
          loadingSheetData.loadingArticle += 1;
          loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

          loadingSheetData.listOfBarcodes.splice(loadingSheetData.barcodeIndex, 1);
          console.log("Updated listOfBarcodes after removal:", loadingSheetData.listOfBarcodes);
          if (loadingSheetData.remArticles == 0) {
            console.log(loadingSheetData);
            console.log(this.loadedLRsDataList);

            if (!this.loadedLRsDataList.find(x => x.lrNumber === loadingSheetData.lrNumber)) {
              this.loadedLRsDataList.push(loadingSheetData);
            }
            const deleteRowIndex = this.loadingSheetDataList.findIndex((x) => x.lrNumber === loadingSheetData.lrNumber);
            if (deleteRowIndex !== -1) {
              this.loadingSheetDataList.splice(deleteRowIndex, 1);
            }

          }

          this.updateSummary();
          this.clearPlusMinusPopupFields();
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  loadingSheetLocalTripLrData(loadingSheetData) {
    console.log("this.selectedLoadingLocalTripData" + this.selectedLoadingLocalTripData);
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoUpdate.mode = 'invoice'
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoUpdate.mode = 'localTrip'
    }
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.remarks = loadingSheetData.remarks;
    this.lrDtoUpdate.barcodeValue = loadingSheetData.barcodeValue;
    this.lrDtoUpdate.type = this.selectedLoadingLocalTripData.type;
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any): void {
    if (this.unsavedChanges) {
      $event.returnValue = true;
      console.log("yes");
    } else {
      console.log("no");
    }
  }

  addStockLr(stockDetailsData) {
    swal({
      title: "Confirm Add",
      text: "Sure your want to add the selected LR into this Local Trip Loading Sheet?",
      icon: "info",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        this.addLRIntoLoadingSheetLocalTrip(stockDetailsData);
      }
    });
  }

  addLRIntoLoadingSheetLocalTrip(stockDetailsData) {
    this.lrDtoNewLR = new LRDto();
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = stockDetailsData.lrNumber;
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoUpdate.mode = 'invoice'
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoUpdate.mode = 'localTrip'
    }

    this.showSpinnerForAction = true;
    this.lrService
      .addLRIntoInvoiceLoadingSheetFlow(this.lrDtoUpdate)
      .subscribe((response) => {
        this.lrDtoNewLR = response;
        if (this.lrDtoNewLR.status == "Success") {
          swal(
            "Success",
            "LR added into Local Trip Loading sheet updated!",
            "info"
          );

          this.loadingSheetDataList.push(this.lrDtoNewLR);
          $("#" + this.pageId + "loadingSheetTableId")
            .DataTable()
            .destroy();
          this.dtTriggerLoadingSheet.next();
          this.updateSummary();

          const deleteStockLrRow = this.stockDetailsDataList.find(
            (x) => x.lrNumber === stockDetailsData.lrNumber
          );
          this.stockDetailsDataList.splice(
            this.stockDetailsDataList.indexOf(deleteStockLrRow),
            1
          );
          $("#" + this.pageId + "stockDetailsTableId")
            .DataTable()
            .destroy();
          this.dtTriggerStockDetails.next();


        } else {
          swal("Error", "Problem occurred while adding the details", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while adding the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getDataForLrPrint(lrPrintCopyPopUpTemplate, lrNumber) {
    console.log(lrNumber);
    this.lrDtoAutoLrPrint = new LRDto();
    this.lrDtoAutoLrPrint.lrNumber = lrNumber;
    this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;

    console.log(this.lrDtoAutoLrPrint);
    this.hireslipService
      .getAutoLrDetails(this.lrDtoAutoLrPrint)
      .subscribe((response) => {
        if (response.lrNumber != "" && response.lrNumber != null) {
          this.getvalueForLrPrint = "";
          this.getvalueForLrPrint = response;
          this.setValueForLrPrint = new LRDto();
          this.setValueForLrPrint = this.getvalueForLrPrint;
          this.setValueForLrPrint.lrEntryPrintMode = "";
          this.popUpPrintMethod(
            this.setValueForLrPrint,
            lrPrintCopyPopUpTemplate
          );
        } else {
          swal("Empty", "No Data Found", "info");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "", "info");
      },
      () => console.log("done");
  }

  popUpPrintMethod(setValueForLrPrint, lrPrintCopyPopUpTemplate) {
    localStorage.clear();
    localStorage.setItem(
      "printAfterSaveFromLrEnquiryData",
      JSON.stringify(setValueForLrPrint)
    );
    localStorage.setItem(
      "printAfterSaveFromLrEnquiryType",
      JSON.stringify("LrEnquiry")
    );

    this.modalRefferenceLrPrintCopyPopUp = this.modalService.open(
      lrPrintCopyPopUpTemplate,
      { centered: true, windowClass: "myCustomModalClass" }
    );
    this.modalRefferenceLrPrintCopyPopUp.result.then(
      (result) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Closed with: ${result}`;
      },
      (reason) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );

    this.viewLrPrintCopyPopUp = true;
    window.addEventListener("afterPopUp", (event) => {
      this.viewLrPrintCopyPopUp = false;
    });
  }

  closeBtnPopup() {
    this.modalRefferenceLrPrintCopyPopUp.close();
  }
  popupClose() {
    this.modalService.dismissAll();
  }
  callLoadedLrs(contentLoadedLrs) {
    this.modalService.open(contentLoadedLrs, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  deleteLrFromLoadingSheetLocalTrip(loadingSheetData) {
    this.loadingSheetLocalTripLrData(loadingSheetData);
    this.lrService.deleteLrLoadingSheetInvoiceDetails(this.lrDtoUpdate).subscribe((response) => {
      if (response.status != "Success") {
        swal("Error", "Problem occurred while deleting the details", "error");
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while deleting the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  deleteLrFromLoadingLocalTrip(loadingSheetData) {
    this.loadingSheetLocalTripLrData(loadingSheetData);
    this.lrDtoUpdate.mode = 'invremove';
    this.lrService.deleteLrLoadingSheetInvoiceDetails(this.lrDtoUpdate).subscribe((response) => {
      if (response.status != "Success") {
        swal("Error", "Problem occurred while deleting the details", "error");
      }
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while deleting the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  removeLoadedLrLocalTripLoading(loadedLRsData) {
    swal({
      title: "Confirm remove!",
      text: "Sure you want to remove the selected loaded LR?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isConfirm) => {
      if (isConfirm) {
        console.log('inside removeLoadedLrLocalTripLoading');
        loadedLRsData.loadingArticle = 0;
        loadedLRsData.remArticles = loadedLRsData.totalArticles;
        this.loadingSheetDataList.push(loadedLRsData);
        //$("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
        //this.dtTriggerLoadingSheet.next();
        this.deleteLrFromLoadingSheetLocalTrip(loadedLRsData);

        const deleteRow = this.loadedLRsDataList.find((x) => x.lrNumber === loadedLRsData.lrNumber);
        this.loadedLRsDataList.splice(this.loadedLRsDataList.indexOf(deleteRow), 1);

        this.updateSummary();
        //$("#" + this.pageId + "loadedLRsDetailTableId").DataTable().destroy();

        //console.log(loadedLRsData);
        //console.log(deleteRow);
        //$('#' + this.pageId + 'loadingSheetTableId').DataTable().row.add(loadedLRsData).draw();
        //$('#' + this.pageId + 'loadingSheetTableId').DataTable().draw();



        //this.dtTriggerloadedLRsDetail.next();

        this.changeDetectorRef.detectChanges();

      }
    });
  }

  callLoadedLrsSummary(contentLoadedLrSummary) {
    this.modalService.open(contentLoadedLrSummary, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  loadingLocalTripRemarksData(loadingSheetData) {
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.loadingRemarks = loadingSheetData.loadingRemarks;
  }


  updateLoadingInvRemarks(loadingSheetData) {

    this.loadingLocalTripRemarksData(loadingSheetData);
    this.lrService.updateLoadingInvRemarks(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status != "Success") {
          swal("Error", "Problem occurred while updating the details", "error");
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");

  }
  // handleBarcodeScan = (event: KeyboardEvent) => {
  //   clearTimeout(this.scanTimeout); 
  //      if (event.key === 'Enter') {
  //     this.processScannedBarcode(this.barcodeInput);
  //     this.barcodeInput = ''; 
  //   } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
  //           this.barcodeInput += event.key;
  //   }

  //   this.scanTimeout = setTimeout(() => {
  //     this.barcodeInput = '';
  //   }, 500); 
  // };



  // processScannedBarcode(scannedLrNumber: string) {
  //   const loadingSheetData = this.loadingSheetDataList.find(data => data.lrNumber === scannedLrNumber);
  //   if (loadingSheetData && loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
  //     loadingSheetData.loadingArticle += 1;
  //     loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

  //     setTimeout(() => {
  //       this.updateLoadingSheetLocalTripLr(loadingSheetData);
  //     }, 10000);
  //     this.changeDetectorRef.detectChanges();
  //   } else {
  //     swal({
  //       title: "Not Allowed!",
  //       text: "Loading Articles has reached the total available articles!",
  //       icon: "warning",
  //       closeOnClickOutside: false,
  //       closeOnEsc: false,
  //     });
  //   }
  // }
  handleBarcodeScan = (event: KeyboardEvent) => {
    const currentTime = new Date().getTime();

    // If the interval between keystrokes is too long, reset the input
    if (this.lastInputTime && currentTime - this.lastInputTime > 100) {
      this.barcodeInput = '';
    }

    this.lastInputTime = currentTime;

    // Handle 'Enter' key specifically for completing the barcode scan
    if (event.key === 'Enter') {
      if (this.barcodeInput.length > 5) { // Set a minimum length for barcode
        this.processScannedBarcode(this.barcodeInput, null, 'scan');
      }
      this.barcodeInput = '';
    } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
      this.barcodeInput += event.key;
    }

    // Reset input if no further input is detected within 100ms (barcode scanner inputs are typically fast)
    clearTimeout(this.scanTimeout);
    this.scanTimeout = setTimeout(() => {
      this.barcodeInput = '';
    }, 100);
  };


  processScannedBarcode(scannedBarcode, lrNumber, mode) {
    console.log("Scanning barcode:", scannedBarcode);
    let loadingSheetData: any; // Declares a variable of type `any`

    // Find if the scanned barcode exists in the loading sheet data list
    if ((mode != null && mode == 'manual') && lrNumber != null && lrNumber != undefined && lrNumber != '') {
      loadingSheetData = this.loadingSheetDataList.find(data =>
        data.lrNumber === lrNumber && data.listOfBarcodes.includes(scannedBarcode)
      );
    } else {
      loadingSheetData = this.loadingSheetDataList.find(data =>
        data.listOfBarcodes.includes(scannedBarcode)
      );
    }
    if (loadingSheetData) {
      const barcodeIndex = loadingSheetData.listOfBarcodes.indexOf(scannedBarcode);
      console.log("Found loadingSheetData:", loadingSheetData);
      console.log("Barcode index in listOfBarcodes:", barcodeIndex);

      if (barcodeIndex !== -1) {
        if (loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
          // Update the necessary properties
          loadingSheetData.barcodeValue = scannedBarcode;
          loadingSheetData.barcodeIndex = barcodeIndex;

          // Call update method
          this.updateLoadingSheetLocalTripLr(loadingSheetData);

          // Trigger change detection
          this.changeDetectorRef.detectChanges();
        } else {
          // Warning when loading limit is reached
          swal({
            title: "Not Allowed!",
            text: "Loading Articles has reached the total available articles!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
      } else {

        swal({
          title: "Barcode Not Valid",
          text: "This barcode has already been scanned or is invalid!",
          icon: "info",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    } else {
      if ((mode != null && mode == 'scan')) {

        swal({
          title: "Barcode Not Found!",
          text: "The scanned barcode is not available in this trip. Do you want to load this LR article into this trip as extra?",
          icon: "info",
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: ["No", "Yes"],
        }).then((yesBtn) => {
          if (yesBtn) {

            this.checkForBarcodeAndUpdate(scannedBarcode);
          }
        });
      } else {


        swal({
          title: "Barcode Not Valid",
          text: "This barcode has already been scanned or is invalid!",
          icon: "info",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });

      }

    }

  }

  validateDepartureTripSheet() {
    if (this.summaryLoadingArt == 0) {
      swal(
        "Not Allowed",
        "No articles loaded to update the Loading Sheet!",
        "warning"
      );
      return false;
    } else if (this.summaryBalArt > 0) {
      swal({
        title: "Incomplete Loading",
        text: "All articles are not loaded, sure you want to depart this trip?",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          this.confirmDepart('Partial');

        }
      });

    } else {
      this.confirmDepart('All');
    }
  }
  confirmDepart(loadingMode) {
    this.lrDtoSetTripDetailsReturn = new LRDto();
    console.log("this.selectedLoadingLocalTripData.tripId" + this.selectedLoadingLocalTripData.tripId);
    this.listOfLrs = [];
    this.lrDtoSetTripDetailsReturn.tripId = this.selectedLoadingLocalTripData.tripId;
    this.lrDtoSetTripDetailsReturn.status = 'Pending';
    this.lrDtoSetTripDetailsReturn.mode = 'booking';
    this.lrDtoSetTripDetailsReturn.mainstation = this.userDataDtoReturnSession.mainStation;
    this.lrDtoSetTripDetailsReturn.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoSetTripDetailsReturn.localTripMulti = this.userDataDtoReturnSession.localTripMulti;
    if (loadingMode != null && loadingMode == 'Partial') {
      for (let i = 0; i < this.loadingSheetDataList.length; i++) {
        this.listOfLrs.push(this.loadingSheetDataList[i].lrNumber);
      }
    } else {
      for (let i = 0; i < this.loadedLRsDataList.length; i++) {
        this.listOfLrs.push(this.loadedLRsDataList[i].lrNumber);
      }
    }

    this.lrDtoSetTripDetailsReturn.list = this.listOfLrs;
    this.departTrip(this.lrDtoSetTripDetailsReturn);
  }
  departTrip(lrdtoNewTripCreate) {
    console.log(lrdtoNewTripCreate);
    this.lrDtoDepartTrip = new LRDto();
    this.lrDtoDepartTrip = lrdtoNewTripCreate;
    this.showSpinnerForAction = true;
    $("#" + this.pageId + "scheduleTripBtn").prop('disabled', true);
    this.hireslipService.updateTripSheetDepartureDetails(this.lrDtoDepartTrip).subscribe(
      (response) => {
        console.log(response);
        console.log("ADDRES");
        if (response.status != null && response.status == "Success") {

          swal("Success", "Trip Departed successfully!", "success");

          $("#" + this.pageId + "scheduleTripBtn").prop('disabled', false);
        } else {
          swal("Server Error", "Failed to  Create A TripSheet Details ", "error");
          $("#" + this.pageId + "scheduleTripBtn").prop('disabled', false);
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "scheduleTripBtn").prop('disabled', false);
        swal("Server Error", "Problem occur while adding a  TripSheet Details ", "error");
      },
      () => console.log('done');
  }
  barcodeDataForLoadingExtraArticles(scannedBarcode) {
    console.log("this.selectedLoadingLocalTripData" + this.selectedLoadingLocalTripData);
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;

    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoUpdate.mode = 'invoice'
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoUpdate.mode = 'localTrip'
    }
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.barcodeValue = scannedBarcode;
    this.lrDtoUpdate.type = this.selectedLoadingLocalTripData.type;
    this.lrDtoUpdate.remarks = 'Extra';

  }


  checkForBarcodeAndUpdate(scannedBarcode,) {

    this.barcodeDataForLoadingExtraArticles(scannedBarcode);
    this.lrService.updateBarcodeForLoadingExtraArticles(this.lrDtoUpdate)
      .subscribe((response) => {
        console.log(response);
        if (response != null && response.status == 'Success') {
          swal("Success", "This scanned barcode loaded in this trip as extra", "success");
          this.scanTimeout = setTimeout(() => {
            this.getExtraLoadedLrsDetails();
          }, 100);

        } else if (response != null && response.status == 'Duplicate') {
          swal("Warning", "This scanned barcode already loaded in this trip", "warning");
        } else if (response != null && response.status == 'Not Found') {
          swal("Warning", "This scanned barcode not found, Please scan valid barcode", "warning");
        } else {
          swal("Warning", "This scanned barcode invalid, Please scan valid barcode", "warning");
        }



        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");

  }

  callExtraLoadedLrs(contentExtraLoadedLrs) {
    this.modalService.open(contentExtraLoadedLrs, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  getExtraLoadedLrsDetails() {
    this.lrDtoSearch = new LRDto();
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoSearch.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      if (this.selectedLoadingLocalTripData.type != null && this.selectedLoadingLocalTripData.type == 'Unload') {
        this.lrDtoSearch.status = "Transit";
      } else {
        this.lrDtoSearch.status = "Loading";
      }
      this.lrDtoSearch.mode = 'invoice';

    } else {
      this.lrDtoSearch.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoSearch.mode = 'localTrip';
      this.lrDtoSearch.status = "localScheduled";
    }
    this.lrDtoSearch.remarks = "Extra";
    this.showSpinnerForAction = true;
    this.stockService
      .getBarcodeExtraLoadedLrsDetails(this.lrDtoSearch).subscribe((response) => {

        this.extraLoadedLRsDataList = [];
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {

            this.extraLoadedLRsDataList.push(response[i]);

          }
        }

        this.showSpinnerForAction = false;

        const dataTable = $("#" + this.pageId + "extraLoadedLRsDetailTableId").DataTable();
        if (dataTable) {
          dataTable.destroy();
        }
        this.dtTriggerExtraLoadedLRsDetail.next();


        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting details!", "warning");
      },
      () => console.log("done");
  }

  validateLoadingSheetFinish() {

    if (this.summaryLoadingArt == 0) {
      swal(
        "Not Allowed",
        "No articles loaded to update the Loading Sheet!",
        "warning"
      );
    } else if (this.summaryBalArt > 0) {
      swal({
        title: "Incomplete Loading",
        text: "All the articles are not loaded into this Loading sheet, you want to mark these balance articles as Short?",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          swal(
            "Success",
            "The balance article marked as short!",
            "info"
          );
        }
      });
    } else {
      swal(
        "Success",
        "Articles loaded successfully!",
        "info"
      );
    }
  }
  onPlusButtonClick(loadingSheetdata, contenetUpdateOnAction) {
    this.lrNumberTxt = loadingSheetdata.lrNumber;
    this.showPopupPlusBtn = true;

    this.modalService.open(contenetUpdateOnAction, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  proceedbarcodeNoPopupPlusBtn() {
    let scannedBarcode = $("#" + this.pageId + "barcodeNoPopupId").val();
    if (scannedBarcode == null || scannedBarcode == undefined || scannedBarcode == '') {
      swal(
        "Not Allowed",
        "Please enter Barcode No to proceed",
        "warning"
      );
    } else {
      this.processScannedBarcode(scannedBarcode, this.lrNumberTxt, 'manual');
    }

  }

  clearPlusMinusPopupFields() {
    $("#" + this.pageId + "barcodeNoPopupId").val('');
    this.lrNumberTxt = null;
    this.modalService.dismissAll();
    this.showPopupPlusBtn = false;
  }

  onMinusButtonClick(loadingSheetdata, contenetUpdateOnAction) {
    this.lrNumberTxt = loadingSheetdata.lrNumber;
    this.showPopupPlusBtn = false;
    this.modalService.open(contenetUpdateOnAction, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }


  proceedbarcodeNoPopupMinusBtn() {
    let scannedBarcode = $("#" + this.pageId + "barcodeNoPopupId").val();
    if (scannedBarcode == null || scannedBarcode == undefined || scannedBarcode == '') {
      swal(
        "Not Allowed",
        "Please enter Barcode No to proceed",
        "warning"
      );
    } else {
      this.removeBarcodeOnMinusClick(scannedBarcode, this.lrNumberTxt);
    }

  }


  getDataForDtoOnMinusButtonClick(scannedBarcode, lrNumber) {
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.barcodeValue = scannedBarcode;
    this.lrDtoUpdate.lrNumber = lrNumber;
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
    }
   this.lrDtoUpdate.type = this.selectedLoadingLocalTripData.type;

  }



  removeBarcodeOnMinusClick(scannedBarcode, lrNumber) {
    this.getDataForDtoOnMinusButtonClick(scannedBarcode, lrNumber);
    this.showSpinnerForAction = true;
    this.lrService
      .checkAndRemoveLoadedBarcodeDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status == "Success") {
          swal(
            "Success",
            "Entered Barcode removed from the loaded article!",
            "info"
          );
          this.clearPlusMinusPopupFields();
          this.getLocalTripLoadinSheetDetails();
        } else {
          swal("Warning", "Entered Barcode not exists please verify the Barcode Number!", "warning");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  validateManualLoad(contentForManualLoad) {
    this.modalService.open(contentForManualLoad, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );


  }
 

  getManualBarcodeEntryPopupData() {
    this.enteredManualLrNumber = $("#" + this.pageId + "manualLoadLrNumberId").val();
    this.enteredManualBarcodeArticles = $("#" + this.pageId + "manualLoadArtcilesId").val();
    this.lrDtoBarcode = new LRDto();
    this.lrDtoBarcode.lrNumber = this.enteredManualLrNumber;
    this.lrDtoBarcode.totalArticles = this.enteredManualBarcodeArticles;
    this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;
    this.lrDtoBarcode.stocksAt = this.userDataDtoReturnSession.office;
    this.lrDtoBarcode.status = 'Pending';
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoBarcode.invoiceNumber = this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoBarcode.mode = 'invoice';
    } else {
      this.lrDtoBarcode.invoiceNumber = this.selectedLoadingLocalTripData.tripId;
      this.lrDtoBarcode.mode = 'localTrip';
      this.lrDtoBarcode.status = 'localScheduled';
    }
    this.lrDtoBarcode.type = this.selectedLoadingLocalTripData.type;
    console.log(this.lrDtoBarcode);
  }
submitManualLoading() {

    this.getManualBarcodeEntryPopupData();
    this.showSpinnerForAction = true;

    this.lrService.generateManualMasterLRBarcode(this.lrDtoBarcode).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.lrDtoForLrRet = new LRDto();
        this.lrDtoForLrRet = response;

        if (this.lrDtoForLrRet.status === "Already Exists") {
          swal({
            title: "Warning",
            text: "Barcode Already Generated for this LR! You can load by scanning LR Number or Use + Icon else contact admin",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "LR Not Found") {
          swal({
            title: "Warning",
            text: "LR Not found in this trip! Please verify the LR Number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }else if (this.lrDtoForLrRet.status === "Art Greater") {
          swal({
            title: "Warning",
            text: "Entered Article is greater than the LR's Article please check the entered article",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "Already Entered") {
          swal({
            title: "Warning",
            text: "This LR Already entered to this trip please verify the LR Number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "Success") {
          this.modalService.dismissAll();
          this.getLocalTripLoadinSheetDetails();
        } else {
          swal({
            title: "Warning",
            text: "Failed to generate barcode",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }

        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred adding barcode details", "info");
      },
      () => console.log('done')
    );

  }  
}
