import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-party-contact-details',
    templateUrl: './party-contact-details.component.html',
    styleUrls: ['./party-contact-details.component.css']
})
export class PartyContactDetailsComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "pcantdtls";

    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    showSpinnerForAction: boolean = false;
    selectedDestination: any;
    selectedSource: any;
    lrDtoGetPartyContactDtls: LRDto = new LRDto();
    partyContactDetailsList: any;
    dtTriggerPartyContactDetails: Subject<any> = new Subject();
    dtOptionsPartyContactDetails: any;
    destinationDtoForAll: LRDto = new LRDto();
    showBookingDate: boolean = false;
    selectedBkgFromDate: any;
    selectedBkgToDate: any;
    selectedContactType: any;
    summaryPartyContactDetails: any;
    totalMobileNo: any;
    showSpinnerForDownloadExcel: boolean = false;
    fromDate: NgbDateStruct | null = null;
    toDate: NgbDateStruct | null = null;
    currentDate = new Date();


    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;


    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getSourceDetails();
        }
    }

    ngOnInit() {
        this.dtOptionsPartyContactDetails = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Party Contact Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerPartyContactDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerPartyContactDetails.next();
    }

    maxDate = {
        year: this.currentDate.getFullYear(),
        month: this.currentDate.getMonth() + 7,
        day: this.currentDate.getDate()
    };

    validateDates() {
        if (this.fromDate && this.toDate) {
            const fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
            const toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
    
            const diffMonths = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + toDate.getMonth() - fromDate.getMonth();
    
            if (diffMonths > 6) {
                swal("Not Allowed", "Please select dates within a maximum range of 6 months!", "warning");
                
                if (toDate > fromDate) {
                    this.toDate = null;
                    $("#" + this.pageId + "bkgToDate").val('');
                } else {
                    this.fromDate = null;
                    $("#" + this.pageId + "bkgFromDate").val('');
                }
            }
        }
    }

    getSourceDetailedList() {
        this.lrDtoSource.mode = 'Booking';
        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSource.reportMode = 'InclMainStation';
        console.log(this.lrDtoSource);
    }

    getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.lrDtoSourceOptions = response;
                        console.log(this.lrDtoSourceOptions);
                        this.sourceTA = [];

                        this.lrDtoSourceAllOption = new LRDto();
                        this.lrDtoSourceAllOption.subStations = "ALL";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.sourceTA.push(this.lrDtoSourceOptions[i]);
                        }
                        // this.lrDtoSourceAllOption = new LRDto();
                        // this.lrDtoSourceAllOption.subStations = "Non Delhi";
                        // this.sourceTA.push(this.lrDtoSourceAllOption);

                        // this.lrDtoSourceAllOption = new LRDto();
                        // this.lrDtoSourceAllOption.subStations = "Delhi & Non Delhi";
                        // this.sourceTA.push(this.lrDtoSourceAllOption);

                        // for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                        //     this.searchSrcListInLoop.push(this.lrDtoSourceOptions[i].subStations);
                        //     this.searchAgentListInLoop.push(this.agentNameOptions[i].subStations);
                        // }
                        // $("#" + this.pageId + "source").val('All');
                        // $("#" + this.pageId + "agentName").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Agent Details", "info");
            },
            () => console.log('done');

    };

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }

    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    this.destinationDtoForAll = new LRDto();
                    this.destinationDtoForAll.destination = "ALL";
                    this.destinationTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

    validatGetPartyContactDetailsMethod() {
        if (($("#" + this.pageId + "source").val() == null || $("#" + this.pageId + "source").val() == undefined ||
            $("#" + this.pageId + "source").val() == "" || $("#" + this.pageId + "destination").val() == null || $("#" + this.pageId + "destination").val() == undefined ||
            $("#" + this.pageId + "destination").val() == "")) {
            swal("Mandatory Field", "All fields are mandatory to get the details!", "warning");
        } else if ((this.showBookingDate) && ($("#" + this.pageId + "bkgFromDate").val() == null || $("#" + this.pageId + "bkgFromDate").val() == undefined ||
            $("#" + this.pageId + "bkgFromDate").val() == "" || $("#" + this.pageId + "bkgToDate").val() == null || $("#" + this.pageId + "bkgToDate").val() == undefined ||
            $("#" + this.pageId + "bkgToDate").val() == "")) {
            swal("Mandatory Field", "Please select the booking dates are mandatory fields to get the details!", "warning");
        } else {
            this.getPartyContactDetailsMethod();
        }
    }

    setPartyContactDetails() {
        this.selectedContactType = $("#" + this.pageId + "contactType").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedBkgFromDate = $("#" + this.pageId + "bkgFromDate").val();
        this.selectedBkgToDate = $("#" + this.pageId + "bkgToDate").val();

        this.lrDtoGetPartyContactDtls = new LRDto();
        this.lrDtoGetPartyContactDtls.source = this.selectedSource;
        this.lrDtoGetPartyContactDtls.destination = this.selectedDestination;
        if (this.showBookingDate) {
            this.lrDtoGetPartyContactDtls.fromDate = this.selectedBkgFromDate;
            this.lrDtoGetPartyContactDtls.toDate = this.selectedBkgToDate;
            this.lrDtoGetPartyContactDtls.isBookingDate = true;
        }
        this.lrDtoGetPartyContactDtls.partyType = this.selectedContactType;
        console.log(this.lrDtoGetPartyContactDtls);
    }

    getPartyContactDetailsMethod() {
        this.setPartyContactDetails();
        this.showSpinnerForAction = true;
        this.lrReportService.getPartyContactDetails(this.lrDtoGetPartyContactDtls).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.partyContactDetailsList = [];
                if (response.listOfPartyDetails.length > 0) {
                    this.partyContactDetailsList = response;
                    this.totalMobileNo = this.partyContactDetailsList.listOfPartyDetails.length;
                } else {
                    this.totalMobileNo = 0;
                    swal("Alert", "No details found!", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Party Contact Details", "warning");
            },
            () => console.log('done');
    }

    toggleEditableForLrBased(event) {
        if (event.target.checked) {
            this.showBookingDate = true;
        } else {
            this.showBookingDate = false;
        }
    }

    // exportToExcel() {
    //     if (!this.partyContactDetailsList || this.partyContactDetailsList.length === 0) {
    //         swal("Alert", "No data available to download!", "warning");
    //         return;
    //     }
    //     this.showSpinnerForDownloadExcel = true;
    //     setTimeout(() => {
    //         try {
    //             const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
    //                 this.partyContactDetailsList.map(item => ({
    //                     'Party Name': item.partyName,
    //                     'GST Number': item.gstNumber,
    //                     'Mobile Number': item.mobileNum,
    //                     'Source': item.source,
    //                     'Destination': item.destination,
    //                 }))
    //             );

    //             const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    //             XLSX.utils.book_append_sheet(workbook, worksheet, 'Party Contact Details');

    //             const excelBuffer: any = XLSX.write(workbook, {
    //                 bookType: 'xlsx',
    //                 type: 'array'
    //             });

    //             const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //             saveAs(blob, 'Party_Contact_Details.xlsx');

    //             swal("Success", "File downloaded successfully!", "success");
    //         } catch (error) {
    //             console.error('Error exporting to Excel:', error);
    //             swal("Error", "An error occurred while downloading the file!", "error");
    //         } finally {
    //             this.showSpinnerForDownloadExcel = false;
    //         }
    //     }, 500);
    // }

    exportToExcel() {
        if (!this.partyContactDetailsList || this.partyContactDetailsList.length === 0) {
            swal("Alert", "No data available to download!", "warning");
            return;
        }
        this.showSpinnerForDownloadExcel = true;
        setTimeout(() => {
            const partyType = this.partyContactDetailsList.partyType;
            const partyContactDetails = this.partyContactDetailsList.listOfPartyDetails || [];
            if (partyContactDetails.length === 0) {
                swal("Alert", "No mobile numbers found to export!", "warning");
                this.showSpinnerForDownloadExcel = false;
                return;
            }
            const worksheetData = [];
            if (partyType == "Consignee") {
                for (let i = 0; i < partyContactDetails.length; i++) {
                    const item = partyContactDetails[i];

                    // Split the string based on '%'
                    const parts = item.split("%");

                    const mobileNumber = parts[0];
                    const city = parts[1] || '';
                    const agentid = parts[2] || '';

                    worksheetData.push({
                        'Mobile No': mobileNumber,
                        'Destination': city,
                        'Source': agentid
                    });
                }
            } else if (partyType == "Consignor") {
                for (let i = 0; i < partyContactDetails.length; i++) {
                    const item = partyContactDetails[i];

                    // Split the string based on '%'
                    const parts = item.split("%");

                    const mobileNumber = parts[0];
                    const agentid = parts[1] || '';
                    const city = parts[2] || '';

                    worksheetData.push({
                        'Mobile No': mobileNumber,
                        'Source': agentid,
                        'Destination': city

                    });
                }
            }
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(worksheetData);
            const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Party Contact Details');

            const excelBuffer: any = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Party_Contact_Details.xlsx');

            swal("Success", "Excel file downloaded successfully!", "success");
            this.showSpinnerForDownloadExcel = false;
            this.changeDetectorRef.detectChanges();

        }, 500);
    }

    clearDetails() {
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "contactType").val('Consignee');
        $("#" + this.pageId + "bkgFromDate").val('');
        $("#" + this.pageId + "bkgToDate").val('');
        this.selectedSource = null;
        this.selectedDestination = null;
        // $("#" + this.pageId + "partyContactDetailsTable").DataTable().destroy();
        // this.partyContactDetailsList = [];
        // this.dtTriggerPartyContactDetails.next();
        this.showBookingDate = false;
        $("#" + this.pageId + "checkboxLrBased").prop("checked", false);
        this.fromDate = null;
        this.toDate = null;
        this.totalMobileNo = 0;
    }
}
