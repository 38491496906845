import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';

@Component({
    selector: 'app-destination-stocks-report',
    templateUrl: './destination-stocks-report.component.html',
    styleUrls: ['./destination-stocks-report.component.css']
})
export class DestinationStocksReportComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "deststkrpt";

    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    showSpinnerForAction: boolean = false;
    selectedDestination: any;
    selectedSource: any;
    lrDtoForGet: LRDto = new LRDto();
    destinationStocksRptSummaryList: any;
    dtTriggerdestinationStocksRpt: Subject<any> = new Subject();
    dtOptionsdestinationStocksRpt: any;
    lrDtoGetDtls: LRDto = new LRDto();
    destinationStocksRptDetailsList: any;
    dtOptionsDestinationStocksRptDetails: any;
    dtTriggerDestinationStocksRptDetails: Subject<any> = new Subject();
    viewSourceAll: boolean = false;

    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;
    agentDtoForAll: AgentDetailsDto = new AgentDetailsDto();

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;


    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getMainBookingSourceDetails();

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "DestStockRpt ViewSrcAll") {
                        this.viewSourceAll = true;
                    }
                }
            }
        }
    }

    ngOnInit() {
        var groupColumn = 0;
        this.dtOptionsdestinationStocksRpt = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "Summary of Destination Stocks Report Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actualWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargedWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(2).footer()).html(articles);
                $(api.column(3).footer()).html(actualWgt);
                $(api.column(4).footer()).html(chargedWgt);

            }
        },
            this.dtOptionsDestinationStocksRptDetails = {
                dom: 'Bfrtip',
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        footer: true,
                        title: function () {
                            return "Details of Destination Stocks Report";
                        },
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
                        }
                    }
                ],
                searching: true,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                pagingType: 'full_numbers',
                select: true,
                footer: true,

                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var totArt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPay = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(9).footer()).html(totArt);
                    $(api.column(10).footer()).html(actWgt);
                    $(api.column(11).footer()).html(chgWt);
                    $(api.column(12).footer()).html(toPay);
                    $(api.column(13).footer()).html(paid);
                }, "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var art = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPay = 0;
                    var paid = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 7,
                                                    "text": ""
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": art
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPay
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));
                                art = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPay = 0;
                                paid = 0;
                                count = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 14,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        art += +val[9];
                        actWgt += +val[10];
                        chgWgt += +val[11];
                        toPay += +val[12];
                        paid += +val[13];
                        if (i == (rows.length - 1)) {
                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 7,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": art
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))


                                        .prop('outerHTML'));
                            art = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPay = 0;
                            paid = 0;
                            count = 0;
                        }
                    });
                }
            }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerdestinationStocksRpt.unsubscribe();
        this.dtTriggerDestinationStocksRptDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerdestinationStocksRpt.next();
        this.dtTriggerDestinationStocksRptDetails.next();
    }

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                this.mainBookingSourceTA = [];
                this.agentDtoOptionSourceStation = [];
                this.agentDtoOptionSourceStation = response;
                // this.agentDtoForAll = new AgentDetailsDto();
                // this.agentDtoForAll.mainBookStations = "ALL";
                // this.mainBookingSourceTA.push(this.agentDtoForAll);
                for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
            },
            () => console.log('done');
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    // this.destinationDtoForAll = new LRDto();
                    // this.destinationDtoForAll.destination = "ALL";
                    // this.destinationTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

    getSummaryOfDestinationStocksRptMethod() {
        this.selectedSource = $("#" + this.pageId + "mainBookingSourceId").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.lrDtoForGet = new LRDto();
        if (this.viewSourceAll) {
            this.lrDtoForGet.source = this.selectedSource;
        } else {
            this.lrDtoForGet.source = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.lrDtoForGet.destination = this.selectedDestination;
        console.log(this.lrDtoForGet);

        if ((this.viewSourceAll) && (this.selectedSource == null || this.selectedSource == undefined || this.selectedSource == ''
            || this.selectedDestination == null || this.selectedDestination == undefined || this.selectedDestination == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please Select Mandatory Fields!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else if (this.selectedDestination == null || this.selectedDestination == undefined || this.selectedDestination == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Select Mandatory Field!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.showSpinnerForAction = true;
            this.lrReportService.getSummaryOfDestinationStocksRpt(this.lrDtoForGet).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    console.log(response);
                    this.destinationStocksRptSummaryList = [];
                    $("#" + this.pageId + "destinationStocksRptTable").DataTable().destroy();
                    this.destinationStocksRptDetailsList = [];
                    $("#" + this.pageId + "destinationStocksRptDetailsTable").DataTable().destroy();
                    if (response.length > 0) {
                        this.destinationStocksRptSummaryList = response;
                        console.log(this.destinationStocksRptSummaryList);
                    } else {
                        swal("Alert", "No Details Found", "warning");
                    }
                    this.dtTriggerdestinationStocksRpt.next();
                    this.dtTriggerDestinationStocksRptDetails.next();
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error While Getting Summary of Destination Stocks Details", "warning");
                },
                () => console.log('done');
        }
    }

    getDetailsOfDestinationStocksRptInfo(destinationStocksRptSummary) {
        this.selectedSource = $("#" + this.pageId + "mainBookingSourceId").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.lrDtoGetDtls = new LRDto();
        this.lrDtoGetDtls.destination = this.selectedDestination;
        if (this.viewSourceAll) {
            this.lrDtoGetDtls.source = this.selectedSource;
        } else {
            this.lrDtoGetDtls.source = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.lrDtoGetDtls.status = destinationStocksRptSummary.chkStatus;
        console.log(this.lrDtoGetDtls);
        this.getDetailsOfDestinationStocksRptMethod();
    }

    getDetailsOfDestinationStocksRptMethod() {
        this.showSpinnerForAction = true;
        this.lrReportService.getDetailsOfDestinationStocksRpt(this.lrDtoGetDtls).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "destinationStocksRptDetailsTable").DataTable().destroy();
                this.destinationStocksRptDetailsList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.destinationStocksRptDetailsList = response;
                }
                this.dtTriggerDestinationStocksRptDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Stocks Details", "info");
            }, () => console.log('done');
    };

    clearDetails() {
        $("#" + this.pageId + "mainBookingSourceId").val('');
        $("#" + this.pageId + "destination").val('');
        this.selectedSource = null;
        this.selectedDestination = null;
        $("#" + this.pageId + "destinationStocksRptTable").DataTable().destroy();
        this.destinationStocksRptSummaryList = [];
        this.dtTriggerdestinationStocksRpt.next();
        $("#" + this.pageId + "destinationStocksRptDetailsTable").DataTable().destroy();
        this.destinationStocksRptDetailsList = [];
        this.dtTriggerDestinationStocksRptDetails.next();
    }

}
