//Chg_PWPM_V1 : Add COD Column on table & filter COD for passing mode to get details (Asrar Jr / 07-01-2025)
import { NgbDateStruct, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { Subject, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Router } from "@angular/router";
import { LrService } from "src/app/dataService/lr-service";
import swal from "sweetalert";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-party-wise-performance-monitor",
  templateUrl: "./party-wise-performance-monitor.component.html",
  styleUrls: ["./party-wise-performance-monitor.component.css"],
})
export class PartywisePerformanceMonitorComponent implements OnInit {
  model: NgbDateStruct;
  model2;
  loadingIndicator = true;


  pageTitle = "Party Wise Performance Monitor";
  lrDto: LRDto = new LRDto();
  lrDtoToRet: any;
  userDataDtoReturnSession: any;
  isLoggedIn = true;

  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  showSpinnerForAction = false;
  dataTable: any;
  pageId = "pwpm";
  dtOptionsPartyPerfMonitor: any;
  dtTriggerPartyPerfMonitor: Subject<any> = new Subject();
  viewConsigneeColumns: boolean = true;
  viewConsignorColumns: boolean = true;
  dtOptionsPartyPerfMonitorDetailView: any;
  dtTriggerPartyPerfMonitorDetailView: Subject<any> = new Subject();
  partyPerfMonitorDataList: any;
  partyPerfMonitorDetailViewDataList: any;
  partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
  partyMasterDtoForConsignor: PartyMasterDto = new PartyMasterDto();
  @ViewChild('lrWiseTrackingModal') private lrWiseTrackingModal;
  modalRefferenceTrackLRPopUp: NgbModalRef;
  modalRefferenceTrackLRClosePopUp: string;
  lrDtoUpdateTrack = new LRDto();
  enteredLrNumber: any;
  lrDtoGetLRTracking = new LRDto();
  viewLrTrackingColumns: boolean = false;
  enteredDescription: any;
  viewLrPerformanceMonitorPrint = false;
  selectedBookingFromDate: any;
  selectedBookingToDate: any;
  consigneeId: any;
  consignorId: any;
  showPartyDetails: boolean = false;
  consigneeName: any;
  consignorName: any;
  showConsigneeName: boolean = false;
  showConsignorName: boolean = false;
  bookingFromDate: NgbDateStruct;
  bookingToDate: NgbDateStruct;
  codMode: any;

  constructor(
    private masterReadService: MasterReadService,
    private router: Router,
    private lrService: LrService,
    public changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      //          sweet alert starts
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );

      // this.partyTypeMode('consigneeWise');
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }
  ngOnInit(): void {
    var groupColumnDestination = 3;
    this.dtOptionsPartyPerfMonitorDetailView = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          title: function () {
            return (
              "Party Wise Performance Monitor"
            );
          },
          exportOptions: {
            columns: ':visible'
          },
        },
      ],


      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 380,
      scrollCollapse: true,
      paging: false,
      info: false,
      drawCallback: function (settings) {
        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;

        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;
        var totArt = 0;
        var actWgt = 0;
        var chgWgt = 0;
        var toPayAmt = 0;
        var paidAmt = 0;
        var count = 0;
        api
          .column(groupColumnDestination, {
            page: "current",
          })
          .data()
          .each(function (group, i) {
            var val = api.row(api.row($(rows).eq(i)).index()).data();
            if (last !== group) {
              if (i != 0) {
                $(rows)
                  .eq(i)
                  .before(
                    $(
                      "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                      {
                        class: "group",
                        "data-id": group,
                      }
                    )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "Total : " + count,
                        })
                      )
                      //
                      // .append(
                      //   $("<td></td>", {
                      //     colspan: 4,
                      //     text: "Total : ",
                      //   })
                      // )
                      //
                      .append(
                        $("<td></td>", {
                          colspan: 5,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: totArt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: actWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: chgWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: toPayAmt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: paidAmt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 27,
                          text: "",
                        })
                      )
                      .prop("outerHTML")
                  );
                count = 0;
                totArt = 0;
                actWgt = 0;
                chgWgt = 0;
                toPayAmt = 0;
                paidAmt = 0;
              }
              $(rows)
                .eq(i)
                .before(
                  $(
                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                    {
                      class: "group",
                      "data-id": group,
                    }
                  )
                    .append(
                      $("<td></td>", {
                        colspan: 39,
                        text: group,
                      })
                    )
                    .prop("outerHTML")
                );
              last = group;
            }
            count++;
            totArt += +val[9];
            actWgt += +val[11];
            chgWgt += +val[12];
            toPayAmt += +val[13];
            paidAmt += +val[14];
          });
      },
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };

        var totArt = api
          .column(9)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var actWgt = api
          .column(11)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(12)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var toPay = api
          .column(13)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api
          .column(14)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(1).footer()).html(data.length);
        $(api.column(9).footer()).html(totArt);
        $(api.column(11).footer()).html(actWgt);
        $(api.column(12).footer()).html(chgWgt);
        $(api.column(13).footer()).html(toPay);
        $(api.column(14).footer()).html(paid);
      },
    };

    this.dtOptionsPartyPerfMonitor = {
      dom: "Bfrtip",
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          title: function () {
            return (
              "Party Wise Performance Monitor Summary"
            );
          },
        },
      ],

      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 380,
      scrollCollapse: true,
      paging: false,
      info: false,
    }
  }
  changeDataTableTitile() {
    return (
      '<table><tr style="width:100%"><td style="width:60%"><img src="assets/images/srdLogisticPrintLogo.png"></td>' +
      '<td style="width:40%"><p>' +
      this.userDataDtoReturnSession.addressId +
      "</p></td></tr></table>"
    );
  }

  chnageDataTableMessageTop() {
    return (
      "<span>" +
      '<div style="text-align:center;">' +
      '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' +
      this.pageTitle +
      "</span></h5>" +
      '<h6 style="font-weight:bold;">' +
      "From : " +
      '<span style="font-weight:400;">' +
      this.lrDto.fromDate +
      "</span>" +
      "To : " +
      '<span style="font-weight:400;">' +
      this.lrDto.toDate +
      "</span>" +
      "</h6>" +
      "</div>" +
      "</span>"
    );
  }
  chnageDataTableMessageTopExcel() {
    return (
      "From: " +
      this.lrDto.fromDate +
      "           " +
      "To: " +
      this.lrDto.toDate
    );
  }

  ngOnDestroy(): void {
    this.dtTriggerPartyPerfMonitor.unsubscribe();
    this.dtTriggerPartyPerfMonitorDetailView.unsubscribe();
  }




  ngAfterViewInit(): void {
    this.dtTriggerPartyPerfMonitor.next();
    this.dtTriggerPartyPerfMonitorDetailView.next();
  }
  clearAllFields() {
    $("#" + this.pageId + "partyPerfMonitorTable")
      .DataTable()
      .destroy();
    this.dtTriggerPartyPerfMonitor.next();
    this.partyPerfMonitorDataList = [];
    this.lrDto = new LRDto();
    this.viewLrPerformanceMonitorPrint = false;
  }
  clearAll() {
    this.clearAllFields();
  }

  partyTypeMode(searchBy: string) {
    this.viewConsigneeColumns = false;
    this.viewConsignorColumns = false;
    this.viewLrTrackingColumns = false;
    if (searchBy === 'consigneeWise') {
      this.viewConsigneeColumns = true;
      this.viewConsignorColumns = false;
      this.callConsigneeData();
      this.showPartyDetails = false;
      this.showConsigneeName = false;
      this.showConsignorName = false;
      this.clearFeilds();
    } else if (searchBy === 'consignorWise') {
      this.viewConsignorColumns = true;
      this.viewConsigneeColumns = false;
      this.callConsignorData();
      this.showPartyDetails = false;
      this.showConsigneeName = false;
      this.showConsignorName = false;
      this.clearFeilds();
    } else if (searchBy === 'lrNumberSearch') {
      this.viewLrTrackingColumns = true;
      this.viewConsigneeColumns = false;
      this.callLrWiseTrackingData();
      this.showPartyDetails = false;
      this.showConsigneeName = false;
      this.showConsignorName = false;
      this.clearFeilds();
    } else if (searchBy === 'selectParty') {
      this.partyPerfMonitorDataList = [];
      $("#" + this.pageId + "partyPerfMonitorTable")
        .DataTable()
        .destroy();
      this.dtTriggerPartyPerfMonitor.next();
      this.showPartyDetails = false;
      this.showConsigneeName = false;
      this.showConsignorName = false;
      this.clearFeilds();
    }
  }
  getConsigneeDetailsList() {
    this.partyMasterDtoForconsignee = new PartyMasterDto();
    this.partyMasterDtoForconsignee.isPerformanceMonitor = true;
    this.partyMasterDtoForconsignee.userName = this.userDataDtoReturnSession.userId;
    this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;

  }
  callConsigneeData = () => {
    this.getConsigneeDetailsList();
    this.masterReadService.getPerfMonitorConsignee(this.partyMasterDtoForconsignee).subscribe(
      (response) => {
        this.partyPerfMonitorDataList = [];
        $("#" + this.pageId + "partyPerfMonitorTable")
          .DataTable()
          .destroy();
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Consignee Details found !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.partyPerfMonitorDataList = response;
        }
        this.dtTriggerPartyPerfMonitor.next();
        this.showSpinnerForAction = false;

        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };


  getConsignorDetailsList() {
    this.partyMasterDtoForConsignor = new PartyMasterDto();
    this.partyMasterDtoForConsignor.isPerformanceMonitor = true;
    this.partyMasterDtoForConsignor.userName = this.userDataDtoReturnSession.userId;
    this.partyMasterDtoForConsignor.companyId = this.userDataDtoReturnSession.companyId;

  }
  callConsignorData = () => {
    this.getConsignorDetailsList();
    this.masterReadService.getPerfMonitorConsignor(this.partyMasterDtoForConsignor).subscribe(
      (response) => {
        this.partyPerfMonitorDataList = [];
        $("#" + this.pageId + "partyPerfMonitorTable")
          .DataTable()
          .destroy();
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Consignee Details found !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.partyPerfMonitorDataList = response;
        }
        this.dtTriggerPartyPerfMonitor.next();
        this.showSpinnerForAction = false;

        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  rowSelectedGetForPerfMonitor(rowData, mode) {
    console.log(rowData);
    this.showSpinnerForAction = true;
    this.lrDto = new LRDto();
    this.lrDto.consigneeId = this.consigneeId;
    this.lrDto.mode = mode;
    this.lrDto.lrNumber = rowData.lrNumber;
    console.log(this.lrDto)

    this.lrService.getPerfMonitorPartyDetails(this.lrDto).subscribe((response) => {
      this.partyPerfMonitorDetailViewDataList = [];
      $("#" + this.pageId + "partyPerfMonitorDetailViewTable")
        .DataTable()
        .destroy();
      if (response.length > 0) {
        this.partyPerfMonitorDetailViewDataList = response;
      } else {
        swal("Alert", "No Data Found", "warning");
      }
      this.dtTriggerPartyPerfMonitorDetailView.next();
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting the LR details", "warning");
      },
      () => console.log("done");

  }

  openLrDetailsModal(lrWiseTrackingModal) {
    this.modalRefferenceTrackLRPopUp = this.modalService.open(lrWiseTrackingModal,
      // { centered: true, size: "sm" }
      { centered: true });
    this.modalRefferenceTrackLRPopUp.result.then((result) => {
      this.modalRefferenceTrackLRClosePopUp = `Closed with: ${result}`;
    }, reason => {
      this.modalRefferenceTrackLRClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closeLrTrackingModal() {
    this.modalRefferenceTrackLRPopUp.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  methodForUpdateLRTrackEnable() {
    this.lrDtoUpdateTrack = new LRDto();
    this.enteredLrNumber = $("#" + this.pageId + "lrNumberForTrack").val();
    this.enteredDescription = $("#" + this.pageId + "description").val();

    this.lrDtoUpdateTrack.lrNumber = this.enteredLrNumber;
    this.lrDtoUpdateTrack.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdateTrack.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdateTrack.description = this.enteredDescription;
  }

  updateLRTrackingDetails() {
    this.methodForUpdateLRTrackEnable();
    this.lrService
      .enableLrTrakingDetails(this.lrDtoUpdateTrack)
      .subscribe((response) => {
        let retrunStatusForEnableBankLr = response;
        if (retrunStatusForEnableBankLr.status == "Duplicate") {
          swal({
            title: "LR Tracking",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " Already enabled!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
          this.modalRefferenceTrackLRPopUp.close();
          this.clearPopupFeilds();
        } else if (retrunStatusForEnableBankLr.status == "Success") {
          swal({
            title: "LR Tracking",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " Tracking enabled successfully!",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
          this.modalRefferenceTrackLRPopUp.close();
          this.clearPopupFeilds();
          this.callLrWiseTrackingData();
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enable the Tracking for the given Lr",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the COD LR for memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  confirmUpdateLrTrackingDetails() {
    this.enteredLrNumber = $("#" + this.pageId + "lrNumberForTrack").val();
    if (this.enteredLrNumber == null || this.enteredLrNumber == undefined || this.enteredLrNumber == '') {
      swal("Not Allowed", "Please enter Lr number is mandatory fields to proceed!", "warning");
    } else {
      swal({
        title: "Confirm LR Tracking",
        text: "Sure you want to enable tracking?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.updateLRTrackingDetails();
        } else {
          swal.close();
        }
      });
    }
  }

  clearPopupFeilds() {
    $("#" + this.pageId + "description").val('');
    this.enteredDescription = null;
  }

  getLrWiseTrackingDetailsList() {
    this.lrDtoGetLRTracking = new LRDto();
    this.lrDtoGetLRTracking.isPerformanceMonitor = true;
    this.lrDtoGetLRTracking.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoGetLRTracking.companyId = this.userDataDtoReturnSession.companyId;

  }
  callLrWiseTrackingData = () => {
    this.getConsignorDetailsList();
    this.lrService.getLrWiseTrackingDetails(this.lrDtoGetLRTracking).subscribe(
      (response) => {
        this.partyPerfMonitorDataList = [];
        $("#" + this.pageId + "partyPerfMonitorTable")
          .DataTable()
          .destroy();
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Consignee Details found !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else {
          this.partyPerfMonitorDataList = response;
        }
        this.dtTriggerPartyPerfMonitor.next();
        this.showSpinnerForAction = false;

        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  confirmDisableLrTrackingDetails(partyPerfMonitorData) {

    swal({
      title: "Confirm Disable",
      text: "Sure you want to disable tracking?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.updatLrTrackingDisable(partyPerfMonitorData);
      } else {
        swal.close();
      }
    });

  }
  methodForUpdateLRTrackDisable(partyPerfMonitorData) {
    this.lrDtoUpdateTrack = new LRDto();
    this.lrDtoUpdateTrack.lrNumber = partyPerfMonitorData.lrNumber;
    this.lrDtoUpdateTrack.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdateTrack.companyId = this.userDataDtoReturnSession.companyId;
  }
  updatLrTrackingDisable(partyPerfMonitorData) {
    this.methodForUpdateLRTrackDisable(partyPerfMonitorData);
    this.lrService
      .disableLrTrakingDetails(this.lrDtoUpdateTrack)
      .subscribe((response) => {
        let retrunStatusForEnableBankLr = response;
        if (retrunStatusForEnableBankLr.status == "Not Exits") {
          swal({
            title: "LR Tracking",
            text:
              "LR Number:" +
              partyPerfMonitorData.lrNumber +
              " Tracking not enabled! please enable it",
            icon: "success",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        } else if (retrunStatusForEnableBankLr.status == "Success") {
          swal({
            title: "LR Tracking",
            text:
              "LR Number:" +
              this.enteredLrNumber +
              " Tracking disabled successfully!",
            icon: "warning",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
          this.callLrWiseTrackingData();
        } else {
          swal({
            title: "Failed To enable",
            text: "Failed to enabled the COD LR for memo generation",
            icon: "error",
            closeOnClickOutside: true,
            closeOnEsc: true,
          });
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) =>
        swal({
          title: "Server Error",
          text: "Failed to enabled the COD LR for memo generation",
          icon: "error",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }),
      () => console.log("done");
  }

  setLrPerformanceMonitorPrintDetails(partyPerfMonitorDetailViewData) {
    localStorage.clear();
    this.viewLrPerformanceMonitorPrint = true;
    console.log("Perf Monitor");
    console.log(partyPerfMonitorDetailViewData);
    localStorage.setItem('lrPerformanceMonitorPrint', JSON.stringify(partyPerfMonitorDetailViewData));
    window.addEventListener('afterprint', (onclick) => {
      if (this.viewLrPerformanceMonitorPrint) {
        this.viewLrPerformanceMonitorPrint = false;
      }
      localStorage.clear();
      this.changeDetectorRef.detectChanges();
    });
  }

  rowSelectedForParties(rowData) {
    console.log(rowData);
    this.consigneeId = 0;
    this.consignorId = 0;
    if (rowData.consigneeId != 0) {
      this.consigneeId = rowData.consigneeId;
      this.consigneeName = rowData.consigneeName;
      this.showPartyDetails = true;
      this.showConsigneeName = true;
      this.showConsignorName = false;
    } else if (rowData.consignorId != 0) {
      this.consignorId = rowData.consignorId;
      this.consignorName = rowData.consignorName;
      this.showPartyDetails = true;
      this.showConsigneeName = false;
      this.showConsignorName = true;
    }
  }

  validateGetPartyDetailsForSelectedBookingDate() {
    this.selectedBookingFromDate = $("#" + this.pageId + "bookingFromDate").val();
    this.selectedBookingToDate = $("#" + this.pageId + "bookingToDate").val();

    if (this.selectedBookingFromDate == null || this.selectedBookingFromDate == undefined || this.selectedBookingFromDate == ''
      || this.selectedBookingToDate == null || this.selectedBookingToDate == undefined || this.selectedBookingToDate == '') {
      swal("Not Allowed", "Please select booking date the mandatory fields to proceed!", "warning");
    } else {
      this.getPartyDetailsForSelectedBookingDate();
    }
  }

  getPartyDetailsForSelectedBookingDate() {
    this.showSpinnerForAction = true;
    this.selectedBookingFromDate = $("#" + this.pageId + "bookingFromDate").val();
    this.selectedBookingToDate = $("#" + this.pageId + "bookingToDate").val();

    this.lrDto = new LRDto();
    this.lrDto.consigneeId = this.consigneeId;
    this.lrDto.consignorId = this.consignorId;
    this.lrDto.fromDate = this.selectedBookingFromDate;
    this.lrDto.toDate = this.selectedBookingToDate;
    //Chg_PWPM_V1
    this.lrDto.mode = this.codMode;
    console.log(this.lrDto)

    this.lrService.getPerfMonitorPartyDetails(this.lrDto).subscribe((response) => {
      this.partyPerfMonitorDetailViewDataList = [];
      $("#" + this.pageId + "partyPerfMonitorDetailViewTable")
        .DataTable()
        .destroy();
      if (response.length > 0) {
        this.partyPerfMonitorDetailViewDataList = response;
        console.log("partyPerfMonitorDetailViewDataList");
        console.log(this.partyPerfMonitorDetailViewDataList);
      } else {
        swal("Alert", "No Data Found", "warning");
      }
      this.dtTriggerPartyPerfMonitorDetailView.next();
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting the LR details", "warning");
      },
      () => console.log("done");
  }

  clearFeilds() {
    $("#" + this.pageId + "bookingFromDate").val('');
    $("#" + this.pageId + "bookingToDate").val('');
    this.consigneeId = 0;
    this.consignorId = 0;
    this.consigneeName = null;
    this.consignorName = null;
    this.selectedBookingFromDate = null;
    this.selectedBookingToDate = null;
    this.partyPerfMonitorDetailViewDataList = [];
    $("#" + this.pageId + "partyPerfMonitorDetailViewTable")
      .DataTable()
      .destroy();
    this.dtTriggerPartyPerfMonitorDetailView.next();
    this.codMode = null;
  }

  // Chg_PWPM_V1
  checkBoxCODForPartyBased(event) {
    if (event.target.checked) {
      this.codMode = 'COD';
    } else {
      this.codMode = null;
    }
  }

}
