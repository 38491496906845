import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from "sweetalert";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { StockService } from "src/app/dataService/stock-service";
import { LrService } from "src/app/dataService/lr-service";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { ReplaySubject, Subject } from "rxjs";
import {
  NgbModal, ModalDismissReasons
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-barcode-loadingsheet-mobile',
  templateUrl: './barcode-loadingsheet-mobile.component.html',
  styleUrls: ['./barcode-loadingsheet-mobile.component.css']
})
export class BarcodeLoadingSheetMobileComponent implements OnInit {
  isLoggedIn = true;
  viewBarcodeEntryForm = true;

  loadedLr = 0; // Example data
  loadedArticles = 0; // Example data
  totalLr = 0; // Example data
  totalArticles = 0; // Example data
  userDataDtoReturnSession: any;
  selectedLoadingLocalTripData: any;
  selectedLoadingLocalTripId: any;
  lrDtoSearch: LRDto = new LRDto();
  showSpinnerForAction = false;
  loadingSheetDataList: any;
  loadedLRsDataList: any;
  lastInputTime: number | null = null;
  barcodeInput: string = '';
  scanTimeout: any;
  lrDtoUpdate: LRDto = new LRDto();
  selectedLoadingLocalTripNo: any;
  lrDtoSetTripDetailsReturn: LRDto = new LRDto();
  listOfLrs: any;
  lrDtoDepartTrip: LRDto = new LRDto();
  pageId = "blsm";
  showPopupPlusBtn: Boolean = false;
  closeResult: string;
  dtTriggerLoadingSheet: ReplaySubject<any> = new ReplaySubject(1);
  dtOptionsLoadingSheet: any;
  totalTableArticles: number = 0;
  totalLoadingArticles: number = 0;
  totalRemainingArticles: number = 0;
  totalActualWeight: number = 0;
  totalChargedWeight: number = 0;
  enteredManualLrNumber: any;
  enteredManualBarcodeArticles: any;
  lrDtoBarcode: LRDto = new LRDto();
  lrDtoForLrRet = new LRDto();
  constructor(private router: Router, private stockService: StockService,
    public changeDetectorRef: ChangeDetectorRef,
    private lrService: LrService,
    private hireslipService: HireslipService,
    private modalService: NgbModal) {

    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.selectedLoadingLocalTripData = JSON.parse(
        localStorage.getItem("stockForTripsheetDashboardLocalStorage")
      );
      if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
        this.selectedLoadingLocalTripId = this.selectedLoadingLocalTripData.invoiceNumber;

      } else {
        this.selectedLoadingLocalTripId = this.selectedLoadingLocalTripData.tripId;


      }
      this.selectedLoadingLocalTripNo = this.selectedLoadingLocalTripData.localTripNumber;

      this.getLocalTripLoadinSheetDetails();

    }
  }

  ngOnInit(): void {
    (this.dtOptionsLoadingSheet = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 400,
      scrollCollapse: true,
      paging: false,
      info: false,
    })
    window.addEventListener('keydown', this.handleBarcodeScan);
  }
  ngOnDestroy(): void {
    this.dtTriggerLoadingSheet.unsubscribe();
    window.removeEventListener('keydown', this.handleBarcodeScan);
  }
  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  getLocalTripLoadinSheetDetails() {
    this.lrDtoSearch = new LRDto();

    this.dtTriggerLoadingSheet = new ReplaySubject<any>();
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoSearch.invoiceNumber = this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoSearch.status = this.selectedLoadingLocalTripData.type != null && this.selectedLoadingLocalTripData.type == 'Unload' ? 'Transit' : 'Loading';
      this.lrDtoSearch.mode = 'invoice';
    } else {
      this.lrDtoSearch.invoiceNumber = this.selectedLoadingLocalTripData.tripId;
      this.lrDtoSearch.mode = 'localTrip';
      this.lrDtoSearch.status = 'localScheduled';
    }

    this.showSpinnerForAction = true;


    this.stockService.getLrBarcodeLoadingSheetFlow(this.lrDtoSearch).subscribe(
      (response) => {
        this.loadingSheetDataList = [];
        this.loadedLRsDataList = [];
        this.totalLr = 0;
        this.totalArticles = 0;
        this.loadedLr = 0;
        this.loadedArticles = 0;

        if (response.length > 0) {
          response.forEach((item) => {
            this.totalArticles += item.totalArticles;
            this.loadedArticles += item.loadingArticle;

            if (item.remArticles == 0) {
              this.loadedLRsDataList.push(item);
              this.loadedLr++;
            } else {
              this.loadingSheetDataList.push(item);
              this.totalLr++;
            }
          });
          console.log("this.loadingSheetDataList>>" + this.loadingSheetDataList);
          console.log("this.loadedLRsDataList>>" + this.loadedLRsDataList);
          this.totalLr += this.loadedLRsDataList.length;
          this.updateTotals();
        } else {
          swal("Alert", "No Records Found", "warning");
        }

        $("#" + this.pageId + "loadingSheetTableId").DataTable().destroy();
        this.dtTriggerLoadingSheet.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting details!", "warning");
      },
      () => console.log("done")
    );
  }


  handleBarcodeScan = (event: KeyboardEvent) => {
    const currentTime = new Date().getTime();

    // If the interval between keystrokes is too long, reset the input
    if (this.lastInputTime && currentTime - this.lastInputTime > 100) {
      this.barcodeInput = '';
    }

    this.lastInputTime = currentTime;

    // Handle 'Enter' key specifically for completing the barcode scan
    if (event.key === 'Enter') {
      if (this.barcodeInput.length > 5) { // Set a minimum length for barcode
        this.processScannedBarcode(this.barcodeInput, null, 'scan');
      }
      this.barcodeInput = '';
    } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
      this.barcodeInput += event.key;
    }

    // Reset input if no further input is detected within 100ms (barcode scanner inputs are typically fast)
    clearTimeout(this.scanTimeout);
    this.scanTimeout = setTimeout(() => {
      this.barcodeInput = '';
    }, 100);
  };
  processScannedBarcode(scannedBarcode, lrNumber, mode) {
    console.log("Scanning barcode:", scannedBarcode);
    let loadingSheetData: any; // Declares a variable of type `any`

    // Find if the scanned barcode exists in the loading sheet data list
    if ((mode != null && mode == 'manual') && lrNumber != null && lrNumber != undefined && lrNumber != '') {
      loadingSheetData = this.loadingSheetDataList.find(data =>
        data.lrNumber === lrNumber && data.listOfBarcodes.includes(scannedBarcode)
      );
    } else {
      loadingSheetData = this.loadingSheetDataList.find(data =>
        data.listOfBarcodes.includes(scannedBarcode)
      );
    }
    if (loadingSheetData) {
      const barcodeIndex = loadingSheetData.listOfBarcodes.indexOf(scannedBarcode);
      console.log("Found loadingSheetData:", loadingSheetData);
      console.log("Barcode index in listOfBarcodes:", barcodeIndex);

      if (barcodeIndex !== -1) {
        if (loadingSheetData.loadingArticle < loadingSheetData.totalArticles) {
          // Update the necessary properties
          loadingSheetData.barcodeValue = scannedBarcode;
          loadingSheetData.barcodeIndex = barcodeIndex;

          // Call update method
          this.updateLoadingSheetLocalTripLr(loadingSheetData);
          this.clearPlusMinusPopupFields();
          // Trigger change detection
          this.changeDetectorRef.detectChanges();
        } else {
          // Warning when loading limit is reached
          swal({
            title: "Not Allowed!",
            text: "Loading Articles has reached the total available articles!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
      } else {

        swal({
          title: "Barcode Not Valid",
          text: "This barcode has already been scanned or is invalid!",
          icon: "info",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }
    } else {



      swal({
        title: "Barcode Not Valid",
        text: "This barcode has already been scanned or is invalid!",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });



    }

  }

  updateLoadingSheetLocalTripLr(loadingSheetData) {
    this.loadingSheetLocalTripLrData(loadingSheetData);
    this.lrService.updateLrLoadingSheetLocalTripSingleLrDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status == "Duplicate") {
          swal("Warning", "This article already loaded or invalid barcode", "warning");
        } else if (response.status == "Failed") {
          swal("Error", "Problem occurred while updating the details", "error");
        } else {
          loadingSheetData.loadingArticle += 1;
          loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

          // Update the global counters for loaded articles and loaded LRs
          this.loadedArticles += 1;

          // If all articles for the LR are loaded, move it to the loaded list
          if (loadingSheetData.remArticles == 0) {
            if (!this.loadedLRsDataList.find(x => x.lrNumber === loadingSheetData.lrNumber)) {
              this.loadedLRsDataList.push(loadingSheetData);
              this.loadedLr += 1; // Increment loaded LR count
            }

            // Remove from the pending list
            const deleteRowIndex = this.loadingSheetDataList.findIndex((x) => x.lrNumber === loadingSheetData.lrNumber);
            if (deleteRowIndex !== -1) {
              this.loadingSheetDataList.splice(deleteRowIndex, 1);
            }
          }
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }





  loadingSheetLocalTripLrData(loadingSheetData) {
    console.log("this.selectedLoadingLocalTripData" + this.selectedLoadingLocalTripData);
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoUpdate.mode = 'invoice'
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
      this.lrDtoUpdate.mode = 'localTrip'
    }
    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.remarks = loadingSheetData.remarks;
    this.lrDtoUpdate.barcodeValue = loadingSheetData.barcodeValue;
    this.lrDtoUpdate.type = this.selectedLoadingLocalTripData.type;
  }

  validateDepartureTripSheet() {
    if (this.loadedArticles == 0) {
      swal(
        "Not Allowed",
        "No articles loaded to update the Loading Sheet!",
        "warning"
      );
      return false;
    } else if (this.loadedArticles < this.totalArticles) {
      swal({
        title: "Incomplete Loading",
        text: "All articles are not loaded, sure you want to depart this trip?",
        icon: "warning",
        buttons: ["No", "Yes"],
      }).then((isConfirm) => {
        if (isConfirm) {
          this.confirmDepart('Partial');

        }
      });

    } else {
      this.confirmDepart('All');
    }
  }

  confirmDepart(loadingMode) {
    this.lrDtoSetTripDetailsReturn = new LRDto();
    console.log("this.selectedLoadingLocalTripData.tripId" + this.selectedLoadingLocalTripData.tripId);
    this.listOfLrs = [];
    this.lrDtoSetTripDetailsReturn.tripId = this.selectedLoadingLocalTripData.tripId;
    this.lrDtoSetTripDetailsReturn.status = 'Pending';
    this.lrDtoSetTripDetailsReturn.mode = 'booking';
    this.lrDtoSetTripDetailsReturn.mainstation = this.userDataDtoReturnSession.mainStation;
    this.lrDtoSetTripDetailsReturn.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoSetTripDetailsReturn.localTripMulti = this.userDataDtoReturnSession.localTripMulti;
    if (loadingMode != null && loadingMode == 'Partial') {
      for (let i = 0; i < this.loadingSheetDataList.length; i++) {
        this.listOfLrs.push(this.loadingSheetDataList[i].lrNumber);
      }
    } else {
      for (let i = 0; i < this.loadedLRsDataList.length; i++) {
        this.listOfLrs.push(this.loadedLRsDataList[i].lrNumber);
      }
    }

    this.lrDtoSetTripDetailsReturn.list = this.listOfLrs;
    this.departTrip(this.lrDtoSetTripDetailsReturn);
  }
  departTrip(lrdtoNewTripCreate) {
    console.log(lrdtoNewTripCreate);
    this.lrDtoDepartTrip = new LRDto();
    this.lrDtoDepartTrip = lrdtoNewTripCreate;
    this.showSpinnerForAction = true;
    $("#" + this.pageId + "departureBtn").prop('disabled', true);
    this.hireslipService.updateTripSheetDepartureDetails(this.lrDtoDepartTrip).subscribe(
      (response) => {
        console.log(response);

        if (response.status != null && response.status == "Success") {

          swal("Success", "Trip Departed successfully!", "success");

          $("#" + this.pageId + "departureBtn").prop('disabled', false);
        } else {
          swal("Server Error", "Failed to  Create A TripSheet Details ", "error");
          $("#" + this.pageId + "departureBtn").prop('disabled', false);
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "departureBtn").prop('disabled', false);
        swal("Server Error", "Problem occur while departing a  TripSheet Details ", "error");
      },
      () => console.log('done');
  }

  onPlusButtonClick(contenetUpdateOnAction) {

    this.showPopupPlusBtn = true;

    this.modalService.open(contenetUpdateOnAction, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  proceedbarcodeNoPopupPlusBtn() {
    let scannedBarcode = $("#" + this.pageId + "barcodeNoPopupId").val();
    if (scannedBarcode == null || scannedBarcode == undefined || scannedBarcode == '') {
      swal(
        "Not Allowed",
        "Please enter Barcode No to proceed",
        "warning"
      );
    } else {
      this.processScannedBarcode(scannedBarcode, null, 'manual');
    }

  }


  proceedbarcodeNoPopupMinusBtn() {
    let scannedBarcode = $("#" + this.pageId + "barcodeNoPopupId").val();
    if (scannedBarcode == null || scannedBarcode == undefined || scannedBarcode == '') {
      swal(
        "Not Allowed",
        "Please enter Barcode No to proceed",
        "warning"
      );
    } else {
      this.removeBarcodeOnMinusClick(scannedBarcode);
    }


  }

  getDataForDtoOnMinusButtonClick(scannedBarcode) {
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.barcodeValue = scannedBarcode;
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.invoiceNumber;
    } else {
      this.lrDtoUpdate.invoiceNumber =
        this.selectedLoadingLocalTripData.tripId;
    }
    this.lrDtoUpdate.type = this.selectedLoadingLocalTripData.type;
    this.lrDtoUpdate.mode = 'allLr';

  }
  removeBarcodeOnMinusClick(scannedBarcode) {
    this.getDataForDtoOnMinusButtonClick(scannedBarcode);
    this.showSpinnerForAction = true;
    this.lrService
      .checkAndRemoveLoadedBarcodeDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status == "Success") {
          swal(
            "Success",
            "Entered Barcode removed from the loaded article!",
            "info"
          );
          this.clearPlusMinusPopupFields();
          this.getLocalTripLoadinSheetDetails();
        } else {
          swal("Warning", "Entered Barcode not exists please verify the Barcode Number!", "warning");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }

  clearPlusMinusPopupFields() {
    $("#" + this.pageId + "barcodeNoPopupId").val('');
    this.modalService.dismissAll();
    this.showPopupPlusBtn = false;
  }

  onMinusButtonClick(contenetUpdateOnAction) {

    this.showPopupPlusBtn = false;
    this.modalService.open(contenetUpdateOnAction, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  validateManualLoad(contentForManualLoad) {
    this.modalService.open(contentForManualLoad, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );


  }
  clearManualLoading() {
    $("#" + this.pageId + "manualLoadLrNumberId").val('');
    $("#" + this.pageId + "manualLoadArtcilesId").val('');
  }

  onLoadedArticlesClick(contentLoadedLrs) {
    this.showSpinnerForAction = true;
    this.getLocalTripLoadinSheetDetails(); // Load the details
    setTimeout(() => {
      this.modalService.open(contentLoadedLrs, { centered: true }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
      this.showSpinnerForAction = false;
    }, 500); // Add a slight delay for smooth UX.
  }

  popupClose() {
    this.modalService.dismissAll();
  }
  updateTotals() {
    this.totalTableArticles = this.loadingSheetDataList.reduce((sum, item) => sum + item.totalArticles, 0);
    this.totalLoadingArticles = this.loadingSheetDataList.reduce((sum, item) => sum + item.loadingArticle, 0);
    this.totalRemainingArticles = this.loadingSheetDataList.reduce((sum, item) => sum + item.remArticles, 0);
    this.totalActualWeight = this.loadingSheetDataList.reduce((sum, item) => sum + item.actualWeight, 0);
    this.totalChargedWeight = this.loadingSheetDataList.reduce((sum, item) => sum + item.chargedWeight, 0);
  }
  getManualBarcodeEntryPopupData() {
    this.enteredManualLrNumber = $("#" + this.pageId + "manualLoadLrNumberId").val();
    this.enteredManualBarcodeArticles = $("#" + this.pageId + "manualLoadArtcilesId").val();
    this.lrDtoBarcode = new LRDto();
    this.lrDtoBarcode.lrNumber = this.enteredManualLrNumber;
    this.lrDtoBarcode.totalArticles = this.enteredManualBarcodeArticles;
    this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;
    this.lrDtoBarcode.stocksAt = this.userDataDtoReturnSession.office;
    this.lrDtoBarcode.status = 'Pending';
    if (this.selectedLoadingLocalTripData.mode != null && this.selectedLoadingLocalTripData.mode == 'invoice') {
      this.lrDtoBarcode.invoiceNumber = this.selectedLoadingLocalTripData.invoiceNumber;
      this.lrDtoBarcode.mode = 'invoice';
    } else {
      this.lrDtoBarcode.invoiceNumber = this.selectedLoadingLocalTripData.tripId;
      this.lrDtoBarcode.mode = 'localTrip';
      this.lrDtoBarcode.status = 'localScheduled';
    }
    this.lrDtoBarcode.type = this.selectedLoadingLocalTripData.type;
    console.log(this.lrDtoBarcode);
  }
  submitManualLoading() {

    this.getManualBarcodeEntryPopupData();
    this.showSpinnerForAction = true;

    this.lrService.generateManualMasterLRBarcode(this.lrDtoBarcode).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.lrDtoForLrRet = new LRDto();
        this.lrDtoForLrRet = response;

        if (this.lrDtoForLrRet.status === "Already Exists") {
          swal({
            title: "Warning",
            text: "Barcode Already Generated for this LR! You can load by scanning LR Number or Use + Icon else contact admin",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "LR Not Found") {
          swal({
            title: "Warning",
            text: "LR Not found in this trip! Please verify the LR Number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }else if (this.lrDtoForLrRet.status === "Art Greater") {
          swal({
            title: "Warning",
            text: "Entered Article is greater than the LR's Article please check the entered article",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "Already Entered") {
          swal({
            title: "Warning",
            text: "This LR Already entered to this trip please verify the LR Number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.lrDtoForLrRet.status === "Success") {
          this.modalService.dismissAll();
          this.getLocalTripLoadinSheetDetails();
        } else {
          swal({
            title: "Warning",
            text: "Failed to generate barcode",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }

        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred adding barcode details", "info");
      },
      () => console.log('done')
    );

  }
}
