<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrShortAndExtraConsolidateRptV2">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Short & Extra Overview</h6>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Search By</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                            name="searchBy" #searchBy
                                                            (change)="searchByMode(searchBy.value)">
                                                            <option selected value="lrNoWise">LR No Wise</option>
                                                            <!-- <option value="hireslipNoWise">Hireslip No Wise</option>
                                                            <option value="dateWise">Date Wise</option> -->

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByLrNo" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>LR Number</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}lrNumber"
                                                            (keyup)="fieldFocus($event, searchBtn)" class="form-control"
                                                            #lrNumber name="lrNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByHireslipNo" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Hireslip No</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}hireslipNumber"
                                                            (keyup)="fieldFocus($event, searchBtn)" #hireslipNumber
                                                            name="hireslipNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchByDateWise" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>Date Type</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" name="modelDateType"
                                                            id="{{pageId}}dateType" #dateType
                                                            (change)="dateTypeMode(dateType.value)">
                                                            <option selected value="hireslipDateWise" selected>HireSlip
                                                                Date</option>
                                                            <option value="stockInDateWise">Stock In Date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewDate" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <div class="input-group">
                                                        <input class="form-control" id="{{pageId}}fromDate"
                                                            placeholder="dd-mm-yyyy" name="fromDate" ngbDatepicker
                                                            #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <label>To Date</label>
                                                    <div class="input-group">
                                                        <input class="form-control" placeholder="dd-mm-yyyy"
                                                            id="{{pageId}}toDate" name="toDate" ngbDatepicker
                                                            #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showSrc" class="col-sm-12 col-md-2">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <label>Source</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #sourceSubStationName name="sourceSubStationNameId"
                                                                type="text" class="form-control"
                                                                placeholder="Select Source Name"
                                                                (selectItem)="sourceSubStationDropDownListner($event)"
                                                                id="{{pageId}}source"
                                                                [ngbTypeahead]="sourceSubStationNameSearchTA"
                                                                [resultFormatter]="formatterSourceSubStationName"
                                                                [inputFormatter]="formatterSourceSubStationName"
                                                                (focus)="focusSourceSubStationNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showDest" class="col-sm-12 col-md-2">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <label>Destination</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #destination type="text" class="form-control"
                                                                (selectItem)="clickListnerForDestination($event,input)"
                                                                id="{{pageId}}destination"
                                                                [ngbTypeahead]="searchDestination"
                                                                [resultFormatter]="formatterDestination"
                                                                [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination.." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}searchBtn" #searchBtn
                                                    (click)="searchDetailsBtn()">Search</button>
                                                <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                                    (click)="clearBtn()">Clear</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <hr style="width: 80%; border-top: none; margin: 3px;"> -->
                    </div>
                    <div class="col-md-12 vl p-t-10">
                        <div *ngIf="showSpinnerForAction" class="col-md-8">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10">
                                        <div class="col-md-8"></div>
                                        <div class="col-md-4">
                                            <button type="submit" class="dt-button" style="float: right;"
                                                id="{{pageId}}printBtn">
                                                <span><i class="fas fa-print">Print</i></span>
                                            </button>

                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{ pageId }}lrShortAndExtraConsolidateTableId"
                                                    class="table table-striped table-bordered row-border hover"
                                                    [dtOptions]="dtOptionsLrShortAndExtraConsolidate"
                                                    [dtTrigger]="dtTriggerLrShortAndExtraConsolidate">
                                                    <thead>
                                                        <tr>
                                                           
                                                            <ng-container *ngFor="let header of dynamicHeaders">
                                                                <th colspan="2">{{ header.title }}</th>
                                                            </ng-container>
                                                        </tr>
                                                        <tr>
                                                            <!-- Dynamic Sub-Headers for Load and Unload -->
                                                            <ng-container *ngFor="let header of dynamicHeaders">
                                                                <th>Load</th>
                                                                <th>Unload</th>
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of lrShortAndExtraConsolidateDataList">
                                                           
                                                            <ng-container *ngFor="let header of dynamicHeaders">
                                                                <td>{{ data[header.key + 'Load'] }}</td>
                                                                <td>{{ data[header.key + 'Unload'] }}</td>
                                                            </ng-container>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>




                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>



</body>

</html>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>