import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { LRDto } from 'src/app/dto/LR-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { map } from 'rxjs/operators';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { TripSheetDto } from 'src/app/dto/TripSheet-dto';
import { Invoice } from 'src/app/dataService/invoice';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { RequestDto } from 'src/app/dto/Request-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-local-trip-sheet',
    templateUrl: './local-trip-sheet.component.html',
    styleUrls: ['./local-trip-sheet.component.css']
})
export class LocalTripSheetComponent implements OnInit {

    localTripSheetRptDataList: any;
    localTripSheetRptByPartyDataList: any;
    onDestroyUnsubscribtionLocalTripSheetRpt: Subscription;
    onDestroyUnsubscribtionLocalTripSheetRptByParty: Subscription;
    pageId = "ltrpsr";
    model: NgbDateStruct;
    dummyHideId = false;
    searchByVehicleNumber = false;
    viewAreaName = false;
    searchByParty = false;
    searchByDeliveryman = false;
    searchByDriver = false;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLocalTripSheetRpt: Subject<any> = new Subject();
    dtTriggerLocalTripSheetRptByParty: Subject<any> = new Subject();
    dtTriggerLocalTripSheetRptDriverwise: Subject<any> = new Subject();
    dtOptionsGcLeftToParty: any;
    dtOptionLocalTripSheetRptByParty: any;
    dtOptionsDriverwise: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction = false;

    areaOptions: LRDto[];
    lrDtoBranchArea: LRDto = new LRDto();
    lrDtoBranchAreaAllOption: LRDto = new LRDto();
    public modelAreaName: any;
    areaNameTA: Array<LRDto> = [];
    focusAreaNameTA$ = new Subject<string>();
    searchAreaName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAreaNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.areaNameTA
                : this.areaNameTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAreaName = (x: { godownName: string }) => x.godownName;

    consigneeNameOptions: PartyMasterDto[];
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    public modelConsigneePartyName: any;
    consigneePartyNameTA: Array<PartyMasterDto> = [];
    focusConsigneePartyNameTA$ = new Subject<string>();
    searchConsigneePartyName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneePartyNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneePartyNameTA
                : this.consigneePartyNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneePartyName = (x: { consigneeName: string }) => x.consigneeName;

    truckDataDto: TruckDataDto = new TruckDataDto();
    vehicleNumberTruckDtoOptions: TruckDataDto[];

    public modelVehicleNumber: any;
    vehicleNumberTA: Array<TruckDataDto> = [];
    focusVehicleNumberTA$ = new Subject<string>();
    searchVehicleNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVehicleNumberTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberTA
                : this.vehicleNumberTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVehicleNumber = (x: { truckNumber: string }) => x.truckNumber;

    public modelVehicleNumberUpdate: any;
    vehicleNumberUpdateTA: Array<TruckDataDto> = [];
    focusVehicleNumberUpdateTA$ = new Subject<string>();
    searchVehicleNumberUpdate = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVehicleNumberUpdateTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberUpdateTA
                : this.vehicleNumberUpdateTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVehicleNumberUpdate = (x: { truckNumber: string }) => x.truckNumber;

    labourHeadNameOption: Array<HireSlipDto> = [];
    hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

    driverDataDtoAllOption: DriverdetailsDto = new DriverdetailsDto();
    driverNameOption: Array<DriverdetailsDto> = [];
    driverDataDtoDriverName: DriverdetailsDto = new DriverdetailsDto();
    public modelDropDownDriverName: any;
    driverNameTA: Array<DriverdetailsDto> = [];
    focusDropDownDriverNameTA$ = new Subject<string>();
    searchDropDownInputFieldDriverName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDriverNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameTA
                : this.driverNameTA.filter(v => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDriverName = (x: { driverName: string }) => x.driverName;

    //
    public modelDropDownDriverNameUpdate: any;
    driverNameUpdateTA: Array<DriverdetailsDto> = [];
    focusDropDownDriverNameUpdateTA$ = new Subject<string>();
    searchDropDownInputFieldDriverNameUpdate = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDriverNameUpdateTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameUpdateTA
                : this.driverNameUpdateTA.filter(v => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDriverNameUpdate = (x: { driverName: string }) => x.driverName;
    //
    hireSlipDtoLabourHeadNameUpdate: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadNameUpdate: any;
    labourHeadNameTAUpdate: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTAUpdate$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadNameUpdate = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTAUpdate
                : this.labourHeadNameTAUpdate.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadNameUpdate = (x: { loaderHead: string }) => x.loaderHead;

    fromDatesModal: any = null;
    toDatesModal: any = null;

    //lrDto: LRDto = new LRDto();
    tripDto: TripSheetDto = new TripSheetDto();
    lrDtoPopUp: LRDto = new LRDto();
    mode: string = null;
    localTripSheetRptByPartyTable = false;
    gcLeftToPartyTable = false;
    driverwiseTable = false;

    validateAreaName: any = null;
    validateConsigneePartyName: any = null;
    validateConsigneePartyId: any = null;
    validateTruckNumber: any = null;
    validateTruckName: any = null;
    validateFromDate: any = null;
    validateToDate: any = null;
    enteredLoaderHeadName = null;
    enteredDriveName = null;

    modalRefferenceLocalTripSheetPopUp: NgbModalRef;
    modalRefferenceLocalTripSheetClosePopUp: string;
    @ViewChild('localTripSheetPopUpTemplate') private localTripSheetPopUpTemplate;

    modalRefferenceLocalTripSheetHamaliNamePopUp: NgbModalRef;
    modalRefferenceLocalTripSheetHamaliNameClosePopUp: string;
    @ViewChild('localTripSheetHamalisNamePopUpTemplate') private localTripSheetHamalisNamePopUpTemplate;

    viewLocalTripSheetLrDetailsPopUp = false;
    labourHamaliNamesDto: TripSheetDto = new TripSheetDto();
    labourHamaliNamesList: TripSheetDto[];

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    enterArtSummary: number;
    articlesSummary: number;

    viewCustomPrintV1: boolean;

    lrsSummary: number;
    artSummary: number;
    actWtSummary: number;
    chgWtSummary: number;
    totAmtSummary: number;
    hamaliSummary: number;
    viewToShowDeliveryManField = false;
    setTripNo = null;
    closeResult: string;
    updatedDriverName = null;
    setTripId = null;
    isDriverEditEnabled: boolean = false;
    localTripOfc = null;
    updatedVehicleNumber = null;
    isTripDeleteEnabled: boolean = false;
    updatedDeliveryMan = null;
    consolidateEwaybillEnabled: boolean = false;
    requestDto: RequestDto = new RequestDto();
    listOfExpiredEwaybills: Array<String> = [];
    dcDataList: any;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dtOptionsLrDetails: any;
    selectedVehicleNumberForDC = null;

    constructor(private router: Router, private masterReadService: MasterReadService, private invoice: Invoice,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef, private reportService: ReportService,
        private ewaybillService: EwaybillService, public dialog: MatDialog) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip) {
                this.consolidateEwaybillEnabled = true;
            }
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LocalTrip Driver Edit") {
                        this.isDriverEditEnabled = true;
                    }
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LocalTrip Trip Delete") {
                        this.isTripDeleteEnabled = true;
                    }
                }
            }
            this.getConsigneeDetails();
            this.getBranchAreaDetailsList();
            this.getVehicleMasterList();
            this.setLabourHeadNameDetailsList();
            this.gcLeftToPartyTable = true;

            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.viewToShowDeliveryManField = true;
                this.setDriverNameDetailsList();
            } else {
                this.viewToShowDeliveryManField = false;
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var groupColumnDriver = 7;
        this.dtOptionsGcLeftToParty = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Local Trip Sheet Report - " +
                            "From Date : " + moment($("#ltrpsrfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ltrpsrtoDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    },
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 310,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArticle = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actualWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalAmt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamaliAmt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoGDHamali = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lrDDAmt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(totalLrs);
                $(api.column(6).footer()).html(totalArticle);
                $(api.column(7).footer()).html(actualWgt);
                $(api.column(8).footer()).html(chgWgt);
                $(api.column(10).footer()).html(totalAmt);
                $(api.column(11).footer()).html(hamaliAmt);
                $(api.column(12).footer()).html(memoGDHamali);
                $(api.column(13).footer()).html(lrDDAmt);
                $(api.column(14).footer()).html(aoc);
            },
        }

        this.dtOptionLocalTripSheetRptByParty = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Local Trip Sheet Report - " +
                            "From Date : " + moment($("#ltrpsrfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ltrpsrtoDates").val()).format('DD-MM-YYYY') + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 310,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var article = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamaliGD = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actualWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(article);
                $(api.column(6).footer()).html(hamaliGD);
                $(api.column(7).footer()).html(actualWgt);
                $(api.column(8).footer()).html(chgWgt);
            },
        },
            this.dtOptionsDriverwise = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "Local Trip Sheet Report - " +
                                "From Date : " + moment($("#ltrpsrfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#ltrpsrtoDates").val()).format('DD-MM-YYYY') + "";
                        },
                        exportOptions: {
                            columns: ':visible'
                        },
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 310,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalArticle = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actualWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totalAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var hamaliAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var aoc = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(5).footer()).html(totalLrs);
                    $(api.column(6).footer()).html(totalArticle);
                    $(api.column(7).footer()).html(actualWgt);
                    $(api.column(8).footer()).html(chgWgt);
                    $(api.column(10).footer()).html(totalAmt);
                    $(api.column(11).footer()).html(hamaliAmt);
                    $(api.column(12).footer()).html(aoc);
                }
            }

        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": false
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerLocalTripSheetRpt.next();
        this.dtTriggerLocalTripSheetRptByParty.next();
        this.dtTriggerLrDetails.next();
        //this.dtTriggerLocalTripSheetRptDriverwise.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerLocalTripSheetRpt.unsubscribe();
        this.dtTriggerLocalTripSheetRptByParty.unsubscribe();
        this.dtTriggerLocalTripSheetRptDriverwise.unsubscribe();
        this.dtTriggerLrDetails.unsubscribe();
    }

    //input field Hide and Show
    searchByMode(searchBy: string) {
        this.tripDto = new TripSheetDto();
        if (searchBy === 'all') {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = false;
            this.searchByParty = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = false;
            this.gcLeftToPartyTable = true;
            this.localTripSheetRptByPartyTable = false;
            this.driverwiseTable = false;
            this.localTripSheetRptDataList = [];
            $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRpt.next();
            this.tripDto.mode = "All";
            this.mode = this.tripDto.mode;
        } else if (searchBy === 'vehicleNumber') {
            this.clearField();
            this.searchByVehicleNumber = true;
            this.viewAreaName = false;
            this.searchByParty = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = false;
            this.gcLeftToPartyTable = true;
            this.localTripSheetRptByPartyTable = false;
            this.driverwiseTable = false;
            this.localTripSheetRptDataList = [];
            $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRpt.next();
            this.tripDto.mode = "vehicle";
            this.mode = this.tripDto.mode;
        } else if (searchBy === 'area') {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = true;
            this.searchByParty = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = false;
            this.gcLeftToPartyTable = true;
            this.localTripSheetRptByPartyTable = false;
            this.driverwiseTable = false;
            this.localTripSheetRptDataList = [];
            $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRpt.next();
            this.tripDto.mode = "area";
            this.mode = this.tripDto.mode;
        } else if (searchBy === 'party') {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = false;
            this.searchByParty = true;
            this.gcLeftToPartyTable = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = false;
            this.localTripSheetRptByPartyTable = true;
            this.driverwiseTable = false;
            this.localTripSheetRptByPartyDataList = [];
            $("#" + this.pageId + "localTripSheetRptByPartyTableId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRptByParty.next();
            this.tripDto.mode = "party";
            this.mode = this.tripDto.mode;
        } else if (searchBy === 'deliveryman') {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = false;
            this.searchByParty = false;
            this.searchByDeliveryman = true;
            this.searchByDriver = false;
            this.gcLeftToPartyTable = true;
            this.localTripSheetRptByPartyTable = false;
            this.driverwiseTable = false;
            this.localTripSheetRptDataList = [];
            this.localTripSheetRptByPartyDataList = [];
            $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRpt.next();
            this.tripDto.mode = "deliveryman";
            this.mode = this.tripDto.mode;
        } else if (searchBy === 'driverwise') {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = false;
            this.searchByParty = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = true;
            this.gcLeftToPartyTable = false;
            this.driverwiseTable = true;
            this.localTripSheetRptByPartyTable = false;
            this.localTripSheetRptDataList = [];
            $("#" + this.pageId + "localTripSheetRptTableDriverwiseId").DataTable().destroy();
            this.dtTriggerLocalTripSheetRptDriverwise.next();
            this.tripDto.mode = "driverwise";
            this.mode = this.tripDto.mode;
        } else {
            this.clearField();
            this.searchByVehicleNumber = false;
            this.viewAreaName = false;
            this.searchByParty = false;
            this.searchByDeliveryman = false;
            this.searchByDriver = false;
            this.tripDto.mode = "All";
            this.mode = this.tripDto.mode;
            this.gcLeftToPartyTable = true;
            this.localTripSheetRptByPartyTable = false;
        }
    }

    clearBtn() {
        this.clearField();
        this.fromDatesModal = null;
        this.toDatesModal = null;
        $("#" + this.pageId + "searchBy").val('all');
        this.searchByVehicleNumber = false;
        this.viewAreaName = false;
        this.searchByParty = false;
        this.searchByDeliveryman = false;
        this.gcLeftToPartyTable = true;
        this.localTripSheetRptByPartyTable = false;
        this.localTripSheetRptDataList = [];
        $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
        this.dtTriggerLocalTripSheetRpt.next();

        this.localTripSheetRptByPartyDataList = [];
        $("#" + this.pageId + "localTripSheetRptByPartyTableId").DataTable().destroy();
        this.dtTriggerLocalTripSheetRptByParty.next();

        this.localTripSheetRptDataList = [];
        $("#" + this.pageId + "localTripSheetRptTableDriverwiseId").DataTable().destroy();
        this.dtTriggerLocalTripSheetRptDriverwise.next();
        this.mode = "All"
    }

    clearField() {
        this.modelAreaName = null;
        this.modelConsigneePartyName = null;
        this.modelVehicleNumber = null;
        this.validateAreaName = null;
        this.validateConsigneePartyName = null;
        this.validateConsigneePartyId = null;
        this.validateTruckNumber = null;
        this.validateTruckName = null;
        this.validateFromDate = null;
        this.validateToDate = null;
        this.tripDto = new TripSheetDto();
    }


    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker


    clickListnerForAreaName(event) {
        this.modelAreaName = event.item;
        $("#" + this.pageId + "areaNameId").val(this.modelAreaName.godownName);
        this.validateAreaName = this.modelAreaName.godownName;
    }

    clickListnerForConsigneePartyName(event) {
        this.modelConsigneePartyName = event.item;
        $("#" + this.pageId + "consigneePartyNameId").val(this.modelConsigneePartyName.consigneeName);
        this.validateConsigneePartyName = this.modelConsigneePartyName.consigneeName;
        this.validateConsigneePartyId = this.modelConsigneePartyName.consigneeId;
    }
    clickListnerForVehicleNumber(event) {
        this.modelVehicleNumber = event.item;
        $("#" + this.pageId + "vehicleNumberId").val(this.modelVehicleNumber.truckNumber);
        this.validateTruckNumber = this.modelVehicleNumber.truckNumber;
        this.validateTruckName = this.modelVehicleNumber.vehicleName;
    }

    clickListnerForVehicleNumberUpdate(event) {
        this.modelVehicleNumberUpdate = event.item;
        $("#" + this.pageId + "vehicleNumberUpdate").val(this.modelVehicleNumberUpdate.truckNumber);
    }

    getMethodForBranchArea() {
        this.lrDtoBranchArea = new LRDto();
        /* if(this.userDataDtoReturnSession.mainStation == "Chennai"){
          this.lrDtoBranchArea.mode = "Binnymills";
         }else{
          this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
         }*/
        this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchArea.companyId = this.userDataDtoReturnSession.companyId;
    }

    getBranchAreaDetailsList() {
        this.showSpinnerForAction = true;
        this.getMethodForBranchArea();
        this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchArea).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.areaOptions = response;
                    this.areaNameTA = [];
                    for (let i = 0; i < this.areaOptions.length; i++) {
                        this.areaNameTA.push(this.areaOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    //swal("Server Error", "No record found of area details", "warning");
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting Area Details", "error");
            },
            () => console.log('done');
    };

    getConsigneeDetailsList() {
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
    }
    getConsigneeDetails = () => {
        this.showSpinnerForAction = true;
        this.getConsigneeDetailsList();
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.consigneeNameOptions = response;
                    this.consigneePartyNameTA = [];
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneePartyNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting party details", "error");
            },
            () => console.log('done');
    };

    getVehicleDetailsForRead() {
        this.truckDataDto = new TruckDataDto();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.isActive = true;
    }
    getVehicleMasterList = () => {
        this.showSpinnerForAction = true;
        this.getVehicleDetailsForRead();
        this.masterReadService.getVehicleSetupMaster(this.truckDataDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.vehicleNumberTruckDtoOptions = response;
                    this.vehicleNumberTA = [];
                    this.vehicleNumberUpdateTA = [];
                    for (let i = 0; i < this.vehicleNumberTruckDtoOptions.length; i++) {
                        this.vehicleNumberTA.push(this.vehicleNumberTruckDtoOptions[i]);
                        this.vehicleNumberUpdateTA.push(this.vehicleNumberTruckDtoOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting vehicle details", "error");
            },
            () => console.log('done');
    };

    searchMethod() {
        console.log($("#" + this.pageId + "searchBy").val());
        if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "all") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "")) {
                swal("Mandatory Fields", "From Date And ToDate are Mandatory Fields", "warning");
                return false;
            } else {
                this.setMode();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "vehicleNumber") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "vehicleNumberId").val() == null) || ($("#" + this.pageId + "vehicleNumberId").val() == undefined) ||
                ($("#" + this.pageId + "vehicleNumberId").val() == "")) {
                swal("Mandatory Fields", "From Date , To Date and Vehicle No are Mandatory Fields", "warning");
                return false;
            } else {
                this.setMode();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "area") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "areaNameId").val() == null) || ($("#" + this.pageId + "areaNameId").val() == undefined) ||
                ($("#" + this.pageId + "areaNameId").val() == "")) {
                swal("Mandatory Fields", "From Date , To Date and Area are Mandatory Fields", "warning");
                return false;
            } else {
                this.setMode();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "party") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "consigneePartyNameId").val() == null) || ($("#" + this.pageId + "consigneePartyNameId").val() == undefined) ||
                ($("#" + this.pageId + "consigneePartyNameId").val() == "")) {
                swal("Mandatory Fields", "From Date , To Date and Party are Mandatory Fields", "warning");
                return false;
            } else {
                this.setMode();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "deliveryman") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == null) || ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == undefined) ||
                ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == "")) {
                swal("Mandatory Fields", "From Date , To Date and Delivery Man are Mandatory Fields", "warning");
                return false;
            } else {
                this.setMode();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "driverwise") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == null) || ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == undefined) ||
                ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == "")) {
                swal("Mandatory Fields", "Please select the Mandatory fields to proceed!", "warning");
                return false;
            } else {
                this.setMode();
            }
        }
    }

    setMode() {
        if (this.mode != null && this.mode == "vehicle") {
            this.localTripSheetDetail();
        } else if (this.mode != null && this.mode == "area") {
            this.localTripSheetDetail();
        } else if (this.mode != null && this.mode == "party") {
            this.localTripSheetDetail();
        } else if (this.mode != null && this.mode == "deliveryman") {
            this.localTripSheetDetail();
        } else if (this.mode != null && this.mode == "driverwise") {
            this.localTripSheetDetail();
        } else {
            this.tripDto.mode = "All";
            this.localTripSheetDetail();
        }
    }
    localTripSheetDetail() {
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();


        this.tripDto.fromdate = this.validateFromDate;
        this.tripDto.todate = this.validateToDate;
        this.tripDto.fromDatePrint = this.validateFromDate;
        this.tripDto.toDatePrint = this.validateToDate;

        if ($("#" + this.pageId + "vehicleNumberId").val() != null && $("#" + this.pageId + "vehicleNumberId").val() != undefined &&
            $("#" + this.pageId + "vehicleNumberId").val() != "") {
            this.tripDto.transportName = this.validateTruckName;
            this.tripDto.vehiclenumber = this.validateTruckNumber;
        }
        if ($("#" + this.pageId + "areaNameId").val() != null && $("#" + this.pageId + "areaNameId").val() != undefined &&
            $("#" + this.pageId + "areaNameId").val() != "") {
            this.tripDto.area = this.validateAreaName;
        }
        if ($("#" + this.pageId + "consigneePartyNameId").val() != null && $("#" + this.pageId + "consigneePartyNameId").val() != undefined &&
            $("#" + this.pageId + "consigneePartyNameId").val() != "") {
            this.tripDto.consigneeId = this.validateConsigneePartyId;
            this.tripDto.consigneeName = this.validateConsigneePartyName;
        }

        if ($("#" + this.pageId + "searchBy").val() == "party") {
            this.tripDto.reportMode = "party";
        } else {
            this.tripDto.reportMode = "others";
        }

        this.enteredLoaderHeadName = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
        this.tripDto.deliveryMan = this.enteredLoaderHeadName;

        this.enteredDriveName = $("#" + this.pageId + "dropDownInputFieldDriverName").val();
        this.tripDto.drivername = this.enteredDriveName;

        this.tripDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.tripDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.tripDto);
        this.showSpinnerForAction = true;
        this.invoice.getTripSheetDetails(this.tripDto).subscribe(
            (response) => {
                // console.log(response);
                if (response.length == 0) {
                    this.showSpinnerForAction = false;
                    swal("No Data", "No Record Found for this search", "info");
                } else {
                    if ($("#" + this.pageId + "searchBy").val() == "party") {
                        this.localTripSheetRptByPartyDataList = [];
                        $("#" + this.pageId + "localTripSheetRptByPartyTableId").DataTable().destroy();
                        this.localTripSheetRptByPartyDataList = response;
                        this.dtTriggerLocalTripSheetRptByParty.next();
                        this.showSpinnerForAction = false;
                    } else {
                        this.localTripSheetRptDataList = [];
                        $("#" + this.pageId + "localTripSheetRptTableId").DataTable().destroy();
                        $("#" + this.pageId + "localTripSheetRptTableDriverwiseId").DataTable().destroy();
                        this.localTripSheetRptDataList = response;
                        this.dtTriggerLocalTripSheetRpt.next();
                        this.dtTriggerLocalTripSheetRptDriverwise.next();
                        this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting local trip sheet details", "error");
            },
            () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    localTripSheetListner(localTripSheetPopUpTemplate, localTripSheetRptData) {
        this.modalRefferenceLocalTripSheetPopUp = this.modalService.open(localTripSheetPopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpLocalTripSheet" });
        this.modalRefferenceLocalTripSheetPopUp.result.then((result) => {
            this.modalRefferenceLocalTripSheetClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceLocalTripSheetClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        // console.log(localTripSheetRptData);
        localStorage.clear();
        localStorage.setItem('popupLocalTripSheet', JSON.stringify(localTripSheetRptData));
        this.viewLocalTripSheetLrDetailsPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewLocalTripSheetLrDetailsPopUp = false;
        });
    }

    closePopUpBtn() {
        this.modalRefferenceLocalTripSheetPopUp.close();
    }

    printLocalTripSheet() {
        this.modalRefferenceLocalTripSheetPopUp.close();
        this.viewCustomPrintV1 = true;

        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCustomPrintV1) {
                this.viewCustomPrintV1 = false;
                localStorage.clear();
            }
        });
    }

    localTripSheetHamaliNameListner(localTripSheetHamalisNamePopUpTemplate, localTripSheetRptData) {
        this.modalRefferenceLocalTripSheetHamaliNamePopUp = this.modalService.open(localTripSheetHamalisNamePopUpTemplate,
            { centered: true, size: 'sm' });
        this.modalRefferenceLocalTripSheetHamaliNamePopUp.result.then((result) => {
            this.modalRefferenceLocalTripSheetHamaliNameClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceLocalTripSheetHamaliNameClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        if (localTripSheetRptData.hamaliNames != null) {
            var hamaliNamesSplit = localTripSheetRptData.hamaliNames.split(",");
            this.labourHamaliNamesList = [];
            for (let i = 0; i < hamaliNamesSplit.length; i++) {
                this.labourHamaliNamesDto = new TripSheetDto();
                this.labourHamaliNamesDto.hamaliNames = hamaliNamesSplit[i];
                this.labourHamaliNamesList.push(this.labourHamaliNamesDto);
            }
            //console.log(this.labourHamaliNamesList);
        } else {
            swal("Empty Result", "No Hamalis Name Available To Show", "info");
        }
    }
    onCloseHandled() {
        this.modalRefferenceLocalTripSheetHamaliNamePopUp.close()
        this.labourHamaliNamesList = null;
    }

    getLabourHeadNameDetails() {
        this.hireSlipDtoLabourHeadName = new HireSlipDto();
        this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.getLabourHeadNameDetails();
        this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameTAUpdate = [];
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                        this.labourHeadNameTAUpdate.push(this.labourHeadNameOption[i]);
                    }
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameTAUpdate = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    }

    clickListnerForDropDownInputFieldDriverName(event) {
        this.modelDropDownDriverName = event.item;
        $("#" + this.pageId + "dropDownInputFieldDriverName").val(this.modelDropDownDriverName.driverName);
    }

    clickListnerForDropDownInputFieldDriverNameUpdate(event) {
        this.modelDropDownDriverNameUpdate = event.item;
        $("#" + this.pageId + "dropDownInputFieldDriverNameUpdate").val(this.modelDropDownDriverNameUpdate.driverName);
    }

    getDriverNameDetails() {
        this.driverDataDtoDriverName = new DriverdetailsDto();
        this.driverDataDtoDriverName.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDataDtoDriverName.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDataDtoDriverName.mode = "MainStation";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.driverDataDtoDriverName.mode = "Station";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.office;
        }
    }

    setDriverNameDetailsList() {
        this.getDriverNameDetails();
        this.masterReadService.getDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.driverNameUpdateTA = [];
                    this.driverNameOption = response;

                    this.driverDataDtoAllOption = new DriverdetailsDto();
                    this.driverDataDtoAllOption.driverName = "All";
                    this.driverNameTA.push(this.driverDataDtoAllOption);

                    for (let i = 0; i < this.driverNameOption.length; i++) {
                        this.driverNameTA.push(this.driverNameOption[i]);
                        this.driverNameUpdateTA.push(this.driverNameOption[i]);
                    }
                } else {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.driverNameUpdateTA = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting the driver details", "error");
            },
            () => console.log('done');
    }

    driverUpdatePopUp(contentDriverUpdate, localTripSheetRptData) {
        this.setTripNo = null;
        this.setTripId = null;
        this.localTripOfc = null;
        this.setTripNo = localTripSheetRptData.localTripNumber;
        this.setTripId = localTripSheetRptData.id;
        this.localTripOfc = localTripSheetRptData.office;
        console.log(this.setTripNo + " - " + this.setTripId);
        this.setDriverNameDetailsListUpdate();
        this.modalService.open(contentDriverUpdate, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    getDriverNameDetailsUpdate() {
        this.driverDataDtoDriverName = new DriverdetailsDto();
        this.driverDataDtoDriverName.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDataDtoDriverName.status = "Working";
        this.driverDataDtoDriverName.mode = "Station";
        this.driverDataDtoDriverName.mainStation = this.localTripOfc;
    }

    setDriverNameDetailsListUpdate() {
        this.getDriverNameDetailsUpdate();
        this.masterReadService.getDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.driverNameOption = [];
                    this.driverNameUpdateTA = [];
                    this.driverNameOption = response;
                    for (let i = 0; i < this.driverNameOption.length; i++) {
                        this.driverNameTA.push(this.driverNameOption[i]);
                        this.driverNameUpdateTA.push(this.driverNameOption[i]);
                    }
                } else {
                    this.driverNameOption = [];
                    this.driverNameUpdateTA = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting the driver details", "error");
            },
            () => console.log('done');
    }

    updateDriverNameBtn() {
        this.updatedDriverName = null;
        this.updatedDriverName = $("#" + this.pageId + "dropDownInputFieldDriverNameUpdate").val();
        console.log('updateDriverNameBtn');
        console.log($("#" + this.pageId + "dropDownInputFieldDriverNameUpdate").val());
        if ((this.updatedDriverName == null) || (this.updatedDriverName == undefined) || (this.updatedDriverName == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please select the Driver Name to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#ltrpsrdropDownInputFieldDriverNameUpdate").focus();
            });
        } else {
            swal({
                title: "Update Driver Name",
                text: "Sure you want to Update the Driver Name '" + this.updatedDriverName + "' for the Local Trip : '" + this.setTripNo + "' ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.driverDataDtoDriverName = new DriverdetailsDto();
                    this.driverDataDtoDriverName.driverName = this.updatedDriverName;
                    this.driverDataDtoDriverName.id = this.setTripId;
                    this.driverDataDtoDriverName.userId = this.userDataDtoReturnSession.userName;
                    this.updateDriverNameMethod();
                }
            });
        }
    }

    updateDriverNameMethod() {
        this.showSpinnerForAction = true;
        this.reportService.updateLocalTripDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                if (response.status == 'success') {
                    this.setTripNo = null;
                    this.setTripId = null;
                    this.updatedDriverName = null;
                    this.localTripOfc = null;
                    $("#" + this.pageId + "dropDownInputFieldDriverNameUpdate").val("");
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Driver updated successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                } else if (response.status == 'failed') {
                    swal("Failed", "Failed to update the Driver!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Driver", "error");
            }, () => console.log('done');
    }

    vehicleUpdatePopUp(contentVehicleUpdate, localTripSheetRptData) {
        this.setTripNo = null;
        this.setTripId = null;
        this.localTripOfc = null;
        this.setTripNo = localTripSheetRptData.localTripNumber;
        this.setTripId = localTripSheetRptData.id;
        this.localTripOfc = localTripSheetRptData.office;
        console.log(this.setTripNo + " - " + this.setTripId);
        this.modalService.open(contentVehicleUpdate, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    updateVehicleBtn() {
        this.updatedVehicleNumber = null;
        this.updatedVehicleNumber = $("#" + this.pageId + "vehicleNumberUpdateId").val();
        console.log($("#" + this.pageId + "vehicleNumberUpdateId").val());
        if ((this.updatedVehicleNumber == null) || (this.updatedVehicleNumber == undefined) || (this.updatedVehicleNumber == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please select the Vehicle Number to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#ltrpsrvehicleNumberUpdateId").focus();
            });
        } else {
            swal({
                title: "Update Vehicle Number",
                text: "Sure you want to Update the Vehicle Number '" + this.updatedVehicleNumber + "' for the Local Trip : '" + this.setTripNo + "' ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.driverDataDtoDriverName = new DriverdetailsDto();
                    this.driverDataDtoDriverName.vehicleNumber = this.updatedVehicleNumber;
                    this.driverDataDtoDriverName.id = this.setTripId;
                    this.driverDataDtoDriverName.userId = this.userDataDtoReturnSession.userName;
                    this.updateVehicleNumberMethod();
                }
            });
        }
    }

    updateVehicleNumberMethod() {
        this.showSpinnerForAction = true;
        this.reportService.updateLocalTripVehicle(this.driverDataDtoDriverName).subscribe(
            (response) => {
                if (response.status == 'success') {
                    this.setTripNo = null;
                    this.setTripId = null;
                    this.updatedVehicleNumber = null;
                    this.localTripOfc = null;
                    $("#" + this.pageId + "vehicleNumberUpdateId").val("");
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Vehicle Number updated successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                } else if (response.status == 'failed') {
                    swal("Failed", "Failed to update the Vehicle Number!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Vehicle Number", "error");
            }, () => console.log('done');
    }

    deletedLocalTrip(localTripSheetRptData) {
        swal({
            title: "Confirm Delete",
            text: "Sure you want to delete this Trip No :'" + localTripSheetRptData.localTripNumber + "' ",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.driverDataDtoDriverName = new DriverdetailsDto();
                this.driverDataDtoDriverName.id = localTripSheetRptData.id;
                this.driverDataDtoDriverName.userId = this.userDataDtoReturnSession.userName;
                this.deletedLocalTripMethod();
            }
        });
    }

    deletedLocalTripMethod() {
        this.showSpinnerForAction = true;
        this.reportService.deleteLocalTripService(this.driverDataDtoDriverName).subscribe(
            (response) => {
                if (response.status == 'success') {
                    this.driverDataDtoDriverName = new DriverdetailsDto();
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Trip details deleted successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                    this.localTripSheetDetail();
                } else if (response.status == 'notallowed') {
                    swal({
                        title: "Not Allowed",
                        text: "This trip contains LRs hence not allowed to delete, please contact Admin!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (response.status == 'failed') {
                    swal("Failed", "Failed to delete the details!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while deleting the details", "error");
            }, () => console.log('done');
    }

    deliveryManUpdatePopUp(contentDeliverManUpdate, localTripSheetRptData) {
        this.setTripNo = null;
        this.setTripId = null;
        this.localTripOfc = null;
        this.setTripNo = localTripSheetRptData.localTripNumber;
        this.setTripId = localTripSheetRptData.id;
        this.localTripOfc = localTripSheetRptData.office;
        this.modalService.open(contentDeliverManUpdate, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    updateDeliveryManBtn() {
        this.updatedDeliveryMan = null;
        this.updatedDeliveryMan = $("#" + this.pageId + "dropDownInputFieldLabourHeadNameUpdate").val();
        if ((this.updatedDeliveryMan == null) || (this.updatedDeliveryMan == undefined) || (this.updatedDeliveryMan == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please select the Delivery Man to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#ltrpsrdropDownInputFieldLabourHeadNameUpdate").focus();
            });
        } else {
            swal({
                title: "Update Delivery Man",
                text: "Sure you want to Update the Delivery Man '" + this.updatedDeliveryMan + "' for the Local Trip : '" + this.setTripNo + "' ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.driverDataDtoDriverName = new DriverdetailsDto();
                    this.driverDataDtoDriverName.loaderHead = this.updatedDeliveryMan;
                    this.driverDataDtoDriverName.id = this.setTripId;
                    this.driverDataDtoDriverName.userId = this.userDataDtoReturnSession.userName;
                    this.updateDeliveryManMethod();
                }
            });
        }
    }

    updateDeliveryManMethod() {
        this.showSpinnerForAction = true;
        this.reportService.updateLocalTripDeliveryMan(this.driverDataDtoDriverName).subscribe(
            (response) => {
                if (response.status == 'success') {
                    this.setTripNo = null;
                    this.setTripId = null;
                    this.updatedDeliveryMan = null;
                    this.localTripOfc = null;
                    $("#" + this.pageId + "dropDownInputFieldLabourHeadNameUpdate").val("");
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Delivery Man updated successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                } else if (response.status == 'failed') {
                    swal("Failed", "Failed to update the Delivery Man!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Delivery Man", "error");
            }, () => console.log('done');
    }

    customPrintLocalTrpShtRptTable() {
        if (this.localTripSheetRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Veh No", "Trip Date", "Del Area", "Lrs", "Art", "Act Wt", "Chg Wt", "Driver Name", "Tot Amt", "Hamali", "Office"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [12, 10, 12, 7, 7, 7, 8, 12, 8, 8, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDate, this.validateToDate];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.artSummary = this.actWtSummary = this.chgWtSummary = this.totAmtSummary = this.hamaliSummary = 0;
            for (let i = 0; i < this.localTripSheetRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.localTripSheetRptDataList[i].vehiclenumber, this.localTripSheetRptDataList[i].tripDateStr,
                this.localTripSheetRptDataList[i].deliveryarea, this.localTripSheetRptDataList[i].totallrs, this.localTripSheetRptDataList[i].totalarticles,
                this.localTripSheetRptDataList[i].actWeight, this.localTripSheetRptDataList[i].chgWeight, this.localTripSheetRptDataList[i].drivername,
                this.localTripSheetRptDataList[i].totalamount, this.localTripSheetRptDataList[i].ddAmt, this.localTripSheetRptDataList[i].office];
                ////

                this.lrsSummary = this.lrsSummary + this.localTripSheetRptDataList[i].totallrs;
                this.artSummary = this.artSummary + this.localTripSheetRptDataList[i].totalarticles;
                this.actWtSummary = this.actWtSummary + this.localTripSheetRptDataList[i].actWeight;
                this.chgWtSummary = this.chgWtSummary + this.localTripSheetRptDataList[i].chgWeight;
                this.totAmtSummary = this.totAmtSummary + this.localTripSheetRptDataList[i].totalamount;
                this.hamaliSummary = this.hamaliSummary + this.localTripSheetRptDataList[i].ddAmt;


                /////////

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.localTripSheetRptDataList.length, "", "", this.lrsSummary, this.artSummary, this.actWtSummary, this.chgWtSummary, "", this.totAmtSummary, this.hamaliSummary, ""];
            //heading logics For Date
            //heading logics For Date

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Local Trip Sheet Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    customPrintLocalTrpShtRptByPartyTable() {
        if (this.localTripSheetRptByPartyDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Trip Date", "Vehicle No", "Source", "L.R.NO", "CNSGR NAME", "ART", "HAM GD", "ACT WT", "CHG WT", "BKNG DT", "UNLDNG DT", "ARR", "DELV", "Driver Name"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [12, 10, 12, 7, 7, 7, 8, 12, 8, 8, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.artSummary = this.hamaliSummary = this.actWtSummary = this.chgWtSummary = 0;
            for (let i = 0; i < this.localTripSheetRptByPartyDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.localTripSheetRptByPartyDataList[i].tripDateStr, this.localTripSheetRptByPartyDataList[i].vehiclenumber,
                this.localTripSheetRptByPartyDataList[i].totalarticles, this.localTripSheetRptByPartyDataList[i].hamaliGD, this.localTripSheetRptByPartyDataList[i].actWeight,
                this.localTripSheetRptByPartyDataList[i].chgWeight, this.localTripSheetRptByPartyDataList[i].bookingDateStr, this.localTripSheetRptByPartyDataList[i].unloadingDateStr,
                this.localTripSheetRptByPartyDataList[i].arrivalwithin, this.localTripSheetRptByPartyDataList[i].deliveredwithin, this.localTripSheetRptByPartyDataList[i].drivername];
                this.artSummary = this.artSummary + this.localTripSheetRptByPartyDataList[i].totalarticles;
                this.hamaliSummary = this.totAmtSummary + this.localTripSheetRptByPartyDataList[i].hamaliGD;
                this.actWtSummary = this.actWtSummary + this.localTripSheetRptByPartyDataList[i].actWeight;
                this.chgWtSummary = this.chgWtSummary + this.localTripSheetRptByPartyDataList[i].chgWeight;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.localTripSheetRptByPartyDataList.length, "", "", "", "", this.artSummary, this.hamaliSummary, this.actWtSummary, this.chgWtSummary, "", "", "", "", ""];
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date,To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [moment(this.validateFromDate).format("DD-MM-YYYY"), moment(this.validateToDate).format("DD-MM-YYYY")];

            ///Heaading Logics For Party Name
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Party Name"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.tripDto.consigneeName];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Local Trip Sheet Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    confirmGenerateConsolidateEwaybill(tripId) {
        swal({
            title: "Confirm Generate",
            text: "You Want to generate the Consolidate Eway Bill for this Trip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                //this.generateConsolidateEwaybill(tripId);
                this.validateEwaybillExpiryDateForConsolidatedgeneration(tripId);
            } else {
                swal.close();
            }
        })
    }

    validateEwaybillExpiryDateForConsolidatedgeneration(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Dest';
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.validateEwaybillExpiryDateForDestLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    var expiredEwaybills = this.invalidExpiryEwayBillsIteration(response);
                    swal({
                        title: "Eway bill Issue",
                        text: "The following Ewaybill with LR numbers are expired/Invalid and this will not include in the Consolidated Ewaybill generation, please generate DC for expired Ewaybills! Do you want to proceed?" + expiredEwaybills,
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.generateConsolidateEwaybill(tripId);
                        } else {
                            swal.close();
                        }
                    })
                } else {
                    this.generateConsolidateEwaybill(tripId);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Failed", "Failed to validate the Eway bill expiry!", "error");
            }, () => console.log('done');
    }

    invalidExpiryEwayBillsIteration(lrDtos) {
        var ewayBills = "";
        this.listOfExpiredEwaybills = [];
        for (let i = 0; i < lrDtos.length; i++) {
            if (i == 0) {
                ewayBills = lrDtos[i].lrNumber + "-" + lrDtos[i].eWayBillNo + "-" + lrDtos[i].ewayBillValidUptoStr;
            } else {
                ewayBills = ewayBills + ", " + lrDtos[i].lrNumber + "-" + lrDtos[i].eWayBillNo + "-" + lrDtos[i].ewayBillValidUptoStr;
            }
            this.listOfExpiredEwaybills.push(lrDtos[i].eWayBillNo);
        }
        return ewayBills;
    }

    generateConsolidateEwaybill(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Dest';
        this.requestDto.list = this.listOfExpiredEwaybills;
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateConsolidateEwaybillLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Completed", "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo, "info");
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1, 5);
                    this.searchMethod();
                } else if (response.status == "No record") {
                    swal("Failed", "No Eway bill details found to generate the Consolidated Eway bill, please try again or contact Admin!", "error");
                } else {
                    swal("Failed", "Failed to generate the Consolidated Eway bill, please try again or contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token, type) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": type };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    printCEWB(eWayBillNo) {
        if (eWayBillNo == null) {
            swal("Not Allowed", "Consolidated Eway Bill not found for this Trip, please verify it!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadConsolidatedEWBPdf(eWayBillNo, response.token1, 5);
                    } else {
                        swal("Failed", "Failed to generate the token for Consolidated Eway Bill print request, please contact Admin!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to generate the token for Consolidated Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }

    dcEwaybillDetailsDialog(contentDCPopup, localTripSheetRptData): void {
        this.modalService.open(contentDCPopup, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );

        this.validateEwaybillExpiryDateForTrip(localTripSheetRptData);
    }
    validateEwaybillExpiryDateForTrip(localTripSheetRptData) {
        this.dcDataList = [];
        //$("#" + this.pageId + "localTripExpiredEwayBillTableId").DataTable().destroy();

        this.requestDto = new RequestDto();
        this.requestDto.param3 = localTripSheetRptData.id;
        this.requestDto.mode = 'Dest';
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.validateEwaybillExpiryDateForDestLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.dcDataList = response;
                    console.log(this.dcDataList);
                } else {
                    swal("Failed", "No details found!", "error");
                }
                this.showSpinnerForAction = false;
                this.dtTriggerLrDetails.next();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Failed", "Failed to load the details!", "error");
            }, () => console.log('done');
    }

    validateDCGeneration(dcData) {
        this.selectedVehicleNumberForDC = $("#" + this.pageId + "vehicleNumberForDC").val();
        console.log('validateDCGeneration - ' + this.selectedVehicleNumberForDC);
        if (dcData.eWayBillNo != null && dcData.eWayBillNo != '0' && dcData.eWayBillNo != 'NA' && dcData.eWayBillNo != '' &&
            (this.selectedVehicleNumberForDC == null || this.selectedVehicleNumberForDC == ''
                || this.selectedVehicleNumberForDC.length < 6 || this.selectedVehicleNumberForDC.length > 11)) {
            swal({
                title: "Not Allowed",
                text: "Please enter the Valid Vehicle Number for DC!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            });
        } else {
            swal({
                title: "Confirm Generate",
                text: "Sure you want to generate the Delivery Challan for this LR?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.generateDCForLREwaybill(dcData);
                } else {
                    swal.close();
                }

            })
        }
    }

    generateDCForLREwaybill(dcData) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = this.selectedVehicleNumberForDC;
        this.requestDto.lrNumber = dcData.lrNumber;
        this.requestDto.userId = this.userDataDtoReturnSession.userId;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateDeliveryChallanForEwayBill(this.requestDto).subscribe(
            (response) => {
                if (response.respStatus == 1) {
                    swal("Completed", "Delivery Challan generated. Eway Bill No : " + response.respEwayBillNo, "info");
                    this.downloadConsolidatedEWBPdf(response.respEwayBillNo, response.remarks, 4);
                } else {
                    if (response.status == 'Not Expired') {
                        swal("Failed", "Ewaybill validity date is not yet expired hence can't generate the Delivery Challan, please generate Consolidated Eway bill else contact Admin!", "warning");
                    } else {
                        swal("Failed", "Failed to generate the Delivery Challan, please try again or contact Admin!", "error");
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Server Error", "Failed to generate the Delivery Challan, please try again or contact Admin!", "error");
            }, () => console.log('done');
    }

    printDCEwaybill(ewayBillDCNo) {
        if (ewayBillDCNo == null) {
            swal("Not Allowed", "DC Eway Bill number not found for this Memo / LR, please verify it!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadConsolidatedEWBPdf(ewayBillDCNo, response.token1, 4);
                    } else {
                        swal("Failed", "Failed to generate the token for DC Eway Bill print request, please contact Admin!", "error");
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    swal("Server Error", "Failed to generate the token for DC Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }
}
