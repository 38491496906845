<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div *ngIf="showSpinnerForAction" class="col-md-12">
            <div class="form-group text-center">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;">
                    </mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Please Wait Loading Details.....</h6>
                </div>
            </div>
        </div>
        <div *ngIf="showSpinnerForDownloadExcel" class="col-md-12">
            <div class="form-group text-center">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;">
                    </mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Please Wait Downloading Details on Excel....</h6>
                </div>
            </div>
        </div>
        <div class="card" style="margin: auto; width: 50%;">
            <div class="card-body">
                <h6 class="card-title text-center" style="color: black;">Search Party Contact</h6>
                <div class="form">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Contact Type *</label>
                        <div class="col-sm-9">
                            <select class="custom-select" id="{{pageId}}contactType" name="contactType" #contactType>
                                <option selected value="Consignee">Consignee</option>
                                <option value="Consignor">Consignor</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Source *</label>
                        <div class="col-sm-9">
                            <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource"
                                [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource"
                                [inputFormatter]="formatterSource"
                                (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Destination *</label>
                        <div class="col-sm-9">
                            <input id="{{pageId}}destination" type="text" class="form-control"
                                [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination"
                                [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                (focus)="focusDestinationTA$.next($any($event).target.value)"
                                placeholder="Select Destination">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Based On LR Booking</label>
                        <div class="col-sm-9">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxLrBased"
                                    #checkboxLrBased (keyup)="fieldFocus($event, paymentType)"
                                    (change)="toggleEditableForLrBased($event)">
                                <label class="custom-control-label" for="{{pageId}}checkboxLrBased"></label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="showBookingDate">
                        <label class="col-sm-3 col-form-label">Booking From Date *</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input id="{{pageId}}bkgFromDate" class="form-control" placeholder="yyyy-mm-dd"
                                    [(ngModel)]="fromDate" [minDate]="currentDate" [maxDate]="maxDate"
                                    (ngModelChange)="validateDates()" ngbDatepicker #bkgFromDate="ngbDatepicker"
                                    autocomplete="off">
                                <div class="input-group-append" (click)="bkgFromDate.toggle()">
                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="showBookingDate">
                        <label class="col-sm-3 col-form-label">Booking To Date *</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input id="{{pageId}}bkgToDate" class="form-control" placeholder="yyyy-mm-dd"
                                    [(ngModel)]="toDate" [minDate]="fromDate" [maxDate]="maxDate"
                                    (ngModelChange)="validateDates()" ngbDatepicker #bkgToDate="ngbDatepicker"
                                    autocomplete="off">
                                <div class="input-group-append" (click)="bkgToDate.toggle()">
                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 class="card-title"
                        style="color: black; font-weight: bold; font-size: 19px; text-align: center;">
                        Total Mobile No : {{ totalMobileNo }}
                    </h6>
                    <a style="cursor: pointer; color: blue; padding-left: 10px; font-size: 17px; display: block; text-align: center;"
                        (click)="exportToExcel()">
                        <u>Click Here to Download Excel</u>
                    </a>
                    &nbsp;
                    <div class="text-center">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                            (click)="validatGetPartyContactDetailsMethod()">Search</button>
                        <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"
                            (click)="clearDetails()">Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-7 vl">
                    <div class="row">
                        <div class="col-md-12 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Party Contact Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}partyContactDetailsTable"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsPartyContactDetails"
                                            [dtTrigger]="dtTriggerPartyContactDetails">
                                            <thead>
                                                <tr>
                                                    <th>Party Name</th>
                                                    <th>Gst No</th>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>Mobile No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let partyContactDetail of partyContactDetailsList; let i = index">
                                                    <td>{{ partyContactDetail.partyName }}</td>
                                                    <td>{{ partyContactDetail.gstNumber }}</td>
                                                    <td>{{ partyContactDetail.source }}</td>
                                                    <td>{{ partyContactDetail.destination }}</td>
                                                    <td>{{ partyContactDetail.mobileNum }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
    </div>
</div>