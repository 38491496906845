//Chg_V1 : Get groupStationForReport for AgentName dropdown as per discuss (Asrar Jr / 31-12-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { HttpClient } from '@angular/common/http';
import { MasterDto } from 'src/app/dto/master-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";


@Component({
    selector: 'app-paid-report',
    templateUrl: './paid-report.component.html',
    styleUrls: ['./paid-report.component.css']
})
export class PaidReportComponent implements OnInit {
    isLoggedIn = true;
    loadingIndicator = true;
    viewTypeDateWise = true;
    viewTypePartyWise = false;
    viewTypeAgentWise = true;
    viewTypeDestinationWise = false;
    paidReportDateNdPartyWiseTable = true;
    paidReportAgentNdDestinationTable = false;
    viewTypeSourceWise = true;
    viewTypeShow = true;
    paidReportSummaryTable = false;
    private newAttribute: any = {};
    //    userdata get through from login starts
    userDataDtoReturnSession: any;
    //    userdata get through from login ends
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;

    today = new Date();


    //    for Print


    todayDate: string;
    datasToExport: string;
    dateWeNeed: string;
    pageTitle: any;
    /////

    //for datepicker ends


    //service starts      
    //    partyModel: PartyModel = new PartyModel();

    private baseUrl = '/api/v1/createconsignee';
    masterDto: MasterDto = new MasterDto();
    partyDto: PartyMasterDto = new PartyMasterDto();
    partyDtoAll: PartyMasterDto = new PartyMasterDto();
    partyDetailsOptionsGet: PartyMasterDto[];
    //    consigneeDetailsModel: ConsigneedetailsModel = new ConsigneedetailsModel();
    lrDto: LRDto = new LRDto();
    lrDtoBkgSrc: LRDto = new LRDto();

    lrDtoBkgSrcAll: LRDto = new LRDto();
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    agentDetailsOptionsGet: AgentDetailsDto[];
    //    consigneeDetailsModel: ConsigneedetailsModel = new ConsigneedetailsModel();
    saveResp: string;
    mainStationRpt = "Paid Rpt Bkg Main Station";
    indexVal: any;
    gstConsignor: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedSource: any;
    selectedSearchType: any;
    selectedConsignorName: any;
    selectedConsignorIndex: any;
    selectedDestination: any;
    selectedReportType: any;
    //    service ends

    paidReportDateNdPartyWiseDataList: any;
    paidReportAgentNdDestinationDataList: any;
    paidReportSummaryDataList: any;
    controlMainSource = new FormControl();
    controlMainDestination = new FormControl();
    controlAgentName = new FormControl();
    controlConsingorIndex = new FormControl();
    controlConsignorName = new FormControl();

    lrDtoDestinationAll: LRDto = new LRDto();
    lrDtoSourceAll: LRDto = new LRDto();
    lrDtosDestinationOptionsGet: LRDto[];
    lrDtosSourceOptionsGet: LRDto[];
    srcForRpt = "Paid Rpt Show Src All";
    isLocalAgent = false;
    mainAdminStation: string;
    isAgentAll: string;
    //consignorIndexOptions: Array<any> = ['Select Consignor Index', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'ALL'];
    //consignorIndexOptionsGet: Array<any> = [];

    selectedAgentName: any;
    showGenerateMemo = true;
    selectedLrNumber: any;
    mainStationDesc = "Bkg Main Admin Station"

    selectedFromDatePrint: any;
    selectedToDatePrint: any;


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerPaidReportDateNdPartyWise: Subject<any> = new Subject();
    dtTriggerPaidReportAgentNdDestination: Subject<any> = new Subject();
    dtTriggerPaidReportSummary: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsPaidReportDateNdPartyWise: any;
    dtOptionsPaidReportAgentNdDestination: any;
    dtOptionsPaidReportSummary: any;


    //For Source
    sourceOptions: AgentDetailsDto[];
    lrDtoSource: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;
    //sourceList
    srcLists: any;
    srcList: AgentDetailsDto = new AgentDetailsDto();
    ///
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    sourceDetailsOptionsGet: AgentDetailsDto[];
    searchLrRptDto = new LRDto();
    agentDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    agentDtoSourceAddOption: AgentDetailsDto = new AgentDetailsDto();


    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    showSpinnerForAction = false;
    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];

    ///For Agent Names

    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;



    consignorIndexOptions = [
        { id: 1, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' },
        { id: 0, label: 'All' }];
    consigneeNameOptions: any;

    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }


    //for ConsignorName
    modelConsignorName: any;
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedIndex: any;
    selectedConsignorGSTNumber: any;
    selectedConsignorId: any;
    lrDtoForSearch: LRDto = new LRDto();
    srcListsSet: Array<any> = [];
    agentList: Array<any> = [];
    public modelConsignorNameId: any;
    lrDtoForBooking: LRDto = new LRDto();
    grandTotalArticles = 0;
    grandActWt = 0;
    grandChWt = 0;
    grandSerTax = 0;
    grandPaid = 0;
    grandMemoAmt = 0;
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    viewCustomPrintV2 = false;
    viewCustomPrintV3 = false;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    pageId = "padrc";
    viewSubStions: boolean = false;

    constructor(private paidReportScreen: ReportService, public changeDetectorRef: ChangeDetectorRef,
        //          service starts
        private http: HttpClient,
        //            service ends
        private router: Router, private datePipe: DatePipe,

        private masterReadService: MasterReadService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            //          userdata get through from login starts

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.mainAdminStation = this.userDataDtoReturnSession.mainAdminStation;

            this.getDestinationDetails();
            //  this.getSourceList();
            this.setConsginorIndex();
            this.getConsignorDetailsList();
            //Chg_V1
            // this.getAgentDetailList();
            setTimeout(() => {
                this.getAgentDetailList();
            }, 2000)
            this.selectTodayDate();
            this.srcList = this.userDataDtoReturnSession.mainStation;
            // if ( this.srcForRpt == "Paid Rpt Show Src All" ) {
            //     this.viewTypeSourceWise = true;
            //     this.getBookingOfficeDetailsList();

            // }
            // if ( this.srcForRpt == "Paid Rpt Show Src Specific" ) {
            //     this.viewTypeSourceWise = true;
            //     //                Source list is showing error
            //     //                this.getSourceList();
            // }
            // if ( this.srcForRpt == "Paid Rpt Show Agent All" ) {
            //     this.viewTypeSourceWise = true;
            //     this.isAgentAll = "AgentAll";
            // }
            // if ( this.srcForRpt == "Paid Rpt Show Delhi local Agent" ) {
            //     this.isLocalAgent = true;
            //     /// here source is showm to check the conditions
            //     this.viewTypeSourceWise = true;

            //     this.getBookingOfficeDetailsList();
            // }

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "PaidRpt SrcSupAdmin") {
                        this.viewTypeSourceWise = true;
                    }
                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "PaidRpt SrcBkgAdmin") {
                        this.viewTypeSourceWise = true;
                    }
                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "PaidRptRpt ShowAll") {
                        this.viewTypeSourceWise = true;
                        this.isAgentAll = "AgentAll";
                    }
                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "PaidRptRpt Delhi local Agent") {
                        this.viewTypeSourceWise = true;
                        this.isLocalAgent = true;
                    }

                    //Chg_V1
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "PaidRpt SubStations") {
                        this.viewSubStions = true;
                    }
                }
            }
        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {

        this.dtOptionsPaidReportDateNdPartyWise = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {

                    var returnPaidDate = null;
                    if ($("#padrcviewType").val() == 'dummy') {
                        returnPaidDate = "Paid Report " +
                            " From Date : " + moment($("#padrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#padrctoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#padrcagentName").val();
                    } else {
                        returnPaidDate = "Paid Report- Party Wise " +
                            " From Date : " + moment($("#padrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#padrctoDate").val()).format('DD-MM-YYYY') + " Consignor Name : " + $("#padrcconsignorName").val();
                    }


                    return returnPaidDate;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoAmt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(10).footer()).html(paid);
                $(api.column(11).footer()).html(fov);
                $(api.column(13).footer()).html(memoAmt);
            }
        }

        this.dtOptionsPaidReportAgentNdDestination = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {

                    var returnPaidDest = null;

                    returnPaidDest = "Paid Report -Destination Wise" +
                        " From Date : " + moment($("#padrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#padrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#padrcdestination").val() + " Agent Name : " + $("#padrcagentName").val();
                    return returnPaidDest;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoAmt = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(12).footer()).html(paid);
                $(api.column(13).footer()).html(fov);
                $(api.column(16).footer()).html(memoAmt);
            }
        }
        this.dtOptionsPaidReportSummary = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var baseFrgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var hamali = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var gcChg = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lcChg = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var bcChg = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var aoc = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var dd = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cod = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var rc = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var sc = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var dHamali = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var disc = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoAmt = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(5).footer()).html(baseFrgt);
                $(api.column(6).footer()).html(hamali);
                $(api.column(7).footer()).html(gcChg);
                $(api.column(8).footer()).html(lcChg);
                $(api.column(9).footer()).html(bcChg);
                $(api.column(10).footer()).html(aoc);
                $(api.column(11).footer()).html(fov);
                $(api.column(12).footer()).html(dd);
                $(api.column(13).footer()).html(cod);
                $(api.column(14).footer()).html(paid);
                $(api.column(15).footer()).html(rc);
                $(api.column(16).footer()).html(sc);
                $(api.column(17).footer()).html(dHamali);
                $(api.column(18).footer()).html(disc);
                $(api.column(19).footer()).html(memoAmt);
            }
        }

    }

    ngAfterViewInit(): void {
        this.dtTriggerPaidReportDateNdPartyWise.next();
        this.dtTriggerPaidReportAgentNdDestination.next();
        this.dtTriggerPaidReportSummary.next();

    }
    ngOnDestroy(): void {
        this.dtTriggerPaidReportDateNdPartyWise.unsubscribe();
        this.dtTriggerPaidReportAgentNdDestination.unsubscribe();
        this.dtTriggerPaidReportSummary.unsubscribe();
    }
    hideTable() {
        this.paidReportDateNdPartyWiseTable = false;
        this.paidReportAgentNdDestinationTable = false;
    }

    viewTypeMode(viewType: string) {
        if (viewType === 'dummy') {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = false;
            this.viewTypeAgentWise = true;
            this.viewTypeDestinationWise = false;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportDateNdPartyWiseTable = true;
        } else if (viewType === 'PartyWise') {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = true;
            this.viewTypeAgentWise = true;
            this.viewTypeDestinationWise = false;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportDateNdPartyWiseTable = true;
        } else if (viewType === 'DestWise') {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = false;
            this.viewTypeAgentWise = true;
            this.viewTypeDestinationWise = true;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportAgentNdDestinationTable = true;
        } else {
            this.viewTypeDateWise = false;
            this.viewTypePartyWise = false;
            this.viewTypeAgentWise = false;
            this.viewTypeDestinationWise = false;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportDateNdPartyWiseTable = true;
        }
        //I have doubt in islocal Agent
        // if ( this.mainStationDesc == 'Bkg Main Admin Station' && !this.isLocalAgent ) {
        //     this.viewTypeAgentWise = false;
        // }
    }
    clearTable() {
        $("#" + this.pageId + "paidReportDateNdPartyWiseId").DataTable().destroy();
        this.paidReportDateNdPartyWiseDataList = [];
        this.dtTriggerPaidReportDateNdPartyWise.next();
        $("#" + this.pageId + "paidReportAgentNdDestinationId").DataTable().destroy();
        this.paidReportAgentNdDestinationDataList = [];
        this.dtTriggerPaidReportAgentNdDestination.next();
        $("#" + this.pageId + "paidReportSummaryId").DataTable().destroy();
        this.paidReportSummaryDataList = [];
        this.dtTriggerPaidReportSummary.next();
    }
    //For Source Listener
    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
    }

    //For Destination Listener
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    //For Agent Name Listener
    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
    }

    //For Consignor Index
    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    //FromStation
    getBookingOfficeDetails() {
        this.lrDtoBkgSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBkgSrc.mode = "All";
    }
    getBookingOfficeDetailsList = () => {
        this.getBookingOfficeDetails();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoBkgSrc).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtosSourceOptionsGet = response;
                    this.lrDtoBkgSrcAll.subStations = "ALL";
                    this.lrDtosSourceOptionsGet.push(this.lrDtoBkgSrcAll);
                    this.srcLists = response;
                    //                    this.srclist is pending 
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    // //From Station
    // getSourceList() {

    //     //        this.srcList="";
    //     this.srcLists = this.userDataDtoReturnSession.stationList;
    //     //        console.log(this.srcLists);
    //     //        this.srcList.subStations = this.srcLists;
    //     this.newAttribute.subStations = this.srcLists;
    //     this.lrDtosSourceOptionsGet = [];
    //     for ( let i = 0; i < this.newAttribute.subStations.length; i++ ) {
    //         this.lrDtosSourceOptionsGet.push( this.newAttribute.subStations[i] );
    //         //            console.log(this.lrDtosSourceOptionsGet);
    //     }
    //     //        console.log(this.lrDtosSourceOptionsGet);
    //     this.lrDtoBkgSrcAll.subStations = "ALL";
    //     this.lrDtosSourceOptionsGet.push( this.lrDtoBkgSrcAll );

    // }

    //  To Station 
    // getDestinationDetails() {
    //     this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    //     this.lrDtoDestination.mode = "Destination";
    // }
    // getDestinationDetailsList = () => {
    //     this.getDestinationDetails();
    //     this.masterReadService.getDestinationListDetails( this.lrDtoDestination ).subscribe(
    //         ( response ) => {
    //             if ( response.length == 0 ) {
    //                 swal( {
    //                     title: "Warning",
    //                     text: "No Destination records found!",
    //                     icon: "warning",
    //                     closeOnClickOutside: false,
    //                     closeOnEsc: false,
    //                 } );
    //             } else {
    //                 this.lrDtosDestinationOptionsGet = response;
    //                 this.lrDtoDestinationAll.source = "ALL";
    //                 this.lrDtosDestinationOptionsGet.push( this.lrDtoDestinationAll );
    //             }
    //         } ),
    //         ( error ) => console.log( error.json() ),
    //         () => console.log( 'done' );
    // };


    /// Get Agent Names
    getAgentListDetails() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        this.agentDto.mode = "Sub";
        this.agentDto.status = "All";
        if (this.isAgentAll == "AgentAll") {
            this.agentDto.mode = "Sub";
            this.agentDto.status = "All";
        } else {
            this.agentDto.mode = "mainBranch";
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;

        }

        this.getAgentList();
    }

    getAgentList = () => {
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {

                    this.agentDetailsOptionsGet = response;
                    this.agentDtoAll.subStation = "ALL";
                    this.agentDetailsOptionsGet.push(this.agentDtoAll);

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    /////////////////////////////////////////////////////////
    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
    }
    validateBtnSearch() {
        this.getEnteredDetails();
    }
    getEnteredDetails() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.selectedSearchType = $("#" + this.pageId + "viewType").val();
        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedConsignorIndex = $("#" + this.pageId + "consignorIndex").val();
        this.selectedReportType = $("#" + this.pageId + "reportType").val();

        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.lrDtoForSearch = new LRDto();
            this.lrDtoForSearch.fromdate = this.selectedFromDate;
            this.lrDtoForSearch.todate = this.selectedToDate;
            this.lrDtoForSearch.fromDate = this.selectedFromDate;
            this.lrDtoForSearch.toDate = this.selectedToDate;
            this.lrDtoForSearch.description = "PAID REPORT";
            this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
            if (this.isAgentAll != "AgentAll") {
                this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
            }
            if (this.selectedAgentName != null && this.selectedAgentName != '' && this.selectedAgentName == 'All') {
                this.srcListsSet = [];
                this.srcListsSet = this.agentList;
                this.lrDtoForSearch.list = this.srcListsSet;
                this.lrDtoForSearch.agentName = 'All';
            } else if (this.selectedAgentName != null && this.selectedAgentName != '' && this.selectedAgentName != 'All') {
                this.srcListsSet = [];
                this.srcListsSet.push(this.selectedAgentName);
                this.lrDtoForSearch.list = this.srcListsSet;
                this.lrDtoForSearch.agentName = this.selectedAgentName;
            } else {
                swal({
                    title: "Warning",
                    text: "Please Select Agent Name",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                return false;
            }

            if (this.selectedReportType == 'Summary') {
                this.getPaidReportSummary();
            } else {
                if (this.selectedSearchType == 'dummy') {
                    this.lrDtoForSearch.mode = 'dummy';
                    this.getPaidReportDetails();
                }
                if (this.selectedSearchType == 'PartyWise') {
                    if (this.selectedConsignorName == null || this.selectedConsignorName == '' || this.selectedConsignorName == undefined) {
                        swal({
                            title: "Warning",
                            text: "Please Select Consignor Name",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.lrDtoForSearch.mode = 'PartyWise';
                        this.lrDtoForSearch.consignorId = 0;
                        this.lrDtoForSearch.gstNoConsignor = this.selectedConsignorGSTNumber;
                        console.log(this.selectedConsignorId);


                        if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                            this.lrDtoForSearch.consignorId = this.selectedConsignorId;
                            console.log(this.selectedConsignorId);
                        }
                        if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {
                            this.lrDtoForSearch.consignorName = this.selectedConsignorName;
                            if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                                this.lrDtoForSearch.consignorId = this.selectedConsignorId;
                                console.log(this.selectedConsignorId);
                            }
                        }
                        this.getPaidReportDetails();
                    }

                }

                if (this.selectedSearchType == 'DestWise') {
                    if ((this.selectedDestination != null) && (this.selectedDestination != "") && (this.selectedAgentName != null) && (this.selectedAgentName != "")) {
                        this.lrDtoForSearch.mode = "destWise";
                        this.lrDtoForSearch.destination = this.selectedDestination;
                        this.lrDtoForSearch.agentName = this.selectedAgentName;
                        this.getPaidReportDetails();

                    } else {

                        swal({
                            title: "Warning",
                            text: "Destination And Agent Is Mandatory Field",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                    }
                }
            }
        }
    }

    getPaidReportDetails = () => {
        this.showSpinnerForAction = true;
        console.log(this.lrDtoForSearch);
        this.paidReportScreen.getPaidRptForCashmemo(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "paidReportDateNdPartyWiseId").DataTable().destroy();
                this.paidReportDateNdPartyWiseDataList = [];
                $("#" + this.pageId + "paidReportAgentNdDestinationId").DataTable().destroy();
                this.paidReportAgentNdDestinationDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.selectedSearchType = $("#" + this.pageId + "viewType").val();
                    if (this.selectedSearchType == 'dummy' || this.selectedSearchType == 'PartyWise') {
                        this.paidReportDateNdPartyWiseDataList = response;
                    } else if (this.selectedSearchType == 'DestWise') {
                        console.log('dest');
                        this.paidReportAgentNdDestinationDataList = response;
                    }
                }
                this.dtTriggerPaidReportDateNdPartyWise.next();
                this.dtTriggerPaidReportAgentNdDestination.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Paid Report details", "info");
            }, () => console.log('done');
    };

    rowSelectedToGenrtMemoParty(paidReportDateNdPartyWiseData) {
        $("#" + this.pageId + "hiddenLrNumber").val(paidReportDateNdPartyWiseData.lrNumber);
        this.generateMemo();
    }

    rowSelectedToGenrtMemoAgent(paidReportAgentNdDestinationData) {
        $("#" + this.pageId + "hiddenLrNumber").val(paidReportAgentNdDestinationData.lrNumber);
        this.generateMemo();
    }

    generateMemo() {
        this.selectedLrNumber = $("#" + this.pageId + "hiddenLrNumber").val();
        if (this.selectedLrNumber == null || this.selectedLrNumber == "") {
            alert('Please Select Lr ');
        } else {
            this.lrDtoForBooking = new LRDto();
            this.lrDtoForBooking.lrNumber = this.selectedLrNumber;
            this.lrDtoForBooking.mode = 'bkgCashmemo';
            localStorage.clear();
            localStorage.setItem('showBkgCashmemo', JSON.stringify(this.lrDtoForBooking));
            this.router.navigate(['/cashmemo/generation/bookingCashmemo']);
        }
    }


    getDateInfo() {

        this.dateWeNeed = '';
        this.selectedSearchType = $("#" + this.pageId + "viewType").val();
        this.selectedFromDatePrint = $("#" + this.pageId + "fromDate").val();
        this.selectedToDatePrint = $("#" + this.pageId + "toDate").val();
        this.dateWeNeed = '<h6 style="font-weight:bold;font-size:16px;">From Period: <span style="font-weight:400;">' + this.selectedFromDatePrint + '</span></h6>'
            + '<h6 style="font-weight:bold;font-size:16px;">To Period: <span style="font-weight:400;">' + this.selectedToDatePrint + '</span></h6>'

        if (this.selectedSearchType == 'dummy') {
            this.pageTitle = "PAID REPORT";
        } else if (this.selectedSearchType == 'PartyWise') {
            this.pageTitle = "PAID REPORT";
        } else if (this.selectedSearchType == 'AgentWise') {
            this.pageTitle = "Paid Report-Agent Wise";
        } else if (this.selectedSearchType == 'DestWise') {
            this.pageTitle = "Paid Report-Destination Wise";

        } else {
            this.dateWeNeed = '<h6 style="font-size:16px;">No Date Found</h6>';
        }
        //        console.log( "At last we get ????????? " + this.dateWeNeed );
        return this.dateWeNeed;

    }
    changeDataTableTitileForPrint() {

        this.datasToExport = '';
        //        console.log( "Error>>>>>> " + this.datasToExport );
        this.datasToExport = this.getDateInfo();


        return '<div class="row">'
            + '<div class="col-md-7">'
            + '<img src="assets/images/srdLogisticPrintLogo.png">'
            + '</div>'
            + '<div class="col-md-5">'
            + '<p style="font-size:16px;">' + this.userDataDtoReturnSession.addressId
            + '</p>'
            + '</div>'
            + '</div>'
            + '<div class="row"  style="text-align:right">'
            + '<div class="col-md-8">'
            + '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' + this.pageTitle + '</span></h5>'
            + '</div>'
            + '<div class="col-md-4">'
            + this.datasToExport
            + '</div>'
            + '</div>'
    }

    changeDataTableTitileForExcel() {
        return "SRDlogo"
            + this.userDataDtoReturnSession.addressId
    }
    chnageDataTableMessageTopExcel() {
        return + this.pageTitle
            + this.selectedFromDatePrint
            + "                 "
            + this.selectedToDatePrint
    }

    clearAll() {
        // $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "consignorGstNumber").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        $("#" + this.pageId + "consignorName").val('');
        this.selectedLrNumber = '';
        $("#" + this.pageId + "hiddenLrNumber").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedConsignorName = '';
        this.selectedSearchType = '';
        this.selectedAgentName = '';
        this.selectedDestination = '';
        this.selectedConsignorIndex = '';
        $("#" + this.pageId + "viewType").val('dummy');
        this.viewTypeDateWise = true;
        this.viewTypePartyWise = false;
        this.viewTypeAgentWise = false;
        this.viewTypeDestinationWise = false;
        this.paidReportDateNdPartyWiseTable = true;
        this.paidReportAgentNdDestinationTable = false;
        this.paidReportDateNdPartyWiseDataList = null;
        this.paidReportAgentNdDestinationDataList = null;
        this.showGenerateMemo = true;
        //For Custom Print
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.grandTotalArticles = 0;
        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandSerTax = 0;
        this.grandPaid = 0;
        this.grandMemoAmt = 0;
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = '';
        this.selectedConsignorGSTNumber = '';
        this.selectedConsignorId = '';
        this.lrDtoForSearch = new LRDto();
        this.srcListsSet = [];
        this.modelConsignorNameId = '';
        this.lrDtoForBooking = new LRDto();
        // this.clearTable();
        $("#" + this.pageId + "paidReportDateNdPartyWiseId").DataTable().destroy();
        this.paidReportDateNdPartyWiseDataList = [];
        //  this.dtTriggerPaidReportDateNdPartyWise.next();
        $("#" + this.pageId + "paidReportAgentNdDestinationId").DataTable().destroy();
        this.paidReportAgentNdDestinationDataList = [];
        //  this.dtTriggerPaidReportAgentNdDestination.next();
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "viewType").val('dummy');
        this.viewTypeDateWise = true;
        this.viewTypePartyWise = false;
        this.viewTypeAgentWise = true;
        this.viewTypeDestinationWise = false;
        this.hideTable();
        this.clearTable();
        this.paidReportDateNdPartyWiseTable = true;
        localStorage.clear();
        this.viewCustomPrintV2 = false;
        this.viewCustomPrintV3 = false;


    }

    //Source Station
    getSourceList() {
        //   this.srcList="";
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStation = this.srcLists;
        // this.sourceDetailsListGet.subStation = this.srcLists;
        console.log(this.srcLists);
        console.log(this.srcList.subStation);
        this.sourceTA = [];
        this.agentDtoSourceAllOption.subStation = "All";
        this.sourceTA.push(this.agentDtoSourceAllOption);
        for (let i = 0; i < this.srcList.subStation.length; i++) {
            this.agentDtoSourceAddOption = new AgentDetailsDto();
            this.agentDtoSourceAddOption.subStation = this.srcList.subStation[i];
            this.sourceTA.push(this.agentDtoSourceAddOption);
        }
    };
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "Destination";
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.destinationDtoForAll.destination = "ALL";
                        this.destinationTA.push(this.destinationDtoForAll);

                        this.destinationList = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                            this.destinationList.push(this.destinationOptions[i].destination);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };


    //For Agent Names
    getUserValuesForAgentDetailsList() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        //Chg_V1
        // this.agentDto.mode = "mainBranch";
        console.log(this.viewSubStions);
        console.log(this.userDataDtoReturnSession.groupStationForRpt);
        if (this.viewSubStions) {
            this.agentDto.mode = "subStation";
            this.agentDto.groupStationForRpt = this.userDataDtoReturnSession.groupStationForRpt;
        } else {
           this.agentDto.mode = "mainBranch";
        }

        this.agentDto.status = "Working";
        this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;

        // if (this.isAgentAll != "AgentAll") {
        //     this.agentDto.mode = "Sub";
        //     this.agentDto.status = "All";
        // } else {
        //     this.agentDto.mode = "mainBranch";
        //     this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        // }
        console.log(this.agentDto);
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                this.agentNameTA = [];
                this.agentNameOptions = [];
                this.agentList = [];
                console.log(this.agentNameOptions);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    this.agentDtoSourceAllOption.subStation = "All";
                    this.agentNameTA.push(this.agentDtoSourceAllOption);
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        //console.log( this.agentDetailsOptionsGet[i].subStation );
                        this.agentList.push(this.agentNameOptions[i].subStation);
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }

                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };

    //For Consignor Name
    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        $("#" + this.pageId + "consignorGSTNumber").val('');
        this.modelConsignorIndex = e.item;

        console.log(this.modelConsignorIndex);
        this.getConsignorDetailsList();
    }

    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "Index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    window.setTimeout(function () {
                        $("#" + this.pageId + "consignorName").focus();
                    }, 100);
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    // setConsginorIndex() {
    //     for (let i = 0; i < this.consignorIndexOptions.length; i++) {
    //         this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
    //     }
    // }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }


    //For Getting Consignor Details
    getConsignorGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
            //            console.log( this.selectedConsignorGSTNumber );
            if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {
                this.getConsignorDetailsForGst();
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignor GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#" + this.pageId + "consignorGSTNumber").focus();
                })
                this.consignorNameOptions = [];
                this.consignorNameTA = [];
            }

        }
    }

    getConsignorDetailsForGst() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();

        this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        //this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.isGST = true;
        this.partyMasterDtoForconsignor.mode = "LREntry";
        this.partyMasterDtoForconsignor.gstNoConsignor = this.selectedConsignorGSTNumber;
        this.getConsignorDetailsForGSTList();
    }
    getConsignorDetailsForGSTList = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.selectedConsignorId = '';
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    $("#" + this.pageId + "consignorName").val(this.consignorNameOptions[0].consignorName);
                    this.selectedConsignorId = this.consignorNameOptions[0].consignorId;
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };

    reportTypeMode(reportType: string) {
        if (reportType === 'Detail') {
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = false;
            this.viewTypeAgentWise = true;
            this.viewTypeDestinationWise = false;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportDateNdPartyWiseTable = true;
            this.viewTypeShow = true;
            this.paidReportSummaryTable = false;
            this.paidReportDateNdPartyWiseTable = true;
            this.paidReportAgentNdDestinationTable = false;
        } else if (reportType === 'Summary') {
            this.viewTypePartyWise = false;
            this.viewTypeAgentWise = true;
            this.viewTypeDestinationWise = false;
            this.hideTable();
            this.clearTable();
            this.selectTodayDate();
            this.paidReportDateNdPartyWiseTable = true;
            this.viewTypeShow = false;
            this.paidReportSummaryTable = true;
            this.paidReportDateNdPartyWiseTable = false;
            this.paidReportAgentNdDestinationTable = false;
        }
    }

    getPaidReportSummary = () => {
        this.showSpinnerForAction = true;
        this.paidReportScreen.getPaidRptSummary(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "paidReportDateNdPartyWiseId").DataTable().destroy();
                this.paidReportDateNdPartyWiseDataList = [];
                $("#" + this.pageId + "paidReportAgentNdDestinationId").DataTable().destroy();
                this.paidReportAgentNdDestinationDataList = [];
                $("#" + this.pageId + "paidReportSummaryId").DataTable().destroy();
                this.paidReportSummaryDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.paidReportSummaryDataList = response;
                }
                this.dtTriggerPaidReportDateNdPartyWise.next();
                this.dtTriggerPaidReportAgentNdDestination.next();
                this.dtTriggerPaidReportSummary.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Paid Report summary", "info");
            }, () => console.log('done');
    }

    printMethodForDateAndPartyWise() {
        this.selectedSearchType = $("#" + this.pageId + "viewType").val();
        if (this.selectedSearchType == 'dummy') {
            this.dateWisePrintMethod();
        } else if (this.selectedSearchType == 'PartyWise') {
            this.partyWisePrintMethod();
        }

    }
    printMethodForDestWise() {
        this.destWisePrintMethod();
    }


    dateWisePrintMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentName = $("#" + this.pageId + "agentName").val();

        if (this.paidReportDateNdPartyWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Articles", "Act Wt", "Chg Wt", "Consignor", "Booking Date", "Bill Number", "S.Tax", "Paid", "Memo Number", "Memo Amt", "Is Print"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 5, 5, 5, 10, 10, 10, 10, 5, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.grandTotalArticles = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandSerTax = 0;
            this.grandPaid = 0;
            this.grandMemoAmt = 0;

            for (let i = 0; i < this.paidReportDateNdPartyWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.paidReportDateNdPartyWiseDataList[i].lrNumber, this.paidReportDateNdPartyWiseDataList[i].totalArticles, this.paidReportDateNdPartyWiseDataList[i].actualWeight, this.paidReportDateNdPartyWiseDataList[i].chargedWeight, this.paidReportDateNdPartyWiseDataList[i].consignorName, this.paidReportDateNdPartyWiseDataList[i].bookingDateStr, this.paidReportDateNdPartyWiseDataList[i].billNumber, this.paidReportDateNdPartyWiseDataList[i].serviceTax, this.paidReportDateNdPartyWiseDataList[i].paid, this.paidReportDateNdPartyWiseDataList[i].memoNumber, this.paidReportDateNdPartyWiseDataList[i].grandTotal, this.paidReportDateNdPartyWiseDataList[i].isPrintStr,];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalArticles = +this.grandTotalArticles + +this.paidReportDateNdPartyWiseDataList[i].totalArticles;
                this.grandSerTax = +this.grandSerTax + +this.paidReportDateNdPartyWiseDataList[i].serviceTax;
                this.grandPaid = +this.grandPaid + +this.paidReportDateNdPartyWiseDataList[i].paid;
                this.grandActWt = +this.grandActWt + +this.paidReportDateNdPartyWiseDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.paidReportDateNdPartyWiseDataList[i].chargedWeight;
                this.grandMemoAmt = +this.grandMemoAmt + +this.paidReportDateNdPartyWiseDataList[i].grandTotal;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.paidReportDateNdPartyWiseDataList.length, this.grandTotalArticles, this.grandActWt, this.grandChWt, "", "", "", this.grandSerTax, this.grandPaid, "", this.grandMemoAmt, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];


            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedAgentName];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "PAID REPORT");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }



    partyWisePrintMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();

        if (this.paidReportDateNdPartyWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Articles", "Act Wt", "Chg Wt", "Consignor", "Booking Date", "Bill Number", "S.Tax", "Paid", "Memo Number", "Memo Amt", "Is Print"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 5, 5, 5, 10, 10, 10, 10, 5, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.grandTotalArticles = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandSerTax = 0;
            this.grandPaid = 0;
            this.grandMemoAmt = 0;

            for (let i = 0; i < this.paidReportDateNdPartyWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.paidReportDateNdPartyWiseDataList[i].lrNumber, this.paidReportDateNdPartyWiseDataList[i].totalArticles, this.paidReportDateNdPartyWiseDataList[i].actualWeight, this.paidReportDateNdPartyWiseDataList[i].chargedWeight, this.paidReportDateNdPartyWiseDataList[i].consignorName, this.paidReportDateNdPartyWiseDataList[i].bookingDateStr, this.paidReportDateNdPartyWiseDataList[i].billNumber, this.paidReportDateNdPartyWiseDataList[i].serviceTax, this.paidReportDateNdPartyWiseDataList[i].paid, this.paidReportDateNdPartyWiseDataList[i].memoNumber, this.paidReportDateNdPartyWiseDataList[i].grandTotal, this.paidReportDateNdPartyWiseDataList[i].isPrintStr,];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalArticles = +this.grandTotalArticles + +this.paidReportDateNdPartyWiseDataList[i].totalArticles;
                this.grandSerTax = +this.grandSerTax + +this.paidReportDateNdPartyWiseDataList[i].serviceTax;
                this.grandPaid = +this.grandPaid + +this.paidReportDateNdPartyWiseDataList[i].paid;
                this.grandActWt = +this.grandActWt + +this.paidReportDateNdPartyWiseDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.paidReportDateNdPartyWiseDataList[i].chargedWeight;
                this.grandMemoAmt = +this.grandMemoAmt + +this.paidReportDateNdPartyWiseDataList[i].grandTotal;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.paidReportDateNdPartyWiseDataList.length, this.grandTotalArticles, this.grandActWt, this.grandChWt, "", "", "", this.grandSerTax, this.grandPaid, "", this.grandMemoAmt, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Consignor"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedConsignorName];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "PAID REPORT - Party Wise");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }


    destWisePrintMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        if (this.paidReportAgentNdDestinationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Articles", "Book Date", "Consignor", "Invoice No", "Source", "Paid", "Memo Number", "Memo Amt", "S.Tax", "Destination"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 5, 10, 10, 10, 10, 10, 10, 10, 5, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.grandTotalArticles = 0;

            this.grandSerTax = 0;
            this.grandPaid = 0;
            this.grandMemoAmt = 0;

            for (let i = 0; i < this.paidReportAgentNdDestinationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.paidReportAgentNdDestinationDataList[i].lrNumber, this.paidReportAgentNdDestinationDataList[i].totalArticles, this.paidReportAgentNdDestinationDataList[i].bookingDateStr, this.paidReportAgentNdDestinationDataList[i].consignorName, this.paidReportAgentNdDestinationDataList[i].invoiceNumber, this.paidReportAgentNdDestinationDataList[i].agentName, this.paidReportAgentNdDestinationDataList[i].paid, this.paidReportAgentNdDestinationDataList[i].memoNumber, this.paidReportAgentNdDestinationDataList[i].grandTotal, this.paidReportAgentNdDestinationDataList[i].serviceTax, this.paidReportAgentNdDestinationDataList[i].destination];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandTotalArticles = +this.grandTotalArticles + +this.paidReportAgentNdDestinationDataList[i].totalArticles;
                this.grandSerTax = +this.grandSerTax + +this.paidReportAgentNdDestinationDataList[i].serviceTax;
                this.grandPaid = +this.grandPaid + +this.paidReportAgentNdDestinationDataList[i].paid;
                this.grandMemoAmt = +this.grandMemoAmt + +this.paidReportAgentNdDestinationDataList[i].grandTotal;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.paidReportAgentNdDestinationDataList.length, this.grandTotalArticles, "", "", "", "", this.grandPaid, "", this.grandMemoAmt, this.grandSerTax, ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];


            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination", "Agent"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedDestination, this.selectedAgentName];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Paid Report-Destination Wise");
            this.viewCustomPrintV3 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV3) {
                    this.viewCustomPrintV3 = false;
                    localStorage.clear();
                }
            });
        }
    }
}


