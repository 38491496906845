<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>

<!-- <div class="col-md-12 col-sm-12">
	<i title="Reload" class="fas fa-sync-alt fa xs cli"
		style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood; cursor: pointer;"
		(click)="gridReconifgureOnReloadBtn()"></i>
</div> -->
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-10">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Trips Of Agent Booking Barcode
			</h6>

		</div>
		<div class="col-md-2">
			<i title="Reload" class="fas fa-sync-alt fa xs cli"
				style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
				(click)="gridReconifgureOnReloadBtn()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}tripsOfAgentBkgTableId"
			class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTripsOfAgentBooking"
			[dtTrigger]="dtTriggerTripsOfAgentBooking">

			<thead>
				<tr>
					<th>Action</th>
					<th>Agent Name</th>
					<th>Vehicle No</th>
					<th>Trip Date</th>
					<th>Challan Number</th>
					<th>LRs</th>
					<th>Trip</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let tripsOfAgentBookingData of tripsOfAgentBookingDataList ">
					<td><i *ngIf="tripsOfAgentBookingData.isLrPending == true" title="Check" style="cursor: pointer;"
							class="fas fa-check" (click)="checkInTrips(tripsOfAgentBookingData)"></i>
						<i *ngIf="tripsOfAgentBookingData.isUnldPending == true" title="Update" style="cursor: pointer;"
							class="fas fa-edit"
							(click)="openUpdateChallanPopUp(contentUpdateChallan,tripsOfAgentBookingData)"></i>
					</td>
					<td>{{ tripsOfAgentBookingData.fromStation }}</td>
					<td>{{ tripsOfAgentBookingData.vehicleNumber }}</td>
					<td>{{ tripsOfAgentBookingData.depatureDtStr }}</td>
					<td>{{ tripsOfAgentBookingData.challanNo }}</td>
					<td>{{ tripsOfAgentBookingData.totalLRs }}</td>
					<td>{{ tripsOfAgentBookingData.tripNo }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ng-template #contentUpdateChallan let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h6 class="modal-title" id="{{pageId}}modal-basic-title">Update Unloading Details</h6><br>
		<h6>Agent :
			{{selectedAgentNameUpdate}} - Vehicle : {{selectedVehicleUpdate}}</h6>

		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<label>Choudhary Name</label>
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class=" fas fa-user"></i>
								</span>
							</div>
							<input #labourHeadNameDropDown id="{{pageId}}dropDownInputFieldLabourHeadName" type="text"
								class="form-control" [(ngModel)]="modelDropDownLabourHeadName"
								[ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
								[resultFormatter]="formatterDropDownLabourHeadName"
								[inputFormatter]="formatterDropDownLabourHeadName" placeholder="Select Loader Head"
								(focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
						</div>
					</div>
				</div>

				<div class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<label>Bay No</label>
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class=" fas fa-user"></i>
								</span>
							</div>
							<input #bayNoDropDown id="{{pageId}}dropDownInputFieldBayNo" type="text"
								class="form-control" [(ngModel)]="modelDropDownBayNo"
								[ngbTypeahead]="searchDropDownInputFieldBayNo"
								[resultFormatter]="formatterDropDownBayNo" [inputFormatter]="formatterDropDownBayNo"
								placeholder="Select Bay No"
								(focus)="focusDropDownBayNoTA$.next($any($event).target.value)" />
						</div>
					</div>
				</div>

				<div class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<label>Unloading Date</label>
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class=" fas fa-user"></i>
								</span>
							</div>
							<input class="form-control" id="{{pageId}}unldDt" placeholder="yyyy-mm-dd" name="unldDt"
								ngbDatepicker #unldDt="ngbDatepicker">
							<div class="input-group-append" (click)="unldDt.toggle()">
								<span class="input-group-text"> <i class="fa fa-calendar"></i>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<label>Unloading Time</label>
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class=" fas fa-user"></i>
								</span>
							</div>
							<input type="time" class="form-control" id="{{pageId}}unldTime"
								aria-describedby="basic-addon11">
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-12">
					<button type="submit" class="btn btn-dark" (click)="confirmLrs()">Update</button>
					<button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
						id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>