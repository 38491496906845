import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends


/* for map */import { AgmCoreModule } from '@agm/core';

import { OthersReportRoutes } from 'src/app/report/others-report/others-report.routing';
import { LcAndBcReportComponent } from 'src/app/report/others-report/lc-and-bc-report/lc-and-bc-report.component';
import { LoaderPerformanceReportComponent } from 'src/app/report/others-report/loader-performance-report/loader-performance-report.component';
import { MonthlyReportComponent } from 'src/app/report/others-report/monthly-report/monthly-report.component';
import { SalesTaxReportComponent } from 'src/app/report/others-report/sales-tax-report/sales-tax-report.component';
import { DriverRewardedReportComponent } from 'src/app/report/others-report/driver-rewarded-report/driver-rewarded-report.component';
import { TempoPerformanceReportComponent } from 'src/app/report/others-report/tempo-performance-report/tempo-performance-report.component';
import { VehicleTrackingComponent } from 'src/app/report/others-report/vehicle-tracking/vehicle-tracking.component';
import { ChequeSearchReportComponent } from 'src/app/report/others-report/cheque-search-report/cheque-search-report.component';
import { MaterialModule } from "src/app/material.module";
import { ChequeDetailsReportComponent } from 'src/app/report/others-report/cheque-details-report/cheque-details-report.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { LocalChallanReportComponent } from 'src/app/report/others-report/local-challan-report/local-challan-report.component';
import { PartwiseCFTDetailsReportComponent } from 'src/app/report/others-report/partwise-cft-details-report/partwise-cft-details-report.component';
import { PartyContactDetailsComponent } from './party-contact-details/party-contact-details.component';

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( OthersReportRoutes ),
         FormsModule,  NgbModule, DataTablesModule, AgmCoreModule, MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, AutocompleteModule, DailyReportModule,MaterialModule,CashmemoReportModule],
    declarations: [
        LcAndBcReportComponent,
        LoaderPerformanceReportComponent,
        MonthlyReportComponent,
        SalesTaxReportComponent,
        DriverRewardedReportComponent,
        TempoPerformanceReportComponent,
        VehicleTrackingComponent,
        ChequeSearchReportComponent,
        ChequeDetailsReportComponent,
        LocalChallanReportComponent,
        PartwiseCFTDetailsReportComponent,
        PartyContactDetailsComponent
    ]
} )
export class OthersReportModule { }