import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import swal from 'sweetalert';
import { StockService } from "src/app/dataService/stock-service";

@Component({
    selector: 'app-re-generate-barcode',
    templateUrl: './re-generate-barcode.component.html',
    styleUrls: ['./re-generate-barcode.component.css']
})
export class ReGenerateBarcodeComponent implements OnInit {
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    enteredLrNumber: string = '';
    enteredArticle: any;
    showSpinnerForAction = false;
    showArticleNo = false;
    selectedArtType: string = 'all';
    PrintBarcode = [];
    pageId = "rgbc";
	lrDto: LRDto = new LRDto();
    constructor(
        private router: Router,
        private stockService: StockService,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    ngOnInit(): void {}

    logInPage(): void {
        this.router.navigate(['/authentication/login']);
    }

    clearFields(): void {
        this.enteredLrNumber = '';
        this.enteredArticle = '';
        this.selectedArtType = 'all';
        this.showArticleNo = false;
        this.changeDetectorRef.detectChanges();
    }

    onArtTypeChange(artType: string): void {
        this.selectedArtType = this.selectedArtType === artType ? '' : artType;
        this.showArticleNo = this.selectedArtType === 'single';
    }

    reGenerateBarcode(): void {
        
        this.lrDto.lrNumber = this.enteredLrNumber;
        this.lrDto.totalArticles = this.enteredArticle;
        this.showSpinnerForAction = true;

        this.stockService.getBarcodeDetailsService(this.lrDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.printBarcodes(response);
                }else{
					swal("Warning", "No Barcodes Generated for this Lr Number!Plese Verify the Lr Number", "warning");
				}
                this.showSpinnerForAction = false;
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting details!", "warning");
            }
        );
    }

	printBarcodes(lrDtoBarcode) {

        document.getElementById('printSection')!.style.display = 'block';


        this.PrintBarcode = [];
        lrDtoBarcode.forEach((barcode, index) => {
            this.PrintBarcode.push({
                barcode: barcode.barcodeValue,
                Name: barcode.lrNumber,
				Destination: barcode.stationCode,
				ArticleIndex: `${barcode.articles}/${barcode.totalArticles}`
            });
        });

        setTimeout(() => {

            window.print();

        }, 1000);
        window.addEventListener("afterprint", (onclick) => {
            document.getElementById('printSection')!.style.display = 'none';
            this.clearFields();
           
        });

        this.changeDetectorRef.detectChanges();
    }
	
	
}
