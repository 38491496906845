<div class="container custom-container">
	<!-- Header -->
	<div class="row justify-content-center">
		<div class="col-12 mb-3">
			<h6 class="text-center mt-3 mb-3" style="font-size: 1.5rem; font-weight: bold;">
				Loading Process: {{ selectedLoadingLocalTripNo }}
			</h6>
		</div>
	</div>

	<!-- Cards Section -->
	<div class="container custom-container">
		<!-- Row 1: LR Information -->
		<div class="row justify-content-between mb-4">
			<div class="col-4">
				<div class="info-card card-color-blue">
					<span class="label">Tot LR</span>
					<div class="info-count">{{ totalLr }}</div>
				</div>
			</div>
			<div class="col-4">
				<div class="info-card card-color-blue">
					<span class="label">Loaded LR</span>
					<div class="info-count">{{ loadedLr }}</div>
				</div>
			</div>
			<div class="col-4">
				<div class="info-card card-color-blue">
					<span class="label">Bal LR</span>
					<div class="info-count">{{ totalLr - loadedLr }}</div>
				</div>
			</div>
		</div>

		<!-- Row 2: Article Information -->
		<div class="row justify-content-between">
			<div class="col-4">
				<div class="info-card card-color-green">
					<span class="label">Tot Articles</span>
					<div class="info-count">{{ totalArticles }}</div>
				</div>
			</div>
			<div class="col-4">
				<div class="info-card card-color-green">
					<span class="label link-style" (click)="onLoadedArticlesClick(contentLoadedLrs)">
						Loaded Articles
					</span>
					<div class="info-count">{{ loadedArticles }}</div>
				</div>
			</div>


			<div class="col-4">
				<div class="info-card card-color-green">
					<span class="label">Bal Articles</span>
					<div class="info-count">{{ totalArticles - loadedArticles }}</div>
				</div>
			</div>
		</div>
	</div>





	<button type="button" class="btn btn-success waves-light" (click)="onPlusButtonClick(contenetUpdateOnAction)">
		<i class="fas fa-plus-circle" style="font-size: 23px;"></i>
	</button>

	<button type="button" class="btn btn-danger waves-light" (click)="onMinusButtonClick(contenetUpdateOnAction)">
		<i class="fas fa-minus-circle" style="font-size: 23px;"></i>
	</button>
	<button style="float: right;" class="btn btn-success m-r-5 button_custome" id="{{pageId}}departureBtn"
		(click)="validateDepartureTripSheet();">Departure</button>
	<button style="float: right;" class="btn btn-success m-r-5 button_custome" id="{{pageId}}manualLoadBtn"
		(click)="validateManualLoad(contentForManualLoading);">Manual Load</button>
</div>
<ng-template #contenetUpdateOnAction let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h5 class="modal-title">Barcode loading</h5>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">


				<div class="col-sm-12 col-md-12">
					<div class="control">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
								<label>Enter Barcode No:</label>
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="ti-home"></i>
									</span>
								</div>
								<input #barcodeNoPopup id="{{pageId}}barcodeNoPopupId" type="text" class="form-control"
									aria-describedby="basic-addon11">
							</div>
						</div>
					</div>

				</div>

				<div class="col-sm-12 col-md-12">
					<button *ngIf="showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
						class="btn btn-success" (click)="proceedbarcodeNoPopupPlusBtn()">Proceed</button>
					<button *ngIf="!showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
						class="btn btn-success" (click)="proceedbarcodeNoPopupMinusBtn()">Proceed</button>
					<button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
						id="{{pageId}}clearActionbtnBtn" (click)="d('Cross click')">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>



<ng-template #contentForManualLoading let-c="close" let-d="dismiss">

	<div class="modal-header">
		<h5 class="modal-title">Manual Loading</h5>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">


				<div class="col-sm-12 col-md-12">
					<div class="control">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
								<label>Enter LR No:</label>
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="ti-home"></i>
									</span>
								</div>
								<input #manualLoadLrNumber id="{{pageId}}manualLoadLrNumberId" type="text"
									class="form-control" aria-describedby="basic-addon11">
							</div>
						</div>
					</div>

				</div>
				<div class="col-sm-12 col-md-12">
					<div class="control">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
								<label>Enter Article:</label>
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="ti-home"></i>
									</span>
								</div>
								<input #manualLoadArtciles id="{{pageId}}manualLoadArtcilesId" type="number"
									class="form-control" aria-describedby="basic-addon11">
							</div>
						</div>
					</div>

				</div>

				<div class="col-sm-12 col-md-12">
					<button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-success"
						(click)="submitManualLoading()">Submit</button>
					<button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
						id="{{pageId}}clearManualLoadingBtn"
						(click)="d('Cross click');clearManualLoading();">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #contentLoadedLrs let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h6 class="modal-title" id="{{pageId}}modal-basic-title">Loaded LR Details
		</h6>

		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="custom-modal redesigned-modal">
		<div class="custom-modal-content">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12 vl">
						<div *ngIf="showSpinnerForAction3" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="box-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">
									Loaded LR Details</h6>
								<div *ngIf="!showSpinnerForAction">
									<table datatable id="{{pageId}}loadingSheetTableId"
										class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsLoadingSheet" [dtTrigger]="dtTriggerLoadingSheet">
										<thead>
											<tr>
												<th>LR No</th>
												<th>T. Art</th>
												<th>L. Art</th>
												<th>B. Art</th>
												<th>Act Wgt</th>
												<th>Chg Wgt</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let loadingSheetData of loadedLRsDataList">
												<td>{{ loadingSheetData.lrNumber }}</td>
												<td>{{ loadingSheetData.totalArticles }}</td>
												<td>{{ loadingSheetData.loadingArticle }}</td>
												<td>{{ loadingSheetData.remArticles }}</td>
												<td>{{ loadingSheetData.actualWeight }}</td>
												<td>{{ loadingSheetData.chargedWeight }}</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td>Total</td>
												<td>{{ totalArticles }}</td>
												<td>{{ totalLoadingArticles }}</td>
												<td>{{ totalRemainingArticles }}</td>
												<td>{{ totalActualWeight }}</td>
												<td>{{ totalChargedWeight }}</td>
											</tr>
										</tfoot>
									</table>
								</div>
								<div *ngIf="showSpinnerForAction" class="text-center">
									<mat-progress-bar mode="indeterminate"></mat-progress-bar>
									<h6>Loading Details...</h6>
								</div>

							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-12 form-group m-b-0 text-right">

						<button type="button" class="btn btn-dark waves-effect waves-light" id="{{pageId}}clearPopupBtn"
							(click)="popupClose();">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>