import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class MemoReport {

    private _baseUrl = serverUrl.webMemoReport;

    constructor(private http: HttpClient) {

    }

    getMemoLessDetailsReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getMemoLessDetailsRptApi`, cashMemoDto);
    }

    getMemoLessDetailsForSourceWiseReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getMemoLessDetailsSourceWiseRptApi`, cashMemoDto);
    }

    getMemoLessDescriptionDetailsReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getMemoLessDescriptionApi`, cashMemoDto);
    }

    getChequeDetailsReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getChequeDetailsApi`, cashMemoDto);
    }

    getOSCashmemoCollectionDeptReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getOSCashmemoCollectionDeptApi`, cashMemoDto);
    }

    getCashMemoReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCashMemoReportApi`, cashMemoDto);
    }

    getConsolidatedMemoReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getConsolidatedMemoReportApi`, cashMemoDto);
    }

    getConsolidatedMemoReportSourceWise(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getConsolidatedMemoReportSourceWiseApi`, cashMemoDto);
    }

    getConsolidatedMemoReportSourceMainWise(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getConsolidatedMemoReportMainSourceWiseApi`, cashMemoDto);
    }

    getDestinationChequeDetailsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDestinationChequeDetailsReportApi`, lrDto);
    }

    updateDestinationChqSentToBankDetailsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/updateDestinationChqSentToBankDetailsApi`, lrDto);
    }

    updateDestinationChequeDateDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}//updateDestinationChequeDateApi`, lrDto);
    }

    getCashMemoHistoryDetailsReport(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCashMemoHistoryDetailsApi`, rateMasterDto);
    }

    getLrdetailsForCashMemoRpt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrdetailsForCashMemoRptApi`, lrDto);
    }

    getGCNotRecievedDetails(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getGCNotRecievedDetailsApi`, cashMemoDto);
    }

    getCashMemoLetterHeadDtls(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getCashMemoLetterHeadDtlsApi`, rateMasterDto);
    }
    updateCashMemoLetterHead(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateCashMemoLetterHeadApi`, rateMasterDto);
    }

    getPartyLessReport(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getPartyLessReportApi`, cashMemoDto);
    }

    getMemoDiscountDetailsSourceWise(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getMemoDiscountDetailsSourceWiseApi`, cashMemoDto);
    }

    getCommodityMismatchDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityMismatchDetailsApi`, lrDto);
    }

    getDailyCollectionRptDetails(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getDailyCollectionRptDetailsApi`, cashMemoDto);
    }

    dailyCollectionReportPrepareSMS(cashMemoDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/dailyCollectionReportPrepareSMSApi`, cashMemoDto);
    }

    getCollectionDetailsGCTaken(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsGCTakenApi`, cashMemoDto);
    }

    getCollectionDetailsLeftToParty(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsLeftToPartyApi`, cashMemoDto);
    }

    getCollectionDetailsLeftToPartyStmtSummary(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsLeftToPartyStmtSummaryApi`, cashMemoDto);
    }


    getCollectionDetailsAmtCollected(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/getCollectionDetailsAmtCollectedApi`, cashMemoDto);
    }

    getCollectionDetailsAmtCollectedConfirm(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsAmtCollectedConfirmApi`, cashMemoDto);
    }

    getCollectionDetailsLeftToPartyPendAckStmt(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsLeftToPartyPendAckStmtApi`, cashMemoDto);
    }


    updateToPartyAckRec(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateToPartyAckRecApi`, cashMemoDto);
    }

    getCollectionDetailsCollectionConsolidatedRpt(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionDetailsCollectionConsolidatedRptApi`, cashMemoDto);
    }
    getCollectionManWiseOSDetailsSummary(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionManWiseOSDetailsSummaryApi`, cashMemoDto);
    }

    getCollectionManWiseOSDetailsService(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionManWiseOSDetailsApi`, cashMemoDto);
    }

    getCollectionManWiseOSPartyWiseDetails(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCollectionManWiseOSPartyWiseDetailsApi`, cashMemoDto);
    }

    blockCashMemo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/blockCashMemoApi`, lrDto);
    }

    getManualCashMemoCheckFirst(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getManualCashMemoCheckFirstApi`, cashMemoDto);
    }

    updateCashMemoCheck(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateCashMemoCheckApi`, cashMemoDto);
    }
    updateCMMemoStockCheck(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateCMMemoStockCheckApi`, cashMemoDto);
    }

    getManualCashMemoCheckPrintOut(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getManualCashMemoCheckPrintOutApi`, cashMemoDto);
    }

    getSingleCashMemo(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/getSingleCashMemoApi`, cashMemoDto);
    }

    transferToGodownLrs(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/transferToGodownLrsApi`, cashMemoDto);
    }

    getTPStmtDetails(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTPStmtDetailsApi`, cashMemoDto);
    }

    updateUploadPartyLessCheck(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateUploadPartyLessCheckApi`, cashMemoDto);
    }

    getDailyCollectionDetailsPrint(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/getDailyCollectionDetailsPrintApi`, cashMemoDto);
    }

    getLRPodDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLRPodDetailsApi`, lrDto);
    }

    getCashmemoOnlinePaymentDetails(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/getCashmemoOnlinePaymentDetailsApi`, cashMemoDto);
    }

    updateCashmemoOnlinePaymentDetails(cashMemoDto: Object): Observable<CashMemoDto> {
        return this.http.post<CashMemoDto>(`${this._baseUrl}/updateCashmemoOnlinePaymentDetailsApi`, cashMemoDto);
    }
}








