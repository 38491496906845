<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}serviceTaxRptId">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Service Tax Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>View Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}viewType"
															name="viewType" #viewType
															(change)="viewTypeMode(viewType.value)">
															<option selected value="bookingServiceTaxReport">Booking
																Service Tax Report</option>
															<option value="destinationServiceTaxReport">Destination
																Service Tax Report</option>
															<option value="subStationDetailsReport">SubStation
																Details Report</option>
															<option value="bookingLessDetailsReport">Total LRs
																&
																Less LRs Details Report</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" name="fromDates"
															id="{{pageId}}fromDates" ngbDatepicker
															#fromDates="ngbDatepicker" [(ngModel)]="fromDatesModal"
															autocomplete="off">
														<div class="input-group-append" (click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input id="{{pageId}}toDates"
															class="form-control" placeholder="yyyy-mm-dd" name="toDates"
															ngbDatepicker #toDates="ngbDatepicker"
															[(ngModel)]="toDatesModal" autocomplete="off">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
														</div>
														<input id="{{pageId}}destinationId" type="text"
															class="form-control"
															(selectItem)="clickListnerForDestination($event)"
															[(ngModel)]="modelDestination"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination.." />

													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Consignee Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																		class="fas fa-user"></i>
																</span>
															</div>
															<input #consigneeName id="{{pageId}}consigneeName"
																name="consigneeName" type="text" class="form-control"
																placeholder="Select Consignee Name"
																(selectItem)="consigneeDropDownListner($event)"
																[(ngModel)]="modelConsigneeName"
																[ngbTypeahead]="consigneeNameSearchTA"
																[ngModelOptions]="{standalone: true}"
																[resultFormatter]="formatterConsigneeName"
																[inputFormatter]="formatterConsigneeName"
																(focus)="focusConsigneeNameTA$.next($any($event).target.value)"
																[ngModelOptions]="{standalone: true}" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="validateSearchBtn();">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="validateClearBtn();">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<h6 [hidden]="!hideshowBkgServiceTaxDefaultRptTable">Booking
							Service Tax Report</h6>
						<h6 [hidden]="!hideshowDestinationServiceTaxRptTable">
							Destination Service Tax Report</h6>
						<h6 [hidden]="!hideshowSubStationServiceTaxRptTable">
							SubStation Details Report</h6>
						<h6 [hidden]="!hideshowBookingLessDetailsReportTable">
							Total LRs
							&
							Less LRs Details Report</h6>

						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
							(click)="customPrintBkgServiceTaxDefaultRpt()" id="{{pageId}}printAllBtn"
							[hidden]="!hideshowBkgServiceTaxDefaultRptTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
							(click)="customPrintDestinationServiceTaxRpt()" id="{{pageId}}printAllBtn"
							[hidden]="!hideshowDestinationServiceTaxRptTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
						<button type="submit" class="dt-button" style="margin-left: 92%;"
							(click)="customPrintSubStationServiceTaxRpt()" id="{{pageId}}printAllBtn"
							[hidden]="!hideshowSubStationServiceTaxRptTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>
						<button type="submit" class="dt-button" style="margin-left: 88%;" (click)="bulkLRRCMemoBtn()"
							id="{{pageId}}bulkRcMemoBtn" [hidden]="!hideshowBookingLessDetailsReportTable">
							Bulk RC Memo
						</button>

						<div class="box-body" [hidden]="!hideshowBkgServiceTaxDefaultRptTable">
							<table datatable id="{{pageId}}bookingServiceTaxDefaultRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsBookingServiceTaxDefault"
								[dtTrigger]="dtTriggerBookingServiceTaxDefault">
								<thead>
									<tr>
										<th>LR No</th>
										<th>Destination</th>
										<th>Booking Date</th>
										<th>Art No</th>
										<th>Consignee Name</th>
										<th>Invoice No</th>
										<th>Invoice Date</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
										<th>To Pay</th>
										<th>Paid</th>
										<th>Hamali Charge</th>
										<th>RC</th>
										<th>SC</th>
										<th>Gross Pay</th>
										<th>Discount</th>
										<th>Net Pay</th>
										<th>Is Consignee Admin Rate</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let bookingServiceTaxDefaultData of bookingServiceTaxDefaultDataList ">
										<td>{{ bookingServiceTaxDefaultData.lrNumber }}</td>
										<td>{{ bookingServiceTaxDefaultData.destination }}</td>
										<td>{{ bookingServiceTaxDefaultData.bookingDateStr }}</td>
										<td>{{ bookingServiceTaxDefaultData.totalArticles }}</td>
										<td>{{ bookingServiceTaxDefaultData.consigneeName }}</td>
										<td>{{ bookingServiceTaxDefaultData.invoiceNumber }}</td>
										<td>{{ bookingServiceTaxDefaultData.invoiceDateStr }}</td>
										<td>{{ bookingServiceTaxDefaultData.actualWeight }}</td>
										<td>{{ bookingServiceTaxDefaultData.chargedWeight }}</td>
										<td>{{ bookingServiceTaxDefaultData.toPay }}</td>
										<td>{{ bookingServiceTaxDefaultData.paid }}</td>
										<td>{{ bookingServiceTaxDefaultData.hamali }}</td>
										<td>{{ bookingServiceTaxDefaultData.receiptCharge }}</td>
										<td>{{ bookingServiceTaxDefaultData.serviceCharge }}</td>
										<td>{{ bookingServiceTaxDefaultData.totalAmount }}</td>
										<td>{{ bookingServiceTaxDefaultData.discountAmount }}</td>
										<td>{{ bookingServiceTaxDefaultData.grandTotal }}</td>
										<td *ngIf="bookingServiceTaxDefaultData.isConsigneeAdminRateConsidered == false" style='font-size: 13px; color: blue; font-weight: bold;'>
											{{ bookingServiceTaxDefaultData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
										<td *ngIf="bookingServiceTaxDefaultData.isConsigneeAdminRateConsidered == true" style='font-size: 13px; color: green; font-weight: bold;'>
											{{ bookingServiceTaxDefaultData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
						<div class="box-body" [hidden]="!hideshowDestinationServiceTaxRptTable">
							<table datatable id="{{pageId}}destinationServiceTaxRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionDestinationServiceTax"
								[dtTrigger]="dtTriggerDestinationServiceTax">
								<thead>
									<tr>
										<th>LR No</th>
										<th>Destination</th>
										<th>Booking Date</th>
										<th>Articles</th>
										<th>Consignee Name</th>
										<th>Invoice No</th>
										<th>Invoice Date</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
										<th>To Pay Amt</th>
										<th>Paid Amt</th>
										<th>Is Consignee Admin Rate</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let destinationServiceTaxData of destinationServiceTaxDataList ">
										<td>{{ destinationServiceTaxData.lrNumber }}</td>
										<td>{{ destinationServiceTaxData.destination }}</td>
										<td>{{ destinationServiceTaxData.bookingDateStr }}</td>
										<td>{{ destinationServiceTaxData.totalArticles }}</td>
										<td>{{ destinationServiceTaxData.consigneeName }}</td>
										<td>{{ destinationServiceTaxData.invoiceNumber }}</td>
										<td>{{ destinationServiceTaxData.invoiceDateStr }}</td>
										<td>{{ destinationServiceTaxData.actualWeight }}</td>
										<td>{{ destinationServiceTaxData.chargedWeight }}</td>
										<td>{{ destinationServiceTaxData.toPay }}</td>
										<td>{{ destinationServiceTaxData.paid }}</td>
										<td *ngIf="destinationServiceTaxData.isConsigneeAdminRateConsidered == false" style='font-size: 13px; color: blue; font-weight: bold;'>
											{{ destinationServiceTaxData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
										<td *ngIf="destinationServiceTaxData.isConsigneeAdminRateConsidered == true" style='font-size: 13px; color: green; font-weight: bold;'>
											{{ destinationServiceTaxData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
						<div class="box-body" [hidden]="!hideshowSubStationServiceTaxRptTable">
							<table datatable id="{{pageId}}subStationServiceTaxRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionSubStationServiceTax" [dtTrigger]="dtTriggerSubStationServiceTax">
								<thead>
									<tr>
										<th>LR No</th>
										<th>Station</th>
										<th>Booking Date</th>
										<th>Articles</th>
										<th>Consignee Name</th>
										<th>Invoice No</th>
										<th>Invoice Date</th>
										<th>Actual Weight</th>
										<th>Charged Weight</th>
										<th>To Pay Amt</th>
										<th>Paid Amt</th>
										<th>Is Consignee Admin Rate</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let subStationServiceTaxData of subStationServiceTaxDataList ">
										<td>{{ subStationServiceTaxData.lrNumber }}</td>
										<td>{{ subStationServiceTaxData.deliveryPoints }}</td>
										<td>{{ subStationServiceTaxData.bookingDateStr }}</td>
										<td>{{ subStationServiceTaxData.totalArticles }}</td>
										<td>{{ subStationServiceTaxData.consigneeName }}</td>
										<td>{{ subStationServiceTaxData.invoiceNumber }}</td>
										<td>{{ subStationServiceTaxData.invoiceDateStr }}</td>
										<td>{{ subStationServiceTaxData.actualWeight }}</td>
										<td>{{ subStationServiceTaxData.chargedWeight }}</td>
										<td>{{ subStationServiceTaxData.toPay }}</td>
										<td>{{ subStationServiceTaxData.paid }}</td>
										<td *ngIf="subStationServiceTaxData.isConsigneeAdminRateConsidered == false" style='font-size: 13px; color: blue; font-weight: bold;'>
											{{ subStationServiceTaxData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
										<td *ngIf="subStationServiceTaxData.isConsigneeAdminRateConsidered == true" style='font-size: 13px; color: green; font-weight: bold;'>
											{{ subStationServiceTaxData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>

						<div class="box-body" [hidden]="!hideshowBookingLessDetailsReportTable">
							<table datatable id="{{pageId}}bookingLessDetailsReportTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsBookingLessDetailsReport"
								[dtTrigger]="dtTriggerBookingLessDetailsReport">
								<thead>
									<tr>
										<th>S.No</th>
										<th>Main Station</th>
										<th>Source</th>
										<!-- <th>Destination</th> -->
										<th>Booking Date</th>
										<th>LR No</th>
										<th>Commodity</th>
										<th>Consignee Name</th>
										<th>Invoice No</th>
										<th>Invoice Date</th>
										<th>Art</th>
										<th>A.Wgt</th>
										<th>C.Wgt</th>
										<th>Bs Frt</th>
										<th>To Pay</th>
										<th>Paid</th>
										<th>FOV Amt</th>
										<th>Discount</th>
										<th>Per Unit</th>
										<th>Rate Type</th>
										<th>Rate</th>
										<th>S.Hamali</th>
										<th>GC</th>
										<!-- <th>FOV</th> -->
										<th>LC</th>
										<th>BC</th>
										<th>AOC</th>
										<th>DD</th>
										<th [hidden]=true>Remarks</th>
										<th [hidden]=true>SH</th>
										<th [hidden]=true>LC</th>
										<th [hidden]=true>BC</th>
										<th [hidden]=true>AOC</th>
										<th [hidden]=true>DD</th>
										<th [hidden]=true>GC</th>
										<th>Is Consignee Admin Rate</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let bookingLessDetailsReportData of bookingLessDetailsReportDataList let i = index">
										<td>{{bookingLessDetailsReportData.autoId}}</td>
										<td>{{ bookingLessDetailsReportData.mainstation }}</td>
										<td>{{ bookingLessDetailsReportData.source }}</td>
										<!-- <td>{{ bookingLessDetailsReportData.destination }}</td> -->
										<td>{{ bookingLessDetailsReportData.bookingDateStr }}</td>
										<td>{{ bookingLessDetailsReportData.lrNumber }}</td>
										<td>{{ bookingLessDetailsReportData.commodityName }}</td>
										<td>{{ bookingLessDetailsReportData.consigneeName }}</td>
										<td>{{ bookingLessDetailsReportData.invoiceNumber }}</td>
										<td>{{ bookingLessDetailsReportData.invoiceDateStr }}</td>
										<td>{{ bookingLessDetailsReportData.totalArticles }}</td>
										<td>{{ bookingLessDetailsReportData.actualWeight }}</td>
										<td>{{ bookingLessDetailsReportData.chargedWeight }}</td>
										<td>{{ bookingLessDetailsReportData.baseFreight }}</td>
										<td>{{ bookingLessDetailsReportData.toPay }}</td>
										<td>{{ bookingLessDetailsReportData.paid }}</td>
										<td>{{ bookingLessDetailsReportData.riskCharge }}</td>
										<td>{{ bookingLessDetailsReportData.discountAmount }}</td>
										<td>{{ bookingLessDetailsReportData.ratePerUnit }}</td>
										<td>{{ bookingLessDetailsReportData.mode }}</td>
										<!-- <td>{{ bookingLessDetailsReportData.rate }}</td> -->
										<td>{{ bookingLessDetailsReportData.rateStr }}</td>
										<!-- <td>{{ (bookingLessDetailsReportData.hamaliCharge ? 'Yes' : 'No') }}</td> -->
										<td>{{ bookingLessDetailsReportData.srcHamaliChgStr }}</td>
										<!-- <td>{{ bookingLessDetailsReportData.rateGcChg }}</td> -->
										<td>{{ bookingLessDetailsReportData.gcChgStr }}</td>
										<!-- <td>{{ (bookingLessDetailsReportData.riskChargeSet ? 'Yes' : 'No') }}
										</td>
										<td>{{ (bookingLessDetailsReportData.lcCharge ? 'Yes' : 'No') }}</td>
										<td>{{
											(bookingLessDetailsReportData.bcCharge ? 'Yes' : 'No') }}</td>
										<td>{{
											(bookingLessDetailsReportData.aoccharge ? 'Yes' : 'No') }}</td>
										<td>{{
											(bookingLessDetailsReportData.ddcharge ? 'Yes' : 'No') }}</td> -->

										<td>{{ bookingLessDetailsReportData.lcChgStr }}</td>
										<td>{{
											bookingLessDetailsReportData.bcChgStr }}</td>
										<td>{{
											bookingLessDetailsReportData.aocChgStr }}</td>
										<td>{{
											bookingLessDetailsReportData.ddChgStr }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.remarks }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.srcHamali }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.lcChg }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.bcChg }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.AOC }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.ddAmt }}</td>
										<td [hidden]=true>{{ bookingLessDetailsReportData.rateGcChg }}</td>
										<td *ngIf="bookingLessDetailsReportData.isConsigneeAdminRateConsidered == false" style='font-size: 13px; color: blue; font-weight: bold;'>
											{{ bookingLessDetailsReportData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
										<td *ngIf="bookingLessDetailsReportData.isConsigneeAdminRateConsidered == true" style='font-size: 13px; color: green; font-weight: bold;'>
											{{ bookingLessDetailsReportData.isConsigneeAdminRateConsidered== true ? 'Yes' : 'No'}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<!-- <td></td> -->
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<!-- <td></td> -->
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td [hidden]=true></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>