import { Component, AfterViewInit } from '@angular/core';


import { LoginComponent } from 'src/app/authentication/login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
declare var $: any;

@Component({
	templateUrl: './dashboard1.component.html',
	styleUrls: ['./dashboard1.component.css']
})
export class Dashboard1Component implements AfterViewInit {

	userDataDtoReturnSession: any;
	viewCashmemoPendingAndMissing = false;
	viewCashmemoPendingFromBinny = false;
	viewLeftToPartyStmtPending = false;
	viewBlockLrDetails = false;
	viewCODLrDetails = false;
	viewTransitLrs = false;
	viewLrsShortExtra = false;
	viewStocksSummary = false;
	viewPendingHamaliMemos = false;
	viewBarcodeScanningFromKVH = false;
	viewScheduledLocalTrips = false;
	viewLocalTrip = false;

	viewPendingCommodity = false;
	viewScheduleTripFrom = false;
	viewScheduleTripTo = false;

	viewTripOnTransitFrom = false;
	viewTripOnTransit = false;
	viewTripOnTransitTo = false;
	viewPendingHireslipGeneration = false;
	viewTripsOfAgentBkg = false;
	viewExpenseOsPaymentFollowup = false;
	viewFortnightStmtSummary = false;
	viewLabourHamaliStmtSummary = false;
	viewSummary = false;
	viewTrucksReqForTrip = false;
	viewFinalNoticeEmail = false;
	viewDelayedTrips = false;
	viewAgentRanking = false;
	viewAgentUnpaidStmt = false;
	viewPendingStockSms = false;
	viewPendingConsignee = false;
	viewDoorDelvLrsNotDelvd = false;
	viewBkgAgentStmtDtls = false;
	viewStocksDestinationSummary = false;
	viewTripsOfAgentBkgBarcode=false;
	constructor(

	) {
		this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
		console.log(this.userDataDtoReturnSession);
		//console.log( Object.keys( this.userDataDtoReturnSession.sortedMapFeatures ) );
		this.scrrensToLoadAsPerRights();

	}

	scrrensToLoadAsPerRights() {

		if (this.userDataDtoReturnSession.listOfDashboard != null) {
			//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Dashboard);
			for (let i = 0; i < this.userDataDtoReturnSession.listOfDashboard.length; i++) {
				console.log(this.userDataDtoReturnSession.listOfDashboard[i]);
				if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Cash Memo Pending and Missing") {
					this.viewCashmemoPendingAndMissing = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Cash Memo Pending From Binnymills") {
					this.viewCashmemoPendingFromBinny = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Left To Party Statements - Pending") {
					this.viewLeftToPartyStmtPending = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Block LR Details") {
					this.viewBlockLrDetails = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"COD LR Details") {
					this.viewCODLrDetails = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Transit Lrs") {
					this.viewTransitLrs = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"LRs Short & Extra") {
					this.viewLrsShortExtra = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Stocks Summary") {
					this.viewStocksSummary = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Pending Hamali Memos") {
					this.viewPendingHamaliMemos = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"BarcodeScanning For Kvh") {
					this.viewBarcodeScanningFromKVH = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Scheduled Local Trips") {
					this.viewScheduledLocalTrips = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Pending Commodity") {
					this.viewPendingCommodity = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Local Trips") {
					this.viewLocalTrip = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Scheduled Trips From") {
					this.viewScheduleTripFrom = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Scheduled Trips To") {
					this.viewScheduleTripTo = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Trips On Transit From") {
					this.viewTripOnTransitFrom = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Trips On Transit") {
					this.viewTripOnTransit = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Trips On Transit To") {
					this.viewTripOnTransitTo = false;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Pending HireSlip Generation") {
					this.viewPendingHireslipGeneration = true;
				} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
					&& this.userDataDtoReturnSession.listOfDashboard[i] ==
					"Trips Of Agent Booking") {
					this.viewTripsOfAgentBkg = true;
				}else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Expenses OS Payment Followup") {
						this.viewExpenseOsPaymentFollowup = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"FortNight Statement Summary") {
						this.viewFortnightStmtSummary = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"SRD Labour Hamali Statement") {
						this.viewLabourHamaliStmtSummary = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Summary") {
						this.viewSummary = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Trucks Required For Trip") {
						this.viewTrucksReqForTrip = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Final Notice Pending Email") {
						this.viewFinalNoticeEmail = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Delayed Trips") {
						this.viewDelayedTrips = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Agent Ranking") {
						this.viewAgentRanking = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Unpaid Booking Agent Statement") {
						this.viewAgentUnpaidStmt = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Pending Stocks SMS") {
						this.viewPendingStockSms = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Pending Consignee") {
						this.viewPendingConsignee = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Door Delivery Consignment Not Delivered Details") {
						this.viewDoorDelvLrsNotDelvd = true;
					}
				else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Booking Agent Statement") {
						this.viewBkgAgentStmtDtls = true;
					}else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Stocks Destination Summary") {
						this.viewStocksDestinationSummary = true;
					} else if (this.userDataDtoReturnSession.listOfDashboard[i] != null
						&& this.userDataDtoReturnSession.listOfDashboard[i] ==
						"Trips Of Agent Booking Barcode") {
						this.viewTripsOfAgentBkgBarcode = true;
					}
				
				/*this.viewPendingHireslipGeneration = true;
				this.viewScheduleTripFrom = true;
				this.viewScheduleTripTo = true;
				this.viewTripOnTransitFrom = true;
				this.viewTripOnTransit = true;
				this.viewTripOnTransitTo = true;
				this.viewPendingHireslipGeneration = true;*/
			}
		}
	}

	ngAfterViewInit() { }
}