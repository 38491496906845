import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LRDto } from 'src/app/dto/LR-dto';
import { serverUrl } from "src/environments/environment";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";

@Injectable({
    providedIn: 'root'
})
export class LrService {
    //for Local
    //        private _baseUrl = "http://localhost:8080/srd/report/v1";
    //for QA
    //    private _baseUrl = "http://54.210.51.40:8080/srd/report/v1";
    //for Live
    //        private _baseUrl = "http://3.222.201.242:8080/srd/report/v1";

    private _baseUrl = serverUrl.webLr;

    constructor(
        private http: HttpClient,
    ) {
    }

    getLRList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRListApi`, lrDto);
    }

    getLRShortageDtls(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLRShortageDtlsApi`, hireSlipDto);
    }

    getLrArticlesDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrArticlesDetailsApi`, lrDto);
    }

    getLrShortageForSingleLr(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrShortageForSingleLrApi`, lrDto);
    }

    getETAForTrip(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getETAForTripApi`, lrDto);
    }

    chkLrExist(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/chkLrExistApi`, lrDto);
    }

    updateLRsShortage(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLRsShortageApi`, lrDto);
    }

    getLrDetForLrShortage(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrDetForLrShortageApi`, lrDto);
    }

    getDlyBkgDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDlyBkgDetailsApi`, lrDto);
    }

    // imran 03042021
    getAutomatedLRDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAutomatedLRDetailsApi`, lrDto);
    }

    updateLrPrintRights(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLrPrintRights`, lrDto);
    }

    // fortnight stmt 12042021
    getBkgAgtCommDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getBkgAgtCommDetailsApi`, rateMasterDto);
    }

    setBkgAgtCommDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/setBkgAgtCommDetailsApi`, rateMasterDto);
    }

    //agent stmt gen
    getBkgStationForStmt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBkgStationForStmtApi`, lrDto);
    }

    validateStmtDate(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/validateStmtDateApi`, fortNightStmtDto);
    }

    generateBkgAgtStmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/generateBkgAgtStmtApi`, fortNightStmtDto);
    }

    //20042021 bkgstmtrpt
    getBookingStatementReportDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getBookingStatementReportDetailsApi`, fortNightStmtDto);
    }
    getLrDetailsForBkgAgentStmt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsForBkgAgentStmtApi`, lrDto);
    }
    bkgAgtAmtPayable(lrDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/bkgAgtAmtPayableApi`, lrDto);
    }

    // lredit update service Maaz

    updateLRDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/updateLRDetailsApi`, lrDto);
    }

    deleteLR(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteLRApi`, lrDto);
    }

    //26042021
    getLabourHamaliStatementReportDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getLabourHamaliStatementReportDetailsApi`, fortNightStmtDto);
    }

    getLrdetailsForLBRHamaliStmtNewUnloadingDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrdetailsForLBRHamaliStmtNewUnloadingDetailsApi`, lrDto);
    }

    getLrdetailsForLBRHamaliStmtNew(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrdetailsForLBRHamaliStmtNewApi`, lrDto);
    }

    getLrdetailsForLBRHamaliStmtNewUnloading(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrdetailsForLBRHamaliStmtNewUnloadingApi`, lrDto);
    }

    getCommodityItemWiseReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityItemWiseReportApi`, lrDto);
    }

    getCommodityItemWiseDetailsReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCommodityItemWiseDetailsReportApi`, lrDto);
    }

    /* Loader Performance RPT START*/
    getLoaderPerformRptDtls(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLoaderPerformRptDtlsApi`, lrDto);
    }
    /* Loader Performance RPT END*/

    /* Office  RPT START*/
    getBookingOfficeDailyReportDestinationWise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingOfficeDailyReportDestinationWiseApi`, hireSlipDto);
    }

    getBookingOfficeDailyReportMainDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBookingOfficeDailyReportMainDetailsApi`, hireSlipDto);
    }
    /* Office  RPT ENd*/

    /* LR ISSUE BALANCE RPT START*/
    getLRIssueBalRpDtls(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRIssueBalRpDtlsApi`, lrDto);
    }

    getLrissueAgentDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrissueAgentDetailsApi`, lrDto);
    }
    /* LR ISSUE BALANCE RPT END*/

    /* LR Performance Report start */
    getLrPerformRptDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrPerformRptDetailsApi`, lrDto);
    }
    /* LR Performance Report end */

    //Basha 22052021
    getLRIssueDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRIssueDetailsApi`, lrDto);
    }
    chkLrRangeExist(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/chkLrRangeExistApi`, lrDto);
    }

    addLRIssueDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addLRIssueDetailsApi`, lrDto);
    }
    removeLRIssueDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/removeLRIssueDetailsApi`, lrDto);
    }

    getChallanIssueDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getChallanIssueDetailsApi`, lrDto);
    }
    chkChallanRangeExist(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/chkChallanRangeExistApi`, lrDto);
    }

    addChallanIssueDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addChallanIssueDetailsApi`, lrDto);
    }
    removeChallanissueDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/removeChallanissueDetailsApi`, lrDto);
    }

    lrEntryMissAgent(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/lrEntryMissAgentApi`, lrDto);
    }

    addLRNumber(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addLRNumberApi`, lrDto);
    }

    addLREntryMissAgent(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addLREntryMissAgentApi`, lrDto);
    }

    getLREntryMissAgent(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLREntryMissAgentApi`, lrDto);
    }

    /* Amount Receivable From Agent start */

    getAgnetAmountBalance(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getAgnetAmountBalanceApi`, fortNightStmtDto);
    }

    amountReceivedFromAgent(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/amountReceivedFromAgentApi`, fortNightStmtDto);
    }

    setDestAgentBalAmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/setDestAgentBalAmtApi`, fortNightStmtDto);
    }

    updateDestStmtBalAmt(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/updateDestStmtBalAmtApi`, fortNightStmtDto);
    }
    /* Amount Receivable From Agent end */

    /* Broker Report start */
    getBrokerReport(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getBrokerReportApi`, hireSlipDto);
    }

    getAvgBrokerReport(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getAvgBrokerReportApi`, hireSlipDto);
    }
    /* Broker Report end */

    /* Party Less Check Upload Type start */
    getDestStmtDetails(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/getDestStmtDetailsApi`, fortNightStmtDto);
    }

    insertIntoLRLessCheck(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/insertIntoLRLessCheckApi`, lrDto);
    }

    setIsLrLessChecked(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/setIsLrLessCheckedApi`, lrDto);
    }

    recalculateBkgMemoAfterAdminRateOnDestLessChkUpload(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/recalculateBkgMemoAfterAdminRateOnDestLessChkUploadApi`, lrDto);
    }

    readLessCheckFile(formData: Object): Observable<FormData> {
        return this.http.post<FormData>(`${this._baseUrl}/readLessCheckFileApi`, formData);
    }

    getDiscountAmtMemoLrEntryDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getDiscountAmtMemoLrEntryDetailsApi`, lrDto);
    }

    getLrLessCheckDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrLessCheckDetailsApi`, lrDto);
    }
    /* Party Less Check Upload Type end */

    /* Performance Report start*/
    getTruckPerformanceReport(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckPerformanceReportApi`, hireSlipDto);
    }
    /* Performance Report end*/

    /* Truck Owner Performance Report start*/
    getTruckOwnerDeclDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTruckOwnerDeclDetailsApi`, lrDto);
    }
    /* Truck Owner Performance Report end*/

    getInvoiceLoadingSheetPrint(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getInvoiceLoadingSheetPrintApi`, lrDto);
    }

    getBkgCashmemoService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBkgCashmemoApi`, lrDto);
    }

    deleteLRLessCheck(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteLRLessCheckApi`, lrDto);
    }

    getFortNightInvoice(fortNightStmtDto: Object): Observable<FortNightStmtDto[]> {
        return this.http.post<FortNightStmtDto[]>(`${this._baseUrl}/getFortNightInvoiceApi`, fortNightStmtDto);
    }

    getLRsShortExtraConsolidatedRpt(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRsShortExtraConsolidatedRptApi`, lrDto);
    }

    getLRsShortExtraConsolidatedRptHireslipNo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRsShortExtraConsolidatedRptHireslipNoApi`, lrDto);
    }

    getLRsShortExtraConsolidatedRptHireslipDate(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLRsShortExtraConsolidatedRptHireslipDateApi`, lrDto);
    }

    labourHamaliAmtPayable(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/labourHamaliAmtPayableApi`, fortNightStmtDto);
    }

    paymentCalculation(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/paymentCalculationApi`, hireSlipDto);
    }

    saveLRBarcode(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/saveLRBarcodeApi`, lrDto);
    }

    getSortingLrsForLoadingSheet(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSortingLrsForLoadingSheetApi`, lrDto);
    }

    insertIntoLRLessCheckBlock(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/insertIntoLRLessCheckBlockApi`, lrDto);
    }

    updateAmountReceivedFromAgent(fortNightStmtDto: Object): Observable<FortNightStmtDto> {
        return this.http.post<FortNightStmtDto>(`${this._baseUrl}/updateAmountReceivedFromAgentApi`, fortNightStmtDto);
    }

    getAOCLRList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getAOCLRListApi`, lrDto);
    }

    getCftRangeLRBkgDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getCftRangeLRBkgDetailsApi`, lrDto);
    }

    updateLRsShortageFromLoadingInvoice(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLRsShortageFromLoadingSheetApi`, lrDto);
    }

    updateLrLoadingSheetInvoiceDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLrLoadingSheetInvoiceApi`, lrDto);
    }

    addLRIntoInvoiceLoadingSheetFlow(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addLRIntoInvoiceLoadingSheetApi`, lrDto);
    }

    updateLrLoadingSheetInvoiceSingleLrDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLrLoadingSheetInvoiceSingleLrApi`, lrDto);
    }

    deleteLrLoadingSheetInvoiceDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/deleteLrLoadingSheetInvoiceApi`, lrDto);
    }
    updateHireSlipGSTDetained(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateHireSlipGSTDetainedApi`, lrDto);
    }

    getHireSlipDetailsForGSTInvDetained(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getHireSlipDetailsForGSTInvDetainedApi`, lrDto);
    }

    setLrLessStatementFinished(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/setLrLessStatementFinishedApi`, lrDto);
    }

    removeLrFromInvoiceDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/removeLrFromInvoiceDetailsApi`, lrDto);
    }

    getLrDetailsByFreight(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getLrDetailsByFreightApi`, lrDto);
    }

    updateLrFreightDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLrFreightDetailsApi`, lrDto);
    }

    updateLoadingInvRemarks(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLoadingInvRemarksApi`, lrDto);
    }
    getLrDetailsForLoadingInvEdit(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsForLoadingInvEditApi`, lrDto);
    }

    getPerfMonitorPartyDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPerfMonitorPartyDetailsApi`, lrDto);
    }
    enableLrTrakingDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/enableLrTrakingDetailsApi`, lrDto);
    }
    getLrWiseTrackingDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrWiseTrackingDetailsApi`, lrDto);
    }

    disableLrTrakingDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/disableLrTrakingDetailsApi`, lrDto);
    }

    addBarcodeDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addBarcodeDetailsApi`, lrDto);
    }
    updateLrLoadingSheetLocalTripSingleLrDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLrLoadingSheetLocalTripSingleLrDetailsApi`, lrDto);
    }

    updateBarcodeForLoadingExtraArticles(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateBarcodeForLoadingExtraArticlesApi`, lrDto);
    }

    checkAndRemoveLoadedBarcodeDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/checkAndRemoveLoadedBarcodeDetailsApi`, lrDto);
    }

    generateManualLRBarcode(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/generateManualLRBarcodeApi`, lrDto);
    }

    generateManualMasterLRBarcode(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/generateManualMasterLRBarcodeApi`, lrDto);
    }

    getLRsShortExtraConsolidatedRptV2(lrDto: Object): Observable<{ data: any[]; headers: any[] }> {
        return this.http.post<{ data: any[]; headers: any[] }>(`${this._baseUrl}/getLRsShortExtraConsolidatedRptV2Api`, lrDto);
    }


}



