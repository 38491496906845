//Chg_1 : Hide and show when isGstDetained is true (Asrar Jr / 19/06/2024)
//Chg_2 : Get neftrefno column data to show For Lr Enquiry (Asrar Jr / 15/07/2024)
//Chg_3 : Show multiple eway details on popup (Asrar Jr / 19/07/2024)
//Chg_4 : Check condition for Booking Local Trip (Asrar Jr / 12/11/2024)
//Chg_5 : Show uncleared Lr reason if have (Panga box)(Asrar Jr / 04/12/2024)
//Chg_6 : Show LR Rate Status (Asrar Jr / 07/01/2025)

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { Subscription, Subject } from 'rxjs';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ShortExtraLrEnquiryPopupComponent } from "src/app/lr/enquiry/short-extra-lr-enquiry-popup/short-extra-lr-enquiry-popup.component";
import { MultipleArticleSizePopupComponent } from "src/app/lr/enquiry/multiple-article-size-popup/multiple-article-size-popup.component";
import { BayInfoPopupComponent } from "src/app/lr/enquiry/bay-details-popup/bay-info-popup.component";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { PodInfoPopupComponent } from '../pod-details-popup/pod-info-popup.component';
import { TripDeliveryConfirmationDetailsComponent } from 'src/app/dashboards/dashboards-popup/trip-delivery-confirmation-details/trip-delivery-confirmation-details.component';
import { RequestDto } from 'src/app/dto/Request-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { GstInvoiceDetainedComponent } from '../../edit/gst-Invoice-Detained/gst-Invoice-Detained.component';
import { MultipleArticleRangePopupComponent } from "src/app/lr/enquiry/multiple-article-range-popup/multiple-article-range-popup.component";
import { MultiEwayDetailsPopupComponent } from '../multi-eway-details-popup/multi-eway-details-popup.component';
import *  as moment from 'moment';
@Component({
    selector: 'app-lr-enquiry-form',
    templateUrl: './lr-enquiry-form.component.html',
    styleUrls: ['./lr-enquiry-form.component.css']
})
export class LrEnquiryFormComponent implements OnInit {
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    closeResult: string;
    listOfDataToPass: string[];
    routeQueryParams$: Subscription;
    //for modal ends
    enteredLrNumber: any;
    listOfMultipleArticles: any;
    lrDtoForMemo: LRDto = new LRDto();
    lrDtoForDummyInvoice: LRDto = new LRDto();
    lrDtoForDummyInvoice2: LRDto = new LRDto();
    lrDtoLocalTrips: LRDto = new LRDto();
    lrDtoLrEnquiry: LRDto = new LRDto();
    selectedSearchWise: any;
    msg: any;
    selectedSearchNumber: any;
    lrEnquiryRetAny: any;
    lrEnquiryRet: LRDto = new LRDto();
    lrDtoLocalTripsRet: any;
    lrDtoForDummyInvoiceRet: any;
    lrDtoForDummyInvoice2Ret: any;
    showBankLr = false;
    showReasonLbl: any;
    viewReasonLbl = false;
    showRemark = false;
    showMemoBlocklabel = false;
    showMemoBlock = "Cash Memo Generation is Blocked";
    viewPodLbl = false;
    viewLrHoldReasonLbl = false;
    showLrHoldLbl: any;
    consigneeCopyLbl: any;
    viewCCCopyLbl = false;
    viewGetPodOtpLbl = false;
    viewLRDownloadEwaybillLbl = false;
    viewEwaybillDCLbl = false;

    showConsignee: any;
    isReadOnly = false;

    lrDtoSetLocal: LRDto = new LRDto();
    lrDtoFreight: LRDto = new LRDto();

    showAdvanceSearchLbl = true;
    lblUpdateCnge = false;
    lblPrint = false;
    lblNewConsignee = false;
    lblConsigneeBusinessDetails = false;
    lblCashmemoBlock = false;
    lblConsigneeCashmemoBlock = false;

    showShortExtra = false;
    showFreight = false;
    showArticleSize = false;

    txtLTVehicleNumber: any;
    txtLTArea: any;
    txtLTDriver: any;
    dfLTTripDate: any;
    localTripId = 0;
    localTripArea: any;
    lblLocalTripDetails = false;
    getFrtAmtOthers: any;
    txtLTVehicleNumbers: any;
    billDateRet: any;
    cashMemoBlockDate; any;
    showCODLbl = false;
    //    For cashmemo
    lrDtoForMemoRet: any;
    lrDtoForMemoRetDto: LRDto = new LRDto();
    showDestCashMemo: any;
    lblDestCashMemo = false;
    lrDtoDestMemo: LRDto = new LRDto();
    showUnloadingChg = false;
    viewUnloadingChg = "Show Unloading Charge for Memo In Lr Enquiry";
    lblSrcMemo = false;
    showSrcCashMemo: any;
    lblSrcMemoNo = false;
    lblDestCashMemoNo = false;
    txMemoDate: any;
    txRecDate: any;
    showRecDate = false;

    showInvoiceNumber: any;
    selectedEnteredDate: any;
    selectedBookingDate: any;
    selectedRecAtBkgDate: any;
    showTripStatus: any;
    localTripAction = false;
    selectedCollectionDate: any;
    //For Dummy invoice 
    showDummyInvoice = false;
    showDummyInvoice2 = false;
    viewDummyInvoice: any;
    viewDummyInvoice2: any;

    showLrPrintLbl = "";
    showLrPrint = false;
    showConsigneeBusLbl = "";
    showConsigneeBus = false;


    lrDtoForDummyInvoiceRetDto: LRDto = new LRDto();
    lrDtoForDummyInvoice2RetDto: LRDto = new LRDto();
    //    dummyInvoiceDepDate:any;
    //    dummyInvoiceSchDate:any;
    //    dummyInvoiceArrDate:any;
    //    
    //    dummyInvoice2DepDate:any;
    //    dummyInvoice2SchDate:any;
    //    dummyInvoice2ArrDate:any;
    showDestMemoStatus: any;
    showDestMemoStatusLbl = false;

    lrDtoGetStatus: LRDto = new LRDto();
    lrDtoGetStatusRet: any;
    showStatus: any;

    ///for Cod Payment
    txtPaymentModeCodLrPayment: any;
    txtBankNameCodLrPayment: any;
    txtCheqNumberCodLrPayment: any;
    dfCheqDateCodLrPayment: any;
    txtRefNoCodLrPayment: any;

    availabel = false;
    showForEdeclaration = false;
    //    showForESugam = true;
    getSrcMemoDate: any;
    listLrDtosBay: any;
    //Basha
    lrDtoAutoLrPrint: LRDto = new LRDto;
    viewLrEntryPrint = false;
    lrEnquiryForm = true;
    getvalueForLrPrint: any;
    setValueForLrPrint: LRDto = new LRDto;
    modalRefferenceLrPrintCopyPopUp: NgbModalRef;
    modalRefferenceLrPrintCopyClosePopUp: string;
    @ViewChild('lrPrintCopyPopUpTemplate') private lrPrintCopyPopUpTemplate;
    viewLrPrintCopyPopUp = false;
    selectedLRNo: any;
    selectedActionType: any;
    searchModeRedirect: any;
    lrEnquiryLrNo: any;
    lrDtoUserValue: LRDto = new LRDto();
    memoBlockDate: any;

    lrDtoForLocalTripSelectedRow: LRDto = new LRDto();
    lrDtoForLocalTripSelectedRowReturn: any;
    lrDtoForLocalTripListForLocalStorage: LRDto = new LRDto();
    modalRefferenceLocalTripSheetPopUp: NgbModalRef;
    requestDto: RequestDto = new RequestDto();
    pageId = "lefc";
    showSpinnerForAction = false;
    vehicleNumberForDC = null;
    showGstDetainedFields = false;
    viewDetainedByGst = false;
    listOfMultipleArticlesRange: any;
    showMultipleArticlesRange: boolean = false;
    viewEwaybill = 'View E-Way Bill';
    lrDtoForEway: LRDto = new LRDto();
    dtTriggerMultEway: Subject<any> = new Subject();
    multiEwayTableDataList: any;
    dtOptionsMultiEway: any;
    checkMultiEway: any;
    lblBookingLocalTripDetails: boolean = false;
    localBkgTripNo: any;
    showUncleredLrReason: boolean = false;
    unclearedLrReason: any;
    lrRateStatus: any;
    // showSingleEwayBill = true;
    // showMultiEwayBill = false;

    constructor(private router: Router, private reportService: ReportService, private hireslipService: HireslipService,
        private modalService: NgbModal, public dialog: MatDialog, private route: ActivatedRoute,
        public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService,
        private ewaybillService: EwaybillService, private datePipe: DatePipe) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //for modal starts
            this.routeQueryParams$ = route.queryParams.subscribe(params => {
                if (params['dialog']) {
                    this.shortExtraDialog();
                    this.bayInfoDialog();
                }
            });
            this.routeQueryParams$ = route.queryParams.subscribe(params => {
                if (params['dialog']) {
                    this.multipelArticleSizeDialog();
                }
            });

            //for modal ends

            ///Unloading Charge for chennai
            if (this.viewUnloadingChg == "Show Unloading Charge for Memo In Lr Enquiry") {
                this.showUnloadingChg = true;
            } else {
                this.showUnloadingChg = false;
            }
            // if ( this.showLrPrintLbl = "Show LR Copy In LR Enquiry" ) {
            //     this.lblPrint = true;
            // }
            // if ( this.showConsigneeBusLbl = "Show Consignee Business view In Lr Enquiry" ) {
            //     this.lblConsigneeBusinessDetails = true;
            // }

            ///Rights
            //basha

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i])
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRCopy InLREnquiry") {
                        this.lblPrint = true;
                        console.log('true');
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "cgneeBusinessViewLREnq") {
                        this.lblConsigneeBusinessDetails = true;

                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "podViewLREnq") {
                        this.viewPodLbl = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Get Pod Otp LREnq") {
                        this.viewGetPodOtpLbl = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Gst Detained") {
                        this.showGstDetainedFields = true;
                    }
                }
            }

            //by default view to all as per Musaddik bh @11/08/2022
            this.viewPodLbl = true;
            //temp view to all, need to chk with Musaddik bh for rights
            this.viewLRDownloadEwaybillLbl = true;
            this.viewEwaybillDCLbl = true;

            this.searchModeRedirect = localStorage.getItem('searchBy');
            this.lrEnquiryLrNo = localStorage.getItem('enteredLrnumber');
            console.log("Inside of LrEnquiry page");
            console.log(this.lrEnquiryLrNo);
            if (this.searchModeRedirect != null && this.searchModeRedirect == "lrnumber") {
                setTimeout(() => {
                    $("#" + this.pageId + "lrAndEwayNumber").val(this.lrEnquiryLrNo);
                    // $("#"+this.pageId+"lrAndEwayNumber" ).add(this.lrEnquiryLrNo);
                    // this.selectedSearchNumber = $("#"+this.pageId+"lrAndEwayNumber" ).val();
                    //console.log("Oh yeah !!!");
                    // console.log(this.selectedSearchNumber);
                    this.getLrEnquiryDetails();
                }, 2000);
            }
        }

    }

    callFromOtherPage(selectedLrNo, pageFrom) {
        console.log("Page From : " + pageFrom);
        console.log("Page From LR : " + selectedLrNo);
        setTimeout(() => {
            $("#" + this.pageId + "lrAndEwayNumber").val(selectedLrNo);
            this.getLrEnquiryDetails();
        }, 2000);
    }
    ngOnInit() {
        console.log("ngOnInit....");
        this.dtOptionsMultiEway = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
            }
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    open2(content2) {
        this.modalService.open(content2, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    open3(content3) {
        this.modalService.open(content3, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    open4(content4) {
        this.getDummyInvoiceDetailsInfo();
        this.modalService.open(content4, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    open5(content5) {
        this.localTripFields();
        this.modalRefferenceLocalTripSheetPopUp = this.modalService.open(content5, { centered: true, size: 'lg' });

        this.modalRefferenceLocalTripSheetPopUp.result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, reason => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });

        // this.modalService.open(content5, { centered: true, size: 'lg' }).result.then(
        //     result => {
        //         this.closeResult = `Closed with: ${result}`;
        //     },
        //     reason => {
        //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        //     }
        // );
    }
    openSrcLocalTrp(contentSrcLocalTrp) {
        this.localTripSrcFields();
        this.modalService.open(contentSrcLocalTrp, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    open7(content7) {
        this.getDummyInvoice2DetailsInfo();
        this.modalService.open(content7, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }


    open8(content8) {
        this.modalService.open(content8, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    open9(content9) {
        this.modalService.open(content9, { centered: true, size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    open10(content10) {
        this.modalService.open(content10, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    open11(content11) {
        this.modalService.open(content11, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    openConsigneeMemoBlockedPopup(popup) {
        this.modalService.open(popup, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    getShortExtraTable() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.shortExtraDialog();
    }
    //for modal starts
    shortExtraDialog(): void {

        const dialogRef = this.dialog.open(ShortExtraLrEnquiryPopupComponent, {

            data: { listOfDataToPass: this.enteredLrNumber }
        });

        // dialogRef.afterClosed().subscribe( result => {

        //     this.router.navigate( ['.'], { relativeTo: this.route } );
        // } );
    }

    getArticleSizeTable() {
        this.listOfMultipleArticles = this.lrEnquiryRet.listOfmultipleArt;
        if (this.lrEnquiryRet.listOfmultipleArt == null) {
            swal({
                title: "Warning",
                text: "No Multiple Article Size for this Lr",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleSizeDialog();
        }

    }
    //for modal starts
    multipelArticleSizeDialog(): void {

        const dialogRef = this.dialog.open(MultipleArticleSizePopupComponent, {

            data: { listOfDataToPass: this.listOfMultipleArticles }
        });

        // dialogRef.afterClosed().subscribe( result => {

        //     this.router.navigate( ['.'], { relativeTo: this.route } );
        // } );
    }
    //for modal ends

    //For Dummy invoice Details 

    getDummyInvoiceDetailsInfo() {
        this.lrDtoForDummyInvoice.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForDummyInvoice.invoiceNumber = this.viewDummyInvoice;
        if (this.viewDummyInvoice != null) {
            this.getDummyInvoiceDetailsForLrEnquiry();
        }
    }
    getDummyInvoiceDetailsForLrEnquiry = () => {
        this.reportService.getDummyInvoiceDetails(this.lrDtoForDummyInvoice).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoForDummyInvoiceRet = response;
                    this.lrDtoForDummyInvoiceRetDto = this.lrDtoForDummyInvoiceRet;
                    this.setDummyInvoiceFields();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };
    // For Dummy invoice 2 details
    getDummyInvoice2DetailsInfo() {
        this.lrDtoForDummyInvoice2.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForDummyInvoice2.invoiceNumber = this.viewDummyInvoice2;
        if (this.viewDummyInvoice2 != null) {
            this.getDummyInvoice2DetailsForLrEnquiry();
        }
    }
    getDummyInvoice2DetailsForLrEnquiry = () => {
        this.reportService.getDummyInvoiceDetails(this.lrDtoForDummyInvoice2).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoForDummyInvoice2Ret = response;
                    this.lrDtoForDummyInvoice2RetDto = this.lrDtoForDummyInvoice2Ret;
                    this.setDummyInvoice2Fields();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };

    validateBtnSearch() {
        this.clearInputFields();
        this.getLrEnquiryDetails();
    }

    getLrEnquiryDetailsInfo() {
        this.lrDtoLrEnquiry.companyId = this.userDataDtoReturnSession.companyId;
        this.selectedSearchWise = $("#" + this.pageId + "searchBy").val();
        this.selectedSearchNumber = $("#" + this.pageId + "lrAndEwayNumber").val();
        //alert(this.selectedSearchWise+"-"+this.selectedSearchNumber);
        if (this.searchModeRedirect != null && this.searchModeRedirect == "lrnumber") {
            console.log("Inside of LrEnquiry page if condition");
            this.lrDtoLrEnquiry.lrNumber = this.lrEnquiryLrNo;
            this.lrDtoLrEnquiry.mode = "LR";
            this.selectedSearchWise = 'lrNumberWise';
        } else {
            console.log("Inside of LrEnquiry page else condition");
            if (this.selectedSearchWise == 'lrNumberWise') {
                console.log("Inside of LrEnquiry page else inside if condition");
                this.lrDtoLrEnquiry.lrNumber = this.selectedSearchNumber;
                this.lrDtoLrEnquiry.mode = "LR";
            } else if (this.selectedSearchWise == 'eWayBillWise') {
                console.log("Inside of LrEnquiry page else inside else if condition");
                this.lrDtoLrEnquiry.eWayBillNo = this.selectedSearchNumber;
                this.lrDtoLrEnquiry.mode = "EWay";
            }
        }


    }
    getLrEnquiryDetails = () => {
        this.getLrEnquiryDetailsInfo();
        console.log(this.lrDtoLrEnquiry);
        this.reportService.getLrEnquiryDetails(this.lrDtoLrEnquiry).subscribe(
            (response) => {
                this.lrEnquiryRetAny = response;

                this.lrEnquiryRet = this.lrEnquiryRetAny;

                this.listLrDtosBay = this.lrEnquiryRet.lrDtos;

                console.log(this.lrEnquiryRet);

                if (this.selectedSearchWise == "lrNumberWise") {
                    if (this.lrEnquiryRet.lrNumber == null) {
                        swal({
                            title: "Warning",
                            text: "No records found for this LR Number :" + this.selectedSearchNumber,
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                        this.lrEnquiryRet = null;
                    } else {
                        this.checkMemoIsBlocked();
                    }

                } else if (this.selectedSearchWise == "eWayBillWise") {
                    if (this.lrEnquiryRet.eWayBillNo == null) {
                        swal({
                            title: "Warning",
                            text: "No records found for this E-Way Bill Number :" + this.selectedSearchNumber,
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                    } else {
                        this.checkMemoIsBlocked();
                    }
                }
                if (this.lrEnquiryRet.multiArtSizeCft != null) {
                    this.showMultipleArticlesRange = true;
                } else {
                    this.showMultipleArticlesRange = false;
                }

                this.changeDetectorRef.detectChanges();

            }), (error) => console.log(error.json()),
            () => console.log('done');
    };


    //    check the cashmemo is Blocked
    ///the Below pop up part is Pending
    checkMemoIsBlocked() {
        if (this.lrEnquiryRet.cashMemoBlocked == true) {
            this.getLrEnquiryStatusDetails();
            this.setLRValues();
            this.lblUpdateCnge = true;
        } else {
            this.getLrEnquiryStatusDetails();
            this.setLRValues();
        }
    }

    setLRValues() {
        this.isReadOnly = true;
        // in invoicedetails link arrival date is not showing
        this.setStatusOfLr();

        ///For Bkg memo
        if (this.lrEnquiryRet.srcMemo != null && this.lrEnquiryRet.paid != 0) {
            this.lblSrcMemo = true;
            this.lblSrcMemoNo = false;
        } else {
            this.lblSrcMemo = false;
            this.lblSrcMemoNo = true;
        }

        this.showSrcCashMemo = this.lrEnquiryRet.srcMemo;
        //        console.log( 'this Bkg memo :' + this.showSrcCashMemo );

        //For Dest Memo
        if (this.lrEnquiryRet.desMemo != null) {
            this.lblDestCashMemo = true;
            this.lblDestCashMemoNo = false;
        } else {
            this.lblDestCashMemo = false;
            this.lblDestCashMemoNo = true;
        }
        this.showDestCashMemo = this.lrEnquiryRet.desMemo;
        //        console.log( this.showDestCashMemo );

        if (this.showDestCashMemo != null) {
            this.getCashmemoDetailsForLrEnquiry();
        }

        ///For Cashmemo block
        if (this.lrEnquiryRet.blocked == true) {
            this.showMemoBlocklabel = true;

            console.log('a' + this.showMemoBlock);

        } else {
            this.showMemoBlocklabel = false;
        }
        console.log();
        /// For new Consignee 
        if (this.lrEnquiryRet.isNewConsignee != null && this.lrEnquiryRet.isNewConsignee == true) {

            this.lblNewConsignee = true;
        } else {
            this.lblNewConsignee = false;
        }

        ///for Dummy Invoice 1 and 2
        if (this.lrEnquiryRet.dummyInvoice != null && this.lrEnquiryRet.dummyinvoice2 != null) {
            this.showDummyInvoice = true;
            this.showDummyInvoice2 = true;
            this.viewDummyInvoice = this.lrEnquiryRet.dummyInvoice;
            this.viewDummyInvoice2 = this.lrEnquiryRet.dummyinvoice2;

        } else if (this.lrEnquiryRet.dummyInvoice != null
            && this.lrEnquiryRet.dummyinvoice2 == null) {
            this.showDummyInvoice = true;
            this.showDummyInvoice2 = false;
            this.viewDummyInvoice = this.lrEnquiryRet.dummyInvoice;
        }
        $("#" + this.pageId + "challanNo").val(this.lrEnquiryRet.challanNo);
        $("#" + this.pageId + "agentName").val(this.lrEnquiryRet.agentId);
        $("#" + this.pageId + "invoiceNo").val(this.lrEnquiryRet.invoiceNumber);
        this.showInvoiceNumber = this.lrEnquiryRet.invoiceNumber;

        // Lr generated By value was not getting from generatedby dto so i used lrGeneratedBy dto
        $("#" + this.pageId + "generatedBy").val(this.lrEnquiryRet.lrGeneratedBy);
        //        $("#"+this.pageId+"loaderName" ).val( this.lrEnquiryRet.loaderName );
        $("#" + this.pageId + "labourContractor").val(this.lrEnquiryRet.unloaderHead);
        $("#" + this.pageId + "lrNumber").val(this.lrEnquiryRet.lrNumber);
        $("#" + this.pageId + "noOfArticles").val(this.lrEnquiryRet.totalArticles);
        $("#" + this.pageId + "source").val(this.lrEnquiryRet.source);
        $("#" + this.pageId + "consignorGstNumber").val(this.lrEnquiryRet.gstNoConsignor);
        $("#" + this.pageId + "consignorName").val(this.lrEnquiryRet.consignorName);
        $("#" + this.pageId + "destination").val(this.lrEnquiryRet.destination);
        $("#" + this.pageId + "subStation").val(this.lrEnquiryRet.subStations);
        $("#" + this.pageId + "consigneeGstNumber").val(this.lrEnquiryRet.gstNoConsignee);
        //        this.showConsignee= this.lrEnquiryRet.consigneeName+'</u>';

        $("#" + this.pageId + "consigneeName").val(this.lrEnquiryRet.consigneeName);
        $("#" + this.pageId + "consigneeArea").val(this.lrEnquiryRet.deliveryarea);
        $("#" + this.pageId + "privateMarker").val(this.lrEnquiryRet.privateMarker);
        $("#" + this.pageId + "packNature").val(this.lrEnquiryRet.packNature);

        this.selectedEnteredDate = this.lrEnquiryRet.enteredDateStr;
        $("#" + this.pageId + "enteredDate").val(this.selectedEnteredDate);

        this.selectedRecAtBkgDate = this.lrEnquiryRet.actLrEntryDateStr;
        $("#" + this.pageId + "bkgRecDate").val(this.selectedRecAtBkgDate);

        this.selectedBookingDate = this.lrEnquiryRet.bookingDateStr;
        $("#" + this.pageId + "bookingDate").val(this.selectedBookingDate);

        $("#" + this.pageId + "godownName").val(this.lrEnquiryRet.godownName);
        $("#" + this.pageId + "remark").val(this.lrEnquiryRet.remarks);
        // console.log('memoblock reason');
        // console.log(this.lrEnquiryRet.memoBlockReason);
        // console.log(this.lrEnquiryRet.memoBlockDate);
        // $("#" + this.pageId + "memoBlockedReason").val(this.lrEnquiryRet.memoBlockReason);
        // this.memoBlockDate = this.lrEnquiryRet.memoBlockDate;
        // $("#" + this.pageId + "memoBlockedDate").val(this.memoBlockDate);

        if (this.lrEnquiryRet.selfLr == true) {
            $("#" + this.pageId + "selfLr").val('Yes');
        } else {
            $("#" + this.pageId + "selfLr").val('No');
        }

        ////for Cod Payment 

        this.txtPaymentModeCodLrPayment = (this.lrEnquiryRet.codPaymentMode == null ? "NA" : this.lrEnquiryRet.codPaymentMode);
        this.lrEnquiryRet.codPaymentMode = this.txtPaymentModeCodLrPayment;
        this.txtBankNameCodLrPayment = (this.lrEnquiryRet.codPaymentBankName == null ? "NA" : this.lrEnquiryRet.codPaymentBankName);
        this.lrEnquiryRet.codPaymentBankName = this.txtBankNameCodLrPayment;

        this.txtCheqNumberCodLrPayment = (this.lrEnquiryRet.codPaymentChqNo == null ? "NA" : this.lrEnquiryRet.codPaymentChqNo);
        this.lrEnquiryRet.codPaymentChqNo = this.txtCheqNumberCodLrPayment;
        this.dfCheqDateCodLrPayment = this.lrEnquiryRet.codPaymentChqDate;
        this.lrEnquiryRet.codPaymentChqDate = this.dfCheqDateCodLrPayment;
        this.txtRefNoCodLrPayment = (this.lrEnquiryRet.codPaymentRefNo == null ? "NA"
            : this.lrEnquiryRet.codPaymentRefNo)
        this.lrEnquiryRet.codPaymentRefNo = this.txtRefNoCodLrPayment;

        if (this.lrEnquiryRet.codPaymentMode == null
            || this.lrEnquiryRet.codPaymentMode == "NA") {
            this.showCODLbl = false;
        } else {
            this.showCODLbl = true;
        }
        //        Pending 
        //        TripDeliveryConfirmation is pending ,AOc,UnloadingChrg for chennai,// cod lr payment details is pending 
        //        $("#"+this.pageId+"dumInvInvoiceNumber" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvVehicleNumber" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvCompanyName" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvAddress" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvCity" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvPincode" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvContactNumber" ).val( this.lrEnquiryRet.source );
        //        $("#"+this.pageId+"dumInvDriverName" ).val( this.lrEnquiryRet.source );

        //        if (lrDto.getCodPaymentMode() == null
        //                || lrDto.getCodPaymentMode().equalsIgnoreCase("NA")) {
        //            lblCodLrPaymentDetail.setVisible(false);
        //        } else {
        //            lblCodLrPaymentDetail.setVisible(true);
        //        }
        ////////////////////////////
        $("#" + this.pageId + "goodsValue").val(this.lrEnquiryRet.goodsValue);
        $("#" + this.pageId + "mainCommodity").val(this.lrEnquiryRet.column2);
        $("#" + this.pageId + "commonCommodity").val(this.lrEnquiryRet.commonCommodity);
        $("#" + this.pageId + "goodsType").val(this.lrEnquiryRet.column1);

        // if (this.lrEnquiryRet.column1 == null) {
        //     $("#" + this.pageId + "mainCommodity").val(this.lrEnquiryRet.column2);
        // } else {
        //     $("#" + this.pageId + "goodsType").val(this.lrEnquiryRet.column1);
        // }
        //Handle With Care
        if (this.lrEnquiryRet.isHandWithCare != null && (this.lrEnquiryRet.isHandWithCare == true)) {
            $("#" + this.pageId + "handleWithCare").val('Yes');
        } else if (this.lrEnquiryRet.isHandWithCare != null && (this.lrEnquiryRet.isHandWithCare == false)) {
            $("#" + this.pageId + "handleWithCare").val('No');
        }
        //Cod
        if (this.lrEnquiryRet.isCod != null && (this.lrEnquiryRet.isCod == true)) {
            $("#" + this.pageId + "cod").val('Yes');
        } else if (this.lrEnquiryRet.isCod != null && (this.lrEnquiryRet.isCod == false)) {
            $("#" + this.pageId + "cod").val('No');
        }
        ///Order goods
        if (this.lrEnquiryRet.isOrderGoods != null && (this.lrEnquiryRet.isOrderGoods == true)) {
            $("#" + this.pageId + "orderGoods").val('Yes');
        } else if (this.lrEnquiryRet.isOrderGoods != null && (this.lrEnquiryRet.isOrderGoods == false)) {
            $("#" + this.pageId + "orderGoods").val('No');
        }

        ///cCAttach
        if (this.lrEnquiryRet.ccAttach != null && (this.lrEnquiryRet.ccAttach == true)) {
            $("#" + this.pageId + "cCAttach").val('Yes');
        } else if (this.lrEnquiryRet.ccAttach != null && (this.lrEnquiryRet.ccAttach == false)) {
            $("#" + this.pageId + "cCAttach").val('No');
        }

        if (this.lrEnquiryRet.actualWeight != null) {
            $("#" + this.pageId + "actualWeight").val(this.lrEnquiryRet.actualWeight);
        }
        if (this.lrEnquiryRet.chargedWeight != null) {
            $("#" + this.pageId + "chargedWeight").val(this.lrEnquiryRet.chargedWeight);
        }

        $("#" + this.pageId + "toPay").val(this.lrEnquiryRet.toPay);
        $("#" + this.pageId + "paid").val(this.lrEnquiryRet.paid);
        $("#" + this.pageId + "billNumber").val(this.lrEnquiryRet.billNumber);
        this.billDateRet = this.lrEnquiryRet.billDateStr;
        $("#" + this.pageId + "billDate").val(this.billDateRet);

        ///"E-Declaration Transaction ID,txteDeclarationBy, input field is pending 
        $("#" + this.pageId + "eSugamNo").val(this.lrEnquiryRet.eSugamNo);
        $("#" + this.pageId + "eWayBillNo").val(this.lrEnquiryRet.eWayBillNo);
        $("#" + this.pageId + "eInvNoConsignor").val(this.lrEnquiryRet.consignorEInvoice);

        if (this.lrEnquiryRet.ewayBillValidUpto != null && this.lrEnquiryRet.ewayBillValidUpto != undefined) {
            $("#" + this.pageId + "ewayBillValidUpto").val(this.datePipe.transform(this.lrEnquiryRet.ewayBillValidUpto, "dd-MM-yyyy"));

        } else {

            $("#" + this.pageId + "ewayBillValidUpto").val('NA');
        }
        ////For kerela is pending 
        if (this.availabel) {
            this.showForEdeclaration = true
            //            this.showForESugam = false;
        } else {

            this.showForEdeclaration = false;
            //            this.showForESugam = true;
        }

        $("#" + this.pageId + "eDeclarationTransID").val(this.lrEnquiryRet.eSugamNo);
        $("#" + this.pageId + "eDeclarationCommodity").val(this.lrEnquiryRet.eDeclarationCommodity);
        $("#" + this.pageId + "eDeclarationBy").val(this.lrEnquiryRet.eDeclarationBy);


        if (this.lrEnquiryRet.srcMemoDate != null) {
            this.getSrcMemoDate = this.lrEnquiryRet.srcMemoDate;
            this.lrEnquiryRet.srcMemoDate = this.getSrcMemoDate;
        } else {
            this.getSrcMemoDate = "";
            this.lrEnquiryRet.srcMemoDate = this.getSrcMemoDate;
        }



        if (this.lrEnquiryRet.godownEntry == null) {
            $("#" + this.pageId + "enteredByGodown").val('--------');
        } else {
            $("#" + this.pageId + "enteredByGodown").val(this.lrEnquiryRet.godownEntry);
        }

        if (this.lrEnquiryRet.isBankLr == "1") {
            this.showBankLr = true;
            $("#" + this.pageId + "bankLr").val(this.lrEnquiryRet.bankName);
        } else if (this.lrEnquiryRet.isBankLr == "0") {
            this.showBankLr = false;
        }

        if (this.lrEnquiryRet.ccCopyEnabled == true) {
            this.viewCCCopyLbl = true;
            this.consigneeCopyLbl = "Do not deliver without collecting the Consignee Copy";
        } else {
            this.viewCCCopyLbl = false;
            this.consigneeCopyLbl = null;
        }

        if (this.lrEnquiryRet.tripId > 1) {
            this.lblLocalTripDetails = true;
        } else {
            this.lblLocalTripDetails = false;
        }

        if (this.lrEnquiryRet.cashStxAmount != null) {
            this.lrDtoDestMemo.cashStxAmount = this.lrEnquiryRet.cashStxAmount;
        }
        //For Local Trip
        this.txtLTVehicleNumber = (this.lrEnquiryRet.vehicleNofromTripSheet == null ? "NA"
            : this.lrEnquiryRet.vehicleNofromTripSheet);
        this.txtLTArea = (this.lrEnquiryRet.localBkgDeliveryArea == null ? "NA"
            : this.lrEnquiryRet.localBkgDeliveryArea);
        this.txtLTDriver = (this.lrEnquiryRet.dummyDriverName == null ? "NA" : this.lrEnquiryRet.dummyDriverName);
        this.dfLTTripDate = (this.lrEnquiryRet.tripDateStr);
        this.localTripId = this.lrEnquiryRet.tripId;
        this.localTripArea = (this.lrEnquiryRet.localBkgDeliveryArea == null ? "NA" : this.lrEnquiryRet.localBkgDeliveryArea);
        this.localTripArea = (this.lrEnquiryRet.ewayBillValidUpto == null ? "NA" : this.lrEnquiryRet.ewayBillValidUpto);

        ///AOC
        if (this.lrEnquiryRet.withOut == true) {
            $("#" + this.pageId + "aoc").val('Yes');
        } else {
            $("#" + this.pageId + "aoc").val('No');
        }
        //// Freight Details 
        this.lrDtoFreight.baseFreight = this.lrEnquiryRet.baseFreight;
        this.lrDtoFreight.hamaliLR = this.lrEnquiryRet.hamaliLR;
        this.lrDtoFreight.gcCharge = this.lrEnquiryRet.gcCharge;
        this.lrDtoFreight.lcChg = this.lrEnquiryRet.lcChg;
        this.lrDtoFreight.bcChg = this.lrEnquiryRet.bcChg;
        this.lrDtoFreight.riskCharge = this.lrEnquiryRet.riskCharge;
        this.lrDtoFreight.aoc = this.lrEnquiryRet.aoc;
        this.lrDtoFreight.insurance = this.lrEnquiryRet.insurance;
        this.lrDtoFreight.others = this.lrEnquiryRet.others;
        this.getFrtAmtOthers = (this.lrEnquiryRet.doorDelivAmtChg == null ? 0.0
            : this.lrEnquiryRet.doorDelivAmtChg);
        this.lrDtoFreight.DoorDelivAmt = this.getFrtAmtOthers;
        this.lrDtoFreight.totalAmount = this.lrEnquiryRet.totalAmount;
        this.lrDtoFreight.codChg = this.lrEnquiryRet.codChg;

        //        for Blocked Cashemo 
        if (this.lrEnquiryRet.memoBlocked == true) {
            this.lblCashmemoBlock = true
            this.lrEnquiryRet.memoBlockReason = this.lrEnquiryRet.memoBlockReason;
            this.cashMemoBlockDate = (this.lrEnquiryRet.memoBlockDate == null ? "NA" : this.lrEnquiryRet.memoBlockDate);
            this.lrEnquiryRet.memoBlockDate = this.cashMemoBlockDate

        } else {
            this.lblCashmemoBlock = false;
            this.lrEnquiryRet.memoBlockReason = "";
            this.cashMemoBlockDate = "";
            this.lrEnquiryRet.memoBlockDate = this.cashMemoBlockDate;
        }

        // for Blocked Consignee Cashemo from Cosignee master
        if (this.lblDestCashMemo == false) {
            if (this.lrEnquiryRet.consigneeMemoBlocked == true) {
                this.lblConsigneeCashmemoBlock = true
                this.lrEnquiryRet.consigneeMemoBlockReason = this.lrEnquiryRet.consigneeMemoBlockReason;
            } else {
                this.lblConsigneeCashmemoBlock = false;
                this.lrEnquiryRet.consigneeMemoBlockReason = "";
            }
        } else {
            this.lblConsigneeCashmemoBlock = false;
            this.lrEnquiryRet.consigneeMemoBlockReason = "";
        }

        //Chg_1
        if (this.lrEnquiryRet.isGstDetained == true) {
            this.viewDetainedByGst = true;
        } else {
            this.viewDetainedByGst = false;
        }

        //Chg_4
        if (this.lrEnquiryRet.bookingLocalTripId > 1) {
            this.lblBookingLocalTripDetails = true;
            this.localBkgTripNo = this.lrEnquiryRet.localTripNumber;
            console.log(this.localBkgTripNo);
        } else {
            this.lblBookingLocalTripDetails = false;
        }

        //Chg_5
        if (this.lrEnquiryRet.isUnclearedLr) {
            this.showUncleredLrReason = true;
            this.unclearedLrReason = this.lrEnquiryRet.unclearedLrReason;
            console.log(this.unclearedLrReason);
        } else {
            this.showUncleredLrReason = false;
            this.unclearedLrReason = "";
        }

        //Chg_6
        if (this.lrEnquiryRet.lrStatus != null) {
            this.lrRateStatus = this.lrEnquiryRet.lrStatus;
        } else {
            this.lrRateStatus = '';
        }
    }

    localTripFields() {
        this.lrDtoSetLocal.localTripNumber = this.txtLTVehicleNumber;
        this.lrDtoSetLocal.vehicleNumber = this.txtLTVehicleNumber;
        this.lrDtoSetLocal.deliveryarea = this.txtLTArea;
        this.lrDtoSetLocal.driverName = this.txtLTDriver;
        this.lrDtoSetLocal.tripDate = this.dfLTTripDate;
        this.lrDtoSetLocal.tripId = this.localTripId;
        /// need to add local trip Id and to chekc the local trip info 

        if (this.lrEnquiryRet.localTripStatus != null
            && this.lrEnquiryRet.localTripStatus == "Pending") {
            this.showTripStatus = "In Transit";
            this.localTripAction = true
        } else if (this.lrEnquiryRet.localTripStatus != null
            && this.lrEnquiryRet.localTripStatus == "Completed") {
            this.showTripStatus = "Trip Completed";
            this.localTripAction = false
        }

    }
    localTripSrcFields() {
        this.lrDtoSetLocal.localTripNumber = this.txtLTVehicleNumber;
        this.lrDtoSetLocal.vehicleNofromTripSheetSrc = (this.lrEnquiryRet.vehicleNofromTripSheetSrc == null ? "NA"
            : this.lrEnquiryRet.vehicleNofromTripSheetSrc);
        this.lrDtoSetLocal.localBkgDeliveryAreaSrc = (this.lrEnquiryRet.localBkgDeliveryAreaSrc == null ? "NA"
            : this.lrEnquiryRet.localBkgDeliveryAreaSrc);
        this.lrDtoSetLocal.dummyDriverNameSrc = (this.lrEnquiryRet.dummyDriverNameSrc == null ? "NA"
            : this.lrEnquiryRet.dummyDriverNameSrc);

        console.log(this.lrEnquiryRet.localBkgDeliveryAreaSrc);
        this.lrDtoSetLocal.tripDateSrc = (this.lrEnquiryRet.tripDateSrc == null ? "NA"
            : this.lrEnquiryRet.tripDateSrc);

        //Chg_4
        this.lrDtoSetLocal.tripDateSrcStr = (this.lrEnquiryRet.tripDateSrcStr == null ? "NA"
            : (moment(this.lrEnquiryRet.tripDateSrc).format("YYYY-MM-DD HH:mm:ss")));

        /// need to add local trip Id and to chekc the local trip info 

        if (this.lrEnquiryRet.localTripStatus != null
            && this.lrEnquiryRet.localTripStatus == "Pending") {
            this.showTripStatus = "In Transit";
            this.localTripAction = true
        } else if (this.lrEnquiryRet.localTripStatus != null
            && this.lrEnquiryRet.localTripStatus == "Completed") {
            this.showTripStatus = "Trip Completed";
            this.localTripAction = false
        }

    }


    setDummyInvoiceFields() {
        // setting the dept,arri,sec date
        this.lrDtoForDummyInvoiceRetDto.dummyInvoice = this.viewDummyInvoice;
        //        this.dummyInvoiceDepDate=this.lrDtoForDummyInvoiceRetDto.dummyDepDate;
        //        this.lrDtoForDummyInvoiceRetDto.dummyDepDate= this.dummyInvoiceDepDate;
        //        this.dummyInvoiceSchDate=this.lrDtoForDummyInvoiceRetDto.dummySchDate;
        //        this.lrDtoForDummyInvoiceRetDto.dummySchDate= this.dummyInvoiceSchDate;
        //        this.dummyInvoiceArrDate=this.lrDtoForDummyInvoiceRetDto.dummyArrDAte;
        //        this.lrDtoForDummyInvoiceRetDto.dummyArrDAte= this.dummyInvoiceArrDate;

    }

    setDummyInvoice2Fields() {

        this.lrDtoForDummyInvoice2RetDto.dummyinvoice2 = this.viewDummyInvoice2;

        //        $("#"+this.pageId+"dumInv2InvoiceNumber" ).val( this.viewDummyInvoice2 );

        ////        this.dummyInvoice2DepDate=this.lrDtoForDummyInvoice2RetDto.dummyDepDate;
        //        this.lrDtoForDummyInvoice2RetDto.dummyDepDate= this.lrDtoForDummyInvoice2RetDto.dummyDepDate;
        //        
        ////        this.dummyInvoice2SchDate=this.lrDtoForDummyInvoice2RetDto.dummySchDate;
        //        this.lrDtoForDummyInvoice2RetDto.dummySchDate=this.lrDtoForDummyInvoice2RetDto.dummySchDate;
        //        
        ////        this.dummyInvoice2ArrDate=this.lrDtoForDummyInvoice2RetDto.dummyArrDAte;
        //        this.lrDtoForDummyInvoice2RetDto.dummyArrDAte= this.lrDtoForDummyInvoice2RetDto.dummyArrDAte;
    }

    getCashmemoDetailsInfo() {
        this.lrDtoForMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForMemo.cashMemoNumber = this.showDestCashMemo;
    }
    getCashmemoDetailsForLrEnquiry = () => {
        this.getCashmemoDetailsInfo();
        this.reportService.getCashmemoDetails(this.lrDtoForMemo).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoForMemoRet = response;
                    this.lrDtoForMemoRetDto = this.lrDtoForMemoRet;
                    this.setDestBillingDetailsFields();
                    //                    console.log( this.lrDtoForMemoRetDto );

                    /// Pending of Status

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };

    setDestBillingDetailsFields() {
        //unLdgChg pending  value of  is pending 
        //        console.log( 'a' );
        //        console.log( this.lrDtoForMemoRetDto.desMemo );
        //        console.log( this.lrDtoForMemoRetDto.demurage );
        //        console.log( this.lrDtoForMemoRetDto.paymentMode );
        //        console.log( this.lrDtoForMemoRetDto.memoLessType );
        //        console.log( 'coll' + this.lrDtoForMemoRetDto.collectionDate );
        this.lrDtoDestMemo.desMemo = this.lrDtoForMemoRetDto.desMemo;
        this.lrDtoDestMemo.deliveryType = this.lrDtoForMemoRetDto.deliveryType;
        this.lrDtoDestMemo.localCharge = this.lrDtoForMemoRetDto.localCharge;
        this.lrDtoDestMemo.hamaliDD = this.lrDtoForMemoRetDto.hamaliDD;
        this.lrDtoDestMemo.hamaliGD = this.lrDtoForMemoRetDto.hamaliGD;
        this.lrDtoDestMemo.receiptCharge = this.lrDtoForMemoRetDto.receiptCharge;
        this.lrDtoDestMemo.serviceCharge = this.lrDtoForMemoRetDto.serviceCharge;
        this.lrDtoDestMemo.discountAmount = this.lrDtoForMemoRetDto.discountAmount;
        this.lrDtoDestMemo.subTotal = this.lrDtoForMemoRetDto.subTotal;
        this.lrDtoDestMemo.servicetax = this.lrDtoForMemoRetDto.servicetax;
        this.lrDtoDestMemo.servTaxAmt = this.lrDtoForMemoRetDto.servTaxAmt;
        this.lrDtoDestMemo.grandTotal = this.lrDtoForMemoRetDto.grandTotal;
        this.lrDtoDestMemo.tripsheetHamali = this.lrDtoForMemoRetDto.tripsheetHamali;
        this.lrDtoDestMemo.unLdgChg = this.lrDtoForMemoRetDto.unLdgChg;
        this.lrDtoDestMemo.demurage = this.lrDtoForMemoRetDto.demurage;
        this.lrDtoDestMemo.memoGeneratedBy = this.lrDtoForMemoRetDto.memoGeneratedBy;
        this.lrDtoDestMemo.others = this.lrDtoForMemoRetDto.others;

        this.txMemoDate = (this.lrDtoForMemoRetDto.enteredDateStr == null ? "NA"
            : this.lrDtoForMemoRetDto.enteredDateStr);
        this.lrDtoDestMemo.cashMemoTime = this.txMemoDate;

        if (this.lrDtoForMemoRetDto.dateInDate != null
            || (this.lrDtoForMemoRetDto.withLetterHead != null && this.lrDtoForMemoRetDto.withLetterHead == true)) {
            this.lrDtoDestMemo.dateInDate = this.lrDtoForMemoRetDto.dateInDate;
            this.showRecDate = true;
        }


        this.selectedCollectionDate = this.lrDtoForMemoRetDto.collectionDateStr;
        this.lrDtoDestMemo.collectionDate = this.selectedCollectionDate;
        this.lrDtoDestMemo.cmAtMemoGeneration = (this.lrDtoForMemoRetDto.cmAtMemoGeneration == null ? this.lrDtoForMemoRetDto.column2 : this.lrDtoForMemoRetDto.cmAtMemoGeneration);
        this.lrDtoDestMemo.column2 = this.lrDtoForMemoRetDto.column2;
        this.lrDtoDestMemo.paymentMode = this.lrDtoForMemoRetDto.paymentMode;
        this.lrDtoDestMemo.cashmemoType = this.lrDtoForMemoRetDto.cashmemoType;
        this.lrDtoDestMemo.memoLessAmt = (this.lrDtoForMemoRetDto.memoLessAmt == null ? 0 : this.lrDtoForMemoRetDto.memoLessAmt);
        this.lrDtoDestMemo.memoLessType = (this.lrDtoForMemoRetDto.memoLessType == null ? "" : this.lrDtoForMemoRetDto.memoLessType);

        if (this.lrDtoForMemoRetDto.deliveryType != null
            && this.lrDtoForMemoRetDto.deliveryType == "GD") {
            $("#" + this.pageId + "deliveryMode").val("Godown Delivery");
        } else if (this.lrDtoForMemoRetDto.deliveryType != null
            && this.lrDtoForMemoRetDto.deliveryType == "DD") {
            $("#" + this.pageId + "deliveryMode").val("Door Delivery");
        } else {
            $("#" + this.pageId + "deliveryMode").val("-------");
        }

        this.showDestMemoStatusLbl = true;

        if (this.lrDtoForMemoRetDto.status == null) {
            this.showDestMemoStatus = "Cash Memo Status - -------- ";

        } else if (this.lrDtoForMemoRetDto.status != null
            && (this.lrDtoForMemoRetDto.status ==
                "clear") || (this.lrDtoForMemoRetDto.status == "CompleTed") || (this.lrDtoForMemoRetDto.status == "completed")) {
            this.showDestMemoStatus = "Cash Memo Status - Cleared";

        } else if (this.lrDtoForMemoRetDto.column1 ==
            "Assigned") {
            this.showDestMemoStatus = "Cash Memo Status - Assigned to " + this.lrDtoForMemoRetDto.collectionMan;

        } else if ((this.lrDtoForMemoRetDto.column1 ==
            "Pending")
            && (this.lrDtoForMemoRetDto.status
                == "billing")) {
            this.showDestMemoStatus = "Cash Memo Status - In Billing";

        } else if ((this.lrDtoForMemoRetDto.column1 ==
            "Pending")
            && (this.lrDtoForMemoRetDto.status ==
                "collection")) {
            this.showDestMemoStatus = "Cash Memo Status - In SRD Collection Stock";

        } else if ((this.lrDtoForMemoRetDto.column1 ==
            "Pending")
            && (this.lrDtoForMemoRetDto.status ==
                "cmstock")) {
            this.showDestMemoStatus = "Cash Memo Status - In Collection Man Stock - " + this.lrDtoForMemoRetDto.collectionMan;

        } else if (this.lrDtoForMemoRetDto.status ==
            "missing") {
            this.showDestMemoStatus = "Cash Memo Status - Missing";

        } else if (this.lrDtoForMemoRetDto.status ==
            "To Party") {
            this.showDestMemoStatus = "Cash Memo Status - Left To Party - " + this.lrDtoForMemoRetDto.consigneeName;

        } else if ((this.lrDtoForMemoRetDto.status ==
            "transfer")
            || (this.lrDtoForMemoRetDto.status ==
                "transferToGodown")) {
            this.showDestMemoStatus = "Cash Memo Status - Transferred To Collection";
        } else if (this.lrDtoForMemoRetDto.status != null
            && this.lrDtoForMemoRetDto.status == "tempcompleted") {
            this.showDestMemoStatus = "Cash Memo Status - Madhavaram Collection To Be Confirm";

        } else {
            this.showDestMemoStatus = "Cash Memo Status - NA";
        }

        // Chg_2
        this.lrDtoDestMemo.refNumber = this.lrDtoForMemoRetDto.refNumber;
    }

    //FromStation
    getLrEnquiryStatusInfo() {
        this.lrDtoGetStatus = this.lrEnquiryRet;
    }
    getLrEnquiryStatusDetails = () => {
        this.getLrEnquiryStatusInfo();
        this.reportService.getLrEnquiryStatus(this.lrDtoGetStatus).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Status found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoGetStatusRet = response;

                    this.showStatus = this.lrDtoGetStatusRet.status;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    setStatusOfLr() {
        this.showShortExtra = true;
        this.showFreight = true;
        this.showArticleSize = true;
        if (this.lrEnquiryRet.reason != null
            && this.lrEnquiryRet.reason != "") {
            this.showReasonLbl = "Due To " + this.lrEnquiryRet.reason;
        }
        if (this.lrEnquiryRet.status == "InCP") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Pending"
            || this.lrEnquiryRet.status == "localPending") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Transit") {

            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Compound") {

            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Delivered") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Scheduled") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "CMWT") {
            this.lrEnquiryRet.status
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "CMNT") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "UnDelivered") {
            this.viewReasonLbl = true;
        } else if (this.lrEnquiryRet.status == "LRisSalesTax") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Blocked") {
            this.showRemark = true;
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "ST") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "localTransit") {
            this.viewReasonLbl = false;
        } else if (this.lrEnquiryRet.status == "Deleted") {
            this.viewReasonLbl = false;
        }
        //Chg_5
        else if (this.lrEnquiryRet.status == "Hold") {
            this.viewReasonLbl = false;
        }
        else {
            this.viewReasonLbl = false;
        }

        // Chg_3
        // if (this.lrEnquiryRet.listOfEWay != null && this.lrEnquiryRet.listOfEWay.length > 0) {
        //     console.log("this.lrEnquiryRet.listOfEWay");
        //     console.log(this.lrEnquiryRet.listOfEWay);
        //     this.showMultiEwayBill = true;
        //     this.showSingleEwayBill = false;
        // } else {
        //     this.showMultiEwayBill = false;
        //     this.showSingleEwayBill = true;
        // }

    }

    validateClear() {
        $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "searchBy").val('lrNumberWise');
        $("#" + this.pageId + "lrAndEwayNumber").val('');
        this.clearInputFields();
    }

    clearInputFields() {

        this.showShortExtra = false;
        this.showFreight = false;
        this.showArticleSize = false;
        this.isReadOnly = false;
        //        this.lrDtoForDummyInvoiceRetDto = null;
        //        this.lrDtoForDummyInvoice2RetDto = null;
        this.lrDtoForDummyInvoiceRetDto = new LRDto();
        this.lrDtoForDummyInvoice2RetDto = new LRDto();
        this.lrEnquiryRet = null;
        this.lrDtoLocalTripsRet = null;

        this.lrDtoForMemoRetDto = null;
        this.showStatus = null;
        this.lrDtoGetStatusRet = null;
        this.showInvoiceNumber = null;
        this.viewDummyInvoice = null;
        this.viewDummyInvoice2 = null;
        this.showDummyInvoice = false;
        this.showDummyInvoice2 = false;
        this.lblSrcMemo = false;
        this.lblSrcMemoNo = true;
        this.showSrcCashMemo = null;
        this.lblDestCashMemo = false;
        this.lblDestCashMemoNo = true;
        this.showDestCashMemo = null;
        this.lblLocalTripDetails = false;
        this.showBankLr = false;
        this.showCODLbl = false;
        this.localTripId = 0;
        ///Labels;
        this.showMemoBlocklabel = false;
        //        this.showMemoBlock = null;
        this.showDestMemoStatusLbl = false;
        this.showDestMemoStatus = null;
        this.viewReasonLbl = false;
        this.showReasonLbl = null;
        this.viewCCCopyLbl = false;
        this.consigneeCopyLbl = null;
        this.viewLrHoldReasonLbl = false;
        this.showLrHoldLbl = null;
        this.showRemark = false;
        //        this.showForESugam = true;
        this.showForEdeclaration = false;
        this.showRecDate = false;
        //for invoice
        $("#" + this.pageId + "invoiceNo").val('');
        $("#" + this.pageId + "vehicleNumber").val('');
        $("#" + this.pageId + "mainVehicleNo").val('');
        $("#" + this.pageId + "companyName").val('');
        $("#" + this.pageId + "vehicleCompanyAddress").val('');
        $("#" + this.pageId + "vehicleCompCity").val('');
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "vehicleCompContactNumber").val('');
        $("#" + this.pageId + "driverName").val('');
        $("#" + this.pageId + "driverContactNumber").val('');
        $("#" + this.pageId + "brokerName").val('');
        $("#" + this.pageId + "invoiceGeneratedBy").val('');
        $("#" + this.pageId + "loaderName").val('');
        $("#" + this.pageId + "departureDate").val('');
        $("#" + this.pageId + "departureTime").val('');
        $("#" + this.pageId + "scheduledDate").val('');
        $("#" + this.pageId + "scheduleTime").val('');
        $("#" + this.pageId + "arrivalDate").val('');
        $("#" + this.pageId + "arrivalTime").val('');

        //        for dumy
        $("#" + this.pageId + "dumInvInvoiceNumber").val('');
        $("#" + this.pageId + "dumInvVehicleNumber").val('');
        $("#" + this.pageId + "dumInvCompanyName").val('');
        $("#" + this.pageId + "dumInvAddress").val('');
        $("#" + this.pageId + "dumInvCity").val('');
        $("#" + this.pageId + "dumInvPincode").val('');
        $("#" + this.pageId + "dumInvContactNumber").val('');
        $("#" + this.pageId + "dumInvDriverName").val('');
        $("#" + this.pageId + "dumInvDriverContNumber").val('');
        $("#" + this.pageId + "dumInvBrokerName").val('');
        $("#" + this.pageId + "dumInvGeneratedBy").val('');
        $("#" + this.pageId + "dumInvDepartureDate").val('');
        $("#" + this.pageId + "dumInvDepartureTime").val('');
        $("#" + this.pageId + "dumInvScheduleDate").val('');
        $("#" + this.pageId + "dumInvScheduleTime").val('');
        $("#" + this.pageId + "dumInvArrivalDate").val('');
        $("#" + this.pageId + "dumInvArrivalTime").val('');

        ///For Dummy 2
        $("#" + this.pageId + "dumInv2InvoiceNumber").val('');
        $("#" + this.pageId + "dumInv2VehicleNumber").val('');
        $("#" + this.pageId + "dumInv2CompanyName").val('');
        $("#" + this.pageId + "dumInv2Address").val('');
        $("#" + this.pageId + "dumInv2City").val('');
        $("#" + this.pageId + "dumInv2Pincode").val('');
        $("#" + this.pageId + "dumInv2ContactNumber").val('');
        $("#" + this.pageId + "dumInv2DriverName").val('');
        $("#" + this.pageId + "dumInv2DriverContNumber").val('');
        $("#" + this.pageId + "dumInv2BrokerName").val('');
        $("#" + this.pageId + "dumInv2GeneratedBy").val('');
        $("#" + this.pageId + "dumInv2DepartureDate").val('');
        $("#" + this.pageId + "dumInv2DepartureTime").val('');
        $("#" + this.pageId + "dumInv2ScheduleDate").val('');
        $("#" + this.pageId + "dumInv2ScheduleTime").val('');
        $("#" + this.pageId + "dumInv2ArrivalDate").val('');
        $("#" + this.pageId + "dumInv2ArrivalTime").val('');

        ////For Src Memo
        $("#" + this.pageId + "srcMemoMemoNumber").val('');
        $("#" + this.pageId + "srcMemoMemoDate").val('');
        $("#" + this.pageId + "srcMemoCreatedBy").val('');
        $("#" + this.pageId + "srcMemoTotalLRAmt").val('');
        $("#" + this.pageId + "srcMemoHamali").val('');
        $("#" + this.pageId + "srcMemoServiceChg").val('');
        $("#" + this.pageId + "srcMemoReceiptChg").val('');
        $("#" + this.pageId + "srcMemoDestHamali").val('');
        $("#" + this.pageId + "srcMemoDiscount").val('');
        $("#" + this.pageId + "srcMemoSubTotal").val('');
        $("#" + this.pageId + "srcMemoServiceTax").val('');
        $("#" + this.pageId + "srcMemoServiceTaxAmt").val('');
        $("#" + this.pageId + "srcMemoGrandTotal").val('');
        //For Dest memo
        $("#" + this.pageId + "destMemoMemoNumber").val('');
        $("#" + this.pageId + "destMemoDeliveryType").val('');
        $("#" + this.pageId + "destMemoLocalCharge").val('');
        $("#" + this.pageId + "destMemoHamaliDD").val('');
        $("#" + this.pageId + "destMemoGD").val('');
        $("#" + this.pageId + "destMemoUnldgChg").val('');
        $("#" + this.pageId + "destMemoReceiptCharge").val('');
        $("#" + this.pageId + "destMemoServiceCharge").val('');
        $("#" + this.pageId + "otherCharge").val('');
        $("#" + this.pageId + "destMemoDemurrage").val('');
        $("#" + this.pageId + "destMemoDiscount").val('');
        $("#" + this.pageId + "destMemoSubTotal").val('');
        $("#" + this.pageId + "destMemoServiceTax").val('');
        $("#" + this.pageId + "destMemoServiceTaxAmt").val('');
        $("#" + this.pageId + "destMemoGrandTotal").val('');
        $("#" + this.pageId + "destMemoTripsheetHamali").val('');
        $("#" + this.pageId + "destMemoMemoDate").val('');
        $("#" + this.pageId + "destMemoMemoAmt").val('');
        $("#" + this.pageId + "destMemoGeneratedBy").val('');
        $("#" + this.pageId + "destMemoDeliveryDate").val('');
        $("#" + this.pageId + "destMemoColManAtMemoGen").val('');
        $("#" + this.pageId + "destMemoCashMemoAssignedTo").val('');
        $("#" + this.pageId + "destMemoPaymentType").val('');
        $("#" + this.pageId + "destMemoColDate").val('');
        $("#" + this.pageId + "destMemoPaymentMode").val('');
        $("#" + this.pageId + "destMemoReceivedDate").val('');
        $("#" + this.pageId + "destMemoMemoLessAmt").val('');
        $("#" + this.pageId + "destMemoMemoLessType").val('');
        // Chg_2
        $("#" + this.pageId + "refNumber").val('');

        ///for Local trip
        $("#" + this.pageId + "localTripVehicleNumber").val('');
        $("#" + this.pageId + "localTripArea").val('');
        $("#" + this.pageId + "localTripDriverName").val('');
        $("#" + this.pageId + "localTripTripDate").val('');
        $("#" + this.pageId + "destLocalTripId").val('');
        this.showTripStatus = null;
        //For Freight Amt remark and esugam 
        $("#" + this.pageId + "frtAmtBaseFreight").val('');
        $("#" + this.pageId + "frtAmtHamali").val('');
        $("#" + this.pageId + "frtAmtGCCharge").val('');
        $("#" + this.pageId + "frtAmtLCCharge").val('');
        $("#" + this.pageId + "frtAmtBCCharge").val('');
        $("#" + this.pageId + "frtAmtFOV").val('');
        $("#" + this.pageId + "frtAmtAOC").val('');
        $("#" + this.pageId + "frtAmtInsuranceCharge").val('');
        $("#" + this.pageId + "frtAmtOthers").val('');
        $("#" + this.pageId + "frtAmtDD").val('');
        $("#" + this.pageId + "frtAmtTotalAmt").val('');
        $("#" + this.pageId + "remark").val('');
        $("#" + this.pageId + "remark").val('');
        $("#" + this.pageId + "eSugamNo").val('');
        $("#" + this.pageId + "eDeclarationTransID").val('');
        $("#" + this.pageId + "eWayBillNo").val('');
        $("#" + this.pageId + "eDeclarationCommodity").val('');
        $("#" + this.pageId + "eDeclarationBy").val('');
        //Memo Block Reason
        $("#" + this.pageId + "memoBlockedReason").val('');
        $("#" + this.pageId + "memoBlockedDate").val('');
        ///Cod Payment
        $("#" + this.pageId + "codPaymentMode").val('');
        $("#" + this.pageId + "codPaymentBankName").val('');
        $("#" + this.pageId + "codPaymentChqNo").val('');
        $("#" + this.pageId + "codPaymentChqDate").val('');
        $("#" + this.pageId + "codPaymentRefNo").val('');

        $("#" + this.pageId + "memoBlockedDate").val('');
        $("#" + this.pageId + "ewayBillValidUpto").val('');
        this.txtPaymentModeCodLrPayment = null;
        this.txtBankNameCodLrPayment = null;
        this.txtCheqNumberCodLrPayment = null;
        this.dfCheqDateCodLrPayment = null;
        this.txtRefNoCodLrPayment = null;
        this.selectedEnteredDate = null;
        this.selectedRecAtBkgDate = null;
        this.selectedBookingDate = null;
        this.billDateRet = null;
        this.getSrcMemoDate = null;
        this.txtLTVehicleNumber = null;
        this.txtLTArea = null;
        this.txtLTDriver = null;
        this.dfLTTripDate = null;
        this.localTripArea = null;
        this.getFrtAmtOthers = null;
        this.cashMemoBlockDate = null;
        this.txMemoDate = null;
        this.selectedCollectionDate = null;
        this.viewDetainedByGst = false;
        this.showMultipleArticlesRange = false;
        this.multiEwayTableDataList = [];
        $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
        this.dtTriggerMultEway.next();
        this.localBkgTripNo = null;
        this.unclearedLrReason = null;
        this.lrRateStatus = null;
        // this.showMultiEwayBill = false;
        // this.showSingleEwayBill = true;
    }





    getLrEnquiry(e) {
        if (e.keyCode == 13) {
            this.selectedSearchNumber = $("#" + this.pageId + "lrAndEwayNumber").val();
            if (this.selectedSearchNumber != null) {
                this.clearInputFields();
                this.getLrEnquiryDetails();
            }

        }
    }

    showConsignorDetails() {
        //alert( 'a' );
    }
    showConsigneeDetails() {
        //alert( 'b' );
    }

    getBayInfoTable() {
        this.bayInfoDialog();
    }

    //for modal starts
    bayInfoDialog(): void {
        const dialogRef = this.dialog.open(BayInfoPopupComponent, {
            data: { listOfDataToPass: this.listLrDtosBay }
        });

        // dialogRef.afterClosed().subscribe( result => {

        //     this.router.navigate( ['.'], { relativeTo: this.route } );
        // } );
    }

    //for datePicker ends#
    ////Basha
    //input field hide and show starts
    actionTypeMethod(actionType: string, lrPrintCopyPopUpTemplate, ewayDCGenerationPopup) {
        localStorage.clear();
        if (actionType === 'lrPrint') {
            this.getDataForLrPrint(lrPrintCopyPopUpTemplate);
        } else if (actionType === 'lrTrack') {
            this.selectedLRNo = $("#" + this.pageId + "lrAndEwayNumber").val();
            localStorage.clear();
            localStorage.setItem('searchMode', "lrnumber");
            localStorage.setItem('enteredLrnumber', this.selectedLRNo);
            this.router.navigate(['/report/others-report/vehicleTracking']);
        } else if (actionType === 'lrAdvance') {
            this.router.navigate(['/lr/enquiry/lrAdvance']);
        } else if (actionType === 'lrPod') {
            this.getImgPod();
        } else if (actionType === 'lrPodOtp') {
            swal({
                title: "Confirm PIN Request",
                // text: "Sure U Want to get the POD Pin, the same will send to Consignee in WhatsApp?",
                text: "Sure U Want to send the POD Pin to Consignee mobile number in WhatsApp?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.getLRPodOtp();
                }
            });
        } else if (actionType === 'lrDownloadEwaybill') {
            this.printLREWB();
        } else if (actionType === 'lrEwaybillDC') {
            this.ewayDCGenerationPopup(ewayDCGenerationPopup);
        }
    }

    getDataForLrPrint(lrPrintCopyPopUpTemplate) {
        this.selectedLRNo = $("#" + this.pageId + "lrAndEwayNumber").val();
        if (this.selectedLRNo == null || this.selectedLRNo == undefined || this.selectedLRNo == '') {
            swal("Not Allowed", "Please Enter the LR Number to proceed!", "warning");
            return false;
        } else {
            this.lrDtoAutoLrPrint = new LRDto;
            this.lrDtoAutoLrPrint.lrNumber = this.selectedLRNo;
            this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;

            console.log(this.lrDtoAutoLrPrint);
            this.hireslipService.getAutoLrDetails(this.lrDtoAutoLrPrint).subscribe(
                (response) => {

                    console.log(response);
                    if (response.lrNumber != '' && response.lrNumber != null) {
                        this.getvalueForLrPrint = '';
                        this.getvalueForLrPrint = response;
                        this.setValueForLrPrint = new LRDto;
                        this.setValueForLrPrint = this.getvalueForLrPrint;
                        this.setValueForLrPrint.lrEntryPrintMode = '';
                        this.popUpPrintMethod(this.setValueForLrPrint, lrPrintCopyPopUpTemplate);

                    } else {
                        swal("Empty", "No Data Found", "info");
                    }
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {

                    swal("Error", "", "info");
                }, () => console.log('done');
        }
    }

    popUpPrintMethod(setValueForLrPrint, lrPrintCopyPopUpTemplate) {
        this.modalRefferenceLrPrintCopyPopUp = this.modalService.open(lrPrintCopyPopUpTemplate,
            { centered: true, windowClass: "myCustomModalClass" });
        this.modalRefferenceLrPrintCopyPopUp.result.then((result) => {
            this.modalRefferenceLrPrintCopyClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceLrPrintCopyClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });


        localStorage.clear();
        localStorage.setItem('printAfterSaveFromLrEnquiryData', JSON.stringify(setValueForLrPrint));
        localStorage.setItem('printAfterSaveFromLrEnquiryType', JSON.stringify('LrEnquiry'));

        this.viewLrPrintCopyPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewLrPrintCopyPopUp = false;
        });
    }


    closeBtnPopup() {
        this.modalRefferenceLrPrintCopyPopUp.close();
    }

    confirmBtnPopup() {
        localStorage.clear();
        console.log('new print');
        localStorage.setItem('printAfterSaveFromLrEnquiryData', JSON.stringify(this.setValueForLrPrint));
        localStorage.setItem('printAfterSaveFromLrEnquiryType', JSON.stringify('NotLrEnquiry'));

        this.modalRefferenceLrPrintCopyPopUp.close();

        this.viewLrEntryPrint = true;
        //this.lrEnquiryForm = false;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewLrEntryPrint) {
                this.viewLrEntryPrint = false;
                //  	this.lrEnquiryForm = true;
                localStorage.clear();
            }
        });

        //window.open('/lr/entry/lrEntryFormPrint3', '_blank');
        //this.router.navigate(['/lr/entry/lrEntryFormPrint3']);

    }

    getImgPod() {
        if (this.lrEnquiryRet.viewPodLrCopy != null && this.lrEnquiryRet.viewPodLrCopy != undefined && this.lrEnquiryRet.viewPodLrCopy != '') {
            window.open(this.lrEnquiryRet.viewPodLrCopy);
        } else {
            swal("No POD", "No POD found for this LR!", "info");
        }
        //this.podInfoDialog();
    }

    //for pod modal
    podInfoDialog(): void {
        const dialogRef = this.dialog.open(PodInfoPopupComponent, {
            data: { listOfDataToPass: this.lrEnquiryRet }
        });
    }

    slectedRowOfLocalTrip() {
        this.lrDtoForLocalTripSelectedRow = new LRDto();
        this.lrDtoForLocalTripSelectedRow.tripId = this.localTripId;
        this.lrDtoForLocalTripSelectedRow.status = "Pending";
        this.lrDtoForLocalTripSelectedRow.godownName = this.userDataDtoReturnSession.office;
        this.lrDtoForLocalTripSelectedRow.companyId = this.userDataDtoReturnSession.companyId;
        this.setLocalTripSheetDetails();
    }

    setLocalTripSheetDetails() {
        this.hireslipService.getLocalTripsLrsDetails(this.lrDtoForLocalTripSelectedRow).subscribe(
            (response) => {
                console.log(response);
                this.lrDtoForLocalTripSelectedRowReturn = [];
                this.lrDtoForLocalTripListForLocalStorage = new LRDto();
                if (response.length > 0) {
                    this.lrDtoForLocalTripSelectedRowReturn = response;
                    this.lrDtoForLocalTripListForLocalStorage.tripId = this.lrDtoForLocalTripSelectedRow.tripId;
                    this.lrDtoForLocalTripListForLocalStorage.deliverygodown = this.lrDtoForLocalTripSelectedRow.deliverygodown;
                    this.lrDtoForLocalTripListForLocalStorage.lrDtoList = this.lrDtoForLocalTripSelectedRowReturn;
                    localStorage.clear();
                    localStorage.setItem('localTripsheetDashboardForPopup',
                        JSON.stringify(this.lrDtoForLocalTripListForLocalStorage));

                    const dialogRef = this.dialog.open(TripDeliveryConfirmationDetailsComponent, {
                    });

                    this.modalRefferenceLocalTripSheetPopUp.close();

                } else {
                    swal("Warning", "No Details found ", "warning");
                }
                console.log(this.lrDtoForLocalTripListForLocalStorage);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting local trip lrs Details ", "error");
            },
            () => console.log('done');
    }

    getLRPodOtp() {
        this.selectedLRNo = $("#" + this.pageId + "lrAndEwayNumber").val();
        if (this.selectedLRNo == null || this.selectedLRNo == undefined || this.selectedLRNo == '') {
            swal("Not Allowed", "Please Enter the LR Number", "info");
            return false;
        } else {
            this.requestDto = new RequestDto();
            this.requestDto.mode = this.userDataDtoReturnSession.mainStation;
            this.requestDto.lrNumber = this.selectedLRNo;
            this.requestDto.userId = this.userDataDtoReturnSession.userId;
            this.getLRPodOtpService();
        }
    }

    getLRPodOtpService() {
        this.masterReadService.getLRPodPinNo(this.requestDto).subscribe(
            (response) => {
                if (response.status == 'Success') {
                    // swal("POD PIN", "POD Pin for the LR Number: " + this.selectedLRNo + " is - " + response.token1, "warning");
                    swal("PIN Sent", "POD Pin for the LR Number: " + this.selectedLRNo + " sent to Consignee registered number!", "info");
                } else if (response.status == 'Not Found') {
                    swal("Warning", "No Details found!", "warning");
                } else {
                    swal("Error", "Problem occur while sending the POD Pin Details!", "error");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while sending the POD Pin Details!", "error");
            },
            () => console.log('done');
    }

    printLREWB() {
        var lrEwayBillNo = $("#" + this.pageId + "eWayBillNo").val();

        if (lrEwayBillNo == null || lrEwayBillNo == undefined || lrEwayBillNo == '') {
            swal("Not Allowed", "Ewaybill number not found, please search the details and try again!", "warning");
            return false;
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadLREWBPdf(lrEwayBillNo, response.token1);
                    } else {
                        swal("Failed", "Failed to generate the token for LR Eway Bill print request, please contact Admin!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to generate the token for LR Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }

    downloadLREWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 4 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-EWB.pdf');
        });
    }

    ewayDCGenerationPopup(contentVehicleUpdatePopup) {
        this.modalService.open(contentVehicleUpdatePopup, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    validateEwayDCGeneration() {
        var lrEwayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        this.vehicleNumberForDC = $("#" + this.pageId + "vehicleNoForDC").val();
        console.log('validateEwayPartBUpdate - ' + this.vehicleNumberForDC);

        if (lrEwayBillNo == null || lrEwayBillNo == undefined || lrEwayBillNo == '') {
            swal("Not Allowed", "Ewaybill number not found, please search the details and try again!", "warning");
            return false;
        } else if (this.vehicleNumberForDC == null || this.vehicleNumberForDC == '' || this.vehicleNumberForDC == undefined
            || this.vehicleNumberForDC.length < 6 || this.vehicleNumberForDC.length > 12) {
            swal({
                title: "Not Allowed",
                text: "Please enter the Valid Vehicle Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            });
        } else {
            swal({
                title: "Confirm Generate DC",
                text: "Sure you want to generate Eway bill Delivery Challan? ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.generateDCForLREwaybill();
                } else {
                    swal.close();
                }

            })
        }
    }

    generateDCForLREwaybill() {
        this.selectedLRNo = $("#" + this.pageId + "lrAndEwayNumber").val();

        this.requestDto = new RequestDto();
        this.requestDto.param1 = this.vehicleNumberForDC;
        this.requestDto.lrNumber = this.selectedLRNo;
        this.requestDto.userId = this.userDataDtoReturnSession.userId;
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateDeliveryChallanForEwayBill(this.requestDto).subscribe(
            (response) => {
                if (response.respStatus == 1) {
                    swal("Success", "Delivery Challan generated!", "info");
                    //this.downloadLREWBPdf(lrEwayBillNo, response.token1);
                } else {
                    swal("Failed", "Failed to generate the Delivery Challan, please try again or contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to generate the Delivery Challan, please try again or contact Admin!", "error");
            }, () => console.log('done');

    }


    getGstDetainedFlow(): void {
        const dialogRef = this.dialog.open(GstInvoiceDetainedComponent, {

        });

    }

    getArticleRangeTable() {
        this.listOfMultipleArticlesRange = this.lrEnquiryRet.listOfmultipleArtCft;
        if (this.lrEnquiryRet.listOfmultipleArtCft == null) {
            swal({
                title: "Warning",
                text: "No Multiple Article Size for this Lr",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleCfteDialog();
        }

    }
    multipelArticleCfteDialog(): void {

        const dialogRef = this.dialog.open(MultipleArticleRangePopupComponent, {

            data: { listOfDataToPass: this.listOfMultipleArticlesRange }
        });

        // dialogRef.afterClosed().subscribe( result => {

        //     this.router.navigate( ['.'], { relativeTo: this.route } );
        // } );
    }

    // Chg_3 start
    ngAfterViewInit(): void {
        this.dtTriggerMultEway.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerMultEway.unsubscribe();
    }

    getMultiEwayBillsNo() {
        this.lrDtoForEway = new LRDto();
        this.lrDtoForEway.lrNumber = this.selectedSearchNumber;
        if (this.selectedSearchNumber != null) {
            this.getMultiEwayBillNoDetails();
        }
    }

    getMultiEwayBillNoDetails() {
        this.reportService.getMultiEwayBillNoDetails(this.lrDtoForEway).subscribe(
            (response) => {
                console.log(response);
                this.multiEwayTableDataList = [];
                if (response.length == 0) {
                    swal("No Records", "E-way bill details not found!", "info");
                } else {
                    $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
                    this.multiEwayTableDataList = response;
                    console.log(this.multiEwayTableDataList);
                    this.multiEwayDetailsDialog();
                }
                setTimeout(() => {
                    this.dtTriggerMultEway.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while getting the E-way Bill Details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    multiEwayDetailsDialog(): void {
        const dialogRef = this.dialog.open(MultiEwayDetailsPopupComponent, {
            data: { multiEwayTableDataList: this.multiEwayTableDataList }
        });
    }
    // Chg_3 End

}
