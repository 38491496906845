import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { AmountReceivableComponent } from 'src/app/report/cashmemo-report/amount-receivable/amount-receivable.component';
import { ToBeBilledPartyComponent } from 'src/app/report/cashmemo-report/to-be-billed-party/to-be-billed-party.component';
import { PaidReportComponent } from 'src/app/report/cashmemo-report/paid-report/paid-report.component';
import { CashMemoReportComponent } from 'src/app/report/cashmemo-report/cash-memo-report/cash-memo-report.component';
import { BookingCashmemoComponent } from 'src/app/report/cashmemo-report/booking-cashmemo/booking-cashmemo.component';
import { BookingCashmemoByInvoiceComponent } from 'src/app/report/cashmemo-report/booking-cashmemo-by-invoice/booking-cashmemo-by-invoice.component';
import { CashmemoDetailsComponent } from 'src/app/report/cashmemo-report/cashmemo-details/cashmemo-details.component';
import { LetterHeadReportComponent } from 'src/app/report/cashmemo-report/letter-head-report/letter-head-report.component';
import { CollectionManReportComponent } from 'src/app/report/cashmemo-report/collection-man-report/collection-man-report.component';
import { SrdCollectionStockReportComponent } from 'src/app/report/cashmemo-report/srd-collection-stock-report/srd-collection-stock-report.component';
import { MemoLessSearchReportComponent } from 'src/app/report/cashmemo-report/memo-less-search-report/memo-less-search-report.component';
import { CashMemoInCollectionManStockComponent } from 'src/app/report/cashmemo-report/cash-memo-in-collection-man-stock/cash-memo-in-collection-man-stock.component';
import { MaterialModule } from "src/app/material.module";
import { DragulaModule } from 'ng2-dragula';
import { NgxBarcodeModule } from 'ngx-barcode';
import { CashmemoDetailsReportComponent } from 'src/app/report//cashmemo-report/cashmemo-details-report/cashmemo-details-report.component';
import { MemoLessDescriptionReportComponent } from 'src/app/report/cashmemo-report/memo-less-description-report/memo-less-description-report.component';
import { CashmemoModule } from "src/app/cashmemo/cashmemo.module";
import { CollectionManGCLeftToPartyPrintComponent } from 'src/app/report/cashmemo-report/collectionman-gc-left-toparty-print/collectionman-gc-left-toparty-print.component';
import { CustomDynamicPrintV1Component } from 'src/app/report/cashmemo-report/custom-dynamic-printV1/custom-dynamic-printV1.component';
import { TotalLessComponent } from 'src/app/report/cashmemo-report/total-less/total-less.component';
import { TotalLessLrDetailsComponent } from "src/app/report/cashmemo-report/totalless-lrdetails/totalless-lrdetails.component";
import { StockForTripsheetDriverPrintRptComponent } from 'src/app/report/cashmemo-report/stock-for-tripsheet-driver-print-rpt/stock-for-tripsheet-driver-print-rpt.component';
import { LeftToPartyStatementComponent } from 'src/app/report/cashmemo-report/left-to-party-statement-details/left-to-party-statement-details.component';
import { LeftToPartyDetailsPrintComponent } from 'src/app/report/cashmemo-report/left-toparty-details-print/left-toparty-details-print.component';
import { GcTakenGroupingRptPrintComponent } from 'src/app/report/cashmemo-report/gc-taken-grouping-rpt-print/gc-taken-grouping-rpt-print.component';
import { GcTakenLeftToPartyGroupingRptPrintComponent } from 'src/app/report/cashmemo-report/gc-taken-left-to-party-rpt-print/gc-taken-left-to-party-grouping-rpt-print.component';
import { PaymentFollowupChallanPrintRptComponent } from 'src/app/report/cashmemo-report/payment-followup-challan-print-rpt/payment-followup-challan-print-rpt.component';
import { CashmemoPodUploadComponent } from 'src/app/report/cashmemo-report/cashmemo-pod-upload/cashmemo-pod-upload.component';
import { BookingPaidCollectionReportComponent } from './booking-paid-collection-report/booking-paid-collection-report.component';
import { BookingPartyOsReportComponent } from './booking-party-os-report/booking-party-os-report.component';
import { BkgLeftToPartyStatementComponent } from './bkg-left-to-party-statement-details/bkg-left-to-party-statement-details.component';
import { BkgLeftTopartyDetailsPrintComponent } from './bkg-left-toparty-details-print/bkg-left-toparty-details-print.component';
import { BkgLeftTopartyDetailsPrint2Component } from './bkg-left-toparty-details-print2/bkg-left-toparty-details-print2.component';
import { CashmemoBkgPrintTopartyComponent } from './cashmemo-bkg-print-toparty/cashmemo-bkg-print-toparty.component';
import { StockForTripsheetDriverPrintRptComponentV2 } from 'src/app/report/cashmemo-report/stock-for-tripsheet-driver-print-rpt-v2/stock-for-tripsheet-driver-print-rpt-v2.component';
import { CashmemoOnlinePaymentDetailsComponent } from './cashmemo-online-payment-details/cashmemo-online-payment-details.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(CashmemoReportRoutes),
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, DragulaModule, NgxBarcodeModule, CashmemoModule],
    declarations: [
        AmountReceivableComponent,
        ToBeBilledPartyComponent,
        PaidReportComponent,
        CashMemoReportComponent,
        BookingCashmemoComponent,
        BookingCashmemoByInvoiceComponent,
        CashmemoDetailsComponent,
        LetterHeadReportComponent,
        CollectionManReportComponent,
        SrdCollectionStockReportComponent,
        MemoLessSearchReportComponent,
        CashMemoInCollectionManStockComponent,
        //GenerateCashmemoComponent,
        //CashmemoGeneratePrintComponent,
        CashmemoDetailsReportComponent,
        MemoLessDescriptionReportComponent,
        CollectionManGCLeftToPartyPrintComponent,
        CustomDynamicPrintV1Component,
        TotalLessComponent,
        TotalLessLrDetailsComponent,
        StockForTripsheetDriverPrintRptComponent, LeftToPartyStatementComponent, LeftToPartyDetailsPrintComponent,
        GcTakenGroupingRptPrintComponent, GcTakenLeftToPartyGroupingRptPrintComponent,
        PaymentFollowupChallanPrintRptComponent, CashmemoPodUploadComponent, BookingPaidCollectionReportComponent,
        BookingPartyOsReportComponent, BkgLeftToPartyStatementComponent, BkgLeftTopartyDetailsPrintComponent, BkgLeftTopartyDetailsPrint2Component, CashmemoBkgPrintTopartyComponent, StockForTripsheetDriverPrintRptComponentV2,
        CashmemoOnlinePaymentDetailsComponent],
    entryComponents: [TotalLessLrDetailsComponent],
    exports: [CustomDynamicPrintV1Component, StockForTripsheetDriverPrintRptComponent, StockForTripsheetDriverPrintRptComponentV2]
})
export class CashmemoReportModule { }


