import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MasterService } from "src/app/dataService/master-service";

@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerArea: Subject<any> = new Subject();
    dtOptionsArea: any;
    areaDataList: any;
    pageId = "aract";
    lrDto: LRDto = new LRDto();
    showSpinnerForAction: any;
    lrDtoSearch: any;
    lrDtoSaveRet: any;
    areaName; any;
    substations: any;
    enteredDdCharge: any;
    selectedDdUnit: any;
    enteredGdChg: any;
    selectedGdUnit: any;
    showHamaliCharges: boolean = false;
    areaDataListWithHamaliCharges: any;
    dtTriggerAreaDetailsWithHamaliChg: Subject<any> = new Subject();
    dtOptionsAreaDetailsWithHamaliChg: any;
    lrDtoSave: LRDto = new LRDto();
    lrDtoRet: any;
    readonlyAreaName: boolean = false;

    constructor(private http: HttpClient, private masterReadService: MasterReadService, private masterService: MasterService,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getAreaList();
            if (this.userDataDtoReturnSession.hamaliChargesInAreaMaster != null &&
                this.userDataDtoReturnSession.hamaliChargesInAreaMaster) {
                this.showHamaliCharges = true;
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsArea = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        },
            this.dtOptionsAreaDetailsWithHamaliChg = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4]
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": true
            }

    }

    ngOnDestroy(): void {
        this.dtTriggerArea.unsubscribe();
        this.dtTriggerAreaDetailsWithHamaliChg.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerArea.next();
        this.dtTriggerAreaDetailsWithHamaliChg.next();
    }

    getAreaDetailsRead() {
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSearch.office = this.userDataDtoReturnSession.office;
        this.lrDtoSearch.branchType = "local";
        // lrDto.setCompanyId(SRDWeb.companyid);

        console.log("this.lrDtoSearch");
        console.log(this.lrDtoSearch);
    }

    getAreaList = () => {
        this.getAreaDetailsRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getAreaDetails(this.lrDtoSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    if (this.showHamaliCharges) {
                        this.areaDataListWithHamaliCharges = response;
                        $("#" + this.pageId + "areaDatatableWithHamaliChgId").DataTable().destroy();
                        this.dtTriggerAreaDetailsWithHamaliChg.next();
                    } else {
                        this.areaDataList = response;
                        console.log("this.areaDataList");
                        console.log(this.areaDataList);
                        $("#" + this.pageId + "areaDatatableId").DataTable().destroy();
                        this.dtTriggerArea.next();
                    }
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Area Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    saveArea() {
        this.lrDto = new LRDto();
        this.areaName = $("#" + this.pageId + "areaName").val();

        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.office = this.userDataDtoReturnSession.office;
        this.lrDto.subStations = this.areaName;
        this.lrDto.branchType = "local";
        console.log("this.lrDto");
        console.log(this.lrDto);
    }

    saveAreaDetails = () => {
        this.saveArea();
        this.masterService.saveAreaDetail(this.lrDto).
            subscribe((data) => {
                this.lrDtoSaveRet = data;
                console.log("this.lrDtoSaveRet");
                console.log(this.lrDtoSaveRet);
                if (this.lrDtoSaveRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "Area Name saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getAreaList();

                } else if (this.lrDtoSaveRet.status == "Exist") {
                    swal("Duplicate Entry", "Entered Area Name is already Exist", "info");
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Save Area Name",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Save Area Name",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    confirmSave() {
        this.areaName = $("#" + this.pageId + "areaName").val();
        if (this.areaName == null || this.areaName == '' || this.areaName == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please entered Area Name! ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Save",
                text: "Sure want to Save",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((saveYes) => {
                if (saveYes) {
                    this.saveAreaDetails();
                }
            });
        }
    }

    setForsaveAreaDetailsWithHamaliCharges() {
        this.lrDtoSave = new LRDto();
        this.areaName = $("#" + this.pageId + "areaName").val();
        this.enteredDdCharge = $("#" + this.pageId + "ddCharge").val();
        this.selectedDdUnit = $("#" + this.pageId + "ddUnit").val();
        this.enteredGdChg = $("#" + this.pageId + "gdCharge").val();
        this.selectedGdUnit = $("#" + this.pageId + "gdUnit").val();

        this.lrDtoSave.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSave.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoSave.office = this.userDataDtoReturnSession.office;
        this.lrDtoSave.subStations = this.areaName;
        this.lrDtoSave.branchType = "local";
        this.lrDtoSave.loadingChg = this.enteredDdCharge;
        this.lrDtoSave.loadingChgUnit = this.selectedDdUnit;
        this.lrDtoSave.unLdgChg = this.enteredGdChg;
        this.lrDtoSave.unldgUnit = this.selectedGdUnit;
        console.log("this.lrDtoSave");
        console.log(this.lrDtoSave);
    }

    saveAreaDetailsWithHamaliChargesMethod = () => {
        this.setForsaveAreaDetailsWithHamaliCharges();
        this.masterService.saveAreaDetailsWithHamaliCharges(this.lrDtoSave).
            subscribe((data) => {
                this.lrDtoRet = data;
                console.log(this.lrDtoRet);
                if (this.lrDtoRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "Area Details saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getAreaList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Area Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Area Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    validateSave() {
        this.areaName = $("#" + this.pageId + "areaName").val();
        this.enteredDdCharge = $("#" + this.pageId + "ddCharge").val();
        this.selectedDdUnit = $("#" + this.pageId + "ddUnit").val();
        this.enteredGdChg = $("#" + this.pageId + "gdCharge").val();
        this.selectedGdUnit = $("#" + this.pageId + "gdUnit").val();
        if (this.areaName == null || this.areaName == undefined || this.areaName == ''
            || this.enteredDdCharge == null || this.enteredDdCharge == undefined || this.enteredDdCharge == ''
            || this.selectedDdUnit == null || this.selectedDdUnit == undefined || this.selectedDdUnit == ''
            || this.enteredGdChg == null || this.enteredGdChg == undefined || this.enteredGdChg == ''
            || this.selectedGdUnit == null || this.selectedGdUnit == undefined || this.selectedGdUnit == '') {
            swal({
                title: "Mandatory Fields",
                text: "All Fields Are Mandatory! ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Save",
                text: "Sure want to Save the Area Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((saveYes) => {
                if (saveYes) {
                    this.saveAreaDetailsWithHamaliChargesMethod();
                }
            });
        }
    }

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "ddUnit").val('');
        $("#" + this.pageId + "gdUnit").val('');
        this.areaName = null;
        this.enteredDdCharge = null;
        this.selectedDdUnit = null;
        this.enteredGdChg = null;
        this.selectedGdUnit = null;
        this.readonlyAreaName = false;
    }

    rowSelectedView(areaDataWithHamaliCharges) {
        this.readonlyAreaName = true;
        $("#" + this.pageId + "areaName").val(areaDataWithHamaliCharges.subStations);
        $("#" + this.pageId + "ddCharge").val(areaDataWithHamaliCharges.loadingChg);
        $("#" + this.pageId + "ddUnit").val(areaDataWithHamaliCharges.loadingChgUnit);
        $("#" + this.pageId + "gdCharge").val(areaDataWithHamaliCharges.unLdgChg);
        $("#" + this.pageId + "gdUnit").val(areaDataWithHamaliCharges.unldgUnit);
    }

    saveAreaDetailsBtn() {
        if (this.showHamaliCharges) {
            this.validateSave();
        } else {
            this.confirmSave();
        }
    }

}