<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row ">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <!-- <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Booking Trip Details</h6>
                </div> -->
                <div class="row system_responsive">
                    <div class="col-md-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Trip Date</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}tripDate" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Vehicle No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}vehicleNumber" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Trip No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}tripNo" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Agent Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}agent" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Total Lrs</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}totalLrs" aria-describedby="basic-addon11" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Choudhary Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input #labourHeadNameDropDown id="{{pageId}}dropDownInputFieldLabourHeadName" type="text" class="form-control" [(ngModel)]="modelDropDownLabourHeadName" [ngbTypeahead]="searchDropDownInputFieldLabourHeadName" [resultFormatter]="formatterDropDownLabourHeadName"
                                                [inputFormatter]="formatterDropDownLabourHeadName" placeholder="Select Loader Head" (focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Bay No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input #bayNoDropDown id="{{pageId}}dropDownInputFieldBayNo" type="text" class="form-control" [(ngModel)]="modelDropDownBayNo" [ngbTypeahead]="searchDropDownInputFieldBayNo" [resultFormatter]="formatterDropDownBayNo" [inputFormatter]="formatterDropDownBayNo"
                                                placeholder="Select Bay No" (focus)="focusDropDownBayNoTA$.next($any($event).target.value)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Unloading Date</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input class="form-control" id="{{pageId}}unldDt" placeholder="yyyy-mm-dd" name="unldDt" ngbDatepicker #unldDt="ngbDatepicker">
                                            <div class="input-group-append" (click)="unldDt.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Unloading Time</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="time" class="form-control" id="{{pageId}}unldTime" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 vl">
                        <div class="card-body">


                            <div class="row">
                                <div class="col-md-6">
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-3">
                                                <h6 class="card-title">LR Details</h6>
                                            </div>
                                            <div class="row">
                                                <!-- spinner start-->
                                                <div *ngIf="showSpinnerForAction" class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                                            <br>
                                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                Please Wait Loading Details.....</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- spinner end-->
                                            </div>
                                            <div class="col-sm-12 col-md-5">
                                                <div class="form-group">
                                                    <div class="input-group">

                                                        <label>LR Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-clipboard-list"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}lrNumber" (keypress)="onKeyPressListnerForLRNumber($event,lrPrintCopyPopUpTemplate);" class="form-control" aria-describedby="basic-addon11">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4">
                                                <button *ngIf="showMovelAllButton" style='float: right;' class="btn btn-dark m-r-10" (click)="validateMoveAllBtnForCheckedLr();" title="Move All To Checked Lrs" id="{{pageId}}moveAllBtn">Move
                                                    All</button>
                                            </div>
                                        </div>
                                        <!-- 							<the first datatable starts  -->
                                        <table datatable id="{{pageId}}bkgTripLrsTableId" class="table table-striped table-bordered row-border hover" style="height: 240px;" [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                                            <thead>
                                                <tr>
                                                    <th>LR Number</th>
                                                    <th>Booking Date</th>
                                                    <!-- 													<th>Source</th> -->
                                                    <th [hidden]=true>Destination</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- 											 class='dragndrop' [dragula]='"first-bag"'> -->
                                                <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                                    <td>{{ lrDetailsData.lrNumber }}</td>
                                                    <td>{{ lrDetailsData.bookingDateStr }}</td>
                                                    <!-- 													<td>{{ lrDetailsData.source }}</td> -->
                                                    <td [hidden]=true>{{ lrDetailsData.destination }}</td>
                                                    <td>{{ lrDetailsData.actualWeight }}</td>
                                                    <td>{{ lrDetailsData.chargedWeight }}</td>
                                                    <td>{{ lrDetailsData.toPay }}</td>
                                                    <td>{{ lrDetailsData.paid }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <!-- 													<td></td> -->
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <!-- 							the first datatable ends  -->






                                <!-- 							<the second datatable starts  -->
                                <div class="col-md-6 vl">
                                    <div class="box-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-8">
                                                <h6 class="card-title">Checked LR Details</h6>
                                            </div>
                                            <!-- 											<div class="col-sm-12 col-md-4" style="text-align: right;"> -->
                                            <!-- 												<button class="btn btn-danger m-r-10" -->
                                            <!-- 													style="padding: 1px 4px;" id="{{pageId}}resetBtn">Remove</button> -->
                                            <!-- 											</div> -->
                                        </div>
                                        <table datatable id="{{pageId}}bkgTripCheckedLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionCheckedLrDetails" [dtTrigger]="dtTriggerCheckedLrDetails">
                                            <thead>
                                                <tr>
                                                    <th>LR Number</th>
                                                    <th>Booking Date</th>
                                                    <!-- 													<th>Source</th> -->
                                                    <th>Destination</th>
                                                    <th>Act Wgt</th>
                                                    <th>Chg Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- 											 class='dragndrop' [dragula]='"first-bag"'> -->

                                                <tr *ngFor="let checkedLrDetailsData of checkedLrDetailsDataList ">
                                                    <td>{{ checkedLrDetailsData.lrNumber }}</td>
                                                    <td>{{ checkedLrDetailsData.bookingDateStr }}</td>
                                                    <!-- 													<td>{{ checkedLrDetailsData.source }}</td> -->
                                                    <td>{{ checkedLrDetailsData.destination }}</td>
                                                    <td>{{ checkedLrDetailsData.actualWeight }}</td>
                                                    <td>{{ checkedLrDetailsData.chargedWeight }}</td>
                                                    <td>{{ checkedLrDetailsData.toPay }}</td>
                                                    <td>{{ checkedLrDetailsData.paid }}</td>
                                                    <td><button type="button" style="padding: 1px 4px; background-color: lightgrey;" class="btn m-r-10" (click)="rowSelectedLrDelete(checkedLrDetailsData);">
                                                            <i title="Remove" class="fas fa-trash"></i>
                                                        </button></td>
                                                </tr>

                                                <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <!-- 													<td></td> -->
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <!--							 the second datatable ends  -->


                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12" style="text-align: right;">
                                    <button class="btn btn-success m-r-10" id="{{pageId}}confirmLrsBtn" (click)="confirmLrs();">Confirm Lrs</button>
                                    <button *ngIf="showMovelAllButton" class="btn btn-success m-r-10" id="{{pageId}}confirmLrsBtn" (click)="confirmAllLrs();">Confirm All Lrs</button>
                                </div>
                            </div>
                        </div>
                    </div>











                    <!--  to open  popup use this << (click)="openPopup(content)" >>  -->
                    <!-- Row -->
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group" id="{{pageId}}popupDetailsTwo">
                                <ng-template #content let-save="close" let-cancel="close" let-d="dismiss">


                                    <div class="modal-header">
                                        <h5>Add New Truck Capacity</h5>
                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="row" style="text-align: center;">
                                            <div class="col-sm-12 col-md-12">
                                                <h5>Add Truck Capacity</h5>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}truckCapacityPopup">
                                                        <label>Truc Capacity (Tons)</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-clipboard-list"></i>
                                                            </span>
                                                        </div>
                                                        <input type="number" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 p-t-10">
                                                <button type="button" class="btn btn-outline-secondary" (click)="save('Save click')">Save</button>
                                                <button type="button" class="btn btn-outline-danger" (click)="cancle('Cancel click')">Cancel</button>

                                            </div>
                                        </div>
                                    </div>
                                </ng-template>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12" id="{{pageId}}hideLrPrintCopy">
        <div class="form-group">
            <div class="input-group">
                <ng-template #lrPrintCopyPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewLrPrintCopyPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupLrPrintCopyDetails">
                                                <app-lrentryform-print3></app-lrentryform-print3>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}confrimBtnId" (click)="confirmBtnPopup()">Confirm</button>
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeBtnPopup()">Cancel</button>
                                        </div>
                                        <div class="col-sm-12 col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</body>

</html>