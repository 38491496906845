<html>

<head>

</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn">
        <div *ngIf="lrEntryForm">
            <div class="col-lg-12">


                <div class="card " style="border: 1px solid darkcyan !important;">
                    <div class="card-header bg-info hide-header"
                        style="background-color: orange !important; padding: 5px;">
                        <h6 class="card-title text-white">LR Entry Form</h6>
                    </div>

                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait getting Eway bill Details.....</h6>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showSpinnerForBlockParty" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait getting Checking Details.....</h6>
                            </div>
                        </div>
                    </div>


                    <div class="row" style="margin-bottom: 10px;">
                        <div class="col-lg-6 col-md-6 ">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 ">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="card-title">Tempo Details</h6>
                                            <div class="row">
                                                <div *ngIf="viewAutoLr" class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}automatedLR"
                                                                (change)="toggleEditableForAutoLr($event)"> <label
                                                                class=" custom-control-label wtfull "
                                                                for="{{pageId}}automatedLR">Auto LR</label>
                                                            <!-- 																<input type="checkbox" class="custom-control-input" -->
                                                            <!-- 															id="{{pageId}}automatedLR" [(ngModel)]="viewByAutoLr" (change)="toggleEditableForAutoLr($event)"> <label -->
                                                            <!-- 															class=" custom-control-label wtfull " for="automatedLR">Auto -->
                                                            <!-- 															LR</label> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="viewAutoLrHold" class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}automatedLRHold"
                                                                (change)="toggleEditableForAutoLrHold($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}automatedLRHold">Auto LR Hold</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group" id="{{pageId}}scrollable-dropdown-menu">
                                                            <div class="input-group">
                                                                <label>User Godown</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #userGodown id="{{pageId}}userGodown" type="text"
                                                                    class="form-control" [(ngModel)]="defaultGodownName"
                                                                    [ngbTypeahead]="userGodownSearchTA"
                                                                    (focus)="focusUserGodownTA$.next($any($event).target.value)"
                                                                    (keyup)="viewGodwn($event);"
                                                                    (click)="clickTA(instanceUserGodown)"
                                                                    #instanceUserGodown="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showLoader" class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Choudhary Name</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #loaderHead id="{{pageId}}loaderHead" type="text"
                                                                    class="form-control" placeholder="Select Choudhary"
                                                                    [ngbTypeahead]="loaderHeadSearchTA"
                                                                    (focus)="focusLoaderHeadTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instanceLoaderHead)"
                                                                    (keyup)="loaderHeadFocus($event)"
                                                                    #instanceLoaderHead="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="showBayInfo" class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Bay No</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #bayNumber id="{{pageId}}bayNumber"
                                                                    name="bayNumber" type="text" class="form-control"
                                                                    placeholder="Select Bay"
                                                                    [ngbTypeahead]="bayNumberSearchTA"
                                                                    (keyup)="bayNumberFocus($event);"
                                                                    (focus)="focusBayNumberTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instanceBayNumber)"
                                                                    #instanceBayNumber="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="showBayInfo" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bay Unloading Date</label>
                                                            <div class="input-group-append"
                                                                (click)="bayUnloadingDate.toggle()">
                                                                <span class="input-group-text"> <i
                                                                        class="fa fa-calendar"
                                                                        style='cursor: pointer;'></i>
                                                                </span>
                                                            </div>

                                                            <input class="form-control" id="{{pageId}}bayUnloadingDate"
                                                                name="bayUnloadingDate"
                                                                [(ngModel)]="tdyBayUnloadingDate"
                                                                (keyup)="bayUnloadingDateFocus($event)" ngbDatepicker
                                                                placeholder="dd-mm-yyyy"
                                                                #bayUnloadingDate="ngbDatepicker"
                                                                (ngModelChange)="focusBayUnloadingCalendar()"
                                                                (keydown.arrowdown)="bayUnloadingDate.toggle();"
                                                                (click)="bayUnloadingDate.toggle();">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="showBayInfo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label style='margin-left: -5px;'>Bay Unloading
                                                            Time</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #bayUnloadingTime type="time"
                                                            (keyup)="bayUnloadingTimeFocus($event);"
                                                            class="form-control" id="{{pageId}}bayUnloadingTime"
                                                            name="bayUnloadingTime" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="!viewByAutoLr">
                                                <div class="col-lg-12">

                                                    <div class="row">
                                                        <!-- <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                              <div class="input-group" id="{{pageId}}arrivalDate">
                                <label>Arrival Date</label>
                                <input ng-change="vm.dateString = (dateObj | date: 'dd/dd/yyyy')" class="form-control" (click)="e.toggle()" placeholder="yyyy-mm-dd"
                                  name="dp" [(ngModel)]="model" ngbDatepicker #e="ngbDatepicker">
                                <div class="input-group-append" (click)="e.toggle()">
                                  <span class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div> -->
                                                        <!-- <div class="col-md-12">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}popupDetails">
																<label>Popup Details</label>
																<ng-template #content let-c="close" let-d="dismiss">
																<div class="modal-header">
																	<h6 class="modal-title" id="{{pageId}}modal-basic-title">Profile
																		update</h6>
																	<button type="button" class="close" aria-label="Close"
																		(click)="d('Cross click')">
																		<span aria-hidden="true">&times;</span>
																	</button>
																</div>
																<div class="modal-body">
																	<div class="row">
																		<div class="col-md-12">
																			<form>
																				<div class="col-sm-12 col-md-12">
																					<div class="form-group">
																						<label>Date of birth</label>
																						<div class="input-group">
																							<input id="{{pageId}}dateOfBirth" class="form-control"
																								placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
																								#dp="ngbDatepicker">
																							<div class="input-group-append"
																								(click)="dp.toggle()">
																								<span class="input-group-text"> <i
																									class="fa fa-calendar"></i>
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div class="col-sm-12 col-md-12">
																					<div class="form-group">
																						<div class="custom-control custom-checkbox">
																							<input type="checkbox"
																								class="custom-control-input"
																								id="{{pageId}}checkboxToBeBilled1"> <label
																								class=" custom-control-label wtfull"
																								for="checkboxToBeBilled1">To Be Billed</label>
																						</div>
																					</div>
																				</div>
																				<div class="col-sm-12 col-md-12">
																					<div class="form-group">
																						<div class="input-group" id="{{pageId}}clerkName">
																							<label>Clerk Name</label>
																							<div class="input-group-prepend">
																								<span class="input-group-text"> <i
																									class="ti-user"></i>
																								</span>
																							</div>
																							<input type="text" class="form-control"
																								aria-describedby="basic-addon11">
																						</div>
																					</div>
																				</div>
																			</form>
																		</div>
																	</div>
																</div>
																<div class="modal-footer">
																	<button type="button" class="btn btn-outline-dark"
																		(click)="c('Save click')">Save</button>
																</div>
																</ng-template>

																<a
																	style="cursor: pointer; color: blue; font-weight: bolder;"
																	(click)="open2(content)">Click Here</a>
															</div>
														</div>
													</div>-->
                                                        <!--<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group">
																<label>Arrival Date</label> <input class="form-control"
																	placeholder="yyyy-mm-dd" name="arrivalDates"
																	id="{{pageId}}arrivalDate" (keyup)="arrivalDateFocus($event)"
																	ngbDatepicker placeholder="dd-mm-yyyy"
																	#arrivalDates="ngbDatepicker"
																	(click)="arrivalDates.toggle();focusCalendar();">
																<div class="input-group-append"
																	(click)="arrivalDates.toggle();focusCalendar();">
																	<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																	</span>
																</div>
															</div>
														</div>
													</div> -->
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Arrival Date</label>

                                                                    <div class="input-group-append"
                                                                        (click)="arrivalDate.toggle()">
                                                                        <span class="input-group-text"> <i
                                                                                class="fa fa-calendar"
                                                                                style='cursor: pointer;'></i>
                                                                        </span>
                                                                    </div>

                                                                    <input class="form-control"
                                                                        id="{{pageId}}arrivalDate" name="arrivalDate"
                                                                        [(ngModel)]="tdyArrivalDate"
                                                                        (keyup)="arrivalDateFocus($event)" ngbDatepicker
                                                                        placeholder="dd-mm-yyyy"
                                                                        #arrivalDate="ngbDatepicker"
                                                                        (ngModelChange)="focusArrivalCalendar()"
                                                                        (keydown.arrowdown)="arrivalDate.toggle();"
                                                                        (click)="arrivalDate.toggle();">


                                                                    <!-- 																<input type="date" id="{{pageId}}arrivalDate" name="arrivalDate" -->
                                                                    <!-- 																	placeholder="dd-mm-yyyy" -->
                                                                    <!-- 																	(keyup)="arrivalDateFocus($event)"> -->
                                                                    <!-- 																<input class="form-control" id="{{pageId}}arrivalDate" -->
                                                                    <!-- 																	name="arrivalDate" [(ngModel)]="tdyArrivalDate" -->
                                                                    <!-- 																	ngbDatepicker #arrivalDates="ngbDatepicker"> -->
                                                                    <!-- 																<div class="input-group-append"> -->
                                                                    <!-- 																	<button class="btn btn-outline-secondary calendar" -->
                                                                    <!-- 																		(click)="arrivalDates.toggle()" type="button"></button> -->
                                                                    <!-- 																</div> -->

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Local Challan No</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="icon-speech"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #challanNo id="{{pageId}}challanNo"
                                                                        (keyup)="checkChallanNo($event)" type="text"
                                                                        class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Local Challan Total Lrs</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="icon-speech"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #challanTotLrs id="{{pageId}}challanTotLrs"
                                                                        (keyup)="fieldFocus($event, localChallanKanta)"
                                                                        type="number" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Local Challan Kanta Wgt</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="icon-speech"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #localChallanKanta
                                                                        id="{{pageId}}localChallanKanta"
                                                                        (keyup)="fieldFocus($event, agentName)"
                                                                        type="text" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--	<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group" id="{{pageId}}settlementDate">
																<label>Settlement Date</label> <input #date
																	class="form-control" placeholder="yyyy-mm-dd"
																	dateFormat="dd-MMM-yyyy" name="settlementDates"
																	ngbDatepicker #settlementDates="ngbDatepicker"
																	(click)="settlementDates.open()"
																	(keyup)="fieldFocus($event, agentName)">
																<div class="input-group-append"
																	(click)="settlementDates.toggle()">
																	<span class="input-group-text"> <i
																		class="fa fa-calendar"></i>
																	</span>
																</div>
															</div>
														</div>
													</div>-->
                                                        <!--<div class="col-sm-12 col-md-12">
														<div class="form-group">
															<div class="input-group">
																<label>Agent Name</label>
																<div class="input-group-prepend">
																	<span class="input-group-text"> <i
																		class="ti-user"></i>
																	</span>
																</div>
																<!--(keyup.enter)="focusVehicleNumber($event)"
													<select #agentName (click)="agentNames()"
														class="custom-select col-12">
														<option selected>Agra</option>
														<option value="1">Bawana</option>
														<option value="2">Daman</option>
														<option value="3">Karnal</option>
													</select>
												</div>
											</div>
										</div>
										-->


                                                        <!-- 											the first autocomplete starts-->
                                                        <!-- 													<div class="col-sm-12 col-md-12"> -->

                                                        <!-- 														<div class="control"> -->
                                                        <!-- 															<div class="form-group"> -->
                                                        <!-- 																<div class="input-group"> -->
                                                        <!-- 																	<label>Agent Name</label> -->
                                                        <!-- 																	<div class="input-group-prepend"> -->
                                                        <!-- 																		<span class="input-group-text"> <i -->
                                                        <!-- 																			class="fas fa-box"></i> -->
                                                        <!-- 																		</span> -->
                                                        <!-- 																	</div> -->
                                                        <!-- 																	<input #agentName -->
                                                        <!-- 																		class="auto_selectOption input is-medium" -->
                                                        <!-- 																		placeholder="Select Agent" -->
                                                        <!-- 																		[formControl]="controlAgentName" -->
                                                        <!-- 																		[appAutocomplete]="autocompleteAgentName" -->
                                                        <!-- 																		(keydown)="onKeydown($event)"> -->
                                                        <!-- 																</div> -->
                                                        <!-- 															</div> -->
                                                        <!-- 														</div> -->

                                                        <!-- 														<app-autocomplete #autocompleteAgentName="appAutocomplete"> -->
                                                        <!-- 														<ng-template appAutocompleteContent> <ng-container -->
                                                        <!-- 															*ngIf="(agentDetailsOptionsGet | filterAgentSorce: controlAgentName.value) as agentDetailsOptionsGet"> -->
                                                        <!-- 														<app-option (click)="agentNames()" -->
                                                        <!-- 															*ngFor="let option of agentDetailsOptionsGet" -->
                                                        <!-- 															[value]="option.subStation"> {{ -->
                                                        <!-- 														option.subStation }} </app-option> <app-option class="no-result" -->
                                                        <!-- 															*ngIf="!agentDetailsOptionsGet.length">No result</app-option> -->
                                                        <!-- 														</ng-container> </ng-template> </app-autocomplete> -->
                                                        <!-- 													</div> -->

                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="control">
                                                                <div class="form-group">
                                                                    <div class="input-group"
                                                                        id="{{pageId}}scrollable-dropdown-menu">
                                                                        <label>Agent Name</label>

                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-home"></i>
                                                                            </span>
                                                                        </div>

                                                                        <input #agentName id="{{pageId}}agentName"
                                                                            type="text" class="form-control"
                                                                            [(ngModel)]="challanAgentName"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            [ngbTypeahead]="agentNameSearchTA"
                                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                                            (click)="clickTA(instanceAgentName)"
                                                                            (keyup)="getAgentName($event,content2)"
                                                                            #instanceAgentName="ngbTypeahead" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>




                                                        <!-- 											the first autocomplete ends -->

                                                        <!-- 														<div class="col-sm-12 col-md-12"> -->
                                                        <!-- 															<div class="form-group"> -->
                                                        <!-- 																<div class="input-group"> -->
                                                        <!-- 																	<label>Vehicle Number</label> -->
                                                        <!-- 																	<div class="input-group-prepend"> -->
                                                        <!-- 																		<span class="input-group-text"> <i -->
                                                        <!-- 																			class="ti-truck"></i> -->
                                                        <!-- 																		</span> -->
                                                        <!-- 																	</div> -->
                                                        <!-- 																	<input #vehicleNumber id="{{pageId}}vehicleNumber" -->
                                                        <!-- 																		(keyup)="fieldFocus($event, companyName)" type="text" -->
                                                        <!-- 																		class="form-control" aria-describedby="basic-addon11"> -->
                                                        <!-- 																</div> -->
                                                        <!-- 															</div> -->
                                                        <!-- 														</div> -->
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="control">
                                                                <div class="form-group">
                                                                    <div class="input-group"
                                                                        id="{{pageId}}scrollable-dropdown-menu">
                                                                        <label>Vehicle Number</label>

                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-truck"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input #vehicleNumber
                                                                            id="{{pageId}}vehicleNumber"
                                                                            name="vehicleNumber" type="text"
                                                                            class="form-control"
                                                                            (selectItem)="vehicleNumberFocusEvent($event,content12)"
                                                                            [ngbTypeahead]="vehicleNumberSearchTA"
                                                                            (focus)="focusVehicleNumberTA$.next($any($event).target.value)"
                                                                            (click)="clickTA(instanceVehicleNumber)"
                                                                            #instanceVehicleNumber="ngbTypeahead" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group" id="{{pageId}}popupDetails">

                                                                    <ng-template #content12 let-c="close"
                                                                        let-d="dismiss">
                                                                        <div class="modal-header">
                                                                            <h6 class="modal-title"
                                                                                id="{{pageId}}modal-basic-title">Tempo
                                                                                Details
                                                                            </h6>
                                                                            <button type="button" class="close"
                                                                                aria-label="Close"
                                                                                (click)="d('Cross click')">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="col-sm-12 col-md-8">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Truck
                                                                                                    Number*</label>
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <span
                                                                                                        class="input-group-text">
                                                                                                        <i
                                                                                                            class="fas fa-truck"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input #truckNumber
                                                                                                    (keyup)="fieldFocus($event, ownerName)"
                                                                                                    id="{{pageId}}truckNumber"
                                                                                                    name="truckNumber"
                                                                                                    type="text"
                                                                                                    (keyup)="checkTempoNumber($event)"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #numberTender="ngModel"
                                                                                                    [(ngModel)]="truckDataDto.truckNumber"
                                                                                                    minlength="9"
                                                                                                    pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{9,}$"
                                                                                                    required /> <small
                                                                                                    [hidden]="numberTender.valid"
                                                                                                    class="errorLabel">
                                                                                                    Please Enter the
                                                                                                    alphanumeric value.
                                                                                                </small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-8">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Owner
                                                                                                    Name</label>
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <span
                                                                                                        class="input-group-text">
                                                                                                        <i
                                                                                                            class="fa fa-user"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input #ownerName
                                                                                                    (keyup)="fieldFocus($event,panNumber)"
                                                                                                    type="text"
                                                                                                    class="form-control"
                                                                                                    id="{{pageId}}ownerName"
                                                                                                    name="ownerName"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    [(ngModel)]="truckDataDto.ownerName">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-sm-12 col-md-8">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>PAN(Enter PAN as
                                                                                                    AAAAA9999A, if
                                                                                                    you don't have
                                                                                                    PAN)</label>
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <span
                                                                                                        class="input-group-text">
                                                                                                        <i
                                                                                                            class="fa fa-card"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input #panNumber
                                                                                                    (keyup)="fieldFocus($event, truckCapacityInTones)"
                                                                                                    type="text"
                                                                                                    class="form-control"
                                                                                                    id="{{pageId}}panNumber"
                                                                                                    name="panNumber"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    [(ngModel)]="truckDataDto.panNo">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-8">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Truck Capacity
                                                                                                    (In Tones)*</label>
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <span
                                                                                                        class="input-group-text">
                                                                                                        <i
                                                                                                            class="fa fa-truck"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input
                                                                                                    #truckCapacityInTones
                                                                                                    (keyup)="fieldFocus($event, newAgentTempoSaveBtn)"
                                                                                                    type="number"
                                                                                                    class="form-control"
                                                                                                    id="{{pageId}}truckCapacityInTones"
                                                                                                    name="truckCapacityInTones"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    [(ngModel)]="truckDataDto.truckCapcityDouble">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <button
                                                                                            style="padding: 1px 4px; float: right;"
                                                                                            type="submit"
                                                                                            class="btn btn-dark"
                                                                                            id="{{pageId}}closePopupBtn"
                                                                                            (click)="d('Cross click');setTempoNext();">Close</button>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <button
                                                                                            style="padding: 1px 4px; float: right;"
                                                                                            type="submit"
                                                                                            class="btn btn-dark"
                                                                                            id="{{pageId}}clearPopupBtn"
                                                                                            (click)="clearAllAgentTempo()">Clear</button>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <button #newAgentTempoSaveBtn
                                                                                            style="padding: 1px 4px; float: right;"
                                                                                            type="submit"
                                                                                            class="btn btn-dark"
                                                                                            id="{{pageId}}newAgentTempoSaveBtn"
                                                                                            (click)="validateAgentTempoDetails()">Save</button>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Company Name</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-home"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #companyName id="{{pageId}}companyName"
                                                                        (keyup)="fieldFocus($event, address)"
                                                                        type="text" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Address</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class=" fas fa-hashtag"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #address (keyup)="fieldFocus($event, city)"
                                                                        type="text" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>City</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class=" fas fa-hashtag"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #city (keyup)="fieldFocus($event, pincode)"
                                                                        type="text" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Pincode</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class=" fas fa-hashtag"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #pincode
                                                                        (keyup)="fieldFocus($event, contactNumber)"
                                                                        type="number" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Contact Number</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class=" fas fa-phone"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #contactNumber
                                                                        (keyup)="fieldFocus($event, driverName)"
                                                                        type="number" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Driver Name</label>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"> <i
                                                                                class="ti-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input #driverName id="{{pageId}}driverName"
                                                                        (keyup)="fieldFocus($event, destination)"
                                                                        type="text" class="form-control"
                                                                        aria-describedby="basic-addon11">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 vl">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="card-title">Consigment Details</h6>

                                            <div class="row">

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>E-Way Bill No.</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-road"></i>
                                                                </span>
                                                            </div>
                                                            <input type="number" aria-describedby=" basic-addon11 "
                                                                autocomplete="off" title="Must Be 12 Number"
                                                                class="form-control" id="{{pageId}}eWayBillNo"
                                                                (keyup)="eWayBillNoFocus($event,contentVehicleUpdate)"
                                                                (blur)="eWayBillNoBlur($event)"
                                                                oninput="javascript: if(this.value.length > this.maxLength) 
                                                                                            this.value=value.slice(0,this.maxLength);" maxLength="12"
                                                                #eWayBillNo>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- CHG-V-LR:02 -->
                                                <!-- <div *ngIf="viewPincode" class="col-sm-12 col-md-12"> -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Enter Pincode</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-clipboard"></i>
                                                                </span>
                                                            </div>
                                                            <input #entryPinCode type="text" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                id="{{pageId}}entryPinCode"
                                                                (keyup)="checkForPinCodeKeyUp($event)">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Destination</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #destination id="{{pageId}}destination"
                                                                    type="text" class="form-control"
                                                                    [ngbTypeahead]="destnationSearchTA"
                                                                    (focus)="focusDestnationTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instanceDestnation)"
                                                                    (keyup)="viewMainCommodity($event)"
                                                                    (selectItem)="clickListnerForDestination($event)"
                                                                    #instanceDestnation="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <!-- <div *ngIf="viewSubstation" class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Substation</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
																			class="ti-home"></i>
																	</span>
                                                                </div>

                                                                <input #substation id="{{pageId}}substation" type="text" class="form-control" [ngbTypeahead]="substationSearchTA" (focus)="focusSubstationTA$.next($any($event).target.value)" (click)="clickTA(instanceSubstation)" #instanceSubstation="ngbTypeahead" (selectItem)="substationEventSelect($event)"
                                                                    (keyup)="substationEvent($event)" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <div *ngIf="viewValidateBkgDate" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" checked class="custom-control-input"
                                                                id="{{pageId}}validateBookingDate"
                                                                (change)="toggleEditableForBookingDate($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}validateBookingDate">Validate
                                                                Booking Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="viewByAutoLr" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Auto LR Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-clipboard"></i>
                                                                </span>
                                                            </div>
                                                            <input #autoLrNumber type="text" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                id="{{pageId}}autoLrNumber" [readonly]="isLRReadOnly">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="!viewByAutoLr" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>LR Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-clipboard"></i>
                                                                </span>
                                                            </div>
                                                            <input *ngIf="showLrCode" #lrCode type="text"
                                                                id="{{pageId}}lrCode" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                [(ngModel)]="showLrCodeInputFields">
                                                            <input #lrNumber type="text" id="{{pageId}}lrNumber"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                (keyup)="checkLrNo($event,lrNumber.value)">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Booking Date</label>

                                                            <div class="input-group-append"
                                                                (click)="bookingDates.toggle()">
                                                                <span class="input-group-text"> <i
                                                                        class="fa fa-calendar"></i>
                                                                </span>
                                                            </div>

                                                            <!-- <input class="form-control" id="{{pageId}}bookingDate" name="bookingDate" ngbDatepicker [(ngModel)]="tdyBookingDate" #bookingDates="ngbDatepicker" (click)="bookingDates.toggle()" (keydown.arrowdown)="bookingDates.toggle();" (ngModelChange)="focusBookingCalendarOnChange('change')"
                                                                (keyup)="fieldFocusBkgDate($event)" [minDate]="minDateBkg" [maxDate]="maxDateBkg"> -->
                                                            <input class="form-control" id="{{pageId}}bookingDate"
                                                                name="bookingDate" ngbDatepicker
                                                                [(ngModel)]="tdyBookingDate"
                                                                #bookingDates="ngbDatepicker"
                                                                (click)="bookingDates.toggle()"
                                                                (keydown.arrowdown)="bookingDates.toggle();"
                                                                (ngModelChange)="focusBookingCalendarOnChange('change')"
                                                                (keyup)="fieldFocusBkgDate($event)">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>No.Of Articles</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-clone"></i>
                                                                </span>
                                                            </div>
                                                            <input #noOfArticles id="{{pageId}}noOfArticles"
                                                                type="number"
                                                                (keyup)="fieldFocusArticles($event,artilesScanPopupOpen)"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <ng-template #artilesScanPopupOpen let-c="close"
                                                                let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Scan Articles
                                                                    </h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label class="wtfull">Scan
                                                                                                Code</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <input type="text"
                                                                                                aria-describedby="basic-addon11"
                                                                                                autocomplete="off"
                                                                                                class="form-control"
                                                                                                id="{{pageId}}lrArtScanCode"
                                                                                                (keyup)="lrArtScanCodeFocus($event)"
                                                                                                #lrArtScanCode>&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="box-body">
                                                                                        <table datatable
                                                                                            id="{{pageId}}lrArtScanPopupTable"
                                                                                            class="table table-striped table-bordered row-border hover"
                                                                                            [dtOptions]="dtOptionsLrArtScan"
                                                                                            [dtTrigger]="dtTriggerLrArtScan">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Scan Number</th>
                                                                                                    <th>Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let lrArtScanTableData of lrArtScanTableDataList let i = index">
                                                                                                    <td>{{
                                                                                                        lrArtScanTableData.lrArtScanCode
                                                                                                        }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                            class="btn m-r-10"
                                                                                                            id="{{pageId}}tableRemoveBtn"
                                                                                                            (click)="rowSelectedDeleteLrArtScan(i)">
                                                                                                            <i title="Remove"
                                                                                                                class="fas fa-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-12"
                                                                                    style="text-align: center;">
                                                                                    <button type="submit"
                                                                                        class="btn btn-success m-r-10"
                                                                                        id="{{pageId}}saveLrArtScanBtn"
                                                                                        (click)="lrArtScanFocus();d('Cross click')">Save</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-success m-r-10"
                                                                                        (click)="lrArtScanFocus();d('Cross click')"
                                                                                        id="{{pageId}}closeLrArtScanBtn">Close</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Pack Nature</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #packNature id="{{pageId}}packNature" type="text"
                                                                    class="form-control"
                                                                    [ngbTypeahead]="packNatureSearchTA"
                                                                    (focus)="focusPackNatureTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instancePackNature)"
                                                                    (keyup)="fieldFocusPackNature($event,content8)"
                                                                    #instancePackNature="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- *ngIf="showMultiPackNature"-->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <!--<label>MultiPack</label>-->
                                                            <ng-template #content8 let-c="close" let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Multiple
                                                                        PackNature Details</h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 col-md-4">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label
                                                                                                class="wtfull">PackNature</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-2">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label
                                                                                                class="wtfull">Articles</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm-12 col-md-4">
                                                                                    <div class="control">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <span
                                                                                                        class="input-group-text">
                                                                                                        <i
                                                                                                            class="fas fa-user"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input
                                                                                                    #multiPackNatureNew
                                                                                                    id="{{pageId}}multiPackNatureNew"
                                                                                                    type="text"
                                                                                                    class="form-control"
                                                                                                    (selectItem)="packNaturesNew()"
                                                                                                    [ngbTypeahead]="packNatureSearchPopupTA"
                                                                                                    (focus)="focusPackNaturePopupTA$.next($any($event).target.value)"
                                                                                                    (click)="clickTA(instancePackNature)"
                                                                                                    (keyup)="fieldFocusPackNatureNew($event)"
                                                                                                    #instancePackNature="ngbTypeahead" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-2">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">

                                                                                            <input type="number"
                                                                                                id="{{pageId}}multiPackNatureArtilces"
                                                                                                name="multiPackNatureArtilces"
                                                                                                class="form-control"
                                                                                                aria-describedby="basic-addon11"
                                                                                                #multiPackNatureArtilces
                                                                                                (keyup)="fieldFocus($event, addBtn)">&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-sm-12 col-md-5"
                                                                                    style='display: none'>
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">

                                                                                            <input type="number"
                                                                                                id="{{pageId}}hiddenIndexPackNature"
                                                                                                class="form-control"
                                                                                                aria-describedby="basic-addon11">&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-1">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <div
                                                                                                class="input-group-prepend">

                                                                                                <a href="javascript:;"
                                                                                                    type='button'
                                                                                                    id="{{pageId}}addInTablePackNature()"
                                                                                                    #addBtn
                                                                                                    class="btn btn-icon-only yellow"
                                                                                                    (click)="addInTablePackNature();">
                                                                                                    <i
                                                                                                        class="fa fa-plus"></i>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="box-body">
                                                                                        <!-- 							the first datatble starts -->
                                                                                        <table datatable
                                                                                            id="{{pageId}}timeTable"
                                                                                            class="table table-striped table-bordered row-border hover"
                                                                                            [dtOptions]="dtOptionsMultiPackTable"
                                                                                            [dtTrigger]="dtTriggerMultiPackTable">

                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>PackNature</th>
                                                                                                    <th>Artilces</th>

                                                                                                    <th>Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let multiplePackNatureTableData of multiPackNatureTableDataList let j = index">
                                                                                                    <td>{{
                                                                                                        multiplePackNatureTableData.multiplePackNatureNew
                                                                                                        }}
                                                                                                    </td>
                                                                                                    <td>{{
                                                                                                        multiplePackNatureTableData.multiplePackNatureArt
                                                                                                        }}
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        <button
                                                                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                            class="btn m-r-10"
                                                                                                            id="{{pageId}}tableEditBtn"
                                                                                                            (click)="rowSelectedEditPackNature(multiplePackNatureTableData,j);">
                                                                                                            <i title="Edit"
                                                                                                                class="fas fa-pencil-alt"></i>
                                                                                                        </button>&nbsp;
                                                                                                        <button
                                                                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                            class="btn m-r-10"
                                                                                                            id="{{pageId}}tableRemoveBtn"
                                                                                                            (click)="rowSelectedDeletePackNature(multiplePackNatureTableData,j)">
                                                                                                            <i title="Remove"
                                                                                                                class="fas fa-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                                                            </tbody>

                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <button type="submit"
                                                                                    class="btn btn-success m-r-10"
                                                                                    id="{{pageId}}saveMultiPackNatureBtn"
                                                                                    (click)="d('Cross click');multiPackNatureFocus()">Save</button>
                                                                                <button type="submit"
                                                                                    class="btn btn-success m-r-10"
                                                                                    (click)="d('Cross click');multiPackNatureFocus()"
                                                                                    id="{{pageId}}closeMultiPackNatureBtn">Close</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h6 class="card-title">Consignor Details</h6>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <select class="custom-select col-12"
                                                                id="{{pageId}}selectType" name="selectType" #selectType
                                                                (change)="selectedType(selectType.value)">
                                                                <option selected value="gst">GST Number</option>
                                                                <option value="pan">PAN Card</option>
                                                                <option value="aadhar">Aadhar Card</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #consignorGSTNumber
                                                                [placeholder]="consignorTextField"
                                                                (keyup)="getConsignorGstNumber($event, openPopupForAadhar)"
                                                                type="text" class="form-control"
                                                                id="{{pageId}}consignorGSTNumber"
                                                                aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <input #consignorName id="{{pageId}}consignorName"
                                                                    type="text" class="form-control"
                                                                    (selectItem)="rowSelectedConsignor($event,content9,contentEinvoice)"
                                                                    [(ngModel)]="modelConsignorName"
                                                                    [ngbTypeahead]="consignorSearchTA"
                                                                    (focus)="focusConsignorTA$.next($any($event).target.value)"
                                                                    [resultFormatter]="formatterConsignorName"
                                                                    [inputFormatter]="formatterConsignorName"
                                                                    (click)="clickTA(instanceConsignorName)"
                                                                    #instanceConsignorName="ngbTypeahead">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #consignorMobNoExist type="text"
                                                                id="{{pageId}}consignorMobNoExist"
                                                                name="consignorMobNoExist" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                placeholder="Consignor Mobile Number">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <textarea class="form-control"
                                                                id="{{pageId}}consignorAddress"
                                                                placeholder="Enter Address" rows="1"
                                                                [(ngModel)]="setConsignorAddress"></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <!-- 														<label>New Consignor</label> -->
                                                            <ng-template #content9 let-c="close" let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Consignor
                                                                        Details
                                                                    </h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div *ngIf="showSpinnerForPopup"
                                                                    class="col-md-12 p-t-10">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <mat-progress-bar mode="indeterminate"
                                                                                style="color: green;"></mat-progress-bar>
                                                                            <br>
                                                                            <h6 class="card-title" align='center'
                                                                                style="color: green; margin: auto; font-size: 18px;">
                                                                                Please Wait getting PAN Details.....
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Add New Consignor</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-file"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            id="{{pageId}}newConsignor"
                                                                                            (keyup)="fieldFocus($event, gstNoConsignor)"
                                                                                            [(ngModel)]="newConsignorName">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>{{consignorPopupLabel}}</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #gstNoConsignor
                                                                                            type="text"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            id="{{pageId}}gstNoConsignor"
                                                                                            (keyup)="fieldFocus($event, newConsignorAddress)"
                                                                                            [(ngModel)]="gstNoConsignorModel">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Address</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #newConsignorAddress
                                                                                            type="text"
                                                                                            id="{{pageId}}newConsignorAddress"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            (keyup)="fieldFocus($event, consignorState)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>State</label>
                                                                                            <div
                                                                                                class="input-group-prepend">
                                                                                                <span
                                                                                                    class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-map-marker-alt"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input #consignorState
                                                                                                class="auto_selectOption input is-medium"
                                                                                                id="{{pageId}}consignorState"
                                                                                                placeholder="Select State"
                                                                                                #consignorState
                                                                                                [formControl]="controlConsignorState"
                                                                                                [appAutocomplete]="autocompleteConsignorState"
                                                                                                (keyup)="fieldFocus($event, consignorMobileNumber)">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <app-autocomplete
                                                                                    #autocompleteConsignorState="appAutocomplete">
                                                                                    <ng-template appAutocompleteContent>
                                                                                        <ng-container
                                                                                            *ngIf="(consignorStateOptions | filterState: controlConsignorState.value) as resultConsignorState">
                                                                                            <app-option
                                                                                                *ngFor="let option of resultConsignorState"
                                                                                                (click)="stateSelectedConsignor(option)"
                                                                                                [value]="option.state">
                                                                                                {{ option.state }}
                                                                                            </app-option>
                                                                                            <app-option
                                                                                                class="no-result"
                                                                                                *ngIf="!resultConsignorState.length">
                                                                                                No result </app-option>
                                                                                        </ng-container>
                                                                                    </ng-template>
                                                                                </app-autocomplete>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Mobile Number</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #consignorMobileNumber
                                                                                            type="number"
                                                                                            id="{{pageId}}consignorMobileNumber"
                                                                                            name="consignorMobileNumber"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            (keyup)="fieldFocus($event, consignorOfficeNumber)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Office Number</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #consignorOfficeNumber
                                                                                            type="number"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            id="{{pageId}}consignorOfficeNumber"
                                                                                            (keyup)="fieldFocus($event, newConsignorSaveBtn)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Enable e-Invoice</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="  fas fa-truck"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <select
                                                                                            class="custom-select col-12"
                                                                                            id="{{pageId}}consignorEInvoiceEnable"
                                                                                            name="consignorEInvoiceEnable"
                                                                                            #consignorEInvoiceEnable
                                                                                            (change)="consignorEInvoiceMode(consignorEInvoiceEnable.value);">
                                                                                            <option selected
                                                                                                value="Select">Select
                                                                                                Mode</option>
                                                                                            <option value="Yes">Yes
                                                                                            </option>
                                                                                            <option value="No">No
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}closePopupBtn"
                                                                                    (click)="closeNewConsignor();d('Cross click')">Close</button>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}clearPopupBtn"
                                                                                    (click)="ValidateClearNewConsignorDetails()">Clear</button>
                                                                            </div>
                                                                            <div #newConsignorSaveBtn
                                                                                class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}newConsignorSaveBtn"
                                                                                    (click)="validateNewConsignor()">Save</button>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- get einvoice enable option @dated:08042022 -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <ng-template #contentEinvoice let-c="close" let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Consignor
                                                                        e-Invoice Mode
                                                                    </h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Enable e-Invoice</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="  fas fa-truck"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <select
                                                                                            class="custom-select col-12"
                                                                                            id="{{pageId}}consignorEInvoiceEnable"
                                                                                            name="consignorEInvoiceEnable"
                                                                                            #consignorEInvoiceEnable
                                                                                            (change)="consignorEInvoiceMode(consignorEInvoiceEnable.value);">
                                                                                            <option selected
                                                                                                value="Select">Select
                                                                                                Mode</option>
                                                                                            <option value="Yes">Yes
                                                                                            </option>
                                                                                            <option value="No">No
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div #consignorEInvoiceSaveBtn
                                                                                class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}consignorEInvoiceSaveBtn"
                                                                                    (click)="validateConsignorEInvoiceSave()">Update</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--  -->


                                                <h6 class="card-title">Consignee Details</h6>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                                name="searchBy" #searchBy
                                                                (change)="searchByMode(searchBy.value)">
                                                                <option selected value="gst">GST Number</option>
                                                                <option value="pan">PAN Card</option>
                                                                <option value="aadhar">Aadhar Card</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #consigneeGSTNumber
                                                                (keyup)="getConsigneeGstNumber($event, openPopupForAadhar)"
                                                                type="text" id="{{pageId}}consigneeGSTNumber"
                                                                class="form-control" [placeholder]="text"
                                                                aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <input #consigneeName id="{{pageId}}consigneeName"
                                                                    type="text" class="form-control"
                                                                    (selectItem)="rowSelectedConsignee($event,content10)"
                                                                    [(ngModel)]="modelConsigneeName"
                                                                    [ngbTypeahead]="consigneeNameSearchTA"
                                                                    (focus)="focusConsigneeNameTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instanceConsigneeName)"
                                                                    [resultFormatter]="formatterConsigneeName"
                                                                    [inputFormatter]="formatterConsigneeName"
                                                                    #instanceConsigneeName="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input #consigneeMobNoExist type="text"
                                                                id="{{pageId}}consigneeMobNoExist"
                                                                name="consigneeMobNoExist" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                placeholder="Consignee Mobile Number">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <textarea class="form-control"
                                                                id="{{pageId}}consigneeAddress"
                                                                placeholder="Enter Address" rows="1"
                                                                [(ngModel)]="setConsigneeAddress"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label></label>
                                                            <button type="submit" style="padding: 1px; font-size: 12px"
                                                                class="btn btn-success m-r-10"
                                                                id="{{pageId}}addressChange"
                                                                (click)="changeAddress()">Change</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <ng-template #openPopupForAadhar let-c="close"
                                                                let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Please enter
                                                                        the Aadhar OTP
                                                                    </h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>OTP :</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class=" fas fa-key"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input
                                                                                            class="form-control col-12"
                                                                                            #otpId id="{{pageId}}otpId"
                                                                                            type="text"
                                                                                            placeholder="Enter OTP Number" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div #consignorEInvoiceSaveBtn
                                                                                class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}aadharProceedBtnId"
                                                                                    (click)="getDetailsFromAadharApiMethod()">Proceed</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}popupDetails">
                                                            <!-- 														<label>New Consignee</label> -->
                                                            <ng-template #content10 let-c="close" let-d="dismiss">
                                                                <div class="modal-header">
                                                                    <h6 class="modal-title"
                                                                        id="{{pageId}}modal-basic-title">Consignee
                                                                        Details
                                                                    </h6>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close" (click)="d('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div *ngIf="showSpinnerForPopup"
                                                                    class="col-md-12 p-t-10">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <mat-progress-bar mode="indeterminate"
                                                                                style="color: green;"></mat-progress-bar>
                                                                            <br>
                                                                            <h6 class="card-title" align='center'
                                                                                style="color: green; margin: auto; font-size: 18px;">
                                                                                Please Wait getting PAN Details.....
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Add New Consignee</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-file"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #newConsignee type="text"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            id="{{pageId}}newConsignee"
                                                                                            (keyup)="fieldFocus($event, gstNoConsignee)"
                                                                                            [(ngModel)]="newConsigneeName">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>{{consigneePopupLabel}}</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #gstNoConsignee
                                                                                            type="text"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            id="lrefcgstNoConsignee"
                                                                                            (keyup)="fieldFocus($event, newConsigneeAddress)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Address</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #newConsigneeAddress
                                                                                            type="text"
                                                                                            id="{{pageId}}newConsigneeAddress"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            (keyup)="fieldFocus($event, consigneeState)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>City</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #consigneeCity
                                                                                            type="text"
                                                                                            id="lrefcconsigneeCity"
                                                                                            name="consigneeCity"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            readonly>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>State</label>
                                                                                            <div
                                                                                                class="input-group-prepend">
                                                                                                <span
                                                                                                    class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-map-marker-alt"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input #consigneeState
                                                                                                class="auto_selectOption input is-medium"
                                                                                                id="{{pageId}}consigneeState"
                                                                                                placeholder="Select State"
                                                                                                #consigneeState
                                                                                                [formControl]="controlConsigneeState"
                                                                                                [appAutocomplete]="autocompleteConsigneeState">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <app-autocomplete
                                                                                    #autocompleteConsigneeState="appAutocomplete">
                                                                                    <ng-template appAutocompleteContent>
                                                                                        <ng-container
                                                                                            *ngIf="(consigneeStateOptions | filterState: controlConsigneeState.value) as resultConsigneeState">
                                                                                            <app-option
                                                                                                *ngFor="let option of resultConsigneeState"
                                                                                                (click)="stateSelectedConsignee(option)"
                                                                                                [value]="option.state">
                                                                                                {{ option.state }}
                                                                                            </app-option>
                                                                                            <app-option
                                                                                                class="no-result"
                                                                                                *ngIf="!resultConsigneeState.length">
                                                                                                No result </app-option>
                                                                                        </ng-container>
                                                                                    </ng-template>
                                                                                </app-autocomplete>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Mobile Number</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fa fa-calendar"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #consigneeMobileNumber
                                                                                            type="number"
                                                                                            id="{{pageId}}consigneeMobileNumber"
                                                                                            name="consigneeMobileNumber"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11"
                                                                                            (keyup)="fieldFocus($event, newConsigneeSaveBtn)">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}closePopupBtn"
                                                                                    (click)="closeNewConsignee();d('Cross click')">Close</button>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}clearPopupBtn"
                                                                                    (click)="ValidateClearNewConsigneeDetails()">Clear</button>
                                                                            </div>
                                                                            <div #newConsigneeSaveBtn
                                                                                class="col-sm-12 col-md-12">
                                                                                <button
                                                                                    style="padding: 1px 4px; float: right;"
                                                                                    type="submit" class="btn btn-dark"
                                                                                    id="{{pageId}}newConsigneeSaveBtn"
                                                                                    (click)="validateNewConsignee()">Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <!-- 														<a style="cursor: pointer; color: blue;" -->
                                                            <!-- 															(click)="open10(content10);setValueForConsignee()"><u> -->
                                                            <!-- 																Add Consignee</u></a> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <!-- <label *ngIf="!showMainCommodity">Party Goods
																	Type</label>  -->
                                                                <label>Rate Commodity</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #partyGoodsType id="{{pageId}}partyCommodity"
                                                                    type="text" class="form-control"
                                                                    [ngbTypeahead]="partyCommoditySearchTA"
                                                                    (focus)="focusPartyCommodityTA$.next($any($event).target.value)"
                                                                    (keyup)="partyCommodityFocus($event)"
                                                                    (click)="clickTA(instancePartyCommodity)"
                                                                    #instancePartyCommodity="ngbTypeahead"
                                                                    (change)="clickListnerForRateCommodity($event);" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Actual Commodity</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #mainCommodity id="{{pageId}}mainCommodity"
                                                                    type="text" class="form-control"
                                                                    [ngbTypeahead]="mainCommoditySearchTA"
                                                                    (focus)="focusMainCommodityTA$.next($any($event).target.value)"
                                                                    (keyup)="mainCommodityFocus($event)"
                                                                    (click)="clickTA(instanceMainCommodity)"
                                                                    #instanceMainCommodity="ngbTypeahead"
                                                                    (change)="clickListnerForActualCommodity($event);" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="showSubCommodity" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Commodity Description</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                            <input #subCommodity id="{{pageId}}subCommodity"
                                                                (keyup)="fieldFocus($event, actualWeight)" type="text"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="showGoodsType" class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group"
                                                                id="{{pageId}}scrollable-dropdown-menu">
                                                                <label>Goods Type</label>

                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>

                                                                <input #goodsType id="{{pageId}}goodsType" type="text"
                                                                    class="form-control"
                                                                    [ngbTypeahead]="goodsTypeSearchTA"
                                                                    (focus)="focusGoodsTypeTA$.next($any($event).target.value)"
                                                                    (click)="clickTA(instanceGoodsType)"
                                                                    (keyup)="rowSelectedGooodsType($event)"
                                                                    #instanceGoodsType="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div *ngIf="showNewCommodity" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label class="wt80">New Commodity</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-hashtag"></i>
                                                                </span>
                                                            </div>
                                                            <!-- 															<textarea class="form-control" id="{{pageId}}newCommodity" rows="1"></textarea> -->
                                                            <input #newCommodity id="{{pageId}}newCommodity" type="text"
                                                                (keyup)="newCommodityEvent($event, actualWeight)"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 vl">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-5 col-md-5 ">
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Actual Weight</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-weight"></i>
                                                                    </span>
                                                                </div>
                                                                <input #actualWeight id="{{pageId}}actualWeight"
                                                                    (keyup)="actualWeightEvent($event)"
                                                                    (onblur)="actualWeightBlur()" type="number"
                                                                    class="form-control"
                                                                    aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-sm-12 col-md-12">
													<div class="form-group">
														<div class="input-group">
															<label>Charged Weight</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-weight"></i>
																</span>
															</div>
															<input #chargedWeight id="{{pageId}}chargedWeight"
																(keyup)="chargedWeightEvent($event)"
																(onblur)="chargedWeightBlur()" type="number"
																class="form-control" aria-describedby="basic-addon11">
														</div>
													</div>
												</div>-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Charged Weight</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-weight"></i>
                                                                    </span>
                                                                </div>
                                                                <input *ngIf="!lrArtScanAllow" #chargedWeight
                                                                    id="{{pageId}}chargedWeight"
                                                                    (keyup)="chargedWeightEvent($event, content7)"
                                                                    (blur)="chargedWeightBlur(content7)" type="number"
                                                                    class="form-control"
                                                                    aria-describedby="basic-addon11"
                                                                    [readonly]="chgWgtReadOnly">

                                                                <input *ngIf="lrArtScanAllow" #chargedWeight
                                                                    id="{{pageId}}chargedWeight"
                                                                    (keyup)="chargedWeightEvent($event, content7WithScan)"
                                                                    (blur)="chargedWeightBlur(content7WithScan)"
                                                                    type="number" class="form-control"
                                                                    aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--*ngIf="showMultiArt"-->
                                                    <!--with scan code-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group" id="{{pageId}}popupDetails">
                                                                <!--<label>Multiple Art</label>-->
                                                                <ng-template #content7WithScan let-c="close"
                                                                    let-d="dismiss">
                                                                    <div class="modal-header">
                                                                        <h6 class="modal-title"
                                                                            id="{{pageId}}modal-basic-title">Multiple
                                                                            Articles Details</h6>
                                                                        <button type="button" class="close"
                                                                            aria-label="Close"
                                                                            (click)="d('Cross click')">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}scanCodeLbl">
                                                                                                <label
                                                                                                    class="wtfull">Scan
                                                                                                    Code</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}artLbl">
                                                                                                <label
                                                                                                    class="wtfull">Article</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}lengthLbl">
                                                                                                <label
                                                                                                    class="wtfull">Length</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}breadthLbl">
                                                                                                <label
                                                                                                    class="wtfull">Breadth</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}heightLbl">
                                                                                                <label
                                                                                                    class="wtfull">Height</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}actWgtLbl">
                                                                                                <label
                                                                                                    class="wtfull">Act.Wgt</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}chgWgtLbl">
                                                                                                <label
                                                                                                    class="wtfull">Chg.Wgt</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiScanCode"
                                                                                                    name="multiScanCode"
                                                                                                    class="form-control"
                                                                                                    #multiScanCode
                                                                                                    (keyup)="fieldFocus($event, multiArtarticle)"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtarticle"
                                                                                                    name="multiArtarticle"
                                                                                                    class="form-control"
                                                                                                    #multiArtarticle
                                                                                                    (keyup)="fieldFocus($event, multiArtlength)"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtlength"
                                                                                                    name="multiArtlength"
                                                                                                    class="form-control"
                                                                                                    #multiArtlength
                                                                                                    (keyup)="fieldFocus($event, multiArtbreadth)"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtbreadth"
                                                                                                    name="multiArtbreadth"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtbreadth
                                                                                                    (keyup)="fieldFocus($event, multiArtheight)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtheight"
                                                                                                    name="multiArtheight"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtheight
                                                                                                    (keyup)="fieldFocus($event, multiArtActWgt)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtActWgt"
                                                                                                    name="multiArtActWgt"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtActWgt
                                                                                                    (keyup)="fieldFocus($event, addBtn)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtChgWgt"
                                                                                                    name="multiArtChgWgt"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtChgWgt
                                                                                                    (keyup)="fieldFocus($event, addBtn)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-5"
                                                                                        style='display: none'>
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}hiddenIndex"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-1">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <div
                                                                                                    class="input-group-prepend">
                                                                                                    <a href="javascript:;"
                                                                                                        type='button'
                                                                                                        id="{{pageId}}addInTableWithScan()"
                                                                                                        #addBtn
                                                                                                        class="btn btn-icon-only yellow"
                                                                                                        (click)="addInTableWithScan();">
                                                                                                        <i
                                                                                                            class="fa fa-plus"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <div class="box-body">
                                                                                            <!-- 							the first datatble starts -->
                                                                                            <!-- timeTable-->
                                                                                            <table datatable
                                                                                                id="{{pageId}}multipleArticleTable"
                                                                                                class="table table-striped table-bordered row-border hover"
                                                                                                [dtOptions]="dtOptionsMultiArtTableScan"
                                                                                                [dtTrigger]="dtTriggerMultiArtTableScan">

                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Scan Code
                                                                                                        </th>
                                                                                                        <th>Article</th>
                                                                                                        <th>Length</th>
                                                                                                        <th>Breadth</th>
                                                                                                        <th>Height</th>
                                                                                                        <th>Act Wgt</th>
                                                                                                        <th>Chg Wgt</th>
                                                                                                        <th>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr
                                                                                                        *ngFor="let multipleArtTableData of multiArtTableDataList let i = index">
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.barCodeValue
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.articlesInMultiple
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.length
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.breadth
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.height
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.actualWeight
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.chargedWeight
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                                class="btn m-r-10"
                                                                                                                id="{{pageId}}tableEditBtn"
                                                                                                                (click)="rowSelectedEditWithScan(multipleArtTableData,i);">
                                                                                                                <i title="Edit"
                                                                                                                    class="fas fa-pencil-alt"></i>
                                                                                                            </button>&nbsp;
                                                                                                            <button
                                                                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                                class="btn m-r-10"
                                                                                                                id="{{pageId}}tableRemoveBtn"
                                                                                                                (click)="rowSelectedDeleteWithScan(multipleArtTableData,i)">
                                                                                                                <i title="Remove"
                                                                                                                    class="fas fa-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                                                                </tbody>
                                                                                                <tfoot>
                                                                                                    <tr>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                </tfoot>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-12"
                                                                                        style="text-align: center;">
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            id="{{pageId}}saveMultiArtBtn"
                                                                                            (click)="multiArtFocus();">Save</button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            (click)="multiArtPopupClose();"
                                                                                            id="{{pageId}}closeMultiArtBtn">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!--without scan code-->
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group" id="{{pageId}}popupDetails">
                                                                <!--<label>Multiple Art</label>-->
                                                                <ng-template #content7 let-c="close" let-d="dismiss">
                                                                    <div class="modal-header">
                                                                        <h6 class="modal-title"
                                                                            id="{{pageId}}modal-basic-title">Multiple
                                                                            Article Details</h6>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-4">
                                                                                <div class="form-group">
                                                                                    <div
                                                                                        class="custom-control custom-checkbox">
                                                                                        <input type="checkbox"
                                                                                            [checked]="viewCftFields"
                                                                                            class="custom-control-input"
                                                                                            id="{{pageId}}enableAutoCft"
                                                                                            (change)="toggleEditableForAutoCft($event)">
                                                                                        <label
                                                                                            class=" custom-control-label wtfull "
                                                                                            for="{{pageId}}enableAutoCft">Enable
                                                                                            Auto CFT</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-sm-12 col-md-4">
                                                                                <div class="form-group">
                                                                                    <div
                                                                                        class="custom-control custom-checkbox">
                                                                                        <input type="checkbox"
                                                                                            [checked]="viewCustomCftFields"
                                                                                            class="custom-control-input"
                                                                                            id="{{pageId}}enableCustomCft"
                                                                                            (change)="toggleEditableForCustomCft($event)">
                                                                                        <label
                                                                                            class=" custom-control-label wtfull "
                                                                                            for="{{pageId}}enableCustomCft">Custom
                                                                                            CFT Value</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf="viewCustomCftFields"
                                                                                class="col-sm-12 col-md-4">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>CFT Value</label>
                                                                                        <div
                                                                                            class="input-group-prepend">
                                                                                            <span
                                                                                                class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-weight"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input #customCftValue
                                                                                            id="{{pageId}}customCftValue"
                                                                                            type="number"
                                                                                            class="form-control"
                                                                                            aria-describedby="basic-addon11">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-1">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}artLbl">
                                                                                                <label
                                                                                                    class="wtfull">Art</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}lengthLbl">
                                                                                                <label
                                                                                                    class="wtfull">Length</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}breadthLbl">
                                                                                                <label
                                                                                                    class="wtfull">Breadth</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}heightLbl">
                                                                                                <label
                                                                                                    class="wtfull">Height</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}actWgtLbl">
                                                                                                <label
                                                                                                    class="wtfull">Act.Wgt</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}chgWgtLbl">
                                                                                                <label
                                                                                                    class="wtfull">Chg.Wgt</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-1">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtarticle"
                                                                                                    name="multiArtarticle"
                                                                                                    class="form-control"
                                                                                                    #multiArtarticle
                                                                                                    (keyup)="fieldFocusMultiArt($event)"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtlength"
                                                                                                    name="multiArtlength"
                                                                                                    class="form-control"
                                                                                                    #multiArtlength
                                                                                                    (keyup)="fieldFocusMultiLgt($event)"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtbreadth"
                                                                                                    name="multiArtbreadth"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtbreadth
                                                                                                    (keyup)="fieldFocusMultiBdt($event)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="viewCftFields"
                                                                                        class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtheight"
                                                                                                    name="multiArtheight"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtheight
                                                                                                    (keyup)="fieldFocusMultiHgt($event)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtActWgt"
                                                                                                    name="multiArtActWgt"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtActWgt
                                                                                                    (keyup)="fieldFocusMultiActWgt($event)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input type="number"
                                                                                                    id="{{pageId}}multiArtChgWgt"
                                                                                                    name="multiArtChgWgt"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11"
                                                                                                    #multiArtChgWgt
                                                                                                    (keyup)="fieldFocus($event, addBtn)">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-5"
                                                                                        style='display: none'>
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">

                                                                                                <input type="number"
                                                                                                    id="{{pageId}}hiddenIndex"
                                                                                                    class="form-control"
                                                                                                    aria-describedby="basic-addon11">&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-1">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <div
                                                                                                    class="input-group-prepend">

                                                                                                    <a href="javascript:;"
                                                                                                        type='button'
                                                                                                        id="{{pageId}}addInTable()"
                                                                                                        #addBtn
                                                                                                        class="btn btn-icon-only yellow"
                                                                                                        (click)="addInTable();">
                                                                                                        <i
                                                                                                            class="fa fa-plus"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <div class="box-body">
                                                                                            <!-- 							the first datatble starts -->
                                                                                            <!-- timeTable-->
                                                                                            <table datatable
                                                                                                id="{{pageId}}multipleArticleTable"
                                                                                                class="table table-striped table-bordered row-border hover"
                                                                                                [dtOptions]="dtOptionsMultiArtTable"
                                                                                                [dtTrigger]="dtTriggerMultiArtTable">

                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Article</th>
                                                                                                        <th>Length</th>
                                                                                                        <th>Breadth</th>
                                                                                                        <th>Height</th>
                                                                                                        <th>Act Wgt</th>
                                                                                                        <th>Chg Wgt</th>
                                                                                                        <th>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr
                                                                                                        *ngFor="let multipleArtTableData of multiArtTableDataList let i = index">
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.articlesInMultiple
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.length
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.breadth
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.height
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.actualWeight
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multipleArtTableData.chargedWeight
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <!-- <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEdit(multipleArtTableData,i);">
																												<i title="Edit"
																													class="fas fa-pencil-alt"></i>
																											</button>&nbsp; -->
                                                                                                            <button
                                                                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                                class="btn m-r-10"
                                                                                                                id="{{pageId}}tableRemoveBtn"
                                                                                                                (click)="rowSelectedDelete(multipleArtTableData,i)">
                                                                                                                <i title="Remove"
                                                                                                                    class="fas fa-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                                                                </tbody>
                                                                                                <tfoot>
                                                                                                    <tr>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                </tfoot>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- 																			<div class="col-md-12"> -->
                                                                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                                                    <!-- 																					id="{{pageId}}saveMultiArtBtn" -->
                                                                                    <!-- 																					(click)="onCancelClick();d('Cross click')">Save</button> -->
                                                                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                                                    <!-- 																					(click)="onCancelClick()" id="{{pageId}}closeMultiArtBtn">close</button> -->
                                                                                    <!-- 																			</div> -->
                                                                                    <div class="col-md-12">
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            id="{{pageId}}saveMultiArtBtn"
                                                                                            (click)="multiArtFocus();">Save</button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            (click)="multiArtPopupClose($event);"
                                                                                            id="{{pageId}}closeMultiArtBtn">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Goods Value</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-rupee-sign"></i>
                                                                    </span>
                                                                </div>
                                                                <input #goodsValue id="{{pageId}}goodsValue"
                                                                    (keyup)="goodsValueEvent($event)"
                                                                    (blur)="goodsValueBlur($event)" type="number"
                                                                    class="form-control"
                                                                    aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Source</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-box"></i>
                                                                    </span>
                                                                </div>
                                                                <input #source id="{{pageId}}source"
                                                                    (keyup)="fieldFocus($event)" type="text"
                                                                    class="form-control"
                                                                    aria-describedby="basic-addon11" readonly
                                                                    [(ngModel)]="getOffice">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>E-Way Bill Exempted</label>

                                                                <div class="custom-control custom-checkbox">
                                                                    <input type="checkbox" class="custom-control-input"
                                                                        id="{{pageId}}checkboxEwayBillExempted"
                                                                        #checkboxEwayBillExempted
                                                                        (keyup)="fieldFocus($event, eWayBillValidUptoDate)"
                                                                        (change)="toggleEditableForEWayBillExempted($event)">
                                                                    <label class=" custom-control-label wtfull"
                                                                        style="padding-top: 15px;"
                                                                        for="{{pageId}}checkboxEwayBillExempted"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>E-Way Bill Valid Upto</label>
                                                                <!-- <div class="input-group-append" (click)="eWayBillValidUptoDate.toggle()"> -->
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text"> <i
                                                                            class="fa fa-calendar"></i>
                                                                    </span>
                                                                </div>
                                                                <!-- (blur)="eWayBillValidUptoDateBlur(multipleEwayBillPopupOpen)" -->
                                                                <input class="form-control"
                                                                    id="{{pageId}}eWayBillValidUptoDate"
                                                                    name="eWayBillValidUptoDate"
                                                                    [(ngModel)]="tdyEWayBillValidUptoDate"
                                                                    (keyup)="eWayBillValidUptoDateFocus($event, multipleEwayBillPopupOpen)"
                                                                    ngbDatepicker placeholder="dd-mm-yyyy"
                                                                    #eWayBillValidUptoDate="ngbDatepicker"
                                                                    (ngModelChange)="focusEWayBillValidUptoCalendar(multipleEwayBillPopupOpen)"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group" id="{{pageId}}popupDetails">
                                                                <!--<label>Multiple Art</label>-->
                                                                <ng-template #multipleEwayBillPopupOpen let-c="close"
                                                                    let-d="dismiss">
                                                                    <div class="modal-header">
                                                                        <h6 class="modal-title"
                                                                            id="{{pageId}}modal-basic-title">Multiple
                                                                            E-way Details</h6>
                                                                        <button type="button" class="close"
                                                                            aria-label="Close"
                                                                            (click)="d('Cross click')">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-3">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}eWallBillLbl">
                                                                                                <label
                                                                                                    class="wtfull">E-Way
                                                                                                    Bill No.</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-3">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group"
                                                                                                id="{{pageId}}eWayDateLbl">
                                                                                                <label
                                                                                                    class="wtfull">E-Way
                                                                                                    Bill Valid
                                                                                                    Upto</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 col-md-3">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <!-- <input type="number"
																									aria-describedby=" basic-addon11 "
																									autocomplete="off"
																									class="form-control"
																									id="{{pageId}}eWayBillNoMulti"
																									(keyup)="eWayBillNoMultiFocus($event)"
																									#eWayBillNoMulti>&nbsp; -->
                                                                                                <input type="number"
                                                                                                    aria-describedby=" basic-addon11 "
                                                                                                    autocomplete="off"
                                                                                                    title="Must Be 12 Number"
                                                                                                    class="form-control"
                                                                                                    id="{{pageId}}eWayBillNoMulti"
                                                                                                    (keyup)="eWayBillNoMultiFocus($event)"
                                                                                                    oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="12" #eWayBillNoMulti>&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-12 col-md-3">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <input
                                                                                                    class="form-control"
                                                                                                    id="{{pageId}}eWayBillValidUptoDateMulti"
                                                                                                    name="eWayBillValidUptoDateMulti"
                                                                                                    (keyup)="eWayBillValidUptoDateFocusMulti($event)"
                                                                                                    ngbDatepicker
                                                                                                    [(ngModel)]="eWayBillValidUptoDateMultiModel"
                                                                                                    placeholder="dd-mm-yyyy"
                                                                                                    #eWayBillValidUptoDateMulti="ngbDatepicker"
                                                                                                    (ngModelChange)="focusEWayBillValidUptoCalendarMulti()"
                                                                                                    (keydown.arrowdown)="eWayBillValidUptoDateMulti.toggle();"
                                                                                                    (click)="eWayBillValidUptoDateMulti.toggle();"
                                                                                                    disabled>&nbsp;
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>



                                                                                    <div class="col-sm-12 col-md-1">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <div
                                                                                                    class="input-group-prepend">

                                                                                                    <a href="javascript:;"
                                                                                                        type='button'
                                                                                                        id="{{pageId}}addInTableMultiEwayBtn"
                                                                                                        #addInTableMultiEwayBtn
                                                                                                        class="btn btn-icon-only yellow"
                                                                                                        (click)="chkDuplicateMultiEwayBillNo();">
                                                                                                        <i
                                                                                                            class="fa fa-plus"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-md-12">
                                                                                        <div class="box-body">
                                                                                            <!-- 							the first datatble starts -->
                                                                                            <!-- timeTable-->
                                                                                            <table datatable
                                                                                                id="{{pageId}}multipleEwayPopupTable"
                                                                                                class="table table-striped table-bordered row-border hover"
                                                                                                [dtOptions]="dtOptionsMultiEway"
                                                                                                [dtTrigger]="dtTriggerMultEway">

                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>E-way Bill
                                                                                                            No
                                                                                                        </th>
                                                                                                        <th>E-Way Bill
                                                                                                            Valid Upto
                                                                                                        </th>
                                                                                                        <th>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr
                                                                                                        *ngFor="let multiEwayTableData of multiEwayTableDataList let i = index">
                                                                                                        <td>{{
                                                                                                            multiEwayTableData.eWayBillNo
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>{{
                                                                                                            multiEwayTableData.ewayBillValidUpto
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                                class="btn m-r-10"
                                                                                                                id="{{pageId}}tableEditBtn"
                                                                                                                (click)="rowSelectedEditMultiEway(multiEwayTableData,i);">
                                                                                                                <i title="Edit"
                                                                                                                    class="fas fa-pencil-alt"></i>
                                                                                                            </button>&nbsp;
                                                                                                            <button
                                                                                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                                                class="btn m-r-10"
                                                                                                                id="{{pageId}}tableRemoveBtn"
                                                                                                                (click)="rowSelectedDeleteMultiEway(multiEwayTableData,i)">
                                                                                                                <i title="Remove"
                                                                                                                    class="fas fa-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                                                                </tbody>
                                                                                                <!-- 																					<tfoot> -->
                                                                                                <!-- 																						<tr> -->
                                                                                                <!-- 																							<td style="text-align: left" id="{{pageId}}totalMultiArticles"></td> -->
                                                                                                <!-- 																							<td></td> -->
                                                                                                <!-- 																							<td></td> -->
                                                                                                <!-- 																							<td></td> -->
                                                                                                <!-- 																							<td></td> -->
                                                                                                <!-- 																						</tr> -->
                                                                                                <!-- 																					</tfoot> -->
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- 																			<div class="col-md-12"> -->
                                                                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                                                    <!-- 																					id="{{pageId}}saveMultiArtBtn" -->
                                                                                    <!-- 																					(click)="onCancelClick();d('Cross click')">Save</button> -->
                                                                                    <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                                                    <!-- 																					(click)="onCancelClick()" id="{{pageId}}closeMultiArtBtn">close</button> -->
                                                                                    <!-- 																			</div> -->
                                                                                    <div class="col-md-12">
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            id="{{pageId}}saveMultiArtBtn"
                                                                                            (click)="multiEwayFocus();d('Cross click')">Save</button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-success m-r-10"
                                                                                            (click)="multiEwayFocus();d('Cross click')"
                                                                                            id="{{pageId}}closeMultiArtBtn">Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                                <!--<a style="cursor: pointer; color: blue;"
																(click)="open7(multipleEwayBillPopupOpen)"><u> View Details</u></a>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="showEDeclarationId" class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>E-Declaration Commodity</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-road"></i>
                                                                    </span>
                                                                </div>
                                                                <input #edeclarationCommodity
                                                                    id="{{pageId}}edeclarationCommodity" type="text"
                                                                    (keyup)="fieldFocus($event, baseFreight)"
                                                                    class="form-control"
                                                                    aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="consignorEInvoiceNoEnabled" class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>e-Invoice ACK No</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-road"></i>
                                                                    </span>
                                                                </div>
                                                                <input #eInvNoConsignor type="number"
                                                                    aria-describedby="basic-addon11" autocomplete="off"
                                                                    class="form-control" id="{{pageId}}eInvNoConsignor"
                                                                    (keyup)="eInvNoConsignorEvent($event);">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-7 col-md-7 vl">
                                                    <div *ngIf="consignorRateFlowSet" class="row">
                                                        <h6 class="card-title">Consignor / Commodity Rate Details</h6>
                                                        <div class="col-lg-7 col-md-7 vl">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Source: </label>
                                                                    <label>{{consignorRateSrc}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Destination: </label>
                                                                    <label>{{consignorRateDest}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Rate For: </label>
                                                                    <label>{{consignorRateFor}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Commodity: </label>
                                                                    <label>{{consignorRateCommodity}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Consignor: </label>
                                                                    <label>{{consignorRateConsignor}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Consignee: </label>
                                                                    <label>{{consignorRateConsignee}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Rate Type: </label>
                                                                    <label>{{consignorRateType}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Type: </label>
                                                                    <label>{{consignorRatePerUnit}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-5 col-md-5 vl">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Rate On AW: </label>
                                                                    <label>{{consignorRatePerUnitRateOnActWgt}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>B. Rate: </label>
                                                                    <label>{{consignorRateBasicRate}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Hamali: </label>
                                                                    <label>{{consignorRateHamali}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>GC Chg: </label>
                                                                    <label>{{consignorRateGcChg}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>AOC: </label>
                                                                    <label>{{consignorRateAoc}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>Others: </label>
                                                                    <label>{{consignorRateOthers}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>DD Chg: </label>
                                                                    <label>{{consignorRateDDChg}}</label>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Effect From : </label>
                                                                <label>{{consignorRateWEF}}</label>
                                                            </div>
                                                        </div> -->
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label>FOV (%): </label>
                                                                    <label>{{consignorRateFov}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style="width: 100%; margin-right: 10px;">
                                <div class="col-lg-4 col-md-4 vl">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="card-title">Freight Details</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>B.Freight</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #baseFreight id="{{pageId}}baseFreight"
                                                                (keyup)="baseFreightEvent($event)"
                                                                (blur)="baseFreightBlur( $event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [readonly]="isFrieghtFieldsReadOnly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Hamali</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #hamali id="{{pageId}}hamali"
                                                                (keyup)="hamaliEvent($event)"
                                                                (blur)="hamaliBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [readonly]="isFrieghtFieldsReadOnly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" style="margin-right: 160px;">
                                                            <mat-spinner></mat-spinner>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <h6 class="card-title" style="color: blue;">Saving Lr
                                                                details......
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>GC.Chg</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #gcCharge id="{{pageId}}gcCharge"
                                                                (keyup)="gcChargeEvent($event)"
                                                                (blur)="gcChargeBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [(ngModel)]="setGcCharge"
                                                                [readonly]="isFrieghtFieldsReadOnly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>LC</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #lcCharge id="{{pageId}}lcCharge"
                                                                (keyup)="lcChargeEvent($event)"
                                                                (blur)="lcChargeBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>BC</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #bcCharge id="{{pageId}}bcCharge"
                                                                (keyup)="bcChargeEvent($event)"
                                                                (blur)="bcChargeBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>F.O.V</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #fov id="{{pageId}}fov" (keyup)="fovEvent($event)"
                                                                (blur)="fovBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [readonly]="readonlyFOV">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>A.O.C</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #aocCharge id="{{pageId}}aocCharge"
                                                                (keyup)="aocChargeEvent($event)"
                                                                (blur)="aocChargeBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [readonly]="isFrieghtFieldsReadOnly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Others</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-donate"></i>
                                                                </span>
                                                            </div>
                                                            <input #others id="{{pageId}}others"
                                                                (keyup)="othersEvent($event)"
                                                                (blur)="othersBlur($event)" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                [(ngModel)]="setOthers"
                                                                [readonly]="isFrieghtFieldsReadOnly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>DD Mode</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="  fas fa-truck"></i>
															</span>
														</div>
														<!-- 												need to research for on change 		(click)="dd()" -->
                                                <!--	<select class="custom-select col-12" id="{{pageId}}doorDeliveryMode"
															name="doorDeliveryMode" #doorDeliveryMode
															(change)="doorDeliMode(doorDeliveryMode.value);"
															(keydown.enter)="doorDeliModeFocus($event,doorDeliveryMode.value)">
															<option selected value="No">No</option>
															<option value="Yes">Yes</option>
														</select>
													</div>
												</div>
											</div> -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>DD Mode</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="  fas fa-truck"></i>
                                                                </span>
                                                            </div>
                                                            <!-- 												need to research for on change 		(click)="dd()" -->
                                                            <select class="custom-select col-12"
                                                                id="{{pageId}}doorDeliveryMode" name="doorDeliveryMode"
                                                                #doorDeliveryMode
                                                                (change)="doorDeliMode(doorDeliveryMode.value);"
                                                                (keydown.enter)="doorDeliModeFocus($event,doorDeliveryMode.value)">
                                                                <option selected value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 vl">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">

                                                <div *ngIf="doorDeliveryYesddAmt" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>DD</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-hashtag"></i>
                                                                </span>
                                                            </div>
                                                            <input #ddAmt id="{{pageId}}ddAmt"
                                                                (keyup)="ddAmtEvent($event)" (blur)="ddAmtBlur($event)"
                                                                type="number" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                [(ngModel)]="ddAmtRateChg">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="viewByAutoLr">
                                                    <div *ngIf="doorDeliveryYes" class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label class="wt80">DD Address</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class=" fas fa-hashtag"></i>
                                                                    </span>
                                                                </div>
                                                                <textarea #ddAddress id="{{pageId}}ddAddress"
                                                                    [(ngModel)]="ddAddressTexArea"
                                                                    (keyup)="fieldFocus($event, checkboxCOD)"
                                                                    class="form-control" rows="1"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>COD</label>

                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="{{pageId}}checkboxCOD" #checkboxCOD
                                                                    (keyup)="fieldFocus($event, paymentType)"
                                                                    (change)="toggleEditableForCOD($event)"> <label
                                                                    class=" custom-control-label wtfull"
                                                                    style="padding-top: 15px;"
                                                                    for="{{pageId}}checkboxCOD"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="showCodChg" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>COD Charge</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #codCharge id="{{pageId}}codCharge" name="codCharge"
                                                                [(ngModel)]="setCodCharge" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                readonly>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Total Amt</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #totalAmount id="{{pageId}}totalAmount" type="number"
                                                                class="form-control" aria-describedby="basic-addon11"
                                                                readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 											<div class="col-sm-12 col-md-12"> -->
                                                <!-- 												<div class="form-group"> -->
                                                <!-- 													<div class="input-group"> -->
                                                <!-- 														<label>P.Type</label> -->
                                                <!-- 														<div class="input-group-prepend"> -->
                                                <!-- 															<span class="input-group-text"> <i -->
                                                <!-- 																class="fas fa-rupee-sign"></i> -->
                                                <!-- 															</span> -->
                                                <!-- 														</div> -->
                                                <!-- 														<select class="custom-select col-12" name="paymentType" -->
                                                <!-- 															#paymentType id="{{pageId}}paymentType" -->
                                                <!-- 															(change)="paymentTypeMode(paymentType.value)"> -->
                                                <!-- 															<option value="toPay" selected>To Pay</option> -->
                                                <!-- 															<option value="paid">Paid</option> -->
                                                <!-- 														</select> -->
                                                <!-- 													</div> -->
                                                <!-- 												</div> -->
                                                <!-- 											</div> -->
                                                <!-- By Kamil -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>P.Type</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <select class="custom-select col-12" name="paymentType"
                                                                #paymentType id="{{pageId}}paymentType"
                                                                (change)="paymentTypeMode(paymentType.value)"
                                                                (keydown.enter)="paymentTypeModeFocus($event,paymentType.value)">
                                                                <option value="toPay" selected>To Pay</option>
                                                                <option value="paid">Paid</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 											<div class="col-sm-12 col-md-12"> -->
                                                <!-- 												<div class="form-group"> -->
                                                <!-- 													<div class="input-group"> -->
                                                <!-- 														<label>Bank LR</label> -->
                                                <!-- 														<div class="input-group-prepend"> -->
                                                <!-- 															<span class="input-group-text"> <i -->
                                                <!-- 																class="fas fa-university"></i> -->
                                                <!-- 															</span> -->
                                                <!-- 														</div> -->
                                                <!-- 														<select id="{{pageId}}bankLr" class="custom-select col-12" -->
                                                <!-- 															name="bankLr" #bankLr (change)="bankLrMode(bankLr.value)"> -->
                                                <!-- 															<option selected value="No">No</option> -->
                                                <!-- 															<option value="Yes">Yes</option> -->
                                                <!-- 														</select> -->
                                                <!-- 													</div> -->
                                                <!-- 												</div> -->
                                                <!-- 											</div> -->
                                                <!-- BY Kamil -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bank LR</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-university"></i>
                                                                </span>
                                                            </div>
                                                            <select id="{{pageId}}bankLr" class="custom-select col-12"
                                                                name="bankLr" #bankLr
                                                                (change)="bankLrMode(bankLr.value)"
                                                                (keydown.enter)="bankLrModeFocus($event,bankLr.value)">
                                                                <option selected value="No">No</option>
                                                                <option value="Yes">Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="bankLrYes" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-university"></i>
                                                                </span>
                                                            </div>
                                                            <input #bankName id="{{pageId}}bankName"
                                                                (keyup)="fieldFocus($event, privateMarker)" type="text"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Pvt. Mark</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-paint-brush"></i>
                                                                </span>
                                                            </div>
                                                            <input #privateMarker id="{{pageId}}privateMarker"
                                                                type="text" class="form-control"
                                                                aria-describedby="basic-addon11"
                                                                (keyup)="fieldFocus($event, billNo)">
                                                            <!-- 															(keyup)="ddAmtEvent($event)" (onblur)="ddAmtBlur()" -->

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bill No.</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-hashtag"></i>
                                                                </span>
                                                            </div>
                                                            <input #billNo id="{{pageId}}billNo"
                                                                (keyup)="fieldFocus($event, clerkName)" type="text"
                                                                class="form-control" aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Clerk Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="ti-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #clerkName id="{{pageId}}clerkName" type="text"
                                                                (keyup)="clerkEvent($event)" class="form-control"
                                                                aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Bill Date</label> <input class="form-control"
															placeholder="dd-mm-yyyy" name="billDate" id="{{pageId}}billDate"
															ngbDatepicker #billDate="ngbDatepicker">
														<div class="input-group-append"
															(click)="billDate.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>-->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bill Date</label>
                                                            <div class="input-group-append"
                                                                (click)="billDates.toggle()">
                                                                <span class="input-group-text"> <i
                                                                        class="fa fa-calendar"></i>
                                                                </span>
                                                            </div>

                                                            <input class="form-control" name="billDate"
                                                                id="{{pageId}}billDate" [(ngModel)]="tdyBillDate"
                                                                ngbDatepicker (ngModelChange)="focusBillCalendar();"
                                                                (keyup)="focusBillCalendarEnter($event)"
                                                                (keydown.arrowdown)="billDates.toggle();"
                                                                (click)="billDates.toggle()" #billDates="ngbDatepicker">

                                                            <!-- 														<input type="date" id="{{pageId}}billDate" name="billDate" -->
                                                            <!-- 															placeholder="dd-mm-yyyy"> -->

                                                            <!-- 														<input class="form-control" id="{{pageId}}billDate" name="billDate" -->
                                                            <!-- 															[(ngModel)]="tdyBillDate" ngbDatepicker -->
                                                            <!-- 															(ngModelChange)="focusBillCalendar()" -->
                                                            <!-- 															(click)="billDates.toggle()" #billDates="ngbDatepicker"> -->
                                                            <!-- 														<div class="input-group-append"> -->
                                                            <!-- 															<button class="btn btn-outline-secondary calendar" -->
                                                            <!-- 																(click)="billDates.toggle()" type="button"></button> -->
                                                            <!-- 															<span class="input-group-text"> <i -->
                                                            <!-- 																class="fa fa-calendar"></i> -->
                                                            <!-- 															</span> -->
                                                            <!-- 														</div> -->

                                                        </div>
                                                    </div>
                                                </div>
                                                <!--<div *ngIf="viewByAutoLr" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label class="wt80">Remarks</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-hashtag"></i>
															</span>
														</div>
														<textarea #remarks id="{{pageId}}remarks" class="form-control"
															rows="1" [(ngModel)]="remarksTexArea"></textarea>
													</div>
												</div>
											</div>-->

                                                <div *ngIf="viewByAutoLr" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label class="wt80">Remarks</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-hashtag"></i>
                                                                </span>
                                                            </div>
                                                            <textarea #remarks id="{{pageId}}remarks"
                                                                class="form-control" (keyup)="remarksFocus($event)"
                                                                rows="1" [(ngModel)]="remarksTexArea"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 											<div class="col-sm-12 col-md-12"> -->
                                                <!-- 												<div class="form-group"> -->
                                                <!-- 													<div class="input-group"> -->
                                                <!-- 														<label>Bank LR</label> -->
                                                <!-- 														<div class="input-group-prepend"> -->
                                                <!-- 															<span class="input-group-text"> <i -->
                                                <!-- 																class="fas fa-university"></i> -->
                                                <!-- 															</span> -->
                                                <!-- 														</div> -->
                                                <!-- 														<input #bankLrInputField -->
                                                <!-- 															(keyup)="fieldFocus($event, actualChargedWeightExtra)" -->
                                                <!-- 															type="text" class="form-control" -->
                                                <!-- 															aria-describedby="basic-addon11"> -->
                                                <!-- 													</div> -->
                                                <!-- 												</div> -->
                                                <!-- 											</div> -->
                                                <div *ngIf="viewByAutoLr" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Article Charged Weight Extra</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input #articleChargedWeightExtra
                                                                id="{{pageId}}articleChargedWeightExtra"
                                                                (keyup)="articleChargedWeightExtraFocus($event)"
                                                                type="number" class="form-control"
                                                                aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>



                                                <!--<div *ngIf="paymentTypePaid" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label> Ser Tax Paying By </label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-user"></i>
															</span>
														</div>
														<select #serTaxPayingBy id="{{pageId}}serTaxPayingBy"
															(keyup)="fieldFocus($event, checkboxAoc)"
															class="custom-select col-12">
															<option selected value="Consignor">Consignor</option>
															<option value="GTA">GTA</option>
														</select>
													</div>
												</div>
											</div>-->
                                                <div *ngIf="paymentTypePaid" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label> Ser Tax Paying By </label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <select #serTaxPayingBy id="{{pageId}}serTaxPayingBy"
                                                                (change)="serTaxPayingByMethod();"
                                                                (keydown.enter)="serTaxPayingByFocusMethod($event)"
                                                                class="custom-select col-12">
                                                                <option selected value="Consignor">Consignor</option>
                                                                <option value="GTA">GTA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="showEDeclarationId" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label> E Declaration ID Created By </label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class=" fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <select #edeclarationId id="{{pageId}}edeclarationId"
                                                                (keydown.enter)="edeclarationIdFocusMethod($event)"
                                                                (change)="edeclarationIdMethod();"
                                                                class="custom-select col-12">
                                                                <option selected value="Consignor">Consignor</option>
                                                                <option value="GTA">GTA</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 vl">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                #checkboxAoc id="{{pageId}}checkboxAoc"
                                                                (keyup)="fieldFocus($event, checkboxToBeBilled)"
                                                                (change)="toggleEditableForAOC($event)"> <label
                                                                class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxAoc">AOC</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                #checkboxToBeBilled id="{{pageId}}checkboxToBeBilled"
                                                                (keyup)="fieldFocus($event, checkboxPickupLr)"
                                                                (change)="toggleEditableForTobeBilled($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxToBeBilled">To Be Billed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxPickupLr"
                                                                (keyup)="fieldFocus($event, checkboxUnloadedByConsignee)"
                                                                (change)="toggleEditableForPickupLr($event)"
                                                                #checkboxPickupLr> <label
                                                                class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxPickupLr">Pickup LR </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxUnloadedByConsignee"
                                                                #checkboxUnloadedByConsignee
                                                                (keyup)="fieldFocus($event, checkboxHandleWithCare)"
                                                                (change)="toggleEditableForUnloadByConsignee($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxUnloadedByConsignee">Unloaded
                                                                By Consignee</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxHandleWithCare"
                                                                #checkboxHandleWithCare
                                                                (keyup)="fieldFocus($event, checkboxGatePass)"
                                                                (change)="toggleEditableForHandleWithCare($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxHandleWithCare">Handle
                                                                With Care</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxGatePass" #checkboxGatePass
                                                                (keyup)="fieldFocus($event, checkboxSelfLr)"
                                                                (change)="toggleEditableForGatePass($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxGatePass">Gate Pass</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxSelfLr" #checkboxSelfLr
                                                                (keyup)="fieldFocus($event, checkboxOrderGoods)"
                                                                (change)="toggleEditableForSelfLr($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxSelfLr">Self LR</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 												<div class="col-sm-12 col-md-12"> -->
                                                <!-- 													<div class="form-group" -->
                                                <!-- 														style="margin-bottom: 0px !important"> -->
                                                <!-- 														<div class="custom-control custom-checkbox"> -->
                                                <!-- 															<input type="checkbox" class="custom-control-input" -->
                                                <!-- 																id="{{pageId}}checkboxCOD" #checkboxCOD -->
                                                <!-- 																(keyup)="fieldFocus($event, checkboxOrderGoods)" -->
                                                <!-- 																(change)="toggleEditableForCOD($event)"> <label -->
                                                <!-- 																class=" custom-control-label wtfull" for="checkboxCOD">COD</label> -->
                                                <!-- 														</div> -->
                                                <!-- 													</div> -->
                                                <!-- 												</div> -->
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxOrderGoods" #checkboxOrderGoods
                                                                (keyup)="fieldFocus($event, checkboxCcAttach)"
                                                                (change)="toggleEditableForOrderGoods($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxOrderGoods">Order Goods</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="showCCAttacheOption" class="col-sm-12 col-md-12">
                                                    <div class="form-group" style="margin-bottom: 0px !important">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="{{pageId}}checkboxCcAttach" #checkboxCcAttach
                                                                (keyup)="fieldFocus($event, nextBtn)"
                                                                (change)="toggleEditableForCCAttached($event)">
                                                            <label class=" custom-control-label wtfull"
                                                                for="{{pageId}}checkboxCcAttach">C.C.Attach</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="form-group">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="{{pageId}}checkbox10" value="check">
                    <label class=" custom-control-label wtfull" for="checkbox10">Remember Me</label>
                  </div>
                </div> -->
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div *ngIf="showSpinnerForBarcode" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center'
                                        style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait while generating Barcode.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3"></div>
                        <div class="col-md-6" style="text-align: center;">
                            <!-- 							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}nextBtn" -->
                            <!-- 								#nextBtn (click)="validateBtnNext(content11)">Next</button> -->

                            <!-- check valid E-Way bill No before save lrEntry details if ewayBillNo is not null as per discuss (Asrar Jr 0n 19/09/2024) -->
                            <!-- <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}nextBtn" #nextBtn (click)="validateBtnNextAfterCheckingChallanTotalLrs(content11)">Next</button> -->
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}nextBtn" #nextBtn
                                (click)="validateNextBtnSaveLrEntryDetails(content11)">Next</button>
                            <button type="submit" class="btn btn-success" id="{{pageId}}finishBtn" disabled
                                (click)="validateBtnFinish()">Finish</button>
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}resetBtn"
                                (click)="validateReset();">Reset</button>
                        </div>
                        <div class="col-md-3">
                            <h6 *ngIf="lblLRCount">{{txtLrCount}}</h6>
                        </div>
                    </div>



                    <!-- 	<div *ngIf="viewBkgCashmemo"> -->
                    <!-- 		<app-booking-cashmemo></app-booking-cashmemo> -->
                    <!-- 	</div> -->
                    <div class="col-sm-12 col-md-12" id="{{pageId}}bkgMemoPrintId">
                        <div class="form-group">
                            <div class="input-group" id="{{pageId}}popupDetails">
                                <!-- 				<label>Paid</label> -->
                                <ng-template #content11 id="content11" let-c="close" let-d="dismiss">
                                    <div class="modal-header">
                                        <!-- 					<h6 class="modal-title" id="{{pageId}}modal-basic-title">Booking Cashmemo -->
                                        <!-- 						Details</h6> -->
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="d('Cross click');showBkgCashmemoPaidClear()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <app-booking-cashmemo></app-booking-cashmemo>
                                                <div class="col-sm-12 col-md-12">
                                                    <button style="padding: 1px 4px; float: right;" type="submit"
                                                        class="btn btn-dark" id="{{pageId}}closePopupBtn"
                                                        (click)="d('Cross click');showBkgCashmemoPaidClear()">Close</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <!-- 				<a style="cursor: pointer; color: blue;" -->
                                <!-- 					(click)="open11(content11);showBkgCashmemoPaidClear()"><u> -->
                                <!-- 						paid</u></a> -->
                            </div>
                        </div>
                    </div>

                    <!-- vehicle update for part-b -->
                    <ng-template #contentVehicleUpdate let-c="close " let-d="dismiss ">
                        <div class="modal-header ">
                            <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Part-B update for Eway Bill No :
                                <b>
                                    {{setTripNo}}</b>
                            </h6>

                            <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
                                <span aria-hidden="true ">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body ">
                            <div class="row ">
                                <div class="col-md-12 ">
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="control ">
                                            <div class="form-group ">
                                                <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                                    <label>Vehicle Number</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i class="ti-truck "></i>
                                                        </span>
                                                    </div>
                                                    <input #partBVehicleNo id="{{pageId}}partBVehicleNo" type="text"
                                                        class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 ">
                                        <button type="submit " class="btn btn-dark "
                                            (click)="validateEwayPartBUpdate() ">Update</button>
                                        <button style="padding: 1px 4px; float:right; " type="submit "
                                            class="btn btn-dark " id="{{pageId}}clearPopupBtn "
                                            (click)="d( 'Cross click') ">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Row -->
                </div>
            </div>
        </div>
        <!-- <div *ngIf="viewBarcodePrint" id="printSection" #printSection>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-borderless compact-table">
                        <tr *ngFor="let PS of PrintBarcode" class="barcode-row">
                            <td class="barcode-cell" style="text-align: center; border: 1px solid #000;">
                                <ngx-barcode6 class="barcode-print" [bc-format]="'CODE128'" [bc-width]="3"
                                    [bc-height]="30" [bc-font-size]="15" [bc-value]="PS.barcode"
                                    [bc-display-value]="false">
                                </ngx-barcode6>
                                <div>{{ PS.Name }}</div>
                                <div>{{ PS.Destination }}</div>
                                <div>Article: {{ PS.ArticleIndex }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div> -->
        <div id="printSection">
            <div *ngFor="let PS of PrintBarcode" class="barcode-page">

                <div class="barcode-text">{{ PS.barcode }}</div>
                <ngx-barcode6 class="barcode-print" [bc-format]="'CODE128'" [bc-width]="4" [bc-height]="90"
                    [bc-font-size]="30" [bc-value]="PS.barcode" [bc-display-value]="false">
                </ngx-barcode6>
                <div>
                    <div class="barcode-details">LR No. {{ PS.Name }}</div>
                    <div class="barcode-details">{{ PS.Destination }}</div>
                    <div class="barcode-details-art">art. {{ PS.ArticleIndex }}</div>
                </div>
            </div>
        </div>

        <!--  <script type="text/javascript" src="assets/js/bootstrap-dynamic-tabs/bootstrap-dynamic-tabs.js"></script>
  <script type="text/javascript" src="assets/js/jquery-ui-sortable.js"></script>
  <script type="text/javascript" src="assets/js/jquery-ui-sortable.min.js"></script>
  <script>
    $(function () {
      var userId = "${loginDetailsSession.loginId}";
      var itemId = 0;

      $("#sessionRelogin").click(function (e) {
        e.preventDefault();
        window.location.href = "/BillingApp/login.do";
      });
      $(document).ready(function () {
        // 					fieldDetails();
        // 					itemDetails();
        show1();
      });

      function show1() {
        document.getElementById('tripInfo').style.display = 'block';

      }

      // 		function show3() {
      // // 			window.location.replace("/BillingApp/billPrint.do");
      // // 			window.location.replace("/BillingApp/billPrintWithoutPartyName.do");
      // // 			window.Print();

      // 		}
      // 		for the menu
      function myFunction() {
        document.getElementById("myDropdown").classList
          .toggle("show");
      }

      var tabs = $('#tabs');
      tabs.bootstrapDynamicTabs().addTab({
        title: 'Home',
        ajaxUrl: '/BillingApp/billing.do',
        closable: false
      });

      $('#inventoryStockIn').click(function () {
        tabs.addTab({
          title: 'Inventory StockIn',
          id: 'ajax1',
          ajaxUrl: '/masters/company/commoncharges',
          // 				loadStyles : [ 'css/test.css', 'css/test2.css' ]
        });
      });
      $('#messageScreen').click(function () {
        tabs.addTab({
          title: 'MessageScreen',
          id: 'ajax',
          ajaxUrl: '/BillingApp/message.do',
          // 					loadScripts : 'js/load.js',
          // 					loadStyles : [ 'css/test.css', 'css/test2.css' ]
        });
      });
    });
    // 			$('#btnIon').click(function() {
    // 				tabs.addTab({
    // 					title : 'Tested via Ajax',
    // 					id : 'ajax2',
    // 					ajaxUrl : 'BillingApp/customer.do',
    // 				// 					loadScripts : 'js/load.js',
    // 				// 					loadStyles : [ 'css/test.css', 'css/test2.css' ]
    // 				});
    // 			});

  </script>-->
    </div>
    <div *ngIf="viewLrEntryPrint" onafterprint="afterPrint()" id="{{pageId}}printLrEntryForm">
        <!-- 							<app-lrentry-print></app-lrentry-print> -->
        <!-- 		<app-lrentryform-print2></app-lrentryform-print2> -->
        <app-lrentryform-print3></app-lrentryform-print3>
    </div>



    <div *ngIf="viewLrEntryBkgCashmemoPrintAutoGStPopup" onafterprint="afterPrint()"
        id="{{pageId}}printLrEntryFormBkgCashmemoPrintAutoGStPopup">
        <app-cashmemo-bkg-print-autogst-popup></app-cashmemo-bkg-print-autogst-popup>
    </div>
    <div *ngIf="viewLrEntryBarcodePrint" onafterprint="afterPrint()" id="{{pageId}}printBarcodeLrEntryForm">
        <!-- 							<app-lrentry-print></app-lrentry-print> -->
        <!-- 		<app-lrentryform-print2></app-lrentryform-print2> -->
        <app-lrentryform-barcode-print></app-lrentryform-barcode-print>
    </div>
    <!-- <div *ngIf="viewBarcodePrint" onafterprint="afterPrint()" id="{{pageId}}printBarcodesForm">
        <app-generate-barcode-print></app-generate-barcode-print>
    </div> -->

</body>

</html>