//Chg_V1 : Block to save with empty Consignor & Consignee Id if selected Consignor & Consignee Name (Asrar Jr / 03/12/2024)
//Chg_V2 : Set '0' for consigneeId if have (Null or Undefined or Empty) (Asrar Jr / 10-12-2024)

import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, ViewChild } from "@angular/core";
import { MasterService } from "src/app/dataService/master-service";
import { Subject, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ConsignorService } from "src/app/dataService/consignor-service";
import { UpdateConsignorRateForAllComponent } from "../update-consignor-rate-for-all/update-consignor-rate-for-all.component";
import { MatDialog } from "@angular/material/dialog";
import { UpdateConsignorRateForAllPartyComponent } from "../update-consignor-rate-for-all-party/update-consignor-rate-for-all-party.component";

@Component({
  selector: "app-consignor-admin-rate-master",
  templateUrl: "./consignor-admin-rate-master.component.html",
  styleUrls: ["./consignor-admin-rate-master.component.css"],
})
export class ConsignorAdminRateMasterComponent implements OnInit {
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  showSpinnerForAction = false;
  showSpinnerForConsignorName = false;
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dataTable: any;

  dtOptionsForConsignorAdminRateMasterForCommodity: any;
  dtTriggerForConsignorAdminRateMasterForCommodity: Subject<any> = new Subject();
  consignorAdminRateMasterDataListForCommodity: any;
  dtOptionsForConsignorAdminRateMasterForParty: any;
  dtTriggerForConsignorAdminRateMasterForParty: Subject<any> = new Subject();
  consignorAdminRateMasterDataListForParty: any;
  dtOptionsForSourceSubStation: any;
  dtTriggerForSourceSubStation: Subject<any> = new Subject();
  sourceSubStationDataList: any;

  indexOptions = [
    { id: 1, label: 'ALL' },
    { id: 2, label: 'A' },
    { id: 2, label: 'B' },
    { id: 3, label: 'C' },
    { id: 4, label: 'D' },
    { id: 5, label: 'E' },
    { id: 6, label: 'F' },
    { id: 7, label: 'G' },
    { id: 8, label: 'H' },
    { id: 9, label: 'I' },
    { id: 10, label: 'J' },
    { id: 11, label: 'K' },
    { id: 12, label: 'L' },
    { id: 13, label: 'M' },
    { id: 14, label: 'N' },
    { id: 15, label: 'O' },
    { id: 16, label: 'P' },
    { id: 17, label: 'Q' },
    { id: 18, label: 'R' },
    { id: 19, label: 'S' },
    { id: 20, label: 'T' },
    { id: 21, label: 'U' },
    { id: 22, label: 'V' },
    { id: 23, label: 'W' },
    { id: 24, label: 'X' },
    { id: 25, label: 'Y' },
    { id: 26, label: 'Z' }];

  public modelIndex: any;
  @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
  indexTA: Array<any> = [];
  focusIndexTA$ = new Subject<string>();
  indexSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusIndexTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.indexTA
        : this.indexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  // index for party search
  public modelIndexPartySearch: any;
  @ViewChild('instanceIndexPartySearch') instanceIndexPartySearch: NgbTypeahead;
  indexPartySearchTA: Array<any> = [];
  focusIndexPartySearchTA$ = new Subject<string>();
  indexSearchPartySearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusIndexPartySearchTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.indexPartySearchTA
        : this.indexPartySearchTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  /* For Source Drop Down Start */
  agentDetailsOptionsSet: AgentDetailsDto[];
  agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
  public agentNameModal: any;
  agentNameTA: Array<AgentDetailsDto> = [];
  focusAgentNameTA$ = new Subject<string>();
  agentNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusAgentNameTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  // source
  // subStation
  formatterAgentName = (x: { source: string }) => x.source;
  /* For Source Drop Down End */
  agentDtoForSourceSubStation: AgentDetailsDto = new AgentDetailsDto();
  /* For Source Sub Station Drop Down */
  /*agentDtoOptionForsourceSubStation: AgentDetailsDto[];
  public sourceSubStationModal: any;
  sorceSubStationTA: Array<AgentDetailsDto> = [];
  focusSubSourceStationTA$ = new Subject<string>();
  subSourceStationSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSubSourceStationTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.sorceSubStationTA
        : this.sorceSubStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSubSourceStation = (x: { subStation: string }) => x.subStation;*/

  /* For Source Drop Down Start Search */
  public agentNameRptSearchModal: any;
  agentNameRptSearchTA: Array<AgentDetailsDto> = [];
  focusAgentNameRptSearchTA$ = new Subject<string>();
  agentNameSearchRptSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusAgentNameRptSearchTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.agentNameRptSearchTA
        : this.agentNameRptSearchTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  // source
  // subStation
  formatterAgentNameRptSearch = (x: { source: string }) => x.source;
  /* For Source Drop Down End */

  /* For Source Drop Down Start Search Party */
  public agentNameRptSearchPartyModal: any;
  agentNameRptSearchPartyTA: Array<AgentDetailsDto> = [];
  focusAgentNameRptSearchPartyTA$ = new Subject<string>();
  agentNameSearchRptSearchPartyTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusAgentNameRptSearchPartyTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.agentNameRptSearchPartyTA
        : this.agentNameRptSearchPartyTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  // source
  // subStation
  formatterAgentNameRptSearchParty = (x: { source: string }) => x.source;
  /* For Source Drop Down End */

  /* For Destination Drop Down */
  destinationOptions: LRDto[];
  lrDtoToStation: LRDto = new LRDto();
  destinationDtoForAll: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { godownName: string }) => x.godownName;

  /* For Destination Drop Down search */
  public modelDestinationSearchRpt: any;
  destinationSearchRptTA: Array<LRDto> = [];
  focusDestinationSearchRptTA$ = new Subject<string>();
  searchDestinationSearchRpt = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationSearchRptTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationSearchRptTA
        : this.destinationSearchRptTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestinationSearchRpt = (x: { godownName: string }) => x.godownName;

  /* For Destination Drop Down search party*/
  public modelDestinationSearchRptParty: any;
  destinationSearchRptPartyTA: Array<LRDto> = [];
  focusDestinationSearchRptPartyTA$ = new Subject<string>();
  searchDestinationSearchRptParty = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationSearchRptPartyTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationSearchRptPartyTA
        : this.destinationSearchRptPartyTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestinationSearchRptParty = (x: { godownName: string }) => x.godownName;

  /* Commodity Drop Down */
  rateMasterDto: RateMasterDto = new RateMasterDto();
  rateMasterDtoOptions: RateMasterDto[];
  rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
  public modelCommodityList: any;
  commodityListTA: Array<RateMasterDto> = [];
  focusCommodityListTA$ = new Subject<string>();
  searchCommodityList = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListTA
        : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

  /* Commodity Drop Down search */
  public modelCommodityListSearchRpt: any;
  commodityListSearchRptTA: Array<RateMasterDto> = [];
  focusCommodityListSearchRptTA$ = new Subject<string>();
  searchCommodityListSearchRpt = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListSearchRptTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListSearchRptTA
        : this.commodityListSearchRptTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityListSearchRpt = (x: { commodityName: string }) => x.commodityName;

  /* Commodity Drop Down search party*/
  public modelCommodityListSearchRptParty: any;
  commodityListSearchRptPartyTA: Array<RateMasterDto> = [];
  focusCommodityListSearchRptPartyTA$ = new Subject<string>();
  searchCommodityListSearchRptParty = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListSearchRptPartyTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListSearchRptPartyTA
        : this.commodityListSearchRptPartyTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityListSearchRptParty = (x: { commodityName: string }) => x.commodityName;

  /* Consignor  Drop Down*/
  validateIndexValue: any;
  consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
  consignorNameOptions: any;
  partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
  public modelConsignorName: any;
  consignorNameTA: Array<PartyMasterDto> = [];
  focusConsignorNameTA$ = new Subject<string>();
  consignorNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorNameTA
        : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorName = (x: { consignorName: string }) => x.consignorName;

  /* Consignor  Drop Down search party*/
  public modelConsignorNamePartySearch: any;
  consignorNamePartySearchTA: Array<PartyMasterDto> = [];
  focusConsignorNamePartySearchTA$ = new Subject<string>();
  consignorNameSearchPartySearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorNamePartySearchTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorNamePartySearchTA
        : this.consignorNamePartySearchTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorNamePartySearch = (x: { consignorName: string }) => x.consignorName;

  /* Consignee  Drop Down*/
  partyDtoConsigneeName: PartyMasterDto = new PartyMasterDto();
  consigneeNameOptions: PartyMasterDto[];
  public modelConsigneeName: any;
  consigneeNameTA: Array<PartyMasterDto> = [];
  focusConsigneeNameTA$ = new Subject<string>();
  searchConsigneeName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeNameTA
        : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

  ///For Source Main Booking Station
  agentDtoOptionSourceStation: AgentDetailsDto[];
  agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
  public modelMainBookingSource: any;
  mainBookingSourceTA: Array<AgentDetailsDto> = [];
  focusMainBookingSourceTA$ = new Subject<string>();
  searchMainBookingSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusMainBookingSourceTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.mainBookingSourceTA
        : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;


  selectedDestinationForConsigneeName: any;
  partyDtoConsigneeNameAll: PartyMasterDto = new PartyMasterDto();

  rateMasterDtoForSaveUpdate: RateMasterDto = new RateMasterDto();
  lrDtoListSourceSubStationChecked: Array<LRDto> = [];
  listSourceSubStation: Array<String> = [];
  private newAttributeSetSourceSubStationList: any = {};
  enteredConsignorId: any;
  enteredConsigneeId: any;
  selectedSorceDropDown: any;
  viewActChgWgtField = false;
  enteredSourceForSave: any;
  enteredConsignorNameForSave: any;
  enteredConsigneeNameForSave: any;
  enteredCommodityForSave: any;
  enteredDestinationForSave: any;
  enteredRateTypeForSave: any;
  enteredBasicRateForSave: any;
  enteredHamaliForSave: any;
  enteredGCChargeForSave: any;
  enteredAOCChargeForSave: any;
  enteredDDChargeForSave: any;
  enteredOtherChargeForSave: any;
  enteredRiskChargeForSave: any;
  enteredFixedBy: any;
  selectedEeffectDate: any;
  selectedFixedOn: any;
  enteredRCChargeForSave: any;
  enteredSCChargeForSave: any;
  enteredDestHamaliForSave: any;
  subStationCheckStatus: boolean = false;
  enteredBasicRateForSaveSub: any;
  enteredHamaliForSaveSub: any;
  enteredGCChargeForSaveSub: any;
  enteredAOCChargeForSaveSub: any;
  enteredDDChargeForSaveSub: any;
  enteredOtherChargeForSaveSub: any;
  enteredRiskChargeForSaveSub: any;
  enteredFixedBySub: any;
  selectedEeffectDateSub: any;
  selectedFixedOnSub: any;
  enteredRCChargeForSaveSub: any;
  enteredSCChargeForSaveSub: any;
  enteredDestHamaliForSaveSub: any;
  enteredPerUnitForSave: any;
  rateOnActWgt: any;
  viewParty = false;
  viewSubRate = false;
  // effectFromDate: NgbDateStruct;
  // fixedOnDate: NgbDateStruct;
  // effectFromDateSub: NgbDateStruct;
  // fixedOnDateSub: NgbDateStruct;
  viewBySuperAdmin: boolean = false;
  selectedDropDownMainStation: any;
  selectedRateFor: any;
  selectedAutoId: any = 0;
  rateMasterDtoForGetDetails: RateMasterDto = new RateMasterDto();
  viewSaveBtn: boolean = true;
  viewUpdateBtn: boolean = false;
  viewDeleteBtn: boolean = false;
  modelConsignor: any;
  tableCommodity: any;
  tableParty: any;
  pageId = "cnarm";
  isRights: boolean = false;
  isMainCommodityValid = false;
  modelfixedBy: string;
  modelEffectFromDate: any;
  modelFixedOnDate: any;

  modelFixedBySub: string;
  modelEffectFromDateSub: any;
  modelFixedOnDateSub: any;
  modelConsignee: any;

  viewRateOnActWgtField: boolean = false;
  viewActionBtn: boolean = false;
  selectedSourceForSearch: any;
  selectedCommodityForSearch: any;
  selectedDestinationForSearch: any;
  selectedSourceForPartySearch: any;
  selectedCommodityForPartySearch: any;
  selectedDestinationForPartySearch: any;
  selectedConsignorForPartySearch: any;
  modelConsignorPartySearch: any;
  enteredConsignorIdPartySearch: any;
  public perUnitIdModal: any;
  sourceView = false;

  constructor(
    private masterReadService: MasterReadService,
    private router: Router, private masterService: MasterService,
    private consignorService: ConsignorService,
    public changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            "CnorAdminRate ViewSrcAll") {
            this.viewBySuperAdmin = true;
          }
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgrRateMtr RateForAll") {
            this.isRights = true;
          }
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgrRateMtr Edit Option") {
            this.viewActionBtn = true;
          }
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "CgrRateMtr All Src View") {
            this.sourceView = true;
          }
        }
      }
      this.getDestinationDetails();
      this.getCommodityMasterDetailsList();
      this.getMainBookingSourceDetails();
      if (this.sourceView == false) {
        this.getAgentDetailList(this.userDataDtoReturnSession.mainStation);
      }

      //this.gettingPartyDataList();
      //this.gettingCommodityDataList();

      //this.getConsignorDetailsList();
      this.setIndex();
      this.modelfixedBy = this.userDataDtoReturnSession.mainStation;
    }
  }

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }

  ngOnInit(): void {
    this.dtOptionsForConsignorAdminRateMasterForCommodity = {
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: '<i class="fas fa-file-excel"> Export</i>',
          titleAttr: 'Export',
          title: function () {
            return "Consignor Rate Details For Commodity";
          },
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      // action: function (e, dt, button, config) {
      //   e.preventDefault();
      //   this.tableCommodity = dt.rows({ search: 'applied' }).select();
      // },


      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": true,
      select: true,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
      }
    },
      this.dtOptionsForConsignorAdminRateMasterForParty = {
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'excel',
            text: '<i class="fas fa-file-excel"> Export</i>',
            titleAttr: 'Export',
            title: function () {
              return "Consignor Rate Details For Party";
            },
            exportOptions: {
              columns: ':visible'
            }
          }
        ],
        // action: function (e, dt, button, config) {
        //   e.preventDefault();
        //   this.tableParty = dt.rows({ search: 'applied' }).select();
        // },

        processing: true,
        responsive: true,
        "scrollX": true,
        "scrollY": 300,
        "scrollCollapse": true,
        "paging": false,
        "info": true,
        select: true,
        "footerCallback": function (row, data, start, end, display) {
          var api = this.api(), data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === 'string' ?
              +i.replace(/[\$,]/g, '') * 1 :
              typeof i === 'number' ?
                i : 0;
          };
        }
      },

      this.dtOptionsForSourceSubStation = {
        dom: 'Bfrtip',
        buttons: [

        ],
        processing: true,
        responsive: true,
        "scrollX": false,
        "scrollY": 100,
        "scrollCollapse": true,
        "paging": false,
        "info": false,
        searching: false,
        "footerCallback": function (row, data, start, end, display) {
          var api = this.api(), data;
          // converting to interger to find total
          var intVal = function (i) {
            return typeof i === 'string' ?
              +i.replace(/[\$,]/g, '') * 1 :
              typeof i === 'number' ?
                i : 0;
          };
        }
      };
  }

  ngOnDestroy(): void {
    this.dtTriggerForConsignorAdminRateMasterForCommodity.unsubscribe();
    this.dtTriggerForConsignorAdminRateMasterForParty.unsubscribe();
    this.dtTriggerForSourceSubStation.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTriggerForConsignorAdminRateMasterForCommodity.next();
    this.dtTriggerForConsignorAdminRateMasterForParty.next();
    this.dtTriggerForSourceSubStation.next();
  }



  /* Source Drop Down Service & Click Listner Start Here */

  clickListnerForSource(event) {
    this.agentNameModal = event.item;
    console.log("this.agentNameModal");
    console.log(this.agentNameModal);
    this.selectedSorceDropDown = null;
    this.selectedDropDownMainStation = null;
    this.selectedSorceDropDown = this.agentNameModal.source;
    this.selectedDropDownMainStation = this.agentNameModal.mainstation
    // this.selectedSorceDropDown = this.agentNameModal.subStation;
    // this.getConsignorDetailsList();
    // this.setSourceSubStationList();
  }

  // getUserValuesForAgentDetailsList() {
  //   this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
  //   this.agentDtoParam.column2 = 'Working';
  //   this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
  //   this.agentDtoParam.mode = 'Sub';
  // }

  // getAgentDetailList() {
  //   this.getUserValuesForAgentDetailsList();
  //   this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
  //     (response) => {
  //       if (response.length == 0) {
  //         this.agentDetailsOptionsSet = [];
  //         this.agentNameTA = [];
  //       } else {
  //         this.agentDetailsOptionsSet = [];
  //         this.agentNameTA = [];
  //         this.agentDetailsOptionsSet = response;
  //         for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
  //           this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
  //         }
  //       }
  //       this.changeDetectorRef.detectChanges();
  //     }),
  //     (error) => console.log(error.json()),
  //     () => console.log('done');
  // }

  getUserValuesForAgentDetailsList(mainSource) {
    this.agentDtoParam = new AgentDetailsDto();
    this.agentDtoParam.mode = "MainAndGrpRateMaster";
    this.agentDtoParam.status = "Working";
    this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoParam.mainStation = mainSource;
    console.log(this.agentDtoParam);
  }

  getAgentDetailList(mainSource) {
    this.showSpinnerForAction = true;
    $("#" + this.pageId + "agentName").val('');
    this.enteredSourceForSave = null;
    this.getUserValuesForAgentDetailsList(mainSource);
    // getAgentNamesGrouping
    this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
      (response) => {
        if (response.length == 0) {
          this.agentDetailsOptionsSet = [];
          this.agentNameTA = [];
          this.agentNameRptSearchTA = [];
          this.agentNameRptSearchPartyTA = [];
        } else {
          this.agentDetailsOptionsSet = [];
          this.agentNameTA = [];
          this.agentNameRptSearchTA = [];
          this.agentNameRptSearchPartyTA = [];
          this.agentDetailsOptionsSet = response;
          console.log("response >> Getting Response");
          console.log(response);

          this.agentDtoParam = new AgentDetailsDto();
          this.agentDtoParam.source = "ALL";
          this.agentNameRptSearchTA.push(this.agentDtoParam);
          this.agentNameRptSearchPartyTA.push(this.agentDtoParam);
          for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
            this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
            this.agentNameRptSearchTA.push(this.agentDetailsOptionsSet[i]);
            this.agentNameRptSearchPartyTA.push(this.agentDetailsOptionsSet[i]);
          }
        }
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "agentName").focus();
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Source Details", "info");
      },
      () => console.log('done');
  }


  /* Source Drop Down Service & Click Listner End Here */

  /* Source Sub Station Drop Down Service & Click Listner Start Here */

  getSourceSubStationDetails() {
    this.agentDtoForSourceSubStation = new AgentDetailsDto();
    this.agentDtoForSourceSubStation.sourceStation = this.selectedSorceDropDown;
    this.agentDtoForSourceSubStation.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoForSourceSubStation.mode = 'sourceGrouping';
  }

  setSourceSubStationList() {
    this.getSourceSubStationDetails();
    this.masterReadService.getSourceListDetails(this.agentDtoForSourceSubStation).subscribe(
      (response) => {
        $("#" + this.pageId + "sourceSubstationTableId").DataTable().destroy();
        this.sourceSubStationDataList = [];
        if (response.length == 0) {
          this.sourceSubStationDataList = [];
        } else {
          this.sourceSubStationDataList = response;
        }
        this.dtTriggerForSourceSubStation.next();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  /* Source Sub Station Drop Down Service & Click Listner End Here */

  /* Destination Drop Down Service & Click Listner Start Here */

  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
    this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
    if (this.selectedRateFor == "Party") {
      this.modelDestination = e.item;
      this.selectedDestinationForConsigneeName = this.modelDestination.godownName;
      $("#" + this.pageId + "destinationId").val(this.modelDestination.godownName);
      this.getConsigneeDetails();
    }
  }

  getDestinationMethod() {
    this.lrDtoToStation = new LRDto();
    this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
    this.showSpinnerForAction = true;
  }

  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
      (response) => {
        console.log(response);
        if (response.length > 0) {
          this.destinationOptions = [];
          this.destinationTA = [];
          this.destinationSearchRptTA = [];
          this.destinationSearchRptPartyTA = [];
          this.destinationOptions = response;

          this.destinationDtoForAll = new LRDto();
          this.destinationDtoForAll.godownName = "ALL";
          this.destinationTA.push(this.destinationDtoForAll);
          this.destinationSearchRptTA.push(this.destinationDtoForAll);
          this.destinationSearchRptPartyTA.push(this.destinationDtoForAll);
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
            this.destinationSearchRptTA.push(this.destinationOptions[i]);
            this.destinationSearchRptPartyTA.push(this.destinationOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
      },
      () => console.log('done');
  };



  /* Destination Drop Down Service & Click Listner End Here */

  /* Commodity Drop Down Service & Click Listner Start Here */

  clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
    this.modelCommodityList = e.item;
    $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
  }

  getCommodityListRead() {
    this.rateMasterDto = new RateMasterDto();
    this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDto.mode = "mainConsigneeMaster";
  }

  getCommodityMasterDetailsList = () => {
    this.getCommodityListRead();
    this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          if (response.length == 0) {
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
            this.commodityListSearchRptTA = [];
            this.commodityListSearchRptPartyTA = [];
          } else {
            this.rateMasterDtoOptions = [];
            this.commodityListTA = [];
            this.commodityListSearchRptTA = [];
            this.commodityListSearchRptPartyTA = [];
            this.rateMasterDtoOptions = response;
            this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
            this.rateMasterDtoCommodityListAllOption.commodityName = "All";
            this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
            this.commodityListSearchRptTA.push(this.rateMasterDtoCommodityListAllOption);
            this.commodityListSearchRptPartyTA.push(this.rateMasterDtoCommodityListAllOption);
            for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
              this.commodityListTA.push(this.rateMasterDtoOptions[i]);
              this.commodityListSearchRptTA.push(this.rateMasterDtoOptions[i]);
              this.commodityListSearchRptPartyTA.push(this.rateMasterDtoOptions[i]);
            }
            this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
            this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
            this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
            this.commodityListSearchRptTA.push(this.rateMasterDtoCommodityListAllOption);
            this.commodityListSearchRptPartyTA.push(this.rateMasterDtoCommodityListAllOption);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Error While Getting Commodity Master Details", "warning");
      },
      () => console.log('done');
  };

  /* Commodity Drop Down Service & Click Listner End Here */

  /* Consignor Drop Down Service & Click Listner Start Here */

  clickListnerForConsignor(event) {
    this.modelConsignor = null;
    this.modelConsignor = event.item;
    this.enteredConsignorId = 0;
    this.modelConsignorName = null;
    this.modelConsignorName = this.modelConsignor.consignorName
    $("#" + this.pageId + "consignorName").val(this.modelConsignor.consignorName);
    if ($("#" + this.pageId + "consignorName").val() == null || $("#" + this.pageId + "consignorName").val() == undefined ||
      $("#" + this.pageId + "consignorName").val() == "") {
      // || $("#" + this.pageId + "consignorName").val() == "All"
      this.enteredConsignorId = 0;
    } else {
      this.enteredConsignorId = this.modelConsignor.consignorId;
    }
    console.log("click cnor >> " + this.enteredConsignorId);
  }

  getConsignorDetailsList() {
    this.partyMasterDtoForconsignor = new PartyMasterDto();
    this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
    // this.partyMasterDtoForconsignor.mainStation = this.selectedSorceDropDown;
    // this.partyMasterDtoForconsignor.mainStation = this.selectedDropDownMainStation;

    if (this.sourceView == false) {
      this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainStation;
    } else {
      this.partyMasterDtoForconsignor.mainStation = this.modelMainBookingSource.mainBookStations;
    }

    this.validateIndexValue = null;
    this.validateIndexValue = $("#cnarmindexId").val();
    if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
      this.validateIndexValue == "") {
      this.validateIndexValue = "A";
      this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
    } else {
      this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
    }
    console.log(this.partyMasterDtoForconsignor);
    this.getConsignorDetails();
  }

  getConsignorDetails = () => {
    this.showSpinnerForConsignorName = true;
    this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
      (response) => {
        console.log(response);

        if (response.length == 0) {
          this.consignorNameOptions = [];
          this.consignorNameTA = [];
          $("#" + this.pageId + "consignorName").val('');
        } else {
          this.consignorNameOptions = [];
          this.consignorNameTA = [];
          $("#" + this.pageId + "consignorName").val('');

          this.consignorNameOptions = response;
          // this.consignorNamePartyDtoAllOption.consignorName = "All";
          // this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
          for (let i = 0; i < this.consignorNameOptions.length; i++) {
            this.consignorNameTA.push(this.consignorNameOptions[i]);
          }
        }
        this.showSpinnerForConsignorName = false;
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForConsignorName = false;
        swal("Error", "Server Problem Occurred While getting the consignor details", "info");
      }, () => console.log('done');
  };

  getConsignorDetailsListPartySearch() {
    this.partyMasterDtoForconsignor = new PartyMasterDto();
    this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForconsignor.mode = "newConsignorByMainStationIndex";
    // this.partyMasterDtoForconsignor.mainStation = this.selectedSorceDropDown;
    // this.partyMasterDtoForconsignor.mainStation = this.selectedDropDownMainStation;

    if (this.sourceView == false) {
      this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainStation;
    } else {
      this.partyMasterDtoForconsignor.mainStation = this.modelMainBookingSource.mainBookStations;
    }

    this.validateIndexValue = null;
    this.validateIndexValue = $("#cnarmindexIdPartySearch").val();
    if (this.validateIndexValue == null || this.validateIndexValue == undefined ||
      this.validateIndexValue == "") {
      this.validateIndexValue = "A";
      this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
    } else {
      this.partyMasterDtoForconsignor.indexValue = this.validateIndexValue;
    }
    this.getConsignorDetailsPartySearch();
  }

  getConsignorDetailsPartySearch = () => {
    this.showSpinnerForConsignorName = true;
    this.masterReadService.getConsignorDetails(this.partyMasterDtoForconsignor).subscribe(
      (response) => {
        if (response.length == 0) {
          this.consignorNameOptions = [];
          this.consignorNamePartySearchTA = [];
          $("#" + this.pageId + "consignorNamePartySearch").val('');
        } else {
          this.consignorNameOptions = [];
          this.consignorNamePartySearchTA = [];
          this.consignorNamePartyDtoAllOption = new PartyMasterDto();
          $("#" + this.pageId + "consignorNamePartySearch").val('');

          this.consignorNameOptions = response;
          this.consignorNamePartyDtoAllOption.consignorName = "All";
          this.consignorNamePartySearchTA.push(this.consignorNamePartyDtoAllOption);
          for (let i = 0; i < this.consignorNameOptions.length; i++) {
            this.consignorNamePartySearchTA.push(this.consignorNameOptions[i]);
          }
        }
        this.showSpinnerForConsignorName = false;
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForConsignorName = false;
        swal("Error", "Server Problem Occurred While getting the consignor details", "info");
      }, () => console.log('done');
  };

  /* Consignor Drop Down Service & Click Listner End Here */

  /*On Key Enter press start here  */
  onKeyPressFieldForBaseChargeAmt(event) {
    if (event == 13) { $("#" + this.pageId + "hamaliChgId").focus(); }
  }

  onKeyPressFieldForHamaliChargeAmt(event) {
    if (event == 13) { $("#" + this.pageId + "gcChargeId").focus(); }
  }

  onKeyPressFieldForGCChargeAmt(event) {
    if (event == 13) { $("#" + this.pageId + "aocChargeId").focus(); }
  }

  onKeyPressFieldForAoc(event) {
    if (event == 13) { $("#" + this.pageId + "ddChargeId").focus(); }
  }

  onKeyPressFieldForDD(event) {
    if (event == 13) { $("#" + this.pageId + "otherChargeId").focus(); }
  }

  onKeyPressFieldForOther(event) {
    if (event == 13) { $("#" + this.pageId + "fovChargeId").focus(); }
  }

  onKeyPressFieldForFov(event) {
    if (event == 13) { $("#" + this.pageId + "fixedBy").focus(); }
  }

  onKeyPressFieldForBaseChargeAmtSub(event) {
    if (event == 13) { $("#" + this.pageId + "hamaliChgIdSub").focus(); }
  }

  onKeyPressFieldForHamaliChargeAmtSub(event) {
    if (event == 13) { $("#" + this.pageId + "gcChargeIdSub").focus(); }
  }

  onKeyPressFieldForGCChargeAmtSub(event) {
    if (event == 13) { $("#" + this.pageId + "aocChargeIdSub").focus(); }
  }

  onKeyPressFieldForAocSub(event) {
    if (event == 13) { $("#" + this.pageId + "ddChargeIdSubb").focus(); }
  }

  onKeyPressFieldForDDSub(event) {
    if (event == 13) { $("#" + this.pageId + "otherChargeIdSu").focus(); }
  }

  onKeyPressFieldForOtherSub(event) {
    if (event == 13) { $("#" + this.pageId + "fovChargeIdSub").focus(); }
  }

  onKeyPressFieldForFovSub(event) {
    if (event == 13) { $("#" + this.pageId + "fixedBySub").focus(); }
  }


  /*On Key Enter press start here  */

  searchByRateTypeMode(searchByRateType: string) {
    // alert(searchByRateType);
    if (searchByRateType === 'Fixed') {
      this.viewActChgWgtField = false;
      this.viewRateOnActWgtField = false;
    } else if (searchByRateType === 'PerKg') {
      this.viewActChgWgtField = true;
      this.viewRateOnActWgtField = true;
    }
  }
  rateForMode(rateFor: string) {
    if (rateFor === 'Commodity') {
      this.viewParty = false;
    } else if (rateFor === 'Party') {
      this.viewParty = true;
      // this.getConsignorDetailsList();
    } else if (rateFor === 'RateForAll') {
      this.viewParty = false;
      if (this.isRights) {
        swal({
          title: "Must Care",
          text: "The changes done through this can not be Undo..So Please take most care. Sure you want to open this pop up",
          icon: "info",
          buttons: ["No", "Yes"]
        }).then((isConfirm) => {
          if (isConfirm) {
            const dialogRef = this.dialog.open(UpdateConsignorRateForAllComponent, {
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog result: ${result}`);
            });
          }
        });
      } else {
        swal("Not Allowed", "User " + this.userDataDtoReturnSession.userId + " Not Allowing to Changes Rate For All.");
      }
    } else if (rateFor === 'RateForAllPartySpec') {
      this.viewParty = false;
      if (this.isRights) {
        swal({
          title: "Must Care",
          text: "The changes done through this can not be Undo..So Please take most care. Sure you want to open this pop up",
          icon: "info",
          buttons: ["No", "Yes"]
        }).then((isConfirm) => {
          if (isConfirm) {
            const dialogRef = this.dialog.open(UpdateConsignorRateForAllPartyComponent, {
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog result: ${result}`);
            });
          }
        });
      } else {
        swal("Not Allowed", "User " + this.userDataDtoReturnSession.userId + " Not Allowing to Changes Rate For All - Party.");
      }
    } else {
      this.viewParty = false;
    }
  }
  validateBeforeSave() {
    this.enteredSourceForSave = null;
    this.selectedRateFor = null;
    this.enteredConsignorNameForSave = null;
    this.enteredConsigneeNameForSave = null;
    this.enteredCommodityForSave = null;
    this.enteredDestinationForSave = null;
    this.enteredRateTypeForSave = null;
    this.enteredPerUnitForSave = null;
    this.rateOnActWgt = null;
    this.enteredBasicRateForSave = null;
    this.enteredHamaliForSave = null;
    this.enteredGCChargeForSave = null;
    this.enteredAOCChargeForSave = null;
    this.enteredDDChargeForSave = null;
    this.enteredOtherChargeForSave = null;
    this.enteredRiskChargeForSave = null;
    this.enteredFixedBy = null;
    this.selectedEeffectDate = null;
    this.selectedFixedOn = null;
    this.enteredRCChargeForSave = null;
    this.enteredSCChargeForSave = null;
    this.enteredDestHamaliForSave = null;
    this.enteredBasicRateForSaveSub = null;
    this.enteredHamaliForSaveSub = null;
    this.enteredGCChargeForSaveSub = null;
    this.enteredAOCChargeForSaveSub = null;
    this.enteredDDChargeForSaveSub = null;
    this.enteredOtherChargeForSaveSub = null;
    this.enteredRiskChargeForSaveSub = null;
    this.enteredFixedBySub = null;
    this.selectedEeffectDateSub = null;
    this.selectedFixedOnSub = null;
    this.enteredRCChargeForSaveSub = null;
    this.enteredSCChargeForSaveSub = null;
    this.enteredDestHamaliForSaveSub = null;


    this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
    this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
    this.enteredConsignorNameForSave = $("#" + this.pageId + "consignorName").val();
    this.enteredConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
    this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
    this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
    this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
    this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
    this.rateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();
    this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
    this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
    this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
    this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
    this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
    this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
    this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
    this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
    this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
    this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
    this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
    this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
    this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
    this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSub").val();
    this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
    this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSub").val();
    this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSub").val();
    this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSub").val();
    this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSub").val();
    this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSub").val();
    this.enteredFixedBySub = $("#" + this.pageId + "fixedBySub").val();
    this.selectedEeffectDateSub = $("#" + this.pageId + "effectFromDateSub").val();
    this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSub").val();
    this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
    this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
    this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();

    console.log("this.subStationCheckStatus");
    console.log(this.subStationCheckStatus);
    //Chg_V1
    console.log("partyDetails");
    console.log(this.enteredConsignorNameForSave);
    console.log(this.enteredConsignorId);
    console.log(this.enteredConsigneeNameForSave);
    console.log(this.enteredConsigneeId);

    if ((this.selectedRateFor == "Commodity") && (this.enteredSourceForSave == null || this.enteredSourceForSave == undefined || this.enteredSourceForSave == ""
      || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined || this.enteredDestinationForSave == ""
      || this.enteredCommodityForSave == null || this.enteredCommodityForSave == undefined || this.enteredCommodityForSave == ""
      || this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
      || this.enteredRiskChargeForSave == null || this.enteredRiskChargeForSave == undefined || this.enteredRiskChargeForSave == ""
      || this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
      || this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
      || this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "")) {
      swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
    } else if ((this.selectedRateFor == "Party") && (this.enteredSourceForSave == null || this.enteredSourceForSave == undefined || this.enteredSourceForSave == ""
      || this.enteredConsignorNameForSave == null || this.enteredConsignorNameForSave == undefined || this.enteredConsignorNameForSave == ""
      || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined || this.enteredDestinationForSave == ""
      || this.enteredCommodityForSave == null || this.enteredCommodityForSave == undefined || this.enteredCommodityForSave == ""
      || this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
      || this.enteredRiskChargeForSave == null || this.enteredRiskChargeForSave == undefined || this.enteredRiskChargeForSave == ""
      || this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
      || this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
      || this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "")) {
      swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
    }
    //Chg_V1
    else if ((this.selectedRateFor == "Party") && (this.enteredConsignorNameForSave != 'ALL')
      && (!(this.enteredConsignorId > 0))) {
      swal("Warning", " Please select a valid Consignor Name", "warning");
    }
    else if ((this.selectedRateFor == "Party") && (this.enteredConsigneeNameForSave != null
      && this.enteredConsigneeNameForSave != undefined && this.enteredConsigneeNameForSave != ''
      && this.enteredConsigneeNameForSave != 'ALL')
      && (!(this.enteredConsigneeId > 0))) {
      swal("Warning", " Please select a valid Consignee Name", "warning");
    }

    else if ((this.subStationCheckStatus == true) && (this.enteredBasicRateForSaveSub == null || this.enteredBasicRateForSaveSub == undefined || this.enteredBasicRateForSaveSub == ""
      || this.enteredRiskChargeForSaveSub == null || this.enteredRiskChargeForSaveSub == undefined || this.enteredRiskChargeForSaveSub == ""
      || this.enteredFixedBySub == null || this.enteredFixedBySub == undefined || this.enteredFixedBySub == ""
      || this.selectedEeffectDateSub == null || this.selectedEeffectDateSub == undefined || this.selectedEeffectDateSub == ""
      || this.selectedFixedOnSub == null || this.selectedFixedOnSub == undefined || this.selectedFixedOnSub == "")) {
      swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
    } else {
      this.validateMainCommoditySelection();
      if (this.isMainCommodityValid) {
        this.confirmSave();
      }
    }

  }

  confirmSave() {
    swal({
      title: "Confirmation",
      text: "Sure you want to Save the changes?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.saveConsignorAdminRateMasterDetails();
      }
    });
  }


  saveConsignorAdminRateMasterDetails() {
    this.rateMasterDtoForSaveUpdate = new RateMasterDto();

    this.enteredSourceForSave = null;
    this.selectedRateFor = null;
    this.enteredConsignorNameForSave = null;
    this.enteredConsigneeNameForSave = null;
    this.enteredCommodityForSave = null;
    this.enteredDestinationForSave = null;
    this.enteredRateTypeForSave = null;
    this.enteredPerUnitForSave = null;
    this.rateOnActWgt = null;
    this.enteredBasicRateForSave = null;
    this.enteredHamaliForSave = null;
    this.enteredGCChargeForSave = null;
    this.enteredAOCChargeForSave = null;
    this.enteredDDChargeForSave = null;
    this.enteredOtherChargeForSave = null;
    this.enteredRiskChargeForSave = null;
    this.enteredFixedBy = null;
    this.selectedEeffectDate = null;
    this.selectedFixedOn = null;
    this.enteredRCChargeForSave = null;
    this.enteredSCChargeForSave = null;
    this.enteredDestHamaliForSave = null;
    this.enteredBasicRateForSaveSub = null;
    this.enteredHamaliForSaveSub = null;
    this.enteredGCChargeForSaveSub = null;
    this.enteredAOCChargeForSaveSub = null;
    this.enteredDDChargeForSaveSub = null;
    this.enteredOtherChargeForSaveSub = null;
    this.enteredRiskChargeForSaveSub = null;
    this.enteredFixedBySub = null;
    this.selectedEeffectDateSub = null;
    this.selectedFixedOnSub = null;
    this.enteredRCChargeForSaveSub = null;
    this.enteredSCChargeForSaveSub = null;
    this.enteredDestHamaliForSaveSub = null;


    this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
    this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
    this.enteredConsignorNameForSave = $("#" + this.pageId + "consignorName").val();
    this.enteredConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
    this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
    this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
    this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
    this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
    this.rateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();
    this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
    this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
    this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
    this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
    this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
    this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
    this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
    this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
    this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
    this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
    this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
    this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
    this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
    // this.subStationCheckStatus = $("#" + this.pageId + "subStationCheckbox").val();
    this.enteredBasicRateForSaveSub = $("#" + this.pageId + "basicRateIdSub").val();
    this.enteredHamaliForSaveSub = $("#" + this.pageId + "hamaliChgIdSub").val();
    this.enteredGCChargeForSaveSub = $("#" + this.pageId + "gcChargeIdSub").val();
    this.enteredAOCChargeForSaveSub = $("#" + this.pageId + "aocChargeIdSub").val();
    this.enteredDDChargeForSaveSub = $("#" + this.pageId + "ddChargeIdSub").val();
    this.enteredOtherChargeForSaveSub = $("#" + this.pageId + "otherChargeIdSub").val();
    this.enteredRiskChargeForSaveSub = $("#" + this.pageId + "fovChargeIdSub").val();
    this.enteredFixedBySub = $("#" + this.pageId + "fixedBySub").val();
    this.selectedEeffectDateSub = $("#" + this.pageId + "effectFromDateSub").val();
    this.selectedFixedOnSub = $("#" + this.pageId + "fixedOnDateSub").val();
    console.log("this.subStationCheckStatus");
    console.log(this.subStationCheckStatus);
    this.enteredRCChargeForSaveSub = $("#" + this.pageId + "rcChargeIdSub").val();
    this.enteredSCChargeForSaveSub = $("#" + this.pageId + "scChargeIdSub").val();
    this.enteredDestHamaliForSaveSub = $("#" + this.pageId + "destHamaliChgIdSub").val();



    // this.listSourceSubStation = [];
    // var multiSubStation = null;
    // if (this.lrDtoListSourceSubStationChecked != null &&
    //   this.lrDtoListSourceSubStationChecked != undefined &&
    //   this.lrDtoListSourceSubStationChecked.length > 0) {
    //   for (let i = 0; i < this.lrDtoListSourceSubStationChecked.length; i++) {
    //     this.listSourceSubStation.push(this.lrDtoListSourceSubStationChecked[i].subStation);
    //   }
    // }

    this.rateMasterDtoForSaveUpdate.source = this.enteredSourceForSave;
    this.rateMasterDtoForSaveUpdate.rateFor = this.selectedRateFor;
    // this.rateMasterDtoForSaveUpdate.list = this.listSourceSubStation;
    console.log("Save >> " + this.enteredConsignorId);
    if (this.selectedRateFor == "Party") {
      this.rateMasterDtoForSaveUpdate.consignorName = this.enteredConsignorNameForSave;
      this.rateMasterDtoForSaveUpdate.consignorId = this.enteredConsignorId;
      //Chg_V2
      // this.rateMasterDtoForSaveUpdate.consigneeId = this.enteredConsigneeId;
      if (this.enteredConsigneeId == null || this.enteredConsigneeId == undefined || this.enteredConsigneeId == '') {
        this.rateMasterDtoForSaveUpdate.consigneeId = 0;
      } else {
        this.rateMasterDtoForSaveUpdate.consigneeId = this.enteredConsigneeId;
      }
      this.rateMasterDtoForSaveUpdate.consigneeName = this.enteredConsigneeNameForSave;
    } else if (this.selectedRateFor == "Commodity") {
      this.rateMasterDtoForSaveUpdate.consignorId = null;
    }
    //alert('On save : ' + this.enteredConsigneeNameForSave);

    this.rateMasterDtoForSaveUpdate.commodityName = this.enteredCommodityForSave == null ? null : this.enteredCommodityForSave == undefined ? null : this.enteredCommodityForSave == "" ? null : this.enteredCommodityForSave;
    this.rateMasterDtoForSaveUpdate.destination = this.enteredDestinationForSave;
    this.rateMasterDtoForSaveUpdate.ratePerUnit = this.enteredRateTypeForSave;
    this.rateMasterDtoForSaveUpdate.perUnit = this.enteredPerUnitForSave == null ? null : this.enteredPerUnitForSave == undefined ? null : this.enteredPerUnitForSave;
    this.rateMasterDtoForSaveUpdate.rateOnActWgt = this.rateOnActWgt == null ? null : this.rateOnActWgt == undefined ? null : this.rateOnActWgt;
    this.rateMasterDtoForSaveUpdate.basicCharge = this.enteredBasicRateForSave == null ? 0.0 : this.enteredBasicRateForSave == undefined ? 0.0 : this.enteredBasicRateForSave == "" ? 0.0 : this.enteredBasicRateForSave;
    this.rateMasterDtoForSaveUpdate.hamali = this.enteredHamaliForSave == null ? 0.0 : this.enteredHamaliForSave == undefined ? 0.0 : this.enteredHamaliForSave == "" ? 0.0 : this.enteredHamaliForSave;
    this.rateMasterDtoForSaveUpdate.gcCharge = this.enteredGCChargeForSave == null ? 0.0 : this.enteredGCChargeForSave == undefined ? 0.0 : this.enteredGCChargeForSave == "" ? 0.0 : this.enteredGCChargeForSave;
    this.rateMasterDtoForSaveUpdate.aocChargeAmt = this.enteredAOCChargeForSave == null ? 0.0 : this.enteredAOCChargeForSave == undefined ? 0.0 : this.enteredAOCChargeForSave == "" ? 0.0 : this.enteredAOCChargeForSave;
    this.rateMasterDtoForSaveUpdate.ddChargeAmt = this.enteredDDChargeForSave == null ? 0.0 : this.enteredDDChargeForSave == undefined ? 0.0 : this.enteredDDChargeForSave == "" ? 0.0 : this.enteredDDChargeForSave;
    this.rateMasterDtoForSaveUpdate.otherChgAmt = this.enteredOtherChargeForSave == null ? 0.0 : this.enteredOtherChargeForSave == undefined ? 0.0 : this.enteredOtherChargeForSave == "" ? 0.0 : this.enteredOtherChargeForSave;
    this.rateMasterDtoForSaveUpdate.riskChargeAmt = this.enteredRiskChargeForSave == null ? 0.0 : this.enteredRiskChargeForSave == undefined ? 0.0 : this.enteredRiskChargeForSave == "" ? 0.0 : this.enteredRiskChargeForSave;
    this.rateMasterDtoForSaveUpdate.fixedBy = this.enteredFixedBy == null ? 0.0 : this.enteredFixedBy == undefined ? 0.0 : this.enteredFixedBy == "" ? 0.0 : this.enteredFixedBy;
    this.rateMasterDtoForSaveUpdate.effectiveFromDate = this.selectedEeffectDate == null ? 0.0 : this.selectedEeffectDate == undefined ? 0.0 : this.selectedEeffectDate == "" ? 0.0 : this.selectedEeffectDate;
    this.rateMasterDtoForSaveUpdate.fixedDate = this.selectedFixedOn == null ? 0.0 : this.selectedFixedOn == undefined ? 0.0 : this.selectedFixedOn == "" ? 0.0 : this.selectedFixedOn;
    this.rateMasterDtoForSaveUpdate.receiptCharges = this.enteredRCChargeForSave == null ? 0.0 : this.enteredRCChargeForSave == undefined ? 0.0 : this.enteredRCChargeForSave == "" ? 0.0 : this.enteredRCChargeForSave;
    this.rateMasterDtoForSaveUpdate.serviceCharges = this.enteredSCChargeForSave == null ? 0.0 : this.enteredSCChargeForSave == undefined ? 0.0 : this.enteredSCChargeForSave == "" ? 0.0 : this.enteredSCChargeForSave;
    this.rateMasterDtoForSaveUpdate.destHamali = this.enteredDestHamaliForSave == null ? 0.0 : this.enteredDestHamaliForSave == undefined ? 0.0 : this.enteredDestHamaliForSave == "" ? 0.0 : this.enteredDestHamaliForSave;
    this.rateMasterDtoForSaveUpdate.subStationRate = this.subStationCheckStatus;
    this.rateMasterDtoForSaveUpdate.basicChargeSub = this.enteredBasicRateForSaveSub == null ? 0.0 : this.enteredBasicRateForSaveSub == undefined ? 0.0 : this.enteredBasicRateForSaveSub == "" ? 0.0 : this.enteredBasicRateForSaveSub;
    this.rateMasterDtoForSaveUpdate.hamaliSub = this.enteredHamaliForSaveSub == null ? 0.0 : this.enteredHamaliForSaveSub == undefined ? 0.0 : this.enteredHamaliForSaveSub == "" ? 0.0 : this.enteredHamaliForSaveSub;
    this.rateMasterDtoForSaveUpdate.gcChargeSub = this.enteredGCChargeForSaveSub == null ? 0.0 : this.enteredGCChargeForSaveSub == undefined ? 0.0 : this.enteredGCChargeForSaveSub == "" ? 0.0 : this.enteredGCChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.aocChargeAmtSub = this.enteredAOCChargeForSaveSub == null ? 0.0 : this.enteredAOCChargeForSaveSub == undefined ? 0.0 : this.enteredAOCChargeForSaveSub == "" ? 0.0 : this.enteredAOCChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.ddChargeAmtSub = this.enteredDDChargeForSaveSub == null ? 0.0 : this.enteredDDChargeForSaveSub == undefined ? 0.0 : this.enteredDDChargeForSaveSub == "" ? 0.0 : this.enteredDDChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.otherChgAmtSub = this.enteredOtherChargeForSaveSub == null ? 0.0 : this.enteredOtherChargeForSaveSub == undefined ? 0.0 : this.enteredOtherChargeForSaveSub == "" ? 0.0 : this.enteredOtherChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.riskChargeAmtSub = this.enteredRiskChargeForSaveSub == null ? 0.0 : this.enteredRiskChargeForSaveSub == undefined ? 0.0 : this.enteredRiskChargeForSaveSub == "" ? 0.0 : this.enteredRiskChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.receiptChargesSub = this.enteredRCChargeForSaveSub == null ? 0.0 : this.enteredRCChargeForSaveSub == undefined ? 0.0 : this.enteredRCChargeForSaveSub == "" ? 0.0 : this.enteredRCChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.serviceChargesSub = this.enteredSCChargeForSaveSub == null ? 0.0 : this.enteredSCChargeForSaveSub == undefined ? 0.0 : this.enteredSCChargeForSaveSub == "" ? 0.0 : this.enteredSCChargeForSaveSub;
    this.rateMasterDtoForSaveUpdate.destHamaliSub = this.enteredDestHamaliForSaveSub == null ? 0.0 : this.enteredDestHamaliForSaveSub == undefined ? 0.0 : this.enteredDestHamaliForSaveSub == "" ? 0.0 : this.enteredDestHamaliForSaveSub;
    this.rateMasterDtoForSaveUpdate.fixedBySub = this.enteredFixedBySub == null ? 0.0 : this.enteredFixedBySub == undefined ? 0.0 : this.enteredFixedBySub == "" ? 0.0 : this.enteredFixedBySub;
    this.rateMasterDtoForSaveUpdate.effectiveFromDateSub = this.selectedEeffectDateSub == null ? 0.0 : this.selectedEeffectDateSub == undefined ? 0.0 : this.selectedEeffectDateSub == "" ? 0.0 : this.selectedEeffectDateSub;
    this.rateMasterDtoForSaveUpdate.fixedDateSub = this.selectedFixedOnSub == null ? 0.0 : this.selectedFixedOnSub == undefined ? 0.0 : this.selectedFixedOnSub == "" ? 0.0 : this.selectedFixedOnSub;
    this.rateMasterDtoForSaveUpdate.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDtoForSaveUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDtoForSaveUpdate.id = this.selectedAutoId;
    this.rateMasterDtoForSaveUpdate.mainStation = this.selectedDropDownMainStation;
    console.log("this.rateMasterDtoForSaveUpdate >> Save");
    console.log(this.rateMasterDtoForSaveUpdate);
    this.showSpinnerForAction = true;
    this.consignorService.saveConsignorAdminRateMaster(this.rateMasterDtoForSaveUpdate).subscribe(
      (response) => {
        console.log(response);
        if (response.status == "success") {
          swal("Success", " Consignor Rate Saved Successfully", "success");
          this.clearFields();

          //temp commented below refresh to avoid the slowness, should enable later
          //this.gettingPartyDataList();
          //this.gettingCommodityDataList();

        } else if (response.status == "duplicate") {
          swal("Duplicate Entry", " Entered type of rate is already exist, Please Verify!!!", "info");
        }
        this.showSpinnerForAction = false;

        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while saving Consignor Rate Master details", "info");
      }, () => console.log('done');

  }

  rowCheckBoxCheckedForSourceSubStation(e, sourceSubStationData, index) {
    if (e.currentTarget.checked) {
      this.newAttributeSetSourceSubStationList = {};
      this.newAttributeSetSourceSubStationList.subStation = sourceSubStationData.subStation;
      if (this.lrDtoListSourceSubStationChecked == null ||
        this.lrDtoListSourceSubStationChecked == undefined) {
        this.lrDtoListSourceSubStationChecked = [];
      }
      if (this.lrDtoListSourceSubStationChecked.length == 0) {
        this.lrDtoListSourceSubStationChecked.push(this.newAttributeSetSourceSubStationList);
      } else {
        const result = this.lrDtoListSourceSubStationChecked.filter(lrDtoListSourceSubStationData =>
          lrDtoListSourceSubStationData.subStation === this.newAttributeSetSourceSubStationList.subStation);
        if (result.length > 0) {
          swal("Duplicate Entry", "" + this.newAttributeSetSourceSubStationList.subStation + " Already Exist", "warning");
        } else {
          this.lrDtoListSourceSubStationChecked.push(this.newAttributeSetSourceSubStationList);
        }
      }
    } else {
      this.lrDtoListSourceSubStationChecked.splice(index, 1);
    }
    console.log(this.lrDtoListSourceSubStationChecked);
  }

  clearFields() {
    // $("#" + this.pageId + "agentName").val("");
    // $("#" + this.pageId + "destination").val("");
    // $("#" + this.pageId + "commodityListId").val("");
    // $("#" + this.pageId + "consignorName").val("");
    this.enteredConsignorId = 0;
    this.enteredConsigneeId = 0;
    this.viewActChgWgtField = false;
    this.viewRateOnActWgtField = false;
    this.enteredConsignorIdPartySearch = 0;

    //below fields are commented to not clear as per user requested
    //this.agentNameModal = null;
    //this.selectedSorceDropDown = null;
    //this.enteredSourceForSave = null;
    //this.selectedDropDownMainStation = null;
    //$("#" + this.pageId + "agentName").val('');
    //$("#" + this.pageId + "fixedBy").val('');
    //$("#" + this.pageId + "commodityListId").val('');
    //this.selectedRateFor = null;
    //$("#" + this.pageId + "rateFor").val("Commodity");
    //$("#" + this.pageId + "consignorName").val('');
    //this.enteredConsignorNameForSave = null;
    //this.viewParty = false;

    this.enteredCommodityForSave = null;
    this.enteredDestinationForSave = null;
    this.enteredRateTypeForSave = null;
    this.enteredBasicRateForSave = null;
    this.enteredHamaliForSave = null;
    this.enteredGCChargeForSave = null;
    this.enteredAOCChargeForSave = null;
    this.enteredDDChargeForSave = null;
    this.enteredOtherChargeForSave = null;
    this.enteredRiskChargeForSave = null;
    this.enteredFixedBy = null;
    this.selectedEeffectDate = null;
    this.selectedFixedOn = null;
    this.enteredRCChargeForSave = null;
    this.enteredSCChargeForSave = null;
    this.enteredDestHamaliForSave = null;
    this.subStationCheckStatus = false;
    this.enteredBasicRateForSaveSub = null;
    this.enteredHamaliForSaveSub = null;
    this.enteredGCChargeForSaveSub = null;
    this.enteredAOCChargeForSaveSub = null;
    this.enteredDDChargeForSaveSub = null;
    this.enteredOtherChargeForSaveSub = null;
    this.enteredRiskChargeForSaveSub = null;
    this.enteredFixedBySub = null;
    this.selectedEeffectDateSub = null;
    this.selectedFixedOnSub = null;
    this.enteredRCChargeForSaveSub = null;
    this.enteredSCChargeForSaveSub = null;
    this.enteredDestHamaliForSaveSub = null;
    this.enteredPerUnitForSave = null;
    this.rateOnActWgt = null;

    this.viewSubRate = false;
    $("#" + this.pageId + "subStationCheckbox").prop("checked", false);
    $("#" + this.pageId + "searchByRateType").val("Fixed");
    $("#" + this.pageId + "destination").val('');
    $("#" + this.pageId + "perUnitId").val('');
    this.perUnitIdModal = null;
    $("#" + this.pageId + "rateOnActWgt").val('');
    $("#" + this.pageId + "basicRateId").val('');
    $("#" + this.pageId + "hamaliChgId").val('');
    $("#" + this.pageId + "gcChargeId").val('');
    $("#" + this.pageId + "aocChargeId").val('');
    $("#" + this.pageId + "ddChargeId").val('');
    $("#" + this.pageId + "otherChargeId").val('');
    $("#" + this.pageId + "fovChargeId").val('');
    $("#" + this.pageId + "effectFromDate").val('');
    $("#" + this.pageId + "fixedOnDate").val('');
    $("#" + this.pageId + "rcChargeId").val('');
    $("#" + this.pageId + "scChargeId").val('');
    $("#" + this.pageId + "destHamaliChgId").val('');
    $("#" + this.pageId + "basicRateIdSub").val('');
    $("#" + this.pageId + "hamaliChgIdSub").val('');
    $("#" + this.pageId + "gcChargeIdSub").val('');
    $("#" + this.pageId + "aocChargeIdSub").val('');
    $("#" + this.pageId + "ddChargeIdSub").val('');
    $("#" + this.pageId + "otherChargeIdSub").val('');
    $("#" + this.pageId + "fovChargeIdSub").val('');
    $("#" + this.pageId + "fixedBySub").val('');
    $("#" + this.pageId + "effectFromDateSub").val('');
    $("#" + this.pageId + "fixedOnDateSub").val('');
    $("#" + this.pageId + "rcChargeIdSub").val('');
    $("#" + this.pageId + "scChargeIdSub").val('');
    $("#" + this.pageId + "destHamaliChgIdSub").val('');
    this.selectedAutoId = 0;
    this.viewUpdateBtn = false;
    this.viewSaveBtn = true;
    this.viewDeleteBtn = false;
    this.isMainCommodityValid = false;
    // this.tableCommodity = $("#" + this.pageId + "ConsignorAdminRateMasterForCommodityTableId").DataTable();
    // console.log("tableCommodity.rows('.selected').any() >> Clear");
    // console.log(this.tableCommodity.rows('.selected').any());

    // this.tableCommodity.rows('.important').deselect();
    // .rows('.selected')
    // .nodes()
    // .to$()
    // .removeClass('selected');

    // this.tableParty = $("#" + this.pageId + "ConsignorAdminRateMasterForPartyTableId").DataTable();
    // console.log("tableParty.rows('.selected').any() >> Clear");
    // console.log(this.tableParty.rows('.selected').any());

    // this.tableParty.rows('.important').deselect();
    // .rows('.selected')
    // .nodes()
    // .to$()
    // .removeClass('selected');
    // table.rows({ selected: true }).deselect();

    //$("#" + this.pageId + "destination").focus();
    this.modelConsignorName = null;
    this.modelConsigneeName = null;
    this.modelConsignorNamePartySearch = null;
    this.changeDetectorRef.detectChanges();
  }

  toggleSubStationCheckbox(event) {
    if (event.target.checked) {
      this.viewSubRate = true;
      this.subStationCheckStatus = true;
      this.modelFixedBySub = this.modelfixedBy;
    } else {
      this.viewSubRate = false;
      this.subStationCheckStatus = false;
    }
  }

  confirmClear() {
    swal({
      title: "Confirmation",
      text: "Sure you want to Clear?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.clearFields();
        this.clearTable();
      }
    });
  }

  getDetailsForCommdtiy() {
    this.rateMasterDtoForGetDetails = new RateMasterDto();
    this.rateMasterDtoForGetDetails.rateFor = "Commodity";
    if (this.sourceView == false) {
      this.rateMasterDtoForGetDetails.mainStation = this.userDataDtoReturnSession.mainStation;
    } else {
      this.rateMasterDtoForGetDetails.mainStation = this.modelMainBookingSource.mainBookStations;
    }
    this.rateMasterDtoForGetDetails.companyId = this.userDataDtoReturnSession.companyId;

    this.selectedSourceForSearch = $("#" + this.pageId + "agentNameRptSearch").val();
    this.selectedCommodityForSearch = $("#" + this.pageId + "commodityListIdSearchRpt").val();
    this.selectedDestinationForSearch = $("#" + this.pageId + "destinationSearchRpt").val();
    this.rateMasterDtoForGetDetails.source = this.selectedSourceForSearch;
    this.rateMasterDtoForGetDetails.commodityName = this.selectedCommodityForSearch;
    this.rateMasterDtoForGetDetails.destination = this.selectedDestinationForSearch;
  }
  gettingCommodityDataList = () => {
    this.getDetailsForCommdtiy();
    this.showSpinnerForAction = true;
    this.consignorService.getConsignorAdminRateMasterDetails(this.rateMasterDtoForGetDetails).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No records found for Commodity details !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.consignorAdminRateMasterDataListForCommodity = [];
          $("#" + this.pageId + "ConsignorAdminRateMasterForCommodityTableId").DataTable().destroy();
          this.consignorAdminRateMasterDataListForCommodity = response;
        }
        this.dtTriggerForConsignorAdminRateMasterForCommodity.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignor Rate Commodity Details", "info");
      }, () => console.log('done');
  }


  getDetailsForParty() {
    this.rateMasterDtoForGetDetails = new RateMasterDto();
    this.rateMasterDtoForGetDetails.rateFor = "Party";

    if (this.sourceView == false) {
      this.rateMasterDtoForGetDetails.mainStation = this.userDataDtoReturnSession.mainStation;
    } else {
      this.rateMasterDtoForGetDetails.mainStation = this.modelMainBookingSource.mainBookStations;
    }

    this.rateMasterDtoForGetDetails.companyId = this.userDataDtoReturnSession.companyId;

    this.selectedSourceForPartySearch = $("#" + this.pageId + "agentNameRptSearchParty").val();
    this.selectedCommodityForPartySearch = $("#" + this.pageId + "commodityListIdSearchRptParty").val();
    this.selectedDestinationForPartySearch = $("#" + this.pageId + "destinationSearchRptParty").val();
    this.selectedConsignorForPartySearch = $("#" + this.pageId + "consignorNamePartySearch").val();
    this.rateMasterDtoForGetDetails.source = this.selectedSourceForPartySearch;
    this.rateMasterDtoForGetDetails.commodityName = this.selectedCommodityForPartySearch;
    this.rateMasterDtoForGetDetails.destination = this.selectedDestinationForPartySearch;
    this.rateMasterDtoForGetDetails.consignorId = this.enteredConsignorIdPartySearch;
    this.rateMasterDtoForGetDetails.consignorName = this.selectedConsignorForPartySearch;
  }
  gettingPartyDataList = () => {
    this.getDetailsForParty();
    this.showSpinnerForAction = true;
    this.consignorService.getConsignorAdminRateMasterDetails(this.rateMasterDtoForGetDetails).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No records found for Party details !",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.consignorAdminRateMasterDataListForParty = [];
          $("#" + this.pageId + "ConsignorAdminRateMasterForPartyTableId").DataTable().destroy();
          this.consignorAdminRateMasterDataListForParty = response;
        }

        this.dtTriggerForConsignorAdminRateMasterForParty.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignor Rate Party Details", "info");
      }, () => console.log('done');
  }

  clearTable() {
    // this.consignorAdminRateMasterDataListForParty = [];
    $("#" + this.pageId + "ConsignorAdminRateMasterForPartyTableId").DataTable().destroy();
    this.dtTriggerForConsignorAdminRateMasterForParty.next();
    // this.consignorAdminRateMasterDataListForCommodity = [];
    $("#" + this.pageId + "ConsignorAdminRateMasterForCommodityTableId").DataTable().destroy();
    this.dtTriggerForConsignorAdminRateMasterForCommodity.next();
  }

  editOnSelecteCommodityRateDetails(consignorAdminRateMasterData) {
    this.tableCommodity = $("#" + this.pageId + "ConsignorAdminRateMasterForCommodityTableId").DataTable();
    console.log("tableCommodity.rows('.selected').any() >> Commodity click");
    console.log(this.tableCommodity.rows('.selected').any());

    this.showSpinnerForAction = true;
    setTimeout(() => {
      if (this.tableCommodity.rows('.selected').any()) {
        this.commdoityTableSelected(consignorAdminRateMasterData);
      } else {
        this.clearFields();
      }
    }, 100);
    this.showSpinnerForAction = false;
    this.changeDetectorRef.detectChanges();
  }
  commdoityTableSelected(consignorAdminRateMasterData) {
    if (consignorAdminRateMasterData.ratePerUnit === "PerKg") {
      this.viewActChgWgtField = true;
      this.viewRateOnActWgtField = true;
    } if (consignorAdminRateMasterData.ratePerUnit === "Fixed") {
      this.viewActChgWgtField = false;
      this.viewRateOnActWgtField = false;
    }
    this.viewParty = false;
    this.viewSaveBtn = false;
    this.viewUpdateBtn = true;
    this.viewDeleteBtn = true;
    console.log("consignorAdminRateMasterData");
    console.log(consignorAdminRateMasterData);
    this.selectedAutoId = consignorAdminRateMasterData.id;
    this.selectedDropDownMainStation = consignorAdminRateMasterData.mainStation;
    this.subStationCheckStatus = consignorAdminRateMasterData.subStationRate;
    $("#" + this.pageId + "searchByRateType").val(consignorAdminRateMasterData.ratePerUnit);
    $("#" + this.pageId + "rateFor").val(consignorAdminRateMasterData.rateFor);
    $("#" + this.pageId + "agentName").val(consignorAdminRateMasterData.source);
    $("#" + this.pageId + "commodityListId").val(consignorAdminRateMasterData.commodityName);
    $("#" + this.pageId + "destination").val(consignorAdminRateMasterData.destination);
    $("#" + this.pageId + "perUnitId").val(consignorAdminRateMasterData.perUnit);
    this.perUnitIdModal = consignorAdminRateMasterData.perUnit;
    $("#" + this.pageId + "basicRateId").val(consignorAdminRateMasterData.basicCharge);
    $("#" + this.pageId + "hamaliChgId").val(consignorAdminRateMasterData.hamali);
    $("#" + this.pageId + "gcChargeId").val(consignorAdminRateMasterData.gcCharge);
    $("#" + this.pageId + "aocChargeId").val(consignorAdminRateMasterData.aocChargeAmt);
    $("#" + this.pageId + "ddChargeId").val(consignorAdminRateMasterData.ddChargeAmt);
    $("#" + this.pageId + "otherChargeId").val(consignorAdminRateMasterData.otherChgAmt);
    $("#" + this.pageId + "fovChargeId").val(consignorAdminRateMasterData.riskChargeAmt);
    $("#" + this.pageId + "rcChargeId").val(consignorAdminRateMasterData.receiptCharges);
    $("#" + this.pageId + "scChargeId").val(consignorAdminRateMasterData.serviceCharges);
    $("#" + this.pageId + "destHamaliChgId").val(consignorAdminRateMasterData.destHamali);
    $("#" + this.pageId + "fixedBy").val(consignorAdminRateMasterData.fixedBy);
    $("#" + this.pageId + "effectFromDate").val(consignorAdminRateMasterData.effectiveFromDate);
    $("#" + this.pageId + "fixedOnDate").val(consignorAdminRateMasterData.fixedDate);
    window.setTimeout(function () {
      $("#cnarmconsignorName").val(consignorAdminRateMasterData.consignorName);
      this.modelConsignorName = consignorAdminRateMasterData.consignorName;
      $("#cnarmconsigneeNameId").val(consignorAdminRateMasterData.consigneeName);
      this.modelConsigneeName = consignorAdminRateMasterData.consigneeName;
    }, 100);
    console.log("cnorId Commodity >>" + consignorAdminRateMasterData.consignorId);
    this.enteredConsignorId = consignorAdminRateMasterData.consignorId;
    this.enteredConsigneeId = consignorAdminRateMasterData.consigneeId;
    console.log("Commodity >> " + this.enteredConsignorId);
    this.changeDetectorRef.detectChanges();
  }

  editOnSelectePartyRateDetails(consignorAdminRateMasterData) {
    this.showSpinnerForAction = true;
    this.tableParty = $("#" + this.pageId + "ConsignorAdminRateMasterForPartyTableId").DataTable();
    console.log("tableParty.rows('.selected').any() >> Selected Party");
    console.log(this.tableParty.rows('.selected').any());

    setTimeout(() => {
      if (this.tableParty.rows('.selected').any()) {
        this.partyTableSelected(consignorAdminRateMasterData);
      } else {
        this.clearFields();
      }
      this.changeDetectorRef.detectChanges();
    }, 100);
    this.showSpinnerForAction = false;
  }

  partyTableSelected(consignorAdminRateMasterData) {
    if (consignorAdminRateMasterData.ratePerUnit === "PerKg") {
      this.viewActChgWgtField = true;
      this.viewRateOnActWgtField = true;
    } if (consignorAdminRateMasterData.ratePerUnit === "Fixed") {
      this.viewActChgWgtField = false;
      this.viewRateOnActWgtField = false;
    }
    this.viewParty = true;
    this.viewSaveBtn = false;
    this.viewUpdateBtn = true;
    this.viewDeleteBtn = true;
    console.log("consignorAdminRateMasterData >> Party");
    console.log(consignorAdminRateMasterData);
    this.selectedAutoId = consignorAdminRateMasterData.id;
    this.selectedDropDownMainStation = consignorAdminRateMasterData.mainStation;
    this.subStationCheckStatus = consignorAdminRateMasterData.subStationRate;
    $("#" + this.pageId + "searchByRateType").val(consignorAdminRateMasterData.ratePerUnit);
    $("#" + this.pageId + "rateFor").val(consignorAdminRateMasterData.rateFor);
    $("#" + this.pageId + "agentName").val(consignorAdminRateMasterData.source);
    $("#" + this.pageId + "commodityListId").val(consignorAdminRateMasterData.commodityName);
    $("#" + this.pageId + "destination").val(consignorAdminRateMasterData.destination);
    $("#" + this.pageId + "perUnitId").val(consignorAdminRateMasterData.perUnit);
    this.perUnitIdModal = consignorAdminRateMasterData.perUnit;
    $("#" + this.pageId + "basicRateId").val(consignorAdminRateMasterData.basicCharge);
    $("#" + this.pageId + "hamaliChgId").val(consignorAdminRateMasterData.hamali);
    $("#" + this.pageId + "gcChargeId").val(consignorAdminRateMasterData.gcCharge);
    $("#" + this.pageId + "aocChargeId").val(consignorAdminRateMasterData.aocChargeAmt);
    $("#" + this.pageId + "ddChargeId").val(consignorAdminRateMasterData.ddChargeAmt);
    $("#" + this.pageId + "otherChargeId").val(consignorAdminRateMasterData.otherChgAmt);
    $("#" + this.pageId + "fovChargeId").val(consignorAdminRateMasterData.riskChargeAmt);
    $("#" + this.pageId + "rcChargeId").val(consignorAdminRateMasterData.receiptCharges);
    $("#" + this.pageId + "scChargeId").val(consignorAdminRateMasterData.serviceCharges);
    $("#" + this.pageId + "destHamaliChgId").val(consignorAdminRateMasterData.destHamali);
    $("#" + this.pageId + "fixedBy").val(consignorAdminRateMasterData.fixedBy);
    $("#" + this.pageId + "effectFromDate").val(consignorAdminRateMasterData.effectiveFromDate);
    $("#" + this.pageId + "fixedOnDate").val(consignorAdminRateMasterData.fixedDate);
    window.setTimeout(function () {
      $("#cnarmconsignorName").val(consignorAdminRateMasterData.consignorName);
      this.modelConsignorName = consignorAdminRateMasterData.consignorName;
      $("#cnarmconsigneeNameId").val(consignorAdminRateMasterData.consigneeName);
      this.modelConsigneeName = consignorAdminRateMasterData.consigneeName;
    }, 100);
    console.log("cnorId Party >>" + consignorAdminRateMasterData.consignorId)
    this.enteredConsignorId = consignorAdminRateMasterData.consignorId;
    this.enteredConsigneeId = consignorAdminRateMasterData.consigneeId;
    console.log("Party >> " + this.enteredConsignorId);
    this.changeDetectorRef.detectChanges();
  }

  validateBeforeUpdate() {

    this.enteredSourceForSave = null;
    this.selectedRateFor = null;
    this.enteredConsignorNameForSave = null;
    this.enteredConsigneeNameForSave = null;
    this.enteredCommodityForSave = null;
    this.enteredDestinationForSave = null;
    this.enteredRateTypeForSave = null;
    this.enteredPerUnitForSave = null;
    this.rateOnActWgt = null;
    this.enteredBasicRateForSave = null;
    this.enteredHamaliForSave = null;
    this.enteredGCChargeForSave = null;
    this.enteredAOCChargeForSave = null;
    this.enteredDDChargeForSave = null;
    this.enteredOtherChargeForSave = null;
    this.enteredRiskChargeForSave = null;
    this.enteredFixedBy = null;
    this.selectedEeffectDate = null;
    this.selectedFixedOn = null;
    this.enteredRCChargeForSave = null;
    this.enteredSCChargeForSave = null;
    this.enteredDestHamaliForSave = null;


    this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
    this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
    this.enteredConsignorNameForSave = $("#" + this.pageId + "consignorName").val();
    this.enteredConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
    this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
    this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
    this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
    this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
    this.rateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();
    this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
    this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
    this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
    this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
    this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
    this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
    this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
    this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
    this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
    this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
    this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
    this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
    this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();


    console.log("this.subStationCheckStatus");
    console.log(this.subStationCheckStatus);
    //Chg_V1
    console.log("update partyDetails");
    console.log(this.enteredConsignorNameForSave);
    console.log(this.enteredConsignorId);
    console.log(this.enteredConsigneeNameForSave);
    console.log(this.enteredConsigneeId);


    if ((this.selectedRateFor == "Commodity") && (this.enteredSourceForSave == null || this.enteredSourceForSave == undefined || this.enteredSourceForSave == ""
      || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined || this.enteredDestinationForSave == ""
      || this.enteredCommodityForSave == null || this.enteredCommodityForSave == undefined || this.enteredCommodityForSave == ""
      || this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
      || this.enteredRiskChargeForSave == null || this.enteredRiskChargeForSave == undefined || this.enteredRiskChargeForSave == ""
      || this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
      || this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
      || this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "")) {
      swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
    } else if ((this.selectedRateFor == "Party") && (this.enteredSourceForSave == null || this.enteredSourceForSave == undefined || this.enteredSourceForSave == ""
      || this.enteredConsignorNameForSave == null || this.enteredConsignorNameForSave == undefined || this.enteredConsignorNameForSave == ""
      || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined || this.enteredDestinationForSave == ""
      || this.enteredCommodityForSave == null || this.enteredCommodityForSave == undefined || this.enteredCommodityForSave == ""
      || this.enteredBasicRateForSave == null || this.enteredBasicRateForSave == undefined || this.enteredBasicRateForSave == ""
      || this.enteredRiskChargeForSave == null || this.enteredRiskChargeForSave == undefined || this.enteredRiskChargeForSave == ""
      || this.enteredFixedBy == null || this.enteredFixedBy == undefined || this.enteredFixedBy == ""
      || this.selectedEeffectDate == null || this.selectedEeffectDate == undefined || this.selectedEeffectDate == ""
      || this.selectedFixedOn == null || this.selectedFixedOn == undefined || this.selectedFixedOn == "")) {
      swal("Mandatory Fields", " Please Fill Mandatory fields (*)", "warning");
    }
    //Chg_V1
    else if ((this.selectedRateFor == "Party") && (this.enteredConsignorNameForSave != 'ALL')
      && (this.enteredConsignorId == 0)) {
      swal("Warning", "Please select a valid Consignor Name", "warning");
    }
    else if ((this.selectedRateFor == "Party") && (this.enteredConsigneeNameForSave != null
      && this.enteredConsigneeNameForSave != undefined && this.enteredConsigneeNameForSave != ''
      && this.enteredConsigneeNameForSave != 'ALL')
      && (this.enteredConsigneeId == 0)) {
      swal("Warning", "Please select a valid Consignee Name", "warning");
    }

    else {
      this.validateMainCommoditySelection();
      if (this.isMainCommodityValid) {
        this.confirmUpdate();
      }
    }
  }

  confirmUpdate() {
    swal({
      title: "Confirmation",
      text: "Sure you want to Update the Selected  Detials?",
      icon: "info",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((yesBtn) => {
      if (yesBtn) {
        this.updateConsignorAdminRateMasterDetails();
      }
    });
  }


  updateConsignorAdminRateMasterDetails() {
    this.rateMasterDtoForSaveUpdate = new RateMasterDto();

    this.enteredSourceForSave = null;
    this.selectedRateFor = null;
    this.enteredConsignorNameForSave = null;
    this.enteredConsigneeNameForSave = null;
    this.enteredCommodityForSave = null;
    this.enteredDestinationForSave = null;
    this.enteredRateTypeForSave = null;
    this.enteredPerUnitForSave = null;
    this.rateOnActWgt = null;
    this.enteredBasicRateForSave = null;
    this.enteredHamaliForSave = null;
    this.enteredGCChargeForSave = null;
    this.enteredAOCChargeForSave = null;
    this.enteredDDChargeForSave = null;
    this.enteredOtherChargeForSave = null;
    this.enteredRiskChargeForSave = null;
    this.enteredFixedBy = null;
    this.selectedEeffectDate = null;
    this.selectedFixedOn = null;
    this.enteredRCChargeForSave = null;
    this.enteredSCChargeForSave = null;
    this.enteredDestHamaliForSave = null;


    this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
    this.selectedRateFor = $("#" + this.pageId + "rateFor").val();
    this.enteredConsignorNameForSave = $("#" + this.pageId + "consignorName").val();
    this.enteredConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
    this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
    this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
    this.enteredRateTypeForSave = $("#" + this.pageId + "searchByRateType").val();
    this.enteredPerUnitForSave = $("#" + this.pageId + "perUnitId").val();
    this.rateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();
    this.enteredBasicRateForSave = $("#" + this.pageId + "basicRateId").val();
    this.enteredHamaliForSave = $("#" + this.pageId + "hamaliChgId").val();
    this.enteredGCChargeForSave = $("#" + this.pageId + "gcChargeId").val();
    this.enteredAOCChargeForSave = $("#" + this.pageId + "aocChargeId").val();
    this.enteredDDChargeForSave = $("#" + this.pageId + "ddChargeId").val();
    this.enteredOtherChargeForSave = $("#" + this.pageId + "otherChargeId").val();
    this.enteredRiskChargeForSave = $("#" + this.pageId + "fovChargeId").val();
    this.enteredFixedBy = $("#" + this.pageId + "fixedBy").val();
    this.selectedEeffectDate = $("#" + this.pageId + "effectFromDate").val();
    this.selectedFixedOn = $("#" + this.pageId + "fixedOnDate").val();
    this.enteredRCChargeForSave = $("#" + this.pageId + "rcChargeId").val();
    this.enteredSCChargeForSave = $("#" + this.pageId + "scChargeId").val();
    this.enteredDestHamaliForSave = $("#" + this.pageId + "destHamaliChgId").val();
    // this.subStationCheckStatus = $("#" + this.pageId + "subStationCheckbox").val();
    console.log("this.subStationCheckStatus");
    console.log(this.subStationCheckStatus);

    this.rateMasterDtoForSaveUpdate.source = this.enteredSourceForSave;
    this.rateMasterDtoForSaveUpdate.rateFor = this.selectedRateFor;
    // this.rateMasterDtoForSaveUpdate.list = this.listSourceSubStation;
    console.log("update >> " + this.enteredConsignorId);
    if (this.selectedRateFor == "Party") {
      this.rateMasterDtoForSaveUpdate.consignorName = this.enteredConsignorNameForSave;
      this.rateMasterDtoForSaveUpdate.consignorId = this.enteredConsignorId;
      //Chg_V2
      // this.rateMasterDtoForSaveUpdate.consigneeId = this.enteredConsigneeId;
      if (this.enteredConsigneeId == null || this.enteredConsigneeId == undefined || this.enteredConsigneeId == '') {
        this.rateMasterDtoForSaveUpdate.consigneeId = 0;
      } else {
        this.rateMasterDtoForSaveUpdate.consigneeId = this.enteredConsigneeId;
      }
      this.rateMasterDtoForSaveUpdate.consigneeName = this.enteredConsigneeNameForSave;
    } else if (this.selectedRateFor == "Commodity") {
      this.rateMasterDtoForSaveUpdate.consignorId = null;
    }

    this.rateMasterDtoForSaveUpdate.commodityName = this.enteredCommodityForSave == null ? null : this.enteredCommodityForSave == undefined ? null : this.enteredCommodityForSave == "" ? null : this.enteredCommodityForSave;
    this.rateMasterDtoForSaveUpdate.destination = this.enteredDestinationForSave;
    this.rateMasterDtoForSaveUpdate.ratePerUnit = this.enteredRateTypeForSave;
    this.rateMasterDtoForSaveUpdate.perUnit = this.enteredPerUnitForSave == null ? null : this.enteredPerUnitForSave == undefined ? null : this.enteredPerUnitForSave;
    this.rateMasterDtoForSaveUpdate.rateOnActWgt = this.rateOnActWgt == null ? null : this.rateOnActWgt == undefined ? null : this.rateOnActWgt;
    this.rateMasterDtoForSaveUpdate.basicCharge = this.enteredBasicRateForSave == null ? 0.0 : this.enteredBasicRateForSave == undefined ? 0.0 : this.enteredBasicRateForSave == "" ? 0.0 : this.enteredBasicRateForSave;
    this.rateMasterDtoForSaveUpdate.hamali = this.enteredHamaliForSave == null ? 0.0 : this.enteredHamaliForSave == undefined ? 0.0 : this.enteredHamaliForSave == "" ? 0.0 : this.enteredHamaliForSave;
    this.rateMasterDtoForSaveUpdate.gcCharge = this.enteredGCChargeForSave == null ? 0.0 : this.enteredGCChargeForSave == undefined ? 0.0 : this.enteredGCChargeForSave == "" ? 0.0 : this.enteredGCChargeForSave;
    this.rateMasterDtoForSaveUpdate.aocChargeAmt = this.enteredAOCChargeForSave == null ? 0.0 : this.enteredAOCChargeForSave == undefined ? 0.0 : this.enteredAOCChargeForSave == "" ? 0.0 : this.enteredAOCChargeForSave;
    this.rateMasterDtoForSaveUpdate.ddChargeAmt = this.enteredDDChargeForSave == null ? 0.0 : this.enteredDDChargeForSave == undefined ? 0.0 : this.enteredDDChargeForSave == "" ? 0.0 : this.enteredDDChargeForSave;
    this.rateMasterDtoForSaveUpdate.otherChgAmt = this.enteredOtherChargeForSave == null ? 0.0 : this.enteredOtherChargeForSave == undefined ? 0.0 : this.enteredOtherChargeForSave == "" ? 0.0 : this.enteredOtherChargeForSave;
    this.rateMasterDtoForSaveUpdate.riskChargeAmt = this.enteredRiskChargeForSave == null ? 0.0 : this.enteredRiskChargeForSave == undefined ? 0.0 : this.enteredRiskChargeForSave == "" ? 0.0 : this.enteredRiskChargeForSave;
    this.rateMasterDtoForSaveUpdate.fixedBy = this.enteredFixedBy == null ? 0.0 : this.enteredFixedBy == undefined ? 0.0 : this.enteredFixedBy == "" ? 0.0 : this.enteredFixedBy;
    this.rateMasterDtoForSaveUpdate.effectiveFromDate = this.selectedEeffectDate == null ? 0.0 : this.selectedEeffectDate == undefined ? 0.0 : this.selectedEeffectDate == "" ? 0.0 : this.selectedEeffectDate;
    this.rateMasterDtoForSaveUpdate.fixedDate = this.selectedFixedOn == null ? 0.0 : this.selectedFixedOn == undefined ? 0.0 : this.selectedFixedOn == "" ? 0.0 : this.selectedFixedOn;
    this.rateMasterDtoForSaveUpdate.receiptCharges = this.enteredRCChargeForSave == null ? 0.0 : this.enteredRCChargeForSave == undefined ? 0.0 : this.enteredRCChargeForSave == "" ? 0.0 : this.enteredRCChargeForSave;
    this.rateMasterDtoForSaveUpdate.serviceCharges = this.enteredSCChargeForSave == null ? 0.0 : this.enteredSCChargeForSave == undefined ? 0.0 : this.enteredSCChargeForSave == "" ? 0.0 : this.enteredSCChargeForSave;
    this.rateMasterDtoForSaveUpdate.destHamali = this.enteredDestHamaliForSave == null ? 0.0 : this.enteredDestHamaliForSave == undefined ? 0.0 : this.enteredDestHamaliForSave == "" ? 0.0 : this.enteredDestHamaliForSave;
    this.rateMasterDtoForSaveUpdate.subStationRate = this.subStationCheckStatus;

    this.rateMasterDtoForSaveUpdate.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDtoForSaveUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDtoForSaveUpdate.id = this.selectedAutoId;
    this.rateMasterDtoForSaveUpdate.mainStation = this.selectedDropDownMainStation;
    console.log("this.rateMasterDtoForSaveUpdate >> updated");
    console.log(this.rateMasterDtoForSaveUpdate);
    this.showSpinnerForAction = true;
    this.consignorService.updateConsignorAdminRateMaster(this.rateMasterDtoForSaveUpdate).subscribe(
      (response) => {
        console.log(response);
        if (response.status == "success") {
          swal("Success", " Consignor Rate Updated Successfully", "success");
          this.clearFields();

          //temp commented below refresh to avoid the slowness, should enable later
          //this.gettingPartyDataList();
          //this.gettingCommodityDataList();

        } else if (response.status == "duplicate") {
          swal("Duplicate Entry", " Entered type of rate is already exist, Please Verify!!!", "info");
        }
        this.showSpinnerForAction = false;

        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while Updating Consignor Rate Master details", "info");
      }, () => console.log('done');

  }

  confirmDelete() {
    if (this.selectedAutoId != "" || this.selectedAutoId != null || this.selectedAutoId != undefined || this.selectedAutoId != 0) {
      swal({
        title: "Confirmation",
        text: "Sure you want to Delete the Selected  Detials?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.deleteConsignorAdminRateMasterDetails();
        }
      });
    } else {
      swal("Mandatory", " No Details Found to delete, Please select details", "warning");
    }
  }

  deleteConsignorAdminRateMasterDetails() {
    this.rateMasterDtoForSaveUpdate = new RateMasterDto();
    this.rateMasterDtoForSaveUpdate.userName = this.userDataDtoReturnSession.userId;
    this.rateMasterDtoForSaveUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDtoForSaveUpdate.id = this.selectedAutoId;
    this.rateMasterDtoForSaveUpdate.mainStation = this.selectedDropDownMainStation;
    console.log("this.rateMasterDtoForSaveUpdate");
    console.log(this.rateMasterDtoForSaveUpdate);
    this.showSpinnerForAction = true;
    this.consignorService.deleteConsignorAdminRateMaster(this.rateMasterDtoForSaveUpdate).subscribe(
      (response) => {
        console.log(response);
        if (response.status == "success") {
          swal("Success", " Consignor Rate Deleted Successfully", "success");
          this.clearFields();
          // this.gettingPartyDataList();
          // this.gettingCommodityDataList();
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server problem occurred while Deleting Consignor Rate Master details", "info");
      }, () => console.log('done');
  }

  setIndex() {
    for (let i = 0; i < this.indexOptions.length; i++) {
      this.indexTA.push(this.indexOptions[i].label);
      this.indexPartySearchTA.push(this.indexOptions[i].label);
    }
  }

  indexDropDownListner(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelIndex = e.item;
    console.log('indexDropDownListner');
    console.log(this.modelIndex);
    $("#" + this.pageId + "indexId").val(this.modelIndex);
    if (this.modelIndex == 'ALL') {
      this.consignorNameOptions = [];
      this.consignorNameTA = [];
      this.consignorNamePartyDtoAllOption = new PartyMasterDto();
      $("#" + this.pageId + "consignorName").val('');
      this.consignorNamePartyDtoAllOption.consignorName = "ALL";
      this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
    } else {
      this.getConsignorDetailsList();
    }
  }

  indexDropDownListnerPartySearch(e: NgbTypeaheadSelectItemEvent) {
    this.modelIndexPartySearch = e.item;
    $("#" + this.pageId + "indexIdPartySearch").val(this.modelIndexPartySearch);
    this.getConsignorDetailsListPartySearch();
  }

  validateMainCommoditySelection() {
    this.isMainCommodityValid = false;
    this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();
    for (let i = 0; i < this.commodityListTA.length; i++) {
      if (this.commodityListTA[i].commodityName == this.enteredCommodityForSave) {
        this.isMainCommodityValid = true;
      }
    }
    if (this.isMainCommodityValid == false) {
      swal({
        title: "Not Allowed",
        text: "Selected Commodity " + this.enteredCommodityForSave + " is not valid, please check and select again!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  rateOnActWgtMethod(selectedUnit: string) {
    if (selectedUnit === 'ActualWeight') {
      this.viewRateOnActWgtField = true;
    } else {
      this.viewRateOnActWgtField = false;
      $("#" + this.pageId + "rateOnActWgt").val('');
      this.rateOnActWgt = null;
    }
  }

  getConsigneeDetailsList() {
    this.partyDtoConsigneeName = new PartyMasterDto();
    //this.selectedDestinationForConsigneeName = $("#" + this.pageId + "destinationId").val();
    this.partyDtoConsigneeName.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoConsigneeName.office = this.selectedDestinationForConsigneeName;
    this.partyDtoConsigneeName.mode = "specific";
  }

  getConsigneeDetails = () => {
    this.getConsigneeDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsigneeMaster(this.partyDtoConsigneeName).subscribe(
      (response) => {
        this.consigneeNameOptions = [];
        this.consigneeNameTA = [];
        this.consigneeNameOptions = response;
        this.partyDtoConsigneeNameAll = new PartyMasterDto();
        this.partyDtoConsigneeNameAll.consigneeName = "ALL";
        this.consigneeNameTA.push(this.partyDtoConsigneeNameAll);
        for (let i = 0; i < this.consigneeNameOptions.length; i++) {
          this.consigneeNameTA.push(this.consigneeNameOptions[i]);
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the consignee details", "info");
      }, () => console.log('done');
  }

  clickListnerForConsigneeNameList(event) {
    this.modelConsignee = null;
    this.modelConsignee = event.item;
    this.modelConsigneeName = null;
    this.modelConsigneeName = this.modelConsignee.consigneeName;
    this.enteredConsigneeId = 0;
    this.enteredConsigneeId = this.modelConsignee.consigneeId;
  }

  getRateDetails() {
    this.gettingCommodityDataList();
  }

  getPartyRateDetails() {
    this.gettingPartyDataList();
  }

  clickListnerForConsignorRptSearch(event) {
    //alert('ok');
    this.modelConsignorPartySearch = null;
    this.modelConsignorPartySearch = event.item;
    this.enteredConsignorIdPartySearch = 0;
    this.modelConsignorNamePartySearch = null;
    this.modelConsignorNamePartySearch = this.modelConsignorPartySearch.consignorName
    $("#" + this.pageId + "consignorNamePartySearch").val(this.modelConsignorPartySearch.consignorName);
    if ($("#" + this.pageId + "consignorNamePartySearch").val() == null || $("#" + this.pageId + "consignorNamePartySearch").val() == undefined ||
      $("#" + this.pageId + "consignorNamePartySearch").val() == "" || $("#" + this.pageId + "consignorNamePartySearch").val() == "All") {
      this.enteredConsignorIdPartySearch = 0;
      //alert('ok 1');
    } else {
      this.enteredConsignorIdPartySearch = this.modelConsignorPartySearch.consignorId;
      //alert('ok 2' + this.enteredConsignorIdPartySearch);
    }
  }

  //Click Listener For Source main Station
  clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelMainBookingSource = e.item;
    $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
    this.getAgentDetailList(this.modelMainBookingSource.mainBookStations);
  }

  getMainBookingSourceDataDetails() {
    this.agentDtoSourceStation = new AgentDetailsDto();
    this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
  }
  getMainBookingSourceDetails() {
    this.getMainBookingSourceDataDetails();
    this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
      (response) => {
        this.mainBookingSourceTA = [];
        this.agentDtoOptionSourceStation = [];
        this.agentDtoOptionSourceStation = response;
        for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
          this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
      },
      () => console.log('done');
  }
}
