<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center'
                                style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}destination" type="text" class="form-control"
                                                        [(ngModel)]="modelDestination"
                                                        [ngbTypeahead]="searchDestination"
                                                        [resultFormatter]="formatterDestination"
                                                        [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Station" />

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="viewSourceAll">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}mainBookingSourceId"
                                                            [(ngModel)]="modelMainBookingSource"
                                                            [ngbTypeahead]="searchMainBookingSource"
                                                            [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource"
                                                            (focus)="focusMainBookingSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Main Source.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        &nbsp;
                                        <div class="col-sm-12 col-md-12">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}saveBtn" #saveBtn
                                                    (click)="getSummaryOfDestinationStocksRptMethod()">Search</button>
                                                <button type="submit" class="btn btn-dark m-r-10"
                                                    id="{{pageId}}clearBtn" (click)="clearDetails()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 vl">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}destinationStocksRptTable"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsdestinationStocksRpt"
                                            [dtTrigger]="dtTriggerdestinationStocksRpt">

                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Total LRs</th>
                                                    <th>No Of Articles</th>
                                                    <th>Act Wt</th>
                                                    <th>Chg Wt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let destinationStocksRptSummary of destinationStocksRptSummaryList ">
                                                    <td style='font-size: 13px; font-weight: bold;'>
                                                        <a style="color: green; cursor: pointer;" (click)="getDetailsOfDestinationStocksRptInfo(destinationStocksRptSummary)">
                                                            <u>{{ destinationStocksRptSummary.status }}</u>
                                                        </a>
                                                    </td>
                                                    <td>{{ destinationStocksRptSummary.totalLrs }}</td>
                                                    <td>{{ destinationStocksRptSummary.totalArticles }}</td>
                                                    <td>{{ destinationStocksRptSummary.actualWeight }}</td>
                                                    <td>{{ destinationStocksRptSummary.chargedWeight }}</td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-md-12  vl p-t-10">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="box-body">
                                        <table datatable style="width: 100%;" id="{{pageId}}destinationStocksRptDetailsTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestinationStocksRptDetails" [dtTrigger]="dtTriggerDestinationStocksRptDetails">

                                            <thead>
                                                <tr>
                                                    <th [hidden]=true>Source</th>
                                                    <th>Lr No.</th>
                                                    <th>Consignee</th>
                                                    <th>Consignor</th>
                                                    <th>Destination</th>
                                                    <th>Commodity</th>
                                                    <th>Bkg Date</th>
                                                    <th>Entry Date</th>
                                                    <th>Unldg Date</th>
                                                    <th>Art.</th>
                                                    <th>Act Wt</th>
                                                    <th>Chg Wt</th>
                                                    <th>ToPay</th>
                                                    <th>Paid</th>
                                                    <th>Stocks At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let destinationStocksRptData of destinationStocksRptDetailsList ">
                                                    <td [hidden]=true>{{ destinationStocksRptData.source }}</td>
                                                    <td>{{ destinationStocksRptData.lrNumber }}</td>
                                                    <td>{{ destinationStocksRptData.consigneeName }}</td>
                                                    <td>{{ destinationStocksRptData.consignorName }}</td>
                                                    <td>{{ destinationStocksRptData.destination }}</td>
                                                    <td>{{ destinationStocksRptData.commodityName }}</td>
                                                    <td>{{ destinationStocksRptData.bookingDateStr }}</td>
                                                    <td>{{ destinationStocksRptData.enteredDateStr }}</td>
                                                    <td>{{ destinationStocksRptData.date }}</td>
                                                    <td>{{ destinationStocksRptData.articles }}</td>
                                                    <td>{{ destinationStocksRptData.actualWeight }}</td>
                                                    <td>{{ destinationStocksRptData.chargedWeight }}</td>
                                                    <td>{{ destinationStocksRptData.toPay }}</td>
                                                    <td>{{ destinationStocksRptData.paid }}</td>
                                                    <td>{{ destinationStocksRptData.stocksAt }}</td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td [hidden]=true></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>