import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { Subject, Subscription, merge } from 'rxjs';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { HireslipService } from 'src/app/dataService/hireslip-service';

@Component({
    selector: 'app-trips-of-agent-booking',
    templateUrl: './trips-of-agent-booking.component.html',
    styleUrls: ['./trips-of-agent-booking.component.css']
})
export class TripsOfAgentBookingComponent implements OnInit {
    getDataTripsOfAgentBookingDataTable: any;
    tripsOfAgentBookingDataList: any;
    onDestroyUnsubscribtionTripsOfAgentBooking: Subscription;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerTripsOfAgentBooking: Subject<any> = new Subject();
    dataTable: any;
    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    hireslipDto: HireSlipDto = new HireSlipDto();
    lrDtoDetailForEdit: LRDto = new LRDto();
    dtOptionsTripsOfAgentBooking: any;
    pageId = "toabc";
    selectedLoaderHead: any = null;
    selectedBayNo: any = null;
    unldDt: NgbDateStruct;
    selectedUnldDt: any;
    selectedUnldTime: any;
    closeResult: string;
    selectedAgentNameUpdate: any;
    selectedVehicleUpdate: any;
    lrDto: LRDto = new LRDto();
    tripsOfAgentBookingDataSelected: any;
    bayFlow = false;

    //labour dropdown
    labourHeadNameOption: Array<HireSlipDto> = [];
    hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;
    //bay dropdown
    bayNoOption: Array<HireSlipDto> = [];
    hireSlipDtoBayNo: HireSlipDto = new HireSlipDto();
    public modelDropDownBayNo: any;
    focusDropDownBayNoTA$ = new Subject<string>();
    searchDropDownInputFieldBayNo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownBayNoTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bayNoOption
                : this.bayNoOption.filter(v => v.bayNo.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownBayNo = (x: { bayNo: string }) => x.bayNo;
    viewActionForBarcodeScan = false;

    constructor(private dashboardService: DashboardService, private router: Router, public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService, private modalService: NgbModal, private hireslipService: HireslipService) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.bayFlowInLR != null && this.userDataDtoReturnSession.bayFlowInLR) {
                this.bayFlow = true;
            }
            this.setLabourHeadNameDetailsList();
            this.setBayNo();
            if (this.userDataDtoReturnSession.enableBarcodeGen != null && this.userDataDtoReturnSession.enableBarcodeGen) {
                this.viewActionForBarcodeScan = true;
            }
        }

    }

    ngOnInit(): void {

        this.dtOptionsTripsOfAgentBooking = {
            dom: 'Bfrtip',
            // language: {
            //     search: "_INPUT_",
            //     searchPlaceholder: "Search..."
            // },
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 170,
            "scrollCollapse": true,
            "paging": false,
            "info": true
            // destroy:true
        }
    }
    // ngOnDestroy(): void {
    //     this.dtTriggerTripsOfAgentBooking.unsubscribe();
    // }
    //for redirect to other page starts
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerTripsOfAgentBooking.next();
    }

    gridReconifgureOnReloadBtn() {
        this.getScheduledLocalTripsDetailsList();
    }

    getScheduledLocalTripsDetailsList() {
        this.getScheduledLocalTripsDetails();
        this.showSpinnerForAction = true;
        this.dashboardService.getScheduledLocalTrips(this.hireslipDto).subscribe(
            (response) => {
                console.log(response);
                this.tripsOfAgentBookingDataList = [];
                //$("#"+this.pageId+"tripsOfAgentBkgTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.tripsOfAgentBookingDataList = response;
                } else {
                    swal("Alert", "No Data Found", "warning");
                }
                // this.dtTriggerTripsOfAgentBooking.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Trips Of Agent Booking Details", "warning");
            }, () => console.log('done');
    }

    getScheduledLocalTripsDetails() {
        this.hireslipDto = new HireSlipDto();
        this.hireslipDto.enteredby = this.userDataDtoReturnSession.userId;
        this.hireslipDto.status = "Pending";
        this.hireslipDto.branch = this.userDataDtoReturnSession.office;
        this.hireslipDto.bayFlow = this.bayFlow;
        this.hireslipDto.localTripTrns = this.userDataDtoReturnSession.localTripTrns;
    }

    checkInTrips(tripsOfAgentBookingData) {
        this.lrDtoDetailForEdit = new LRDto();
        this.lrDtoDetailForEdit.tripId = tripsOfAgentBookingData.tripId;
        localStorage.clear();
        localStorage.setItem('tripOfAgntBkgLocalStorage',
            JSON.stringify(tripsOfAgentBookingData));

        // if (this.viewActionForBarcodeScan) {
        console.log(tripsOfAgentBookingData);
        console.log(tripsOfAgentBookingData.enableBarcodeGen);
        if (tripsOfAgentBookingData.enableBarcodeGen) {
            this.router.navigate(['/dashboard/bookingTripDetailsBarcode']);
        } else {
            this.router.navigate(['/dashboard/bookingTripDetails']);
        }

    }

    getLabourHeadNameDetails() {
        this.hireSlipDtoLabourHeadName = new HireSlipDto();
        this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainAdminStation;
        this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.getLabourHeadNameDetails();
        this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
            (response) => {
                console.log("loader : " + response);
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                    }
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    };

    setBayNo() {
        this.bayNoOption = [];
        for (let i = 1; i < 13; i++) {
            this.hireSlipDtoBayNo = new HireSlipDto();
            this.hireSlipDtoBayNo.bayNo = "Bay " + i;
            this.bayNoOption.push(this.hireSlipDtoBayNo);
        }
    }

    openUpdateChallanPopUp(contentUpdateChallan, tripsOfAgentBookingData) {
        this.selectedAgentNameUpdate = null, this.selectedVehicleUpdate = null;
        this.selectedAgentNameUpdate = tripsOfAgentBookingData.fromStation;
        this.selectedVehicleUpdate = tripsOfAgentBookingData.vehicleNumber;
        this.tripsOfAgentBookingDataSelected = tripsOfAgentBookingData;

        this.modalService.open(contentUpdateChallan, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getConfirmLrDetails() {
        this.lrDto = new LRDto();
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.selectedLoaderHead = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
        this.lrDto.unloaderHead = this.selectedLoaderHead;
        this.selectedBayNo = $("#" + this.pageId + "dropDownInputFieldBayNo").val();
        this.lrDto.bayNo = this.selectedBayNo;
        this.selectedUnldDt = $("#" + this.pageId + "unldDt").val();
        this.lrDto.unLoadingDate = this.selectedUnldDt;
        this.selectedUnldTime = $("#" + this.pageId + "unldTime").val();
        this.lrDto.fromTime = this.selectedUnldTime;
        this.lrDto.tripId = this.tripsOfAgentBookingDataSelected.tripId;
    }

    confirmLrs() {
        if (($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == "") ||
            ($("#" + this.pageId + "dropDownInputFieldBayNo").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldBayNo").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldBayNo").val() == "") ||
            ($("#" + this.pageId + "unldDt").val() == null) || ($("#" + this.pageId + "unldDt").val() == "") ||
            ($("#" + this.pageId + "unldTime").val() == null) ||
            ($("#" + this.pageId + "unldTime").val() == "")) {
            swal("Mandatory Field", "Please select the mandatory fields", "warning");
            return false;
        } else {
            swal({
                title: "Update Unloading Details",
                text: "Sure U want to update the unloading details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.getConfirmLrDetails();
                    this.showSpinnerForAction = true;
                    this.hireslipService.updateLocalBkgUnloadingDetails(this.lrDto).subscribe(
                        (response) => {

                            if (response.status == "Success") {
                                swal("Sucessfully updated", "Details updated successfully", "success");
                            } else {
                                swal("Alert", "Error while updating the details", "warning");
                            }
                            this.getScheduledLocalTripsDetailsList();
                            this.showSpinnerForAction = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            this.showSpinnerForAction = false;
                            swal("Error", "Server Error While updating the details", "warning");
                        }, () => console.log('done');
                }
            });
        }
    }
}
